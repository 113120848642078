import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialMetadataState} from "../interfaces/initialStates";
import {Country, Term, Title} from "../interfaces/backend";
import {getTermAction} from "../interfaces/actions";

const initialState: initialMetadataState = {
    countries: [],
    titles: [],
    statuses: [],
    term: {} as Term,
    serverDate: new Date()
}

export const metadataSlice = createSlice(
    {
        name: "metadata",
        initialState: initialState,
        reducers:
            {
                setCountries: (state: initialMetadataState, action: PayloadAction<Array<Country>>) => {
                    state.countries = action.payload
                },
                getCountries: () => {

                },
                getTitles: () => {
                },
                getTerm: (state, action: getTermAction) => {
                },
                setTerm: (state: initialMetadataState, action: PayloadAction<Term>) => {
                    state.term = action.payload;
                },
                setTitles: (state: initialMetadataState, action: PayloadAction<Array<Title>>) => {
                    state.titles = action.payload
                },
                getPublicData: () => {
                },
                setServerDate: (state: initialMetadataState, action: PayloadAction<Date>) => {
                    state.serverDate = action.payload
                },
            }
    }
)

export const {
    setCountries,
    getCountries,
    getTitles,
    setTitles,
    getPublicData,
    setServerDate,
    getTerm,
    setTerm
} = metadataSlice.actions

export default metadataSlice.reducer