import {Box, FormControl, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {primaryColor} from "../../theme";

interface UserAuthFormProps {
    formContent: ReactNode;
    formTitle?: string;
    pageTitle: string;
}

const UserAuthForm = (props: UserAuthFormProps) => {
    const {formContent, formTitle, pageTitle} = props;
    return (
        <Box
            sx={{
                paddingX: {xs: "8px", sm: 'unset'},
                marginY: "20px",
                marginX: "auto",
                marginLeft: {md: "unset"},
                width: {xs: "100%", md: "96%"},
                maxWidth: {xs: "555px", sm: "450px"},
            }}
        >
            {pageTitle && <Typography
                sx={{
                    fontSize: {xs: "2.6rem", md: "2.8rem", lg: "5rem"},
                    fontWeight: 700,
                    color: primaryColor.textTopHeader,
                    textAlign: {xs: "center", md: "left"},
                    paddingY: {xs: "30px", lg: "20px"},
                }}
            >
                {pageTitle}
            </Typography>}

            <Typography
                sx={{
                    fontSize: {xs: "1.8rem", md: "2rem"},
                    fontWeight: 400,
                    color: primaryColor.textTopHeader,
                    textAlign: "start",
                }}
            >
                {formTitle}
            </Typography>

            <FormControl
                sx={{
                    width: "100%",
                }}
            >
                {formContent}
            </FormControl>
        </Box>
    );
};

export default UserAuthForm;
