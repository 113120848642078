import {MyAccountState} from "../interfaces/initialStates";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MyMessage, OrderHistory, User} from "../interfaces/backend";
import {initialOrderHistory, initialOrderResult, initialUser} from "./initialCartValues";

const initialState: MyAccountState = {
    orderHistory: initialOrderHistory,
    passwordChangedSuccessfully: false,
    users: [],
    isDataChanged: false,
    newUser: initialUser,
    isNewUserChanged: false,
    myMessages: [],
    orderDetails: initialOrderResult,
    isConfirmationOpen: false,
    sortUsersBy: "userName",
    searchUserByInput: "",
    filteredUsers: [],
    cancelOrderId: ""
}

export const myAccountSlice = createSlice(
    {
        name: "myAccount",
        initialState: initialState,
        reducers: {
            changePassword: (state, action) => {
            },
            setPasswordChangedSuccessfully: (state: MyAccountState, action: PayloadAction<boolean>) => {
                state.passwordChangedSuccessfully = action.payload
            },
            getOrderHistory: (state: MyAccountState, action) => {
            },
            getOrderDetails: (state: MyAccountState, action) => {
            },
            setOrderDetails: (state: MyAccountState, action) => {
                state.orderDetails = action.payload
            },
            cancelOrder: (state: MyAccountState, action) => {
            },
            setCancelOrderId: (state: MyAccountState, action) => {
                state.cancelOrderId = action.payload
            },
            setOrderHistory: (state: MyAccountState, action: PayloadAction<OrderHistory>) => {
                state.orderHistory = action.payload
            },
            getUsers: () => {
            },
            setUsers: (state: MyAccountState, action: PayloadAction<User[]>) => {
                state.users = action.payload.map((u) => ({
                    ...u,
                    userName: u.firstName + ' ' + u.lastName,
                }))
                state.isDataChanged = true
            },
            setChanged: (state: MyAccountState) => {
                state.isDataChanged = false
            },
            setConfirmationOpen: (state: MyAccountState, action: PayloadAction<boolean>) => {
                state.isConfirmationOpen = action.payload
            },
            setUserTitle: (state: MyAccountState, action: PayloadAction<string>) => {
                state.isNewUserChanged = true
                state.newUser = {
                    ...state.newUser,
                    title: action.payload
                }
            },
            setNewUserInput: (state: MyAccountState, action: PayloadAction<{
                target: EventTarget & HTMLInputElement | { name: string, value: string | boolean }
            }>) => {
                state.isNewUserChanged = true
                state.newUser = {
                    ...state.newUser,
                    [action.payload.target.name]: action.payload.target.value,
                }
            },
            setNewUserRole: (state: MyAccountState, action: PayloadAction<string>) => {
                state.isNewUserChanged = true
                state.newUser = {
                    ...state.newUser,
                    isAdmin: action.payload === 'keyUser',
                }
            },
            addUser: (state: MyAccountState, action) => {
            },
            patchUser: (state: MyAccountState, action) => {
            },
            deleteUser: (state: MyAccountState, action) => {
            },
            setMyMessages: (state: MyAccountState, action: PayloadAction<MyMessage[]>) => {
                state.myMessages = action.payload
            },
            getMyMessages: (state: MyAccountState, action) => {
            },
            sendMyMessage: (state: MyAccountState, action) => {
            },
            addMyMessage: (state: MyAccountState, action: PayloadAction<MyMessage>) => {
                state.myMessages.unshift(action.payload)
            },
            addMyOldMessages: (state: MyAccountState, action: PayloadAction<MyMessage[]>) => {
                const allMessageCodes: string[] = state.myMessages.map(m => m.code)
                const newMessages = action.payload.filter(i => allMessageCodes.indexOf(i.code) === -1)
                if (newMessages.length) {
                    state.myMessages = [
                        ...state.myMessages,
                        ...newMessages
                    ]
                }
            },
            makeMyMessageRead: (state: MyAccountState, action: PayloadAction<{ code: string }>) => {
            },
            setSortUsersBy: (state: MyAccountState, action: PayloadAction<string>) => {
                state.sortUsersBy = action.payload
            },
            setSearchUserByInput: (state: MyAccountState, action: PayloadAction<string>) => {
                state.searchUserByInput = action.payload
            },
            setFilteredUsers: (state: MyAccountState, action: PayloadAction<User[]>) => {
                state.filteredUsers = action.payload
            }
        }
    }
)

export const {
    changePassword,
    getOrderHistory,
    setOrderHistory,
    setOrderDetails,
    getOrderDetails,
    setPasswordChangedSuccessfully,
    cancelOrder,
    getUsers,
    setUsers,
    setChanged,
    setUserTitle,
    setNewUserInput,
    setNewUserRole,
    addUser,
    patchUser,
    deleteUser,
    getMyMessages,
    sendMyMessage,
    addMyMessage,
    addMyOldMessages,
    setMyMessages,
    makeMyMessageRead,
    setConfirmationOpen,
    setSortUsersBy,
    setSearchUserByInput,
    setFilteredUsers,
    setCancelOrderId,
} = myAccountSlice.actions

export default myAccountSlice.reducer
