import {useAppDispatch, useAppSelector, useAppSettings} from "../../hook";
import * as settings from "../../settings";
import {Article} from "../../interfaces/backend";
import {useEffect} from "react";
import {getArticles} from "../../reducers/start";
import {Box, useMediaQuery} from "@mui/material";
import theme from "../../theme";

import ArticleBox from "./article";
import ArticlesCarousel from "../common/carousel/articles";


const Articles = () => {
    const dispatch = useAppDispatch()
    const perPage = settings?.max_articles
    const articles: Array<Article> = useAppSelector(state => state.start.articles)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const {backgroundImageUrl} = useAppSettings()

    useEffect(() => {
        dispatch(getArticles({perPage}))
    }, [dispatch])

    const imageBoxStyling = !mobileView ?
        {
            width: '100%',
            height: '920px',
            backgroundSize: 'cover',
        }
        :
        {
            width: '100%',
            minHeight: '268px',
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'flex-end',
            backgroundRepeat: 'no-repeat',
        }


    const articleBoxes = articles.map((item, key) => {
        return (
            <ArticleBox key={key} article={item} imageBoxStyling={imageBoxStyling}/>
        )
    });


    return (
        <>
            {articles.length > 0 ? (
                !mobileView ? <ArticlesCarousel articleBoxes={articleBoxes}/> : articleBoxes
            ) : (
                <Box
                    sx={{
                        backgroundImage: 'url(' + backgroundImageUrl + ')',
                        ...imageBoxStyling
                    }}
                >
                </Box>
            )}
        </>
    )
}

export default Articles