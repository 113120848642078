import * as React from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ContactPhone from "./contactPhone";

export default function PasswordResetConfirm() {
    const {t} = useTranslation('startPage')
    return (
        <>
            <Typography>
                {t('confirmText')}
            </Typography>
            <ContactPhone/>
        </>
    )
}