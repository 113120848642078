import {callHttp, callHttpWithoutSpinner} from "../utils/api";
import {del, get, patch, post} from "../utils/httpUtil";
import {put, takeEvery} from "redux-saga/effects";
import {openErrorSnack, openSuccessSnack} from "../reducers/snackbar";
import {ADD_MY_MESSAGE, CHANGE_PASSWORD, MY_MESSAGE_MAKE_READ, MY_MESSAGES, USER, USERS} from "../constants/api";
import i18next from "i18next";
import {
    addUserAction,
    cancelOrderAction,
    deleteUserAction,
    filteredOrderHistoryAction,
    getMyMessagesAction,
    getOrderDetailsAction,
    makeMyMessageReadAction,
    patchUserAction,
    saveNewPassword,
    sendMyMessageAction
} from "../interfaces/actions";
import {MyMessage, User} from "../interfaces/backend";
import {
    addMyOldMessages,
    getUsers,
    setMyMessages,
    setOrderDetails,
    setOrderHistory,
    setPasswordChangedSuccessfully,
    setUsers
} from "../reducers/myAccount";
import {cancelOrderOrInquiry, getOrderOrInquiryDetails, getOrdersOrInquiriesHistory} from "./helpers/orderSaga";

i18next.loadNamespaces('messages');

export function* changePassword(action: saveNewPassword) {
    try {
        const {oldPassword, newPassword} = action.payload
        yield callHttp(post, CHANGE_PASSWORD, {
            oldPassword,
            newPassword,
        });
        yield put(openSuccessSnack(i18next.t('messages:successChangedPassword')));
        yield put(setPasswordChangedSuccessfully(true))
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getOrderHistory(action: filteredOrderHistoryAction) {
    try {
        const ordersHistory = yield* getOrdersOrInquiriesHistory(action);
        yield put(setOrderHistory(ordersHistory))

    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getOrderDetails(action: getOrderDetailsAction) {
    try {

        const orderDetails = yield* getOrderOrInquiryDetails(action);

        yield put(setOrderDetails(orderDetails))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* cancelOrder(action: cancelOrderAction) {

    try {
        const {orderHistory, message} = yield* cancelOrderOrInquiry(action);

        yield put(setOrderHistory(orderHistory))
        yield put(openSuccessSnack(i18next.t(message.key, message.options)));

    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getUsersSaga() {
    try {
        const allUsers: User[] = yield callHttp(get, USERS);
        const userList = Object.values(allUsers);
        yield put(setUsers(userList))
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* addUser(action: addUserAction) {
    const {user} = action.payload;
    const {id, ...newUser} = user
    try {
        yield callHttp(post, USERS, newUser);
        yield put(getUsers());
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* patchUser(action: patchUserAction) {
    const {user} = action.payload;
    try {
        yield callHttp(patch, USER(user.id), user)
        yield put(getUsers());
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* deleteUser(action: deleteUserAction) {
    try {
        const {id} = action.payload;
        yield callHttp(del, USER(id));
        yield put(getUsers())
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getMyMessagesSaga(action: getMyMessagesAction) {
    const {fromMessage, perPage} = action.payload
    try {
        const myMessages: MyMessage[] = yield callHttp(get, MY_MESSAGES({fromMessage: fromMessage, perPage: perPage}))
        if (myMessages.length > 0) {
            if (fromMessage) {
                yield put(addMyOldMessages(myMessages))
            } else {
                yield put(setMyMessages(myMessages))
            }
        }
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* sendMyMessageSaga(action: sendMyMessageAction) {
    const {message} = action.payload
    try {
        yield callHttpWithoutSpinner(post, ADD_MY_MESSAGE, {message: message})
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* makeMyMessageRead(action: makeMyMessageReadAction) {
    const {code} = action.payload

    try {
        yield callHttpWithoutSpinner(post, MY_MESSAGE_MAKE_READ(code))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export default function* myAccountSaga() {
    yield takeEvery('myAccount/cancelOrder', cancelOrder)
    yield takeEvery('myAccount/changePassword', changePassword)
    yield takeEvery('myAccount/getOrderHistory', getOrderHistory)
    yield takeEvery('myAccount/getOrderDetails', getOrderDetails)
    yield takeEvery('myAccount/getUsers', getUsersSaga)
    yield takeEvery('myAccount/addUser', addUser)
    yield takeEvery('myAccount/patchUser', patchUser)
    yield takeEvery('myAccount/deleteUser', deleteUser)
    yield takeEvery('myAccount/getMyMessages', getMyMessagesSaga)
    yield takeEvery('myAccount/sendMyMessage', sendMyMessageSaga)
    yield takeEvery('myAccount/makeMyMessageRead', makeMyMessageRead)
}
