import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {setSortUsersBy} from "../../../reducers/myAccount";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {useTranslation} from "react-i18next";
import {User} from "../../../interfaces/backend";
import {defaultFonts, primaryColor} from "../../../theme";
import {sortUsers, useUserTableData} from "./userHelper";
import UserTableMobileItem from "./userTableMobileItem";
import AddNewUserButton from "./addNewUserButton";
import SortByItemsMenu from "../SortByItemsMenu";
import StyledUserSearch from "./StyledSearchField";
import CustomPagination from "../../../pages/ListingPage/Pagination";
import {userManagementColumns} from "../../../constants/table";
import {useNavigate} from "react-router";

const UserTableMobile = () => {
    const users = useAppSelector(state => state.myAccount.users)

    const {t} = useTranslation("myAccount");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const sortUsersBy = useAppSelector(state => state.myAccount.sortUsersBy)
    const searchUserByInput = useAppSelector(state => state.myAccount.searchUserByInput)
    const filteredUsers = useAppSelector(state => state.myAccount.filteredUsers)

    const {numberOfActiveUsers} = useUserTableData(users);
    const [usersToShow, setUsersToShow] = useState<User[]>([]);
    const [page, setPage] = React.useState(0);

    const sortedUsers = usersToShow ? sortUsers(usersToShow, sortUsersBy) : sortUsers(users, sortUsersBy);

    const rowsPerPage = 10;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageUsers = sortedUsers.slice(startIndex, endIndex)
    let paginationArray: number[] = useMemo(() => {
        const totalPages = Math.ceil(usersToShow.length / rowsPerPage);
        return Array.from({length: totalPages}, (_, i) => i + 1);
    }, [usersToShow.length]);

    const handleChangePage = (newPage: number) => {
        setPage(newPage - 1);
    };

    useEffect(() => {
        if (filteredUsers && filteredUsers.length !== 0) {
            setUsersToShow(filteredUsers);
        } else if (!searchUserByInput) {
            if (sortUsersBy) {
                setUsersToShow(sortUsers(users, sortUsersBy));
                setPage(0);
            } else {
                setUsersToShow(users);
            }
        } else {
            setUsersToShow([]);
        }
    }, [filteredUsers, users, searchUserByInput, sortUsersBy]);

    const menuItemClick = (itemId: string) => {
        dispatch(setSortUsersBy(itemId));
    };
    const selectedSortValue = useAppSelector(state => state.myAccount.sortUsersBy)

    return (
        <Box
            sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
                padding: {
                    md: "15px 25px",
                },
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: {
                    md: primaryColor.userManagementTableMobileBackground,
                },
                color: primaryColor.myAccountColorLimitText,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "inherit",
                    fontSize: defaultFonts,
                    paddingY: "15px",
                    paddingX: {xs: "30px", md: '0px'},
                }}
            >
                <Typography>
                    {t("allUsers{{count}}", {count: users?.length})}
                </Typography>
                <Typography>
                    {t("activeUsers{{count}}", {count: numberOfActiveUsers})}
                </Typography>
            </Box>

            <AddNewUserButton
                buttonContent={t("addUser") as string}
                onClick={() => navigate({
                    pathname: '/my-account/management/new-user',
                })
                }
                fillColor={primaryColor.addUserMobileIcon}
            />

            <Box
                sx={{
                    width: "100%",
                    padding: {
                        xs: "0px 25px",
                        md: "unset",
                    },
                }}
            >
                <StyledUserSearch/>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    justifyContent: {
                        xs: 'center',
                        md: 'flex-end',
                    },
                    mt: '25px',
                    mb: '15px',
                    display: "flex",
                    paddingX: {
                        xs: '25px',
                        md: 'unset'
                    },
                    alignItems: "center",
                }}
            >
                <SortByItemsMenu
                    handleSortMenuItemClick={menuItemClick}
                    sortValue={selectedSortValue}
                    sortByItems={userManagementColumns}
                    translationNamespace="myAccount"

                />
            </Box>

            {currentPageUsers?.map((user, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <UserTableMobileItem user={user}/>
                </Box>
            ))}
            <Box sx={{
                mt: '60px',
            }}>
                {paginationArray.length > 1 &&
                    <Box
                        sx={{
                            display: "flex", justifyContent: "center",
                            marginTop: "20px",
                            mb: '90px'
                        }}
                    >
                        <CustomPagination
                            pagination={paginationArray}
                            currentPage={page + 1}
                            setSelectedPage={handleChangePage}
                        />
                    </Box>}
            </Box>
        </Box>
    );
};

export default UserTableMobile;
