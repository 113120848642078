import {Box, Container, Grid} from "@mui/material";
import Logo from "../logo";
import {primaryColor} from "../../../../theme";
import LeftIcons from "./leftIcons";
import {useAppSelector, useAppSettings} from "../../../../hook";
import {useHeaderImage} from "../../contexts/HeaderImageContext";
import AuthDynamicComponent from "../../../accessControl/AuthDynamicComponent";
import MainMenu from "./mainMenu";

export default function DesktopView() {
    const showHeaderImage = useHeaderImage();
    const {backgroundImageUrl} = useAppSettings();
    const backgroundBlurred = useAppSelector(state => state.styleControls.backgroundBlurred)

    return (
        <>
            <Box id="bottomHeader" sx={{
                paddingY: '28px',
                backgroundColor: primaryColor.headerColor,
                zIndex: 3,
                position: 'relative'
            }}>
                <Container maxWidth={'2xl'}>
                    <Grid container
                          justifyContent='space-between'
                    >
                        <Grid item sm={2}>
                            <Logo mobileView={false}/>
                        </Grid>
                        <Grid item sm={8} container
                              alignContent='stretch'
                              alignItems='center'
                              justifyContent='space-evenly'
                              columnSpacing={1}
                              rowSpacing={{sm: 1, md: 0}}
                        >
                            <AuthDynamicComponent
                                children={<MainMenu/>}
                            />
                        </Grid>
                        <Grid item
                              sm={2}
                              alignItems='center'
                              justifyContent='end'
                              display={"flex"}
                        >
                            <LeftIcons/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {showHeaderImage &&
                <Box sx={{
                    height: '155px',
                    width: '100%',
                    background: `url('${backgroundImageUrl}') no-repeat 0% 0% / 100%`,
                    filter: backgroundBlurred ? 'blur(25px)' : 'unset',
                    zIndex: 1
                }}/>
            }
        </>
    )
}
