import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {defaultFonts, primaryColor} from "../../../theme";
import {Cart, CheckoutStageType} from "../../../interfaces/backend";
import VoucherArea from "./VoucherArea";
import CostArea from "./CostArea";
import CartTableDesktop from "../../Cart/Table/CartTableDesktop";
import CheckoutCartTableMobile from '../../Cart/Table/CheckoutCartTableMobile';

export interface CheckoutSummaryProps {
    handleClick: () => void,
    enableBuyButton: boolean,
    checkoutCart: Cart,
    validate: () => boolean
}

const CheckoutSummary = ({handleClick, enableBuyButton, checkoutCart, validate}: CheckoutSummaryProps) => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const {t} = useTranslation('checkoutPage')

    return (
        !mobileView ? <Box
                sx={{
                    mb: '50px',
                    backgroundColor: primaryColor.backgroundOverlay,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 'fit-content',
                        maxWidth: {xs: '100%', lg: '70%'},
                        margin: 'auto',

                        padding: {
                            xs: '0',
                            md: '90px 40px',
                            lg: '100px 0px'
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: defaultFonts,
                            color: primaryColor.shoppingCartTitle,
                            fontWeight: 300,
                            // mt: '93px',
                        }}
                    >{checkoutCart.itemsQuantity} {t('items')}</Typography>

                    {checkoutCart.itemsQuantity > 0 ?
                        <>
                            <CartTableDesktop stage={CheckoutStageType.Checkout} data={checkoutCart}/>
                            <Box
                                sx={{
                                    marginTop: '100px',
                                    color: primaryColor.shoppingCartTitle,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    maxWidth: '100%',
                                }}
                            >
                                <VoucherArea/>
                                <CostArea
                                    checkoutCart={checkoutCart}
                                    handleClick={handleClick}
                                    enableBuyButton={enableBuyButton}
                                    validate={() => validate()}
                                />
                            </Box>
                        </>
                        : null
                    }
                </Box>
            </Box>
            :
            <>
                {checkoutCart.itemsQuantity > 0 ?
                    <CheckoutCartTableMobile data={checkoutCart}/>
                    : null
                }
            </>
    )
}

export default (CheckoutSummary)
