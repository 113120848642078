import SvgIcon from '@mui/material/SvgIcon';
import React from "react";
import {primaryColor} from "../../../theme";
import {IconComponentProps} from '../../../interfaces/general';


const AccountIcon = (props: IconComponentProps) => {
    const {isActive = false, sx = {}} = props;

    return (
        <SvgIcon width="17" height="21" viewBox="0 0 17 21" fill="none" sx={sx}>
            {isActive ?
                <>
                    <path
                        d="M10.7567 7.39844C10.1913 7.77617 9.52673 7.97778 8.84684 7.97778C7.93514 7.97778 7.06078 7.61561 6.4161 6.97094C5.77143 6.32627 5.40926 5.4519 5.40926 4.5402C5.40926 3.86031 5.61087 3.19569 5.9886 2.63038C6.36632 2.06508 6.9032 1.62447 7.53134 1.36429C8.15947 1.10411 8.85065 1.03604 9.51748 1.16867C10.1843 1.30131 10.7968 1.62871 11.2776 2.10947C11.7583 2.59022 12.0857 3.20274 12.2184 3.86956C12.351 4.53639 12.2829 5.22757 12.0227 5.85571C11.7626 6.48384 11.322 7.02072 10.7567 7.39844Z"
                        fill={primaryColor.orangeHoverColor} stroke={primaryColor.orangeHoverColor}
                        strokeWidth="2.12321"/>
                    <path
                        d="M15.6397 19.2083C15.6278 19.2201 15.6118 19.2268 15.595 19.2268H2.09745C2.08069 19.2268 2.06462 19.2201 2.05277 19.2083C2.04092 19.1964 2.03426 19.1804 2.03426 19.1636C2.03426 17.357 2.75195 15.6243 4.02944 14.3468C5.30693 13.0693 7.03958 12.3516 8.84623 12.3516C10.6529 12.3516 12.3855 13.0693 13.663 14.3468C14.9405 15.6243 15.6582 17.357 15.6582 19.1636C15.6582 19.1804 15.6515 19.1964 15.6397 19.2083Z"
                        fill={primaryColor.orangeHoverColor} stroke={primaryColor.orangeHoverColor}
                        strokeWidth="2.12321"/>
                </>
                :
                <>
                    <path
                        d="M10.7391 7.39844C10.1738 7.77617 9.50915 7.97778 8.82926 7.97778C7.91756 7.97778 7.0432 7.61561 6.39853 6.97094C5.75386 6.32627 5.39168 5.4519 5.39168 4.5402C5.39168 3.86031 5.59329 3.19569 5.97102 2.63038C6.34875 2.06508 6.88562 1.62447 7.51376 1.36429C8.14189 1.10411 8.83308 1.03604 9.4999 1.16867C10.1667 1.30131 10.7792 1.62871 11.26 2.10947C11.7408 2.59022 12.0681 3.20274 12.2008 3.86956C12.3334 4.53639 12.2654 5.22757 12.0052 5.85571C11.745 6.48384 11.3044 7.02072 10.7391 7.39844Z"
                        stroke="white" strokeWidth="2.12321"/>
                    <path
                        d="M15.6221 19.2083C15.6103 19.2201 15.5942 19.2268 15.5774 19.2268H2.07987C2.06311 19.2268 2.04704 19.2201 2.03519 19.2083C2.02334 19.1964 2.01668 19.1804 2.01668 19.1636C2.01668 17.357 2.73437 15.6243 4.01186 14.3468C5.28935 13.0693 7.022 12.3516 8.82865 12.3516C10.6353 12.3516 12.3679 13.0693 13.6454 14.3468C14.9229 15.6243 15.6406 17.357 15.6406 19.1636C15.6406 19.1804 15.634 19.1964 15.6221 19.2083Z"
                        stroke="white" strokeWidth="2.12321"/>
                </>
            }
        </SvgIcon>
    );
}

export default AccountIcon;