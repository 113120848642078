import {Box, Button, Grid, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import SearchBox from "../../components/common/searchBox";
import {Link, useNavigate} from "react-router-dom";
import {SearchSuggestionElement} from "../../interfaces/backend";

interface TopContentProps {
    numberContent: number;
    title: string;
    description: string;
}

export const commonStyles = {
    card: {
        width: '100%',
        margin: 'auto',
        maxWidth: {
            xs: '400px',
            sm: '550px',
            md: '600px',
            lg: '710px'
        },
    },

    buttons: {
        // height: '100%',
        width: "100%",
        margin: "auto",
        // textAlign: "center",
        maxWidth: {
            xs: "390px",
            sm: "300px",
        },
    }
}

export const TopContent = (props: TopContentProps) => {
    return (
        <Grid
            container
            justifyContent="flex-end"
            alignItems={{
                xs: "flex-end",
                sm: 'center'
            }}
            columnSpacing={1}
            sx={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: "flex-end",
                alignItems: {
                    xs: "center",
                    md: "flex-start"
                },
                height: {
                    xs: '200px',
                    md: '320px',
                    lg: '275px',
                },
                width: "100%",
                margin: '0 auto 10px',
            }}
        >

            <Grid
                item
                xs={3}
                sm={2}
                md={3}
                sx={{
                    padding: "0px",
                    height: {
                        xs: '100px',
                        sm: "120px",
                        md: "200px",
                    }
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: "10rem",
                            md: "13rem",
                            lg: "16rem",
                            xl: "17rem",
                        },
                        color: primaryColor.sparePartsTop,
                        alignItems: {
                            xs: "flex-start"
                        },
                        fontWeight: "700",
                        marginTop: {
                            xs: "-10px",
                            sm: "-5px",
                            md: "-30px"
                        },
                        lineHeight: "normal",
                    }}
                >
                    {props.numberContent}
                </Typography>
            </Grid>

            <Grid item
                  xs={9}
                  sm={10}
                  md={9}
                  sx={{
                      paddingY: '25px',
                  }}>

                <Typography
                    sx={{
                        color: primaryColor.sparePartsTop,
                        fontSize: {
                            xs: "1.8rem",
                            sm: "2.5rem",
                            md: "3rem",
                        },
                        fontWeight: "300",
                        textAlign: 'left',
                        overflowWrap: "break-word"
                    }}
                >
                    {props.title}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                xl={9}
                sx={{
                    textAlign: {
                        xs: 'center',
                        lg: 'left'
                    },
                    marginTop: {
                        md: '-15px',
                        lg: '-60px'
                    }
                }}>

                <Typography sx={{
                    color: primaryColor.sparePartsText,
                    fontSize: {
                        xs: "1.5rem",
                        sm: "2rem"
                    },
                    paddingX: {
                        md: '30px',
                        lg: 'unset'
                    }
                }}>
                    {props.description}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface MiddleContentProps {
    handleInputChange: (searchValue: string) => void;
    handleOnSelect: (item: SearchSuggestionElement) => void;
    placeHolder: string;
}

export const MiddleContent = (props: MiddleContentProps) => {
    const {handleInputChange, handleOnSelect, placeHolder} = props;


    return (
        <Box
            sx={{
                paddingY: "15px",
            }}
        >
            <SearchBox
                onInputChange={handleInputChange}
                onSelect={handleOnSelect}
                styleText={{
                    "& ::placeholder": {
                        color: primaryColor.sparePartsSearchPlaceholderTextColor,
                    },
                }}
                placeholder={placeHolder as string}
                styledPaper={{
                    maxHeight: "290px",
                }}
            />
        </Box>
    );
};

interface NavigateButtonProps {
    content: string;
    navigateTo: string;
}

export const NavigateButton = (props: NavigateButtonProps) => {
    const {content, navigateTo} = props;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(navigateTo);
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            sx={{/*flex: "1 1 auto"*/width: "100%"}}
        >
            {content}
        </Button>
    );
};


interface NavigateBoxProps {
    buttonProps: NavigateButtonProps
    variant: "button" | "image";
    imageSrc: string;
}

export const NavigateBox = (props: NavigateBoxProps) => {
    const {buttonProps, variant, imageSrc} = props;

    switch (variant) {
        case "button":
            return (
                <Box sx={{paddingY: "7px", ...commonStyles.buttons}}>
                    <NavigateButton
                        content={buttonProps.content}
                        navigateTo={buttonProps.navigateTo}
                    />
                </Box>
            );
        case "image":
            return (
                <Box>
                    <Link to={buttonProps.navigateTo}>

                        <Typography
                            sx={{
                                paddingY: "10px",
                                textAlign: 'center',
                                fontSize: '2rem',
                            }}
                        >
                            {buttonProps.content}
                        </Typography>

                        <Box
                            component="img"
                            src={imageSrc}
                            alt={buttonProps.content}
                            sx={{
                                margin: '5px',
                                width: '270px',
                                height: 'auto'
                            }}
                        />
                    </Link>
                </Box>
            );
        default:
            return null;
    }
};
