import {Box, Typography, useMediaQuery} from "@mui/material";
import theme from "../../../../theme";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hook";
import {Machine, ProductDetailsPageContent} from "../../../../interfaces/backend";
import ContextMenu from "../../../viewer/contextMenu";
import * as settings from "../../../../settings";
import {ClickedNode} from "./helper";
import {setContextMenuOpen} from "../../../../reducers/viewerReducer";
import {getProductDetailsByCode} from "../../../../reducers/productdetails";
import {useTranslation} from "react-i18next";
import Viewer from "../../../viewer";

const MachineViewer = () => {
    const dispatch = useAppDispatch();
    const smView = useMediaQuery(theme.breakpoints.down("sm"));
    const modelKeyProperty = settings.model_key_property;
    const {t} = useTranslation("productPage");
    const smallStyle = {
        alignItems: "center",
        flexDirection: "column-reverse",
    };

    const selectedMachine: Machine = useAppSelector(
        (state) => state.machine.machine
    );
    const urn = selectedMachine.product.image3d?.url as string;
    const productDetails: ProductDetailsPageContent = useAppSelector(
        (state) => state.productDetails.productDetails
    );
    const isMenuOpen = useAppSelector((state) => state.viewer.isMenuOpen);

    const [viewerContainerRef, setViewerContainerRef] = useState<
        React.RefObject<HTMLDivElement>
    >(React.createRef<HTMLDivElement>());
    const [selectedNode, setSelectedNode] = useState("");


    useEffect(() => {
        if (selectedNode) {
            dispatch(getProductDetailsByCode({code: selectedNode}));
        }
    }, [dispatch, selectedNode]);

    useEffect(() => {
        if (productDetails.code && productDetails.code === selectedNode) {
            dispatch(setContextMenuOpen(true));
        } else {
            dispatch(setContextMenuOpen(false));
        }
    }, [dispatch, productDetails.code, selectedNode]);

    const handleOnClickModel = (clickedNode: ClickedNode) => {
        if (clickedNode) {
            setSelectedNode(clickedNode.attributeValue);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                background: "#141414",
                ...(smView && smallStyle),
            }}
        >
            <Box
                sx={{
                    height: smView ? "330px" : "fit-content",
                }}
            >
                {!isMenuOpen ? (
                    smView && (
                        <Typography
                            sx={{
                                marginTop: "36px",
                                fontSize: "1.5rem",
                                fontWeight: "400px",
                                opacity: 0.4,
                                color: "#fff",
                                width: "226px",
                                textAlign: "center",
                            }}
                        >
                            {t("SelectProductIn3DModel")}{" "}
                        </Typography>
                    )
                ) : (
                    <ContextMenu viewerContainerRef={viewerContainerRef}/>
                )}
            </Box>

            {urn && (
                <Viewer
                    urn={urn}
                    modelKeyProperty={modelKeyProperty}
                    onClickModel={handleOnClickModel}
                    viewerContainerRef={viewerContainerRef}
                    setViewerContainerRef={setViewerContainerRef}
                />
            )}
        </Box>
    );
};
export default MachineViewer;
