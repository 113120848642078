import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import TemplateBox from "../index";
import {csvReader} from "./fileReader";
import {getBatchProducts} from "../../../reducers/quickOrder";
import {useAppDispatch} from "../../../hook";
import {QuickOrderSimpleProduct} from "../../../interfaces/general";
import {getSelectedLng} from "../../../utils/localization";
import {secondaryFonts} from "../../../theme";


const UploadFile = () => {
    const {t} = useTranslation("quickOrder");
    const dispatch = useAppDispatch();
    const [uploadedItems, setUploadedItems] = useState<QuickOrderSimpleProduct[]>([]);
    const csvFileName = '/images/csvFileExample/example.' + getSelectedLng() + '.csv';

    useEffect(() => {
        if (uploadedItems?.length) {
            const load = {
                products: uploadedItems,
                // isCSVuploaded: true,
                isInitialLoad: false
            };
            dispatch(getBatchProducts(load));
        }
    }, [dispatch, uploadedItems]);

    const handleUploadClick = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.addEventListener("change", handleFileUpload);
        input.click();
    }

    const handleFileUpload = (event: Event): any => {
        const input = event.target as HTMLInputElement;
        const files = input.files;
        if (!files || files.length === 0) {
            return;
        }

        csvReader<QuickOrderSimpleProduct>(files, (load) => {
            if (load.isCSVuploaded) {
                setUploadedItems(load.products);
            }
        });
    }

    const fileSections = [
        {
            title: "downloadFile",
            href: csvFileName,
            imgSrc: "/images/icons/quickOrderDownloadFile.svg",
            alt: "xls",
        },
        {
            title: "uploadFile",
            onClick: handleUploadClick,
            imgSrc: "/images/icons/quickOrderUploadFile.svg",
            alt: "upload",
        },
    ];

    const uploadFileBoxContent = (
        <Grid
            container
            item
            sx={{
                height: 'fit-content',
                width: '90%',
                margin: '0 auto',
                paddingY: {xs: '5px', md: '10px', lg: '25px'}
            }}
        >
            {fileSections.map((section, index) =>
                <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    sx={{
                        height: '100%',
                        minHeight: {xs: 'fit-content', md: '120px'},
                        textTransform: 'uppercase',
                        cursor: section.onClick ? "pointer" : "default",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: secondaryFonts,
                            textAlign: 'center',
                            margin: '15px auto',
                            width: {md: '55%', lg: '100%'},
                            minHeight: {md: '50px', lg: 'unset'},
                        }}>
                        {t(section.title)}
                    </Typography>

                    <Box
                        component={section.href ? "a" : "div"}
                        href={section.href}
                        download={section.href ? true : undefined}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            paddingY: '5px',
                        }}
                        onClick={section.onClick}
                    >
                        <Box
                            component={"img"}
                            src={section.imgSrc}
                            alt={section.alt}
                            sx={{
                                height: {xs: "25px", md: "35px", lg: "40px"},
                            }}
                        />
                    </Box>

                </Grid>
            )}
        </Grid>
    );

    return (
        <TemplateBox
            title={t("uploadFileTitle")}
            description={t("uploadFileDescription")}
            relatedContent={uploadFileBoxContent}
            isDynamic={true}
        />
    );
}

export default UploadFile;