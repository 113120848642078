import {Link, styled, SxProps, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";


interface ProductLinkProps {
    linkTo: string | undefined;
    text: string;
    sx?: SxProps;
}

interface StyledLinkProps {
    to: string;
    children: React.ReactNode;
}

const StyledLink = styled(({to, children, ...otherProps}: StyledLinkProps) => (
    <Link href={to} {...otherProps}>{children}</Link>
))(({theme}) => ({
    "&:not(:hover)": {
        color: 'inherit'
    }
}));

const ProductLink = (props: ProductLinkProps) => {
    const {linkTo, text, sx} = props;

    return (
        <Typography sx={{color: primaryColor.shoppingCartTitle, wordBreak: 'break-word', fontWeight: 400, ...sx}}>
            {linkTo ? (
                <StyledLink to={`/product/${linkTo}`}>{text}</StyledLink>
            ) : (
                <>{text}</>
            )}
        </Typography>
    );
};

export default ProductLink;
