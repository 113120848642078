import {Box, Button, Typography} from "@mui/material";
import OrderStatusBar from "../OrderStatusBar";
import {defaultFonts, primaryColor} from "../../../../../theme";
import ItemHeader from "../../../data/mobile/ItemHeader";
import ConfirmationDialog, {ConfirmationContent} from "../../../../../components/common/Dialogs/ConfirmationDialog";
import {setCancelOrderId, setConfirmationOpen} from "../../../../../reducers/myAccount";
import {ItemsCount} from "../MyOrderItem";
import {getStatus, OrderItemProp, useMyOrderCommon} from "../../../../../components/myData/MyOrders/orderUtils";
import {countTotalItems} from "../../../../../utils/productFunctions";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../../../hook";
import {useNavigate} from "react-router";


const MyOrderItemTablet = (props: OrderItemProp) => {
    const {order, setSelectedOrder, cancelOrder} = props;
    const {t} = useTranslation("myAccount");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        isConfirmationOpen,
        CANCELLABLE_ORDERS,
        style,
        getOperation
    } = useMyOrderCommon(props);


    return (
        <Box color={primaryColor.accountTextColor} fontWeight={700} p={{xs: '30px', md: '26px 20px'}} mb={'12px'}
             bgcolor={primaryColor.ordersListBackgroundColor} fontSize={defaultFonts}
        >
            {isConfirmationOpen && (
                <ConfirmationDialog
                    onConfirm={() => {
                        !!cancelOrder && cancelOrder();
                        dispatch(setConfirmationOpen(false));
                    }}
                    handleCancel={() => {
                        dispatch(setConfirmationOpen(false));
                    }}
                    modalContent={<ConfirmationContent dataContent={t(getOperation('SureToCancel'))}/>}
                />
            )}

            <Box sx={style.element}>
                <Typography sx={style.description}>{t(getOperation('Status'))}:</Typography>
                <Typography sx={{...style.value, ml: {md: "6px"}}}>{order.status.name}</Typography>
            </Box>

            <OrderStatusBar isRejected={order.status.code === 'rejected'}
                            percent={getStatus(order.status.code)}/>

            <Box display={'flex'} flexDirection={'column'} fontSize={defaultFonts}>
                <ItemHeader order={order}/>
                {setSelectedOrder &&
                    <Box sx={{
                        paddingTop: '16px',
                        alignItems: 'center',
                    }}>
                        <ItemsCount length={countTotalItems(order.items)}/>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '17px',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setSelectedOrder(order)
                                    navigate({
                                        pathname: `/my-account/my-orders/${order.orderId}`,
                                    });
                                }}
                                sx={{
                                    width: '60%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                {t(getOperation('View'))}
                            </Button>
                            {cancelOrder && CANCELLABLE_ORDERS.findIndex(code => code === order.status.code) !== -1 &&
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        dispatch(setCancelOrderId(order.orderId))
                                        dispatch(setConfirmationOpen(true))
                                    }}
                                    sx={{
                                        marginTop: '8px',
                                        width: '60%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        backgroundColor: primaryColor.cancellationButtonBackground
                                    }}
                                >
                                    {t(getOperation('Cancel'))}
                                </Button>}
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}
export default MyOrderItemTablet