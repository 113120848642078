import {FormControl, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {primaryColor} from "../../../../theme";
import {ArrowDropDown} from "@mui/icons-material";
import {getSelectedLng, lngList} from "../../../../utils/localization";

const SelectLanguageDesktop = () => {
    const {i18n, t} = useTranslation("common");

    return (
        <FormControl>
            <Select
                value={getSelectedLng()}
                onChange={async (e: SelectChangeEvent) => {
                    const newLanguage = e.target.value as string
                    await i18n.changeLanguage(newLanguage).then(() => {
                        localStorage.setItem("i18nextLng", newLanguage);
                        window.location.reload();
                    });
                }}
                IconComponent={(props) =>
                    <ArrowDropDown
                        {...props}
                        sx={{
                            color: primaryColor.whiteText + "!important"
                        }}
                    />
                }
                MenuProps={{
                    MenuListProps: {
                        sx: {
                            paddingY: 0
                        }
                    },
                    PaperProps: {
                        sx: {
                            marginTop: "5px",
                            borderRadius: "unset",
                        },
                    },
                }}
                inputProps={{
                    sx: {
                        fontSize: {xs: '1.2rem'},
                        paddingY: 0
                    }
                }}
            >
                {lngList().map(key =>
                    <MenuItem
                        key={key}
                        value={key}
                        sx={{
                            paddingX: 0,
                            justifyContent: "center"
                        }}
                    >
                        <Typography variant={'subtitle2'}>
                            {t("language" + key)}
                        </Typography>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default SelectLanguageDesktop;