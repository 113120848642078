import React from "react";
import {Box, Typography} from "@mui/material";
import {formatMoney} from "../../utils/localization";
import {defaultFonts, primaryColor} from "../../theme";
import {StyledDivider} from "../Cart/styleElements";
import {CartItem} from "../../interfaces/backend";
import {useAppSettings} from "../../hook";
import {calculateCartPriceDetails} from "../../utils/productFunctions";

interface OrderItemsListProps {
    items: CartItem[];
    withPrices?: boolean;
}

const OrderItemsList = (props: OrderItemsListProps) => {
    const {items, withPrices = true} = props;
    const {showDiscountPerItem} = useAppSettings();

    const calculatePrice = (item: CartItem) => {
        const {lastPrice} = calculateCartPriceDetails(item, showDiscountPerItem);
        return lastPrice * item.quantity
    }

    return (
        <>
            <StyledDivider sx={{
                marginTop: "15px", marginBottom: "15px",
                borderColor: primaryColor.orderCompletedDividerColor,
            }}/>

            {items.map((item, index) =>
                <Box key={index} sx={{
                    paddingY: "5px",
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    "& .MuiTypography-root": {
                        fontSize: defaultFonts,
                        fontWeight: 400,
                    }
                }}>
                    <Typography sx={{maxWidth: "80%"}}>
                        {item.quantity} x {item.name}
                    </Typography>
                    {withPrices && <Typography>{formatMoney(calculatePrice(item))}</Typography>}
                </Box>
            )}
        </>
    );
};

export default OrderItemsList;
