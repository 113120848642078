import React from 'react';
import {useAppDispatch} from "../../hook";
import {deleteAttachment, getMessageFile} from "../../reducers/cart";
import {Box, BoxProps, styled, Typography, TypographyProps} from "@mui/material";
import {File} from "../../interfaces/backend";
import {primaryColor} from "../../theme";
import {SxProps} from "@mui/system/styleFunctionSx";

interface UploadFilePreviewProps {
    canDelete: boolean,
    filesToShow: File[],
    style?: UploadFilesStyleProps
}

export interface UploadFilesStyleProps {
    fileNameBox: SxProps,
    fileName: SxProps
}

const Container = styled((props: BoxProps) => (
    <Box {...props}  />
))(({theme}) => ({
    marginBottom: '3px'
}));

const FileContainerBox = styled((props: BoxProps) => (
    <Box {...props}  />
))(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'space-between'
}));

const FileNameBox = styled((props: BoxProps) => (
    <Box {...props}  />
))(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    marginRight: '15px',
    justifyContent: 'space-between',
    [theme.breakpoints.down("md")]: {
        marginRight: '10px'
    },
}));

const FileName = styled((props: TypographyProps) => (
    <Typography variant={'h20'} {...props}  />
))(({theme}) => ({
    color: primaryColor.textTopHeader,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    marginLeft: '10px',
    wordBreak: 'break-all',
    [theme.breakpoints.down("md")]: {
        fontSize: '1.5rem'
    },
}));

const DeleteIconBox = styled((props: BoxProps) => (
    <Box
        component={'img'}
        src='/images/icons/delete-icon-new.svg'
        {...props}
    />
))(({theme}) => ({
    cursor: 'pointer'
}));

const FileIconBox = styled((props: BoxProps) => (
    <Box
        component={'img'}
        src={'/images/icons/icon-pdf-document.svg'}
        height='26px'
        {...props}
    />
))(({theme}) => ({
    cursor: 'pointer'
}));


export default function UploadFilePreview(props: UploadFilePreviewProps) {

    const dispatch = useAppDispatch()
    const handleOnClick = (file: File) => {
        dispatch(getMessageFile({url: file.url, name: file.name}))
    };
    const handleDeletePreview = (file: string) => {
        dispatch(deleteAttachment({file}))
    }
    const {filesToShow, canDelete, style} = props

    return (
        <Container>
            {filesToShow.map((item: File, index: number) =>
                (<FileContainerBox key={index}>
                    <FileNameBox sx={style?.fileNameBox} onClick={() => handleOnClick(item)}>
                        <FileIconBox/>
                        <FileName sx={style?.fileName}>{item.name}</FileName>
                    </FileNameBox>
                    {canDelete &&
                        <DeleteIconBox
                            onClick={() => {
                                item.file && handleDeletePreview(item.file)
                            }}
                        />}
                </FileContainerBox>))}
        </Container>

    )
}

