import {CartState} from "../interfaces/initialStates";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Cart, InquiryCart, OrderOption, ShippingAddress, Term,} from "../interfaces/backend";
import {
    initialCart,
    initialOrderResult,
    initialShippingAddress,
    initialStripePaymentIntents,
    initialStripePublicData
} from "./initialCartValues";

const storedComment = localStorage.getItem('comment') || '';
const storedNumber = localStorage.getItem('internalOrderNumber') || '';

const initialState: CartState = {
    cart: initialCart,
    checkoutCart: initialCart,
    shippingAddress: initialShippingAddress,
    shippingOptions: [],
    orderOptions: [],
    paymentMethods: [],
    orderResult: initialOrderResult,
    orderCompleted: false,
    isNewAddress: false,
    isEditable: false,
    terms: [],
    comment: storedComment,
    internalOrderNumber: storedNumber,
    isStripeExist: false,
    isElementsExist: false,
    paymentIsLoading: false,
    stripePaymentIntents: initialStripePaymentIntents,
    stripePublicData: initialStripePublicData
};

export const cartSlice = createSlice(
    {
        name: "cart",
        initialState: initialState,
        reducers: {
            setTerms: (state: CartState, action) => {
                const parsed = action.payload.map((t: Term) => {
                    if (t.label?.match('LINK].*[/LINK]')) {
                        let labelLink
                        const labelLinkArray = t.label.match('LINK].*[/LINK]')
                        if (labelLinkArray) {
                            labelLink = labelLinkArray[0].slice(5, -6)
                        }

                        const startArray = t.label.match(/.*\[LINK\]/)
                        let start
                        if (startArray) {
                            start = startArray[0].slice(0, -6)
                        }

                        const endArray = t.label.match(/\[\/LINK\].*/)
                        let end
                        if (endArray) {
                            end = endArray[0].slice(7)
                        }

                        return {
                            ...t,
                            labelLink: labelLink,
                            start: start,
                            end: end,
                            checked: false,
                        };
                    } else return t;
                })

                return {
                    ...state,
                    terms: parsed,
                };

            },

            checkTerm: (state: CartState, action: PayloadAction<number>) => {
                const terms = [...state.terms];
                terms[action.payload].checked = !terms[action.payload].checked;
                state.terms = terms
            },
            getTerms: (state, action) => {
            },

            setCart: (state: CartState, action: PayloadAction<Cart | InquiryCart>) => {
                state.cart = action.payload as Cart;
                state.orderCompleted = false
            },
            setCheckoutCart: (state: CartState, action: PayloadAction<Cart>) => {
                state.checkoutCart = action.payload
                state.orderCompleted = false
            },
            getCart: () => {
            },
            getCheckoutCart: () => {
            },
            deleteCartItem: (state, action) => {
            },
            updateCartItem: (state, action) => {
            },
            setShippingOptions: (state: CartState, action) => {
                state.shippingOptions = action.payload
            },
            getShippingOptions: () => {
            },
            getPaymentMethods: () => {
            },
            setPaymentMethods: (state: CartState, action) => {
                state.paymentMethods = action.payload
            },
            setInternalOrderNumber: (state: CartState, action: PayloadAction<string | null>) => {
                state.internalOrderNumber = action.payload || "";
                action.payload === null
                    ? localStorage.removeItem('internalOrderNumber')
                    : localStorage.setItem('internalOrderNumber', action.payload)
            },
            setComment: (state: CartState, action: PayloadAction<string | null>) => {
                state.comment = action.payload || "";
                action.payload === null
                    ? localStorage.removeItem('comment')
                    : localStorage.setItem('comment', action.payload)
            },
            setStripeExist: (state: CartState, action) => {
                state.isStripeExist = action.payload
            },
            setElementsExist: (state: CartState, action) => {
                state.isElementsExist = action.payload
            },
            setPaymentIsLoading: (state: CartState, action) => {
                state.paymentIsLoading = action.payload
            },
            setStripePaymentIntents: (state: CartState, action) => {
                state.stripePaymentIntents = action.payload
            },
            setStripePublicData: (state: CartState, action) => {
                state.stripePublicData = action.payload
            },
            addCartItem: (state, action) => {
            },
            addCartMultipleItems: (state, action) => {
            },
            setOrderResult: (state: CartState, action) => {
                state.orderResult = action.payload
                state.orderCompleted = true
            },
            order: (state, action) => {
            },
            addShippingOption: (state, action) => {
            },
            getStripePaymentIntents: () => {
            },
            getStripePublicData: () => {
            },
            addPaymentMethod: (state, action) => {
            },
            setCartShippingAddress: (state: CartState) => {
                state.isEditable = false
            },
            addCartShippingAddress: (state, action) => {
            },
            setShippingAddress: (state: CartState, action: PayloadAction<ShippingAddress>) => {
                state.shippingAddress = action.payload
            },
            setOrderOptions: (state: CartState, action: PayloadAction<Array<OrderOption>>) => {
                state.orderOptions = action.payload
            },
            setDefaultShippingAddress: (state: CartState, action: PayloadAction<ShippingAddress[]>) => {
                const result = action.payload?.filter((a) => a.isDefault) || [];

                state.shippingAddress = state.cart.shippingAddress ? state.cart.shippingAddress
                    : result.length
                        ? result[0]
                        : initialShippingAddress

                state.isNewAddress = state.cart.shippingAddress
                    ? state.cart.shippingAddress.saveAddress !== undefined
                        ? state.cart.shippingAddress.saveAddress
                        : false
                    : false

                state.isEditable =
                    !(state.cart.shippingAddress &&
                        state.cart.shippingAddress.saveAddress !== undefined)
            },
            addCartOrderOption: (state, action) => {
            },
            getOrderOptions: () => {
            },
            deleteAttachment: (state, action) => {
            },
            uploadAttachments: (state, action) => {
            },
            getMessageFile: (state, action) => {
            },
            getDefaultShippingAddress: () => {
            },
            removeCheckoutVoucher: (state, action) => {
            },
            addCheckoutVoucher: (state, action) => {
            },
            setEventSourceCartItemsQuantity: (state: CartState, action: PayloadAction<number>) => {
                state.cart.itemsQuantity = action.payload
            }
        },
    }
)
export const {
    checkTerm,
    setTerms,
    getTerms,
    setCart,
    getCart,
    deleteCartItem,
    updateCartItem,
    setShippingOptions,
    getShippingOptions,
    getPaymentMethods,
    setPaymentMethods,
    setComment,
    setStripeExist,
    setElementsExist,
    setPaymentIsLoading,
    setInternalOrderNumber,
    addCartItem,
    addCartMultipleItems,
    setOrderResult,
    order,
    addShippingOption,
    addPaymentMethod,
    setCartShippingAddress,
    addCartShippingAddress,
    setShippingAddress,
    setOrderOptions,
    addCartOrderOption,
    getOrderOptions,
    deleteAttachment,
    uploadAttachments,
    getMessageFile,
    setCheckoutCart,
    getCheckoutCart,
    setDefaultShippingAddress,
    getDefaultShippingAddress,
    removeCheckoutVoucher,
    addCheckoutVoucher,
    setStripePaymentIntents,
    setStripePublicData,
    getStripePaymentIntents,
    getStripePublicData,
    setEventSourceCartItemsQuantity
} = cartSlice.actions

export default cartSlice.reducer
