import {Box, Button, DialogActions, Typography} from "@mui/material";
import * as React from "react";
import {ReactElement} from "react";
import CommonDialog from "./commonDialog";
import {defaultFonts, primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";


export const ConfirmationContent = (props: {
    dataContent: string;
}): ReactElement => {
    const {dataContent} = props;
    return (
        <Typography variant="h6">
            {dataContent}
        </Typography>
    );
};


export interface ConfirmationDialogProps {
    handleCancel: Function;
    onConfirm: Function;
    modalContent: ReactElement;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    const {handleCancel, onConfirm, modalContent} = props;
    const {t} = useTranslation("common");
    const buttonsStyle = {
        width: "100%",
        fontSize: defaultFonts,
        marginY: "5px",
        cursor: "pointer",
        minWidth: 'unset',
    };

    return (
        <CommonDialog
            title={t("cancellationConfirmation")}
            closeState={() => handleCancel()}
        >
            <Box sx={{marginY: '10px'}}>
                {modalContent}
            </Box>
            <DialogActions sx={{padding: '25px 0 0'}}>
                <Button
                    onClick={() => handleCancel()}
                    sx={{
                        color: primaryColor.linkColor,
                        "&:hover": {
                            color: primaryColor.badgeColor,
                        },
                        justifyContent: "flex-start",
                        ...buttonsStyle,
                    }}
                >
                    {t("not")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onConfirm()}
                    sx={buttonsStyle}
                >
                    {t("yes")}
                </Button>
            </DialogActions>
        </CommonDialog>
    );
};

export default ConfirmationDialog;