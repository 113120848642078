import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from '../../../theme';
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {
    setCheckedBoxesList,
    setCloseFilter,
    setExpandedTreeNodes,
    setPricesFilter,
    setSelectedCategory,
    setSortValue
} from "../../../reducers/sparePart";
import SortByItemsMenu from "../../../components/myData/SortByItemsMenu";
import {productSortValues} from "../../../constants/table";
import ArrowButton from "../../../components/common/ArrowButton";

interface FilterHeaderProps {
    isDisabled: boolean;
}

export default function FilterHeader(props: FilterHeaderProps) {

    const {t} = useTranslation('searchPage')
    const {isDisabled} = props;
    const dispatch = useAppDispatch()

    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

    const {selectedCategory, pricesFilter, isFilterClose, checkedBoxesList} = useAppSelector(state => state.sparePart)
    const isAnyFilterSet: boolean = !!(pricesFilter.minPrice || pricesFilter.minPrice || !!Object.keys(checkedBoxesList).length || selectedCategory);


    const [selectedSortValue, setSelectedSortValue] = useState<string>("sortAZ")
    useEffect(() => {
        dispatch(setSortValue(selectedSortValue))
    }, [selectedSortValue])


    const clear = () => {
        dispatch(setCheckedBoxesList({}))
        dispatch(setPricesFilter({minPrice: undefined, maxPrice: undefined}))
        dispatch(setSelectedCategory(""))
        dispatch(setExpandedTreeNodes([]))

    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '35px',
                textOverflow: 'hidden',
                justifyContent: {xs: 'space-between', md: 'flex-start'},
                width: '100%',
                marginX: 'auto',
                maxWidth: isFilterClose ? {xs: '430px', sm: '390px', lg: 'unset'} : 'unset',

            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: !isDisabled ? 'pointer' : undefined,
                    opacity: !isDisabled ? 1 : 0.5,
                    alignItems: 'center',
                }}
                onClick={() => !isDisabled && dispatch(setCloseFilter(!isFilterClose))}
            >
                <Typography
                    sx={{
                        fontSize: {xs: '1.8rem', lg: '2.4rem'},
                        color: isFilterClose ? primaryColor.drawerText : primaryColor.filterText,
                        fontWeight: {xs: 400, md: 300},
                    }}
                >
                    {t('filter')}
                </Typography>

                <ArrowButton
                    direction={isFilterClose ? "right" : "left"}
                    color={isFilterClose ? primaryColor.arrowButtonColorSlider : primaryColor.badgeColor}
                    width={!isTablet ? 26 : (isMobile ? 16 : 23)}
                    sx={{marginLeft: {xs: '10px', md: '30px', opacity: '0.8'}}}
                />
            </Box>

            {isAnyFilterSet && <Typography
                color={primaryColor.machinePageText}
                sx={{
                    display: isFilterClose ? 'none' : 'flex',
                    transitionDuration: 'visibility 500ms',
                    cursor: 'pointer',
                    color: primaryColor.clearFilter,
                    opacity: 0.8,
                    fontSize: {xs: '1.8rem', lg: '2.4rem'},
                    fontWeight: {xs: 400, md: 300},
                    height: 'fit-content',
                    marginLeft: 'auto'
                }}
                onClick={() => clear()}
            >
                {t('filterClear')}
            </Typography>}

            {isMobile && isFilterClose &&
                <SortByItemsMenu
                    handleSortMenuItemClick={setSelectedSortValue}
                    sortValue={selectedSortValue}
                    sortByItems={productSortValues}
                    translationNamespace="searchPage"
                />
            }

        </Box>
    )
}