import * as stripeJs from "@stripe/stripe-js";
import {loadStripe, StripePaymentElementOptions} from "@stripe/stripe-js";
import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {Appearance, StripeElementLocale,} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {getStripePaymentIntents, getStripePublicData} from "../../../reducers/cart";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {Stripe} from "@stripe/stripe-js/types/stripe-js";
import {getSelectedLng} from "../../../utils/localization";

//const stripePromise = loadStripe("pk_test_51OCfQ3ChwSjc6H4KZb19OYfkgVfFtkal0tGUftGH1VnxWYx340WATAlSRz0N9xh7KXcPwfDjQoygxGCuunO4qdSd00iCkDqj29");

interface StripePaymentProps {
    validate: () => boolean,

}

export default function StripePayment(props: StripePaymentProps) {
    const [clientSecret, setClientSecret] = useState("");
    const {validate} = props
    const dispatch = useAppDispatch()
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const stripePaymentIntents = useAppSelector(state => state.cart.stripePaymentIntents)
    const stripePublicData = useAppSelector(state => state.cart.stripePublicData)


    useEffect(() => {
        dispatch(getStripePaymentIntents())
        dispatch(getStripePublicData())
    }, []);

    useEffect(() => {

        if ((stripePublicData.publicKey && !stripePromise) || clientSecret !== stripePaymentIntents.clientSecret) {
            setStripePromise(
                loadStripe(stripePublicData.publicKey,
                    {
                        locale: getSelectedLng() as StripeElementLocale,
                        stripeAccount: stripePublicData.accountId
                    })
            )
        }
        setClientSecret(stripePaymentIntents.clientSecret)

    }, [stripePublicData, stripePaymentIntents]);

    const appearance: Appearance = {
        theme: 'night',
        labels: 'floating'
    };

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: "accordion"
    }

    const options: stripeJs.StripeElementsOptions = {
        clientSecret,
        appearance,
    };
    return (
        <>
            {clientSecret &&
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm validate={() => validate()} paymentElementOptions={paymentElementOptions}/>
                </Elements>
            }
        </>
    )
}