import {Box, Grid, SxProps} from "@mui/material"
import * as React from "react";
import {useState} from "react";
import theme, {primaryColor} from "../../theme";
import {ProductDetailsProps} from "../../interfaces/backend";
import SwiperComponent from "./SwiperComponent/SwiperComponent";
import {useTranslation} from "react-i18next";
import TechnicalDetailsList from "./TechnicalDetailsList/TechnicalDetailsList";
import {formatMoney} from "../../utils/localization";
import InputWrapper from "../../components/common/InputWrapper";
import {Availability} from "../../components/common/general/availability";
import {useAppDispatch, useAppSettings} from "../../hook";
import {addCartItem} from "../../reducers/cart";
import AddToCartButton from "../../components/common/AddToCartButton";


const Product = (props: ProductDetailsProps) => {
    const {t} = useTranslation('productPage')
    const {product, showLongDescription} = props;
    const [quantity, setQuantity] = useState<number>(1)
    const dispatch = useAppDispatch()
    const haveDiscountPrice = product.discountPrice > 0;
    const {shopUsesPrices} = useAppSettings();

    const priceStyles: SxProps = {
        fontSize: '4rem',
        fontWeight: '300',
        color: haveDiscountPrice ? primaryColor.discountCheckoutPage : 'inherit'
    }

    return (
        <Box>
            <Grid container sx={{
                color: primaryColor.textHeader,
                padding: '40px',
            }}>
                <Grid item sm={7}>
                    <SwiperComponent images={product.image}/>
                    {product.details?.shortDescription &&
                        <Box sx={{margin: '68px 0px 0px 92px'}}>
                            <Box sx={{fontWeight: '300', fontSize: '2rem'}}>{t('description')}</Box>
                            <Box
                                sx={{fontSize: '2rem'}}
                                dangerouslySetInnerHTML={{
                                    __html: product.details.shortDescription,
                                }}
                            />
                        </Box>
                    }
                    {showLongDescription && product.details?.longDescription &&
                        <Box sx={{margin: '68px 0px 0px 92px'}}>
                            <Box sx={{fontWeight: '300', fontSize: '2rem'}}>{t('longDescription')}</Box>
                            <Box
                                sx={{fontSize: '2rem'}}
                                dangerouslySetInnerHTML={{
                                    __html: product.details.longDescription,
                                }}
                            />
                        </Box>
                    }
                </Grid>
                <Grid sx={{paddingLeft: "80px"}} item sm={5}>
                    <Box sx={{fontSize: '2rem'}}>
                        <Box sx={{marginBottom: '20px'}}>
                            <Box sx={{fontWeight: '700'}}>{t('materialNumber')}</Box>
                            <Box>{product.code}</Box>
                        </Box>
                        <Availability inStock={product.inStock} isOrderable={product.isOrderable}/>
                        <Box sx={{paddingTop: '20px'}}>
                            {product.price ? <><Box
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Box sx={{
                                        textAlign: "right"
                                    }}>
                                        {haveDiscountPrice &&
                                            <Box
                                                sx={{
                                                    fontSize: '2rem',
                                                    fontWeight: '300',
                                                    textDecoration: "line-through",
                                                    textDecorationThickness: '1px'
                                                }}>
                                                {formatMoney(product.price)}
                                            </Box>
                                        }
                                        <Box
                                            sx={priceStyles}
                                        >
                                            {formatMoney(product.discountPrice || product.price)}
                                        </Box>
                                    </Box>
                                    <Box
                                        component={"span"}
                                        sx={{
                                            fontSize: '2rem',
                                            fontWeight: '400',
                                            pl: '20px',
                                            pb: '10px',
                                            alignSelf: "end"
                                        }}
                                    > /{product.unit.name}</Box>
                                </Box>
                                    <Box>{t('taxInfo')}</Box></>
                                :
                                <Box
                                    sx={priceStyles}
                                >
                                    {t('priceOnInquiry')}
                                </Box>
                            }
                        </Box>
                        <Box>
                            <TechnicalDetailsList details={product.details}/>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '60px'
                        }}>
                            <Box sx={{
                                padding: '0px 10px',
                                fontWeight: '600',
                                justifyContent: 'flex-end',

                            }}>{t('amount')}</Box>
                            <Box sx={{
                                [theme.breakpoints.up("lg")]: {
                                    marginRight: '-15px'
                                }
                            }}
                            >
                                <InputWrapper onChange={(quantity: number) => {
                                    setQuantity(quantity)
                                }} value={quantity}/>
                            </Box>
                        </Box>

                        <Box sx={{
                            width: 'fit-content',
                            float: 'right',
                            paddingY: '30px',
                        }}>
                            <AddToCartButton
                                isDisabled={!product.isOrderable}
                                buttonContent={(shopUsesPrices
                                    ? t('addToCart')
                                    : t('addToInquiry')) as string
                                }
                                onClick={() => {
                                    dispatch(addCartItem({
                                        code: product.code,
                                        quantity: quantity,
                                    }))
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default (Product)
