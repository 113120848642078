import * as React from 'react';
import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import {Link} from "react-router-dom";
import {useAppSettings} from '../../../hook';

export default function ContactPhone() {
    const {contactPhone} = useAppSettings();

    return (
        contactPhone ? <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'start',
                mt: {xs: '20px', md: '30px'}
            }}
        >
            <Box
                component={'img'}
                src='/images/phoneOrange.svg'
                height='24px'
                mr='10px'
            />
            <Link to={`tel:${contactPhone}`}>
                <Typography sx={{color: primaryColor.badgeColor}}>
                    {contactPhone}
                </Typography>
            </Link>
        </Box> : <></>
    )
}