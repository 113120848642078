import {formatMoney} from "../../../utils/localization";
import {defaultFonts, primaryColor, secondaryFonts} from "../../../theme";
import {useTranslation} from "react-i18next";
import {Cart, DiscountValueType} from "../../../interfaces/backend";
import {ColumnFlexBox} from "./CostArea";
import {useAppSettings} from "../../../hook";


interface CheckoutDiscountsBoxProps {
    checkoutCart: Cart;
}

const CheckoutDiscountsBox = (props: CheckoutDiscountsBoxProps) => {
    const {t} = useTranslation("checkoutPage");
    const {checkoutCart} = props;
    const {showDiscountPerItem} = useAppSettings();

    return (
        <>
            <ColumnFlexBox
                rowName={t("productCostWithoutDiscount")}
                rowValue={formatMoney(checkoutCart.totalWithoutDiscount)}
                customStyle={{fontSize: defaultFonts}}
            />

            {checkoutCart.discounts.map(
                (item, index) =>
                    (!showDiscountPerItem ||
                        item.discount.valueType === DiscountValueType.CertainAmount) && (
                        <ColumnFlexBox
                            key={item.discount.discountCode}
                            rowValue={formatMoney(-item.discountAmount)}
                            rowName={item.discount.discountCode}
                            customStyle={{
                                color: primaryColor.discountCheckoutPage,
                                fontSize: secondaryFonts,
                            }}
                        />
                    )
            )}
        </>
    );
};

export default CheckoutDiscountsBox;
