import React from "react";
import {MenuHomePage} from "../../interfaces/backend";
import {Box, Typography} from "@mui/material";

const HomeContent = (props: { data: MenuHomePage }) => {
    const {title, description} = props.data;

    return (
        <Box
            sx={{
                height: 'fit-content',
                maxHeight: '250px',
                width: {xs: "100%", lg: "60%"},

                '& >*': {
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    maxHeight: 'fit-content',
                }


            }}

        >
            <Typography
                sx={{
                    fontWeight: 300,
                    fontSize: {
                        xs: "1.8rem",
                        lg: "4rem",
                    },
                    textTransform: {
                        xs: "uppercase",
                        lg: "unset",
                    },
                    width: "100%",
                    margin: "0 auto",

                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: '1',

                }}
            >
                {title}
            </Typography>

            <Typography
                sx={{
                    paddingY: {md: '10px'},
                    WebkitLineClamp: '7',
                    wordBreak: 'break-word',

                }}
                dangerouslySetInnerHTML={{__html: description}}/>
        </Box>
    );
};
export default HomeContent;
