import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import AuthDynamicComponent from "../../../components/accessControl/AuthDynamicComponent";
import {StyledBoxButton} from "../styleElements";
import {primaryColor, tableFontSize} from "../../../theme";

interface SummaryInterface {
    summaryDetailsContent: ReactNode;
    handleClick: () => void;
    confirmButtonText: string;
}

const Summary = (props: SummaryInterface) => {
    const {summaryDetailsContent, handleClick, confirmButtonText} = props;
    const {t} = useTranslation("checkoutPage");

    const renderButton = (content: string) => (
        <StyledBoxButton labelButton={content} handleClick={handleClick}/>
    );

    return (
        <Box
            sx={{
                color: primaryColor.shoppingCartTitle,
                fontSize: tableFontSize.contentMain,
                alignSelf: "end",
                paddingX: {
                    xs: "30px",
                    sm: "25px",
                    md: "unset",
                },
                margin: {
                    xs: "50px auto 0px",
                    lg: "90px 55px 52px 0",
                },
                width: "100%",
                maxWidth: {
                    xs: "450px",
                    md: "500px",
                },
            }}
        >
            <Box>{summaryDetailsContent}</Box>

            <Box
                sx={{
                    mt: "25px",
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: "inherit",
                            md: "350px",
                            lg: "unset",
                        },
                    }}
                >
                    <AuthDynamicComponent
                        children={renderButton(confirmButtonText)}
                        fallback={
                            <>
                                <Typography
                                    sx={{
                                        mt: "45px",
                                        mb: "16px",
                                        fontSize: tableFontSize.contentMain,
                                    }}
                                >
                                    {t("pleaseLogin")}
                                </Typography>
                                {renderButton(t("loginNow"))}
                            </>
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Summary;
