import {Box, useMediaQuery} from "@mui/material";
import ServiceSelectUser from "../../../login/ServiceSelectUser"
import theme, {primaryColor} from "../../../../theme";

const ServiceHeader = () => {

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const mobileStyles = {
        position: 'fixed',
        zIndex: 1300,
        top: 0,
        left: 0,
        height: '75px',
        paddingY: '15px',
        paddingX: {xs: '22px', sm: '30px'},
    }
    return (
        <Box
            id="serviceHeader"
            sx={{
                backgroundColor: primaryColor.background,
                width: {
                    xs: '100%',
                    md: '350px',
                },
                height: '45px',
                marginRight: {md: 'auto'},
                ...mobileView && mobileStyles

            }}>
            <ServiceSelectUser/>
        </Box>
    )
}

export default ServiceHeader