import {CartItem, DiscountValueType, Image} from "../interfaces/backend";
import {useAppSettings} from "../hook";
import {NavigateFunction} from 'react-router-dom';
import {OperationList} from "../interfaces/general";
import {SxProps} from '@mui/system';

export function TakeFirst2DImage(imageList?: Array<Image>) {
    const noImageUrl: string = TakeEmptyImage()//useAppSelector(state => state.staticContent.noImageUrl)
    return imageList && imageList.length ? imageList[0].url : noImageUrl
}

export function TakeEmptyImage(): string {
    const {noImageUrl} = useAppSettings();
    return noImageUrl;
}

export const checkEmptyTag = (description: string): boolean => {
    const plainText = cleanHTMLTags(description)
    return plainText.length > 0
}

export const cleanHTMLTags = (htmlString: string): string => {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "").trim();
};

export const plainDataExists = (description: string): boolean => {
    const excludeTags = ["img", "embed", "iframe", "video", "source", "track", "audio"]
    let parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    let result = excludeTags.find(et => doc.getElementsByTagName(et).length > 0)
    if (result) {
        return true
    }
    return checkEmptyTag(description)
}

export const countTotalItems = (items: Array<CartItem>) => {
    return items.reduce((allItems, current) => allItems + current.quantity, 0)
}

export const fileNameFromUrl = (url: string): string => {
    const parts = url.split('/');
    return parts[parts.length - 1];
}

export const isGoBackActive = (locationKey: string) => {
    return locationKey !== 'default';
}

export const handleNavBack = (navigate: NavigateFunction) => {
    return navigate(-1);
}

export const calculateCartPriceDetails = (item: CartItem, showDiscountPerItem: boolean) => {
    let lastPrice = item.discountPrice || item.price;
    let prices = [item.price];
    if (item.discountPrice) prices.push(item.discountPrice);

    item.discounts.forEach(discount => {
        discount.discount.valueType === DiscountValueType.Percentage && showDiscountPerItem && prices.push(lastPrice -= discount.discountAmountPerItem);
    });

    return {prices, lastPrice}
}

export function setOperation(isShopUsesPrices: boolean, action: keyof OperationList): string {
    const prefix = isShopUsesPrices ? 'order' : 'inquiry';

    return `${prefix}${action.charAt(0).toUpperCase()}${action.slice(1)}`;
}

export const setLineLimit = (lineNumber: number): SxProps => {
    return {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: lineNumber,
        WebkitBoxOrient: 'vertical',
        whiteSpace: "normal"
    };
};