import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import {useAppSelector} from "../../hook";
import {Backdrop} from "@mui/material";

const Spinner = () => {
    const tasks = useAppSelector(state => state.styleControls.tasks);
    return (
        <>
            {tasks > 0 &&
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <CircularProgress size={80} color={"inherit"}/>
                </Backdrop>}
        </>
    );
};

export default Spinner;