import {Box, BoxProps, Typography, TypographyProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {defaultFonts, primaryColor} from "../../theme";
import styled from "@emotion/styled";
import {useAppSelector} from "../../hook";
import {formatMoney} from "../../utils/localization";
import {useNavigate} from "react-router";
import {StyledTypography} from "../../components/common/StyledComponents";
import OrderItemsList from "./OrderItemsList";
import {StyledDivider} from "../Cart/styleElements";
import {Order} from "../../interfaces/backend";

const StyledBox = styled((props: BoxProps) => (
        <Box {...props}/>
    )
)
(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const StyledTypographyDiscount = styled((props: TypographyProps) => (
    <Typography sx={{
        fontSize: defaultFonts,
        fontWeight: 400,
    }} {...props}/>
))
(({theme}) => ({
    color: primaryColor.filterText
}))

const CustomStyledDivider = () => (
    <StyledDivider
        sx={{
            borderColor: primaryColor.orderCompletedDividerColor,
            marginTop: "15px",
            marginBottom: "15px",
        }}
    />
)

export default function OrderCompletedSummary() {
    const {t} = useTranslation('checkoutPage')
    const stateOrder = useAppSelector(state => state.cart.orderResult)
    const order = stateOrder as Order;
    const navigate = useNavigate()

    useEffect((() => {
        if (order.orderId === '') navigate('/cart')
    }), [order])

    return order.orderId ? (
        <Box sx={{
            textAlign: 'start',
            mt: {xs: '30px', sm: '50px'},
        }}>
            <StyledBox>
                <StyledTypography>{t('items')}</StyledTypography>
                <StyledTypography>{t('price')}</StyledTypography>
            </StyledBox>

            <OrderItemsList items={order.items}/>

            <CustomStyledDivider/>
            <StyledBox>
                <StyledTypography>{t('cart')}</StyledTypography>
                <StyledTypography>{formatMoney(order.totalWithoutDiscount)}</StyledTypography>
            </StyledBox>
            {order.totalDiscount > 0 && <StyledBox>
                <StyledTypographyDiscount>{t('discount')}</StyledTypographyDiscount>
                <StyledTypographyDiscount>{formatMoney(-order.totalDiscount)}</StyledTypographyDiscount>
            </StyledBox>}

            <StyledBox>
                <StyledTypography>{t('shippingCost')}</StyledTypography>
                <StyledTypography>{formatMoney(order.shippingMethod ? order.shippingMethod.price : 0)}</StyledTypography>
            </StyledBox>
            <StyledBox>
                <StyledTypography>{t('tax')}</StyledTypography>
                <StyledTypography>{formatMoney(order.totalTax)}</StyledTypography>
            </StyledBox>
            <CustomStyledDivider/>
            <StyledBox>
                <StyledTypography style={{fontWeight: 600}}>{t('ordersum')}</StyledTypography>
                <StyledTypography
                    style={{fontWeight: 600}}>{formatMoney(order.totalWithTaxAndShipping)}</StyledTypography>
            </StyledBox>
        </Box>
    ) : (<></>)
}
