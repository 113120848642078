import CloseIcon from "../../../components/common/CloseButton";
import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";

interface FilterChipProps {
    onClick: () => void;
    label: string;
}

const FilterChip = (props: FilterChipProps) => {
    const {onClick, label} = props;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                maxWidth: {md: "120px", lg: "185px", xl: "225px"},
            }}
        >
            <CloseIcon handleClick={onClick}/>
            <Typography
                sx={{
                    fontSize: {xs: "1.6rem", lg: '2rem'},
                    color: primaryColor.whiteText,
                    wordBreak: "break-word",
                    margin: "5px 10px",
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};
export default FilterChip;
