import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Category} from "../../../interfaces/backend";
import {Box, Typography} from "@mui/material";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import {defaultFonts, primaryColor} from "../../../theme";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {useAppDispatch, useAppSelector} from '../../../hook';
import {setExpandedTreeNodes, setSelectedCategory} from '../../../reducers/sparePart';

interface TreeProps {
    category: Category,
    name?: string
}

const TreeItemStyle =
    {
        '& .MuiTreeItem-content': {
            background: primaryColor.treeItemBackgroundColor,
            marginY: '2px',
            height: '45px',

            flexDirection: 'row-reverse',
            opacity: 0.5,
            color: primaryColor.treeItemsColor,
            '&.Mui-expanded': {
                opacity: 1,
                color: primaryColor.treeItemsColor,
                background: primaryColor.treeParentBackgroundColor

            },
            '&.Mui-selected': {
                opacity: 1,
            },
            '.MuiTreeItem-iconContainer': {
                marginLeft: '20px',
                "svg":
                    {
                        fontSize: '25px'
                    }
            },
            '.MuiTreeItem-label': {
                fontWeight: 400,
                fontSize: defaultFonts,
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            }
        },
    }


const Tree = (params: TreeProps) => {
    const {category, name} = params;
    const dispatch = useAppDispatch();

    const selectedCategory = useAppSelector(state => state.sparePart.selectedCategory)


    const handleNodeSelect = (event: React.SyntheticEvent, nodeIds: string) => {
        dispatch(setSelectedCategory(nodeIds));
    };
    const expanded = useAppSelector(
        (state) => state.sparePart.expandedTreeNodes
    );

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        dispatch(setExpandedTreeNodes(nodeIds));
    };

    const Node = (props: TreeProps) => {
        const {category} = props;
        return (
            <TreeItem sx={TreeItemStyle} nodeId={category.alias} label={category.name}>
                {category.children.map(cat => (
                    <Node category={cat} key={cat.alias}/>
                ))}
            </TreeItem>
        );
    };


    return (
        <Box sx={{
            // visibility: closeFilter ? 'hidden' : 'visible',
            marginBottom: '35px',
        }}>
            <Typography
                variant={'h20'}
                sx={{

                    fontWeight: 300,
                    textTransform: 'uppercase',
                    // paddingLeft: '8px',
                    fontSize: {xs: '1.8rem', lg: '2rem'},
                    '&.MuiTypography-root': {
                        pl: 0
                    }
                }}
            >
                {name}
            </Typography>
            <TreeView
                expanded={expanded}
                selected={selectedCategory || ''}
                defaultCollapseIcon={<ExpandLessIcon/>}
                defaultExpandIcon={<ExpandMoreIcon/>}
                // defaultSelected={type ? type : ""}
                // defaultExpanded={[type ? type : ""]}
                onNodeToggle={handleToggle}
                onNodeSelect={handleNodeSelect}
                sx={{
                    flexGrow: 1,
                    maxWidth: '100%',
                    '&.MuiTreeItem-root .MuiTreeItem-content': {
                        padding: '0px'
                    },
                    '.MuiTreeItem-label': {
                        fontSize: defaultFonts,
                    },

                    '.MuiTreeItem-root .MuiTreeItem-content.Mui-selected': {
                        opacity: 0.9,
                        background: primaryColor.selectedTreeTab,
                        '&:hover': {
                            opacity: 1,
                        },
                    },
                }}
            >
                <Node category={category}/>
            </TreeView>
        </Box>
    )
}

export default Tree