import {Link} from "react-router-dom";
import {useAppDispatch, useAppSettings} from "../../../hook";
import {Box} from "@mui/material";
import React from "react";
import {setOpenedDrawerID} from "../../../reducers/static";


const Logo = (props: { mobileView: boolean }) => {
    const {mobileView} = props
    const {logoUrl} = useAppSettings();
    const dispatch = useAppDispatch()

    return (
        <Link to={'/'} onClick={() => dispatch(setOpenedDrawerID(''))}>
            <Box component={"img"}
                 src={logoUrl}
                 alt={'logo'}
                 sx={{
                     width: !mobileView ? '100%' : 'unset',
                     maxWidth: '148px',
                     maxHeight: !mobileView ? '74px' : '36px',
                     display: 'flex',
                     alignItems: 'flex-start'
                 }}
            />
        </Link>
    )
}

export default React.memo(Logo)