const env = window.envData

export const show_sensor_data = env?.REACT_APP_SHOW_SENSOR_DATA ?? true
export const extend_tree = env?.REACT_APP_EXTEND_TREE ?? false
export const backend_api_url = env?.REACT_APP_BACKEND_API_URL ?? ""
export const max_articles = env?.REACT_APP_MAX_ARTICLES ?? '3'
export const is_debug = env?.REACT_APP_IS_DEBUG ?? false
export const model_key_property = env?.MODEL_KEY_PROPERTY ?? ""


delete window.envData