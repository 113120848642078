import {Box, Container, Grid, useMediaQuery} from "@mui/material";
import LoginHeader from "../components/login/header";
import Footer from "../components/common/footer";
import Articles from "../components/login/articles";
import theme from "../theme";
import {ReactNode, useRef} from "react";
import TopHeader from "../components/common/header/topHeader";

interface LoginPageContentProps {
    children: ReactNode;
    showArticles: boolean;
}

const LoginPageContent = (props: LoginPageContentProps) => {
    const {children, showArticles} = props;
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const Ref = useRef<HTMLDivElement>(null);

    return (
        <Box
            sx={{
                position: {md: "relative"},
            }}
        >
            <Grid container>
                <Grid item xs={12} md={5}>
                    <TopHeader/>
                </Grid>
            </Grid>
            <LoginHeader/>

            <Container maxWidth={"2xl"}>
                <Grid
                    container
                    ref={Ref}
                    sx={{
                        height: '100%',
                        minHeight: "765px",
                        maxHeight: 'fit-content',
                    }}
                >
                    <Grid item xs={12} md={5} sx={{
                        marginBottom: mobileView && showArticles ? '35px' : '0px'
                    }}>
                        {children}
                    </Grid>

                    {showArticles ? (
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                paddingX: {xs: '6px', md: 'unset'},
                                ...(!mobileView && {
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                }),
                            }}
                        >
                            <Articles/>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Container>
            <Box
                sx={{
                    ...(!mobileView && {
                        position: "absolute",
                        left: 0,
                        right: 0,
                    }),
                }}
            >
                <Footer/>
            </Box>
        </Box>
    );
};

export default LoginPageContent;
