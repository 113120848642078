import {
    CANCEL_INQUIRY,
    CANCEL_ORDER,
    CART_INQUIRY_ITEMS,
    CART_ITEMS,
    FILTER_INQUIRY_ORDER,
    FILTER_ORDER,
    USER_INQUIRY_DETAILS,
    USER_ORDER_DETAILS,
} from "../../constants/api";
import {
    addQuickOrderProductsAction,
    cancelOrderAction,
    filteredOrderHistoryAction,
    getOrderDetailsAction,
} from "../../interfaces/actions";
import {Cart, Order, OrderHistory} from "../../interfaces/backend";
import {callHttp} from "../../utils/api";
import {get, post, put as putRequest} from "../../utils/httpUtil";
import {select} from "redux-saga/effects";

export function* addQuickOrderToCartOrInquiry(
    action: addQuickOrderProductsAction
) {
    try {
        const {shopUsesPrices} = yield select(state => state.settings.items);

        const url = shopUsesPrices ? CART_ITEMS : CART_INQUIRY_ITEMS;
        const onSuccessMessage = shopUsesPrices
            ? "messages:Added in cart"
            : "messages:Added in inquiry";

        const products = action.payload.products.map((p) => ({
            product: p.code,
            quantity: p.quantity,
        }));
        const cart: Cart = yield callHttp(post, url, {items: products});

        return {cart, message: onSuccessMessage};
    } catch (err: any) {
        throw err;
    }
}

export function* getOrdersOrInquiriesHistory(
    action: filteredOrderHistoryAction
) {
    try {
        const {shopUsesPrices} = yield select(state => state.settings.items);

        const url: string = shopUsesPrices
            ? FILTER_ORDER(action.payload)
            : FILTER_INQUIRY_ORDER(action.payload);
        const ordersHistory: OrderHistory = yield callHttp(get, url);

        return ordersHistory;
    } catch (err: any) {
        throw err;
    }
}

export function* getOrderOrInquiryDetails(action: getOrderDetailsAction) {
    try {
        const {shopUsesPrices} = yield select(state => state.settings.items);

        const {orderId} = action.payload;

        const url: string = shopUsesPrices
            ? USER_ORDER_DETAILS(orderId)
            : USER_INQUIRY_DETAILS(orderId);
        const orderHistory: Order = yield callHttp(get, url);

        return orderHistory;
    } catch (err: any) {
        throw err;
    }
}

export function* cancelOrderOrInquiry(action: cancelOrderAction) {
    try {
        const {orderId, filtering} = action.payload
        const {shopUsesPrices} = yield select(state => state.settings.items);

        const filter: string = shopUsesPrices
            ? FILTER_ORDER(filtering)
            : FILTER_INQUIRY_ORDER(filtering);

        const url = shopUsesPrices
            ? CANCEL_ORDER(orderId)
            : CANCEL_INQUIRY(orderId)

        const orderHistory: OrderHistory = yield callHttp(get, filter);
        yield callHttp(putRequest, url)

        const onSuccessMessage = shopUsesPrices
            ? `messages:orderCancelled{{orderId}}`
            : `messages:inquiryCancelled{{orderId}}`;


        return {orderHistory, message: {key: onSuccessMessage, options: {orderId}}};
    } catch (err: any) {
        throw err;
    }
}