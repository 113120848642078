import {Box, SxProps} from "@mui/material";
import {primaryColor} from "../../../theme";

interface ProductImageProps {
    src: string;
    sx?: SxProps;
}

const ProductImage = (props: ProductImageProps) => {
    const {src, sx} = props;

    return (
        <Box sx={{
            width: 'auto',
            minWidth: '120px',
            maxWidth: '125px',
            height: '77px',
            backgroundColor: primaryColor.pdpImageBackground,
            ...sx
        }}>

            <Box
                component={"img"}
                src={src}
                alt={'product_image'}
                sx={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    margin: "auto",
                }}
            />
        </Box>
    )
}
export default ProductImage;