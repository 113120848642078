import {put} from "redux-saga/effects";
import {getCart, setCart, setComment, setInternalOrderNumber} from "../../reducers/cart";
import {openErrorSnack, openSuccessSnack} from "../../reducers/snackbar";
import {CartMethods, InquiryCart} from "../../interfaces/backend";
import {callHttp} from "../../utils/api";
import {del, get, patch, post} from "../../utils/httpUtil";
import {CART_INQUIRY, CART_INQUIRY_ITEMS, CART_INQUIRY_ITEMS_CODE} from "../../constants/api";
import {deleteCartItemAction, updateCartItemAction, updateCartMultipleItemsAction} from "../../interfaces/actions";
import i18next from "i18next";

i18next.loadNamespaces(['messages']);

class InquirySagaClass implements CartMethods {
    * getCart() {
        try {
            const cart: InquiryCart = yield callHttp(get, CART_INQUIRY)
            yield put(setCart(cart))
        } catch (err: any) {
            yield put(openErrorSnack(err))
        }
    }

    * deleteCartItem(action: deleteCartItemAction) {
        try {
            const {code} = action.payload
            yield callHttp(del, CART_INQUIRY_ITEMS_CODE(code));
            const cart: InquiryCart = yield callHttp(get, CART_INQUIRY);

            if (cart.items === undefined) {
                yield put(setComment(''))
                yield put(setInternalOrderNumber(''))
            }
            yield put(setCart(cart))
            yield put(openSuccessSnack(i18next.t('messages:Removed from inquiry')));
        } catch (err) {
            throw err;
        }
    }

    * updateCartItem(action: updateCartItemAction) {
        try {
            const {code, quantity} = action.payload
            const cart: InquiryCart = yield callHttp(patch, CART_INQUIRY_ITEMS_CODE(code), {quantity})
            yield put(setCart(cart))
        } catch (err: any) {
            yield put(getCart())
            yield put(openErrorSnack(err))
        }
    }

    * addCartItem(action: updateCartItemAction) {
        try {
            const {code, quantity} = action.payload
            const cart: InquiryCart = yield callHttp(post, CART_INQUIRY_ITEMS, {items: [{product: code, quantity}]})

            yield put(setCart(cart))
            yield put(openSuccessSnack(i18next.t('messages:Added in inquiry')));
        } catch (err: any) {
            yield put(openErrorSnack(err))
        }
    }

    * addCartMultipleItems(action: updateCartMultipleItemsAction) {
        try {
            const {items} = action.payload
            const cart: InquiryCart = yield callHttp(post, CART_INQUIRY_ITEMS, {items: items})

            yield put(setCart(cart))
            yield put(openSuccessSnack(i18next.t('messages:Added in inquiry')));
        } catch (err: any) {
            yield put(getCart())
            yield put(openErrorSnack(err))
        }
    }
}

export default InquirySagaClass;