import * as React from 'react';
import {Dispatch, ReactNode} from 'react';
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import {primaryColor} from "../../../theme";
import CloseIcon from '../CloseButton';

interface modalProps {
    title: string,
    closeAction?: () => void,
    closeState?: Dispatch<React.SetStateAction<boolean>>,
    children: ReactNode
}

export default function CommonDialog(props: modalProps) {
    const {title, closeAction, closeState, children} = props
    return (
        <Dialog
            open={true}
            onClose={() => {
                closeAction ? closeAction() : closeState && closeState(false)
            }}
            sx={{
                '& .MuiPaper-root.MuiDialog-paper': {
                    borderRadius: '0',
                    overflowX: 'hidden',
                    backgroundColor: primaryColor.backgroundOverlay,
                    minHeight: '300px',

                }
            }}
            fullWidth={true}
        >
            <Box
                sx={{

                    margin: 'auto',
                    padding: {
                        xs: '25px 35px',
                        md: '30px 50px',
                    },
                    color: primaryColor.whiteText,
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '45px',
                        padding: 'unset',
                        width: '100%',
                        wordBreak: "break-word",
                        fontSize: {xs: '2.6rem', md: '2.8rem', lg: '5rem'},
                        fontWeight: {xs: 400, md: 700},
                        lineHeight: 'normal !important',
                    }}
                >
                    {title}
                    <CloseIcon
                        sx={{
                            marginTop: '4px',
                            height: {xs: '17px', md: '20px'},
                            ml: '30px'
                        }}
                        handleClick={() => {
                            closeAction ? closeAction() : closeState && closeState(false)

                        }}
                    />
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: 'unset',
                        width: '100%',
                        '& .MuiTypography-root': {
                            fontSize: {xs: '1.5rem', md: '2.0rem'},
                            fontWeight: 400,

                        }

                    }}
                >
                    {children}
                </DialogContent>
            </Box>
        </Dialog>
    );
}