import {useAppDispatch, useAppSelector} from "../../hook";
import {useTranslation} from "react-i18next";
import {Box, Tooltip} from "@mui/material";
import {openErrorSnack} from "../../reducers/snackbar";
import {uploadAttachments} from "../../reducers/cart";
import Dropzone, {ErrorCode, FileError, FileRejection} from "react-dropzone";
import {PDF_EXTENSIONS} from "../../constants/extensions";
import UploadFilePreview from "./UploadFilePreview";
import {StyledTypography} from "../../components/common/StyledComponents";
import {File as CartDocument} from "../../interfaces/backend";


import theme from "../../theme";

// interface CheckoutDropzoneProps {
//     showFilesInPreview: boolean,
//     messageSent: boolean
// }

const fileMaxSize = 3 * 1024 * 1024; // Rule: max 3MB/file
const fileMaxCount = 3;

export default function CheckoutDropzone(/*props: CheckoutDropzoneProps*/) {
    // const {showFilesInPreview, messageSent} = props
    const dispatch = useAppDispatch()
    const {t} = useTranslation('checkoutPage')
    const documents = useAppSelector(state => state.cart.checkoutCart.documents)

    // const [state, setState] = useState({
    //     files: [],
    //     filesToPassToPreview: [],
    //     numberUploadedFiles: 0,
    //     filesToPassToMessage: [],
    // })

    // useEffect(() => {
    //     if (messageSent) setState({...state, filesToPassToMessage: [], numberUploadedFiles: 0})
    // }, [messageSent])

    // useEffect(() => {
    //     !showFilesInPreview && setState({...state, filesToPassToPreview: []})
    // }, [showFilesInPreview])

    // let currentFileSize: number
    // let currentFileName: string
    // const verifyFile = (file: File) => {
    //     let message = '';
    //
    //     if (file) {
    //         if (file.size && file.name) {
    //             currentFileSize = file.size
    //             currentFileName = file.name
    //
    //             if (currentFileSize > imageMaxSize) {
    //                 message = t('maxSize')
    //                 dispatch(openErrorSnack({message: message}))
    //                 return false
    //             }
    //             if (
    //                 state.filesToPassToPreview.some((e: { name: string, size: number }) => e.name === currentFileName)
    //             ) {
    //                 message = t('FileIsAdded {{currentFileName}}', {currentFileName: currentFileName,});
    //                 dispatch(openErrorSnack({message: message}))
    //                 return false
    //             }
    //         }
    //         return true
    //     }
    // }

    const handleCustomValidation = (file: File): FileError[] | null => {
        let errors: FileError[] = [];

        // Check unique file case
        if (documents && documents.length) {
            if (documents.some((e: CartDocument) => e.name === file.name /*&& e.size === file.size*/)) {//@TODO check also by size, need changes in BE
                errors.push({
                    code: "file-in-use",
                    message: `File ${file.name} is already used`
                });
            }
        }

        if (errors.length) {
            return errors
        }

        return null;
    }

    const handleDropRejected = (fileRejections: FileRejection[]) => {
        type ErrorMessage = {
            code: string,
            value: string
        }

        let messages: ErrorMessage[] = [];
        fileRejections.forEach(file => {
            const error = file.errors.at(0);
            if (!error) {
                return
            }

            let message: ErrorMessage = {code: error.code, value: ""};
            switch (error.code) {
                case ErrorCode.FileInvalidType:
                    message.value = t('invalidFileTypeOnlyPdf{{fileName}}', {fileName: file.file.name});
                    break;
                case ErrorCode.FileTooLarge:
                    message.value = t('tooLargeFile{{fileName}}{{fileMaxSize}}', {
                        fileName: file.file.name,
                        fileMaxSize: fileMaxSize
                    });
                    break;
                case ErrorCode.TooManyFiles:
                    message.value = t('tooManyFiles{{fileMaxCount}}', {fileMaxCount: fileMaxCount});
                    break;
                case 'file-in-use':
                    message.value = t('fileAlreadyUploaded{{fileName}}', {fileName: file.file.name,});
                    break;
                default:
                    message.value = "Unknown error"
            }
            messages.push(message);
        })

        //if too many error is generated then take it only one time
        const tooManyFilesErrors = messages.filter(message => message.code === ErrorCode.TooManyFiles);
        if (tooManyFilesErrors.length) {
            messages = [tooManyFilesErrors[0]];
        }

        messages.forEach(message => setTimeout(() => dispatch(openErrorSnack({message: message.value})), 0));
    }

    const handleOnDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        // if (fileRejections && fileRejections.length > 0) {
        //     fileRejections.forEach((item) => {
        //         verifyFile(item.file)
        //         return item
        //     });
        // }

        if (acceptedFiles && acceptedFiles.length > 0) {
            dispatch(uploadAttachments({attachedFiles: acceptedFiles}))
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: '32px',
                width: 'fit-content',
                [theme.breakpoints.down("md")]: {
                    padding: 'unset'
                }
            }}
        >
            {documents && documents.length &&
                <UploadFilePreview
                    filesToShow={documents}
                    canDelete={true}
                />
            }

            <Dropzone
                // disableClick
                multiple={true}
                maxSize={fileMaxSize}
                maxFiles={fileMaxCount}
                accept={{
                    'application/pdf': PDF_EXTENSIONS,
                    'application/x-pdf': PDF_EXTENSIONS
                }}
                onDrop={handleOnDrop}
                onDropRejected={handleDropRejected}
                validator={handleCustomValidation}
            >
                {({getRootProps, getInputProps}) => (
                    <div>
                        <Tooltip
                            title={t('attachFile')}
                            placement={'top'}
                        >
                            <div {...getRootProps()} style={{cursor: 'pointer'}}>
                                <input {...getInputProps()} />
                                <Box sx={{display: 'flex'}}>
                                    <Box
                                        component={'img'}
                                        alt='Attach file'
                                        src={'/images/icons/attachFileIcon.svg'}
                                        sx={{
                                            width: '17px',
                                            mr: '15px'
                                        }}
                                    />
                                    <StyledTypography>{t('addAttachment')}</StyledTypography>
                                </Box>
                            </div>
                        </Tooltip>
                    </div>
                )}
            </Dropzone>
        </Box>
    )
}