import {Box, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import theme, {defaultFonts} from "../../../theme";
import CheckoutAccordion from "../../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import VoucherAreaForm from "./VoucherAreaForm";

export default function VoucherArea() {
    const {t} = useTranslation('checkoutPage')
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        mobileView ?
            <CheckoutAccordion
                title={t('Enter action code')}
                content={
                    <VoucherAreaForm/>
                }
                mandatory={false}/>
            :
            <Box sx={{
                marginRight: '15px',
            }}>
                <Typography sx={{
                    fontSize: defaultFonts,
                }}> {t('Enter action code')}</Typography>

                <VoucherAreaForm/>

            </Box>
    )
}