import React, {ChangeEvent, ReactNode} from "react";
import {Box, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {defaultFonts} from "../../theme";

export interface RadioGroupItem {
    id: string;
    content: ReactNode
}

interface RadioGroupProps {
    options: Array<RadioGroupItem>,
    value?: string,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function RadioGroupComponent(props: RadioGroupProps) {
    const {options, value, handleChange} = props

    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) =>
                    <FormControlLabel
                        sx={{
                            alignItems: "flex-start"
                        }}
                        key={index}
                        value={option.id}
                        control={<Radio/>}
                        label={
                            <Box sx={{
                                fontSize: defaultFonts,
                                fontWeight: 400
                            }}>
                                {option.content}
                            </Box>
                        }
                        slotProps={{
                            typography: {
                                width: "calc(100% - 43px)", //43 is the width of checkbox
                                paddingY: {xs: "9px", md: "7px"}
                            }
                        }}
                    />
                )}
            </RadioGroup>
        </FormControl>
    )
}