import theme from "../../../theme";
import {useMediaQuery} from "@mui/material";
import UserTableMobile from "./userTableMobile";
import UserTableDesktop from "./userTableDesktop";


export default function UserTable() {

    const lgView = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <>
            {lgView ?
                <UserTableMobile/>
                :
                <UserTableDesktop/>

            }
        </>
    );
}
