import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Accordion, AccordionDetails, AccordionSummary, Box, SxProps, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {Article} from "../../interfaces/backend";
import Link from '@mui/material/Link';
import {useTranslation} from "react-i18next";
import ArrowButton from '../common/ArrowButton';
import {cleanHTMLTags, setLineLimit} from '../../utils/productFunctions';


export default function ArticleBox(props: { article: Article, imageBoxStyling: SxProps }) {
    const {article, imageBoxStyling} = props
    const {t} = useTranslation('startPage')
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const handleChange = () => {
        setExpanded(!expanded)
    }

    return (
        <Box
            sx={{
                background: `url('${article.image}')`,
                backgroundSize: 'cover',
                textAlign: 'start',
                ...imageBoxStyling
            }}
        >
            <Accordion
                expanded={expanded}
                square={true}
                sx={{
                    width: '100%',
                    backgroundColor: primaryColor.articleDescriptionBackground,
                    opacity: {xs: expanded ? 1 : 0.8, md: 0.9},
                    top: {md: '50%'},
                    lineHeight: 1.1,
                    paddingY: {xs: '5px', md: '25px'},
                    paddingX: {xs: '0px', md: '25px', lg: '50px'},
                    color: primaryColor.whiteText
                }}
                onClick={handleChange}
            >
                <AccordionSummary
                    expandIcon={
                        <ArrowButton
                            direction={mobileView ? "down" : "right"}
                            color={!expanded ? 'white' : primaryColor.badgeColor}
                            width={mobileView ? 24 : 60}
                        />
                    }
                    sx={{
                        paddingX: 'unset',
                        textAlign: 'center',
                        height: {md: '92px', lg: '144px'},
                        flexDirection: {xs: 'column', md: 'row-reverse'},
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                            marginY: "12px"
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            marginRight: {md: '19px'},
                            '&.Mui-expanded': {
                                transform: {md: 'rotate(90deg)'}
                            },
                            '&:hover': {
                                '& svg': {
                                    fill: primaryColor.drawerHover
                                }
                            }
                        }
                    }}
                >
                    <Typography sx={{
                        textAlign: {xs: 'center', md: 'start'},
                        fontWeight: 700,
                        fontSize: {xs: '1.8rem', md: '2.8rem', lg: '5rem'},
                        lineHeight: 'normal',
                        ...setLineLimit(2)
                    }}>{article.title} </Typography>
                </AccordionSummary>

                <AccordionDetails
                    sx={{
                        paddingRight: 0,
                        paddingLeft: {xs: 0, md: '80px'},
                        display: expanded ? "block" : 'none',
                        paddingY: {xs: "8px", md: "18px"}
                    }}
                >
                    <Typography
                        sx={{
                            wordBreak: "break-word",
                            maxHeight: '60px',
                            lineHeight: 'normal',
                            fontSize: {xs: '1.5rem', md: '1.6rem'},
                            ...setLineLimit(3)
                        }}
                    >
                        {cleanHTMLTags(article.description)}
                    </Typography>
                    <Typography sx={{
                        marginBottom: {xs: '5px', md: 'unset'},
                        marginTop: {xs: '5px', md: '10px'},
                    }}
                    >
                        <Link
                            href={`/blog/${article.alias}`}
                            target='_blank'
                            rel="noopener"
                            underline={"hover"}
                            sx={{
                                textUnderlineOffset: 6,
                                color: primaryColor.badgeColor,
                                fontSize: {xs: '1.5rem', md: '2rem'}
                            }}
                        >
                            {t('learnMore')}
                        </Link>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}