import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {openSearchBox} from "../../reducers/quickSearch";
import {defaultFonts, primaryColor} from "../../theme";
import {Box, Typography} from "@mui/material";
import {inputPropsType, renderSuggestionParams,} from "../../interfaces/general";
import CloseIcon from "./CloseButton";

export function renderInput(inputProps: inputPropsType) {
    const {
        InputProps,
        placeholder,
        dispatch,
        styleRoot,
        styleText,
        inputStyle,
        showClearIcon,
        ...other
    } = inputProps;

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                ...(styleRoot || {}),
            }}
        >
            <Box sx={{
                width: '100%',

                "& ::placeholder": {
                    color: primaryColor.inputTextColorOnDark,
                    fontSize: defaultFonts,
                },
                ...(styleText || {})
            }}>
                <TextField
                    sx={{
                        "&.MuiFormControl-root.MuiTextField-root .MuiInputBase-input.MuiInput-input": {
                            fontWeight: 300,
                            color: primaryColor.inputTextColorOnDark,
                            fontSize: defaultFonts,
                            ...(inputStyle
                                    ? {

                                        ...inputStyle as Object,
                                    }
                                    : {}
                            ),
                            '&::placeholder': {opacity: 0.5},
                        },
                    }}

                    InputProps={{
                        placeholder: placeholder,

                        ...InputProps,
                    }}
                    {...other}
                />
            </Box>

            {showClearIcon && (
                <CloseIcon
                    sx={{
                        marginTop: "12px",
                        marginLeft: "23px",
                    }}
                    handleClick={() => dispatch(openSearchBox(false))}
                />
            )}
        </Box>
    );
}

export function renderSuggestion(params: renderSuggestionParams) {
    const {suggestion, index, itemProps} = params;

    return (
        <MenuItem
            {...itemProps}
            key={index}
            variant={"suggestion"}
            component="div"
            sx={{
                color: primaryColor.whiteText,
                backgroundColor: primaryColor.drawerBackground,
                marginY: "5px",
                "&:hover": {
                    backgroundColor: primaryColor.drawerHover,
                },
            }}
        >
            <Typography
                variant={"h7"}
                sx={{
                    color: primaryColor.searchSuggestionText,
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "normal",
                    textOverflow: "ellipsis",
                    overflow: "hidden",

                }}
            >
                {`${suggestion.code}: ${suggestion.name}`}
            </Typography>
        </MenuItem>
    );
}
