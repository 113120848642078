import React, {useEffect, useState} from "react";
import {addShippingAddress, getShippingAddresses} from "../../../reducers/address";
import {useAppDispatch, useAppSelector} from "../../../hook";
import ShippingAddressSmallCard, {ContainerBoxSmallCard} from "./ShippingAddressSmallCard";
import {Box, BoxProps, styled, Typography} from "@mui/material";
import ShippingAddressCheckout from "./ShippingAddressCheckout";
import {getDefaultShippingAddress} from "../../../reducers/cart";
import ShippingSameAsBillingAddress from "./ShippingSameAsBillingAddress";
import ShippingAddressMyData from "../../../components/myData/Addresses/ShippingAddressMyData";
import {ContainerBox} from "./style";
import PlusIcon from "../../../components/common/svg/plusIcon";
import {primaryColor} from "../../../theme";

interface CardBoxProps extends BoxProps {
    isMyAccountPage?: boolean | undefined
}

const Container = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '11px',
    flexWrap: 'wrap',
    justifyContent: 'start',

    [theme.breakpoints.down("md")]: {
        justifyContent: 'center',
    },

}));
const CardBox = styled(({isMyAccountPage, ...otherProps}: CardBoxProps) => (
    <Box {...otherProps} />
))(({theme, isMyAccountPage}) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    [theme.breakpoints.down("md")]: {
        justifyContent: isMyAccountPage ? 'start' : 'center'
    },

}));


interface ShippingAddressCardListProps {
    isMyAccountPage?: boolean | undefined;
}

export default function ShippingAddressCardList(props: ShippingAddressCardListProps) {

    const {isMyAccountPage} = props;
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getShippingAddresses())
        dispatch(getDefaultShippingAddress())
    }, [])
    const isShippingAddressFormOpened = useAppSelector(state => state.address.isShippingAddressFormOpened)
    const shippingAddressesList = useAppSelector(state => state.address.shippingAddresses)
    const [isBillingAddressUsed, setIsBillingAddressUsed] = useState<boolean>(false)

    return (
        <>
            <Container sx={{display: isShippingAddressFormOpened ? 'none' : 'flex'}}>
                <CardBox isMyAccountPage={isMyAccountPage}>
                    {shippingAddressesList?.map((sh_address, index) =>

                        isMyAccountPage ?
                            <ShippingAddressMyData key={index} address={sh_address}/>
                            :
                            <ShippingAddressCheckout key={index}
                                                     address={sh_address}
                                                     isBillingAddressUsed={isBillingAddressUsed}
                                                     setIsBillingAddressUsed={setIsBillingAddressUsed}
                            />
                    )}
                    {!isMyAccountPage &&
                        <ShippingSameAsBillingAddress
                            setIsBillingAddressUsed={setIsBillingAddressUsed}
                            isBillingAddressUsed={isBillingAddressUsed}
                        />
                    }
                    <ContainerBox sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                                  onClick={() => dispatch(addShippingAddress())}
                    >
                        <PlusIcon fillColor={primaryColor.addNewShippingAddressIcon}/>
                    </ContainerBox>

                </CardBox>
            </Container>
            <Container sx={{display: isShippingAddressFormOpened ? 'flex' : 'none'}}>
                {shippingAddressesList?.map((sh_address, index) =>
                    <ShippingAddressSmallCard key={index}
                                              address={sh_address}
                    />
                )}
                {isShippingAddressFormOpened &&
                    <ContainerBoxSmallCard
                        sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <Typography fontWeight={1000}>. . .</Typography>
                    </ContainerBoxSmallCard>
                }            </Container>
        </>
    )
}
