import {useState} from "react";
import theme, {primaryColor} from "../../../theme";
import {SvgIcon, useMediaQuery} from "@mui/material";

interface Dimensions {
    width: string | number;
    height: string | number;
}

interface ArrowProps {
    fill: string;
    rotate?: boolean;
    customSizes?: Dimensions;
}

export default function Arrow(props: ArrowProps) {

    const {fill, rotate, customSizes} = props

    const lgView = useMediaQuery(theme.breakpoints.down('lg'))
    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }

    return (
        !lgView ?
            <SvgIcon
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={
                    {
                        fill: (hover) ? primaryColor.badgeColor : fill,
                        width: "40px",
                        height: "80px"
                    }
                }
                viewBox="0 0 40 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M39.6738 39.9942C39.6764 41.3182 39.2152 42.6014 38.3704 43.6209L10.0371 77.6209C9.07525 78.7781 7.69308 79.5058 6.19466 79.644C4.69625 79.7821 3.20432 79.3194 2.04709 78.3575C0.889866 77.3957 0.16213 76.0135 0.0239787 74.5151C-0.114173 73.0167 0.348575 71.5248 1.31043 70.3675L26.6971 39.9942L2.2171 9.62087C1.74639 9.04124 1.39488 8.3743 1.18277 7.65837C0.970652 6.94245 0.902121 6.19167 0.981109 5.44918C1.0601 4.70668 1.28505 3.98711 1.64303 3.33184C2.00102 2.67657 2.48498 2.0985 3.0671 1.63087C3.64975 1.11192 4.3333 0.718887 5.07492 0.476395C5.81654 0.233902 6.60024 0.147186 7.37693 0.221665C8.15362 0.296143 8.90657 0.530205 9.58859 0.90921C10.2706 1.28822 10.867 1.80399 11.3404 2.42421L38.7104 36.4242C39.4217 37.473 39.7609 38.73 39.6738 39.9942Z"
                />
            </SvgIcon>
            :
            <SvgIcon
                sx={
                    {
                        transform: rotate ? 'rotate(180deg)' : '',
                        transition: 'transform 500ms',
                        width: customSizes?.width ? customSizes.width : "24px",
                        height: customSizes?.height ? customSizes.height : "12px"
                    }
                }
                viewBox="0 0 24 12"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.0047 11.6678C11.6153 11.6685 11.2379 11.5329 10.938 11.2844L0.938025 2.95111C0.597664 2.66821 0.383623 2.26169 0.34299 1.82098C0.302357 1.38027 0.43846 0.941471 0.721358 0.60111C1.00426 0.260749 1.41077 0.0467088 1.85149 0.00607597C2.2922 -0.0345569 2.731 0.101545 3.07136 0.384443L12.0047 7.85111L20.938 0.651111C21.1085 0.512668 21.3047 0.409282 21.5152 0.346895C21.7258 0.284509 21.9466 0.264353 22.165 0.287585C22.3834 0.310817 22.595 0.376978 22.7877 0.482268C22.9805 0.587558 23.1505 0.729899 23.288 0.90111C23.4407 1.07248 23.5563 1.27352 23.6276 1.49165C23.6989 1.70977 23.7244 1.94027 23.7025 2.16871C23.6806 2.39715 23.6118 2.6186 23.5003 2.8192C23.3888 3.01979 23.2371 3.1952 23.0547 3.33444L13.0547 11.3844C12.7462 11.5936 12.3765 11.6934 12.0047 11.6678Z"
                    fill={hover ? primaryColor.badgeColor : fill}/>
            </SvgIcon>


    )
}
