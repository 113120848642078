import {Box, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {getSocialLinks} from "../../../reducers/static";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {SocialElement} from "../../../interfaces/backend";
import {primaryColor} from "../../../theme";
import {Link} from "react-router-dom";

const BottomFooter = () => {
    const {t} = useTranslation('common')
    const social: Array<SocialElement> = useAppSelector(state => state.staticContent.social)
    const serverDate: Date = useAppSelector(state => state.metadata.serverDate)
    const dispatch = useAppDispatch()
    const year: number = serverDate.getFullYear();

    useEffect(() => {
        dispatch(getSocialLinks())
    }, [dispatch])

    return (
        <Container maxWidth={'2xl'}>
            <Box
                paddingTop={{xs: 2, md: 5}}
                paddingBottom={2}
                justifyContent={'center'}
                display={'flex'}
                alignItems={'center'}
                flexWrap={'wrap'}
                columnGap={4}
            >
                {social.map((im: SocialElement) =>
                    <Box key={im.code} maxWidth={80}>
                        <Link to={im.url} target='_blank' rel="noreferrer">
                            <Box
                                component={"img"}
                                src={im.image}
                                alt={im.name}
                                sx={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                        </Link>
                    </Box>
                )}
            </Box>
            <Box
                paddingBottom={3}
                color={primaryColor.textHeader}
                display={'flex'}
                justifyContent={'center'}
                columnGap={2}
            >
                <Typography variant={'subtitle2'}>{t('© Copyright {{ year }} dplain.de', {'year': year})}</Typography>
                <Typography variant={'subtitle2'}>{t('right')}</Typography>
            </Box>
        </Container>
    )
}

export default React.memo(BottomFooter)