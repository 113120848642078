import {memo, useEffect} from "react";
import {setMiniCartOpen} from "../../../../../../reducers/styleControls";
import {Link} from "react-router-dom";
import {Badge, Box, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../../../../theme";
import MiniCart from "../../MiniCart";
import {useAppDispatch, useAppSelector} from "../../../../../../hook";
import {useLocation} from "react-router";
import {useAccessRights} from "../../../../../accessControl/accessControlUtils";
import {getCart, setEventSourceCartItemsQuantity} from "../../../../../../reducers/cart";
import AddToCartIcon from "../../../../AddToCartIcon";
import initializeEventSource from "../../../../../../utils/eventSource";
import {CART_DISCOVER} from "../../../../../../constants/api";


const MiniCartMenu = () => {
    const cart = useAppSelector(state => state.cart.cart);
    const dispatch = useAppDispatch();
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();
    const isCartPage = location.pathname === '/cart';
    const isCustomerSupportPage = location.pathname === '/my-account/customer-support';
    const isAuthenticatedOrGuestAllowed = useAccessRights();

    const isMiniCartOpened = useAppSelector(
        state => state.styleControls.isMiniCartOpened
    );
    const isMiniCartOpenNeeded = useAppSelector(
        state => state.styleControls.isMiniCartOpenNeeded
    );

    useEffect(() => {
        if (isAuthenticatedOrGuestAllowed) {
            dispatch(getCart());
        }
    }, [isAuthenticatedOrGuestAllowed]);

    useEffect(() => {
        if (!isCustomerSupportPage) {
            return;
        }

        const eventSourcePromise = initializeEventSource(
            CART_DISCOVER,
            () => {
            },
            setData
        );
        return () => {
            eventSourcePromise.then(res => res?.abort());
        };
    }, [isCustomerSupportPage]);

    const setData = (data: { cartCount: number }) => {
        dispatch(setEventSourceCartItemsQuantity(data.cartCount));
    }

    return (
        <Box
            sx={{
                position: "relative",
                lineHeight: 0 //remove additional vertical space
            }}
            onMouseLeave={() => {
                !isMiniCartOpenNeeded && dispatch(setMiniCartOpen(false));
            }}
            onMouseEnter={() => {
                if (cart?.items?.length > 0 && !mobileView) {
                    dispatch(setMiniCartOpen(true));
                }
            }}
        >
            <Link to={"/cart"}>
                <Badge
                    badgeContent={cart?.itemsQuantity}
                    slotProps={{
                        badge: {
                            style: {
                                fontSize: "1rem",
                                backgroundColor: primaryColor.orangeHoverColor,
                            },
                        },
                    }}
                    color={"primary"}
                >
                    <AddToCartIcon
                        isActive={isMiniCartOpened || (mobileView && isCartPage)}
                        onClick={() => dispatch(setMiniCartOpen(false))}
                        sx={{
                            height: {xs: '20px', md: '26px', lg: '28px'},
                            width: 'auto'
                        }}
                    />
                </Badge>
            </Link>
            <MiniCart/>
        </Box>
    );
}

export default memo(MiniCartMenu);