import {Box, Grid, keyframes, Typography, useMediaQuery} from "@mui/material"
import * as React from "react";
import {useEffect, useState} from "react";
import theme, {highlightedFonts, primaryColor} from "../../theme";
import Page from "../page";
import ListingList from "./ListingList";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hook";
import Filters from "./filters/Filters";
import {useParams} from "react-router-dom";
import {Category, FilteredSparePart, ListingListTypes, MachinesSearch} from "../../interfaces/backend";
import {getMyMachinesSearch, getStandardMachines, getStandardMachinesSearch} from "../../reducers/machine";
import CustomPagination from "./Pagination";
import {useLocation, useNavigate} from 'react-router';
import qs from 'qs';
import {isNumber} from "../../utils/validation";
import {
    findSpareParts,
    getCategory,
    setCheckedBoxesList,
    setCloseFilter,
    setPricesFilter,
    setSelectedCategory,
    setSortValue
} from "../../reducers/sparePart";
import {BreadCrumbsObject} from "../../interfaces/general";
import {getFindProducts} from "../../reducers/quickSearch";
import EmptySearchResults from "./quickSearch/EmptySearchResults";
import MobileView from "./mobile";
import SortByItemsMenu from "../../components/myData/SortByItemsMenu";
import {productSortValues} from "../../constants/table";

const leftFlow = keyframes`
  to {
    transform: translateX(-15%);
  }
`

interface TreeItemsProps {
    type: ListingListTypes,
}

interface FilterProps {
    page: number,
    perPage: number,
    order: string,
    attribute: { [key: string]: Array<string> },
    minPrice: number | undefined,
    maxPrice: number | undefined,
}

const rightFlow = keyframes`
  from {
    transform: translateX(-15%);
  }
  to {
    transform: translateX(0);
  }
`

const transformProperties = '0.5s linear normal forwards'
const machineListFlowLeft = `${leftFlow} ${transformProperties}`
const machineListFlowRight = `${rightFlow} ${transformProperties}`

const ListingPage = (props: TreeItemsProps) => {
    const {type} = props
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useAppDispatch()
    const {t} = useTranslation('searchPage')
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()


    const [selectedPage, setSelectedPage] = useState<number>(1)

    const checkedBoxesList = useAppSelector(state => state.sparePart.checkedBoxesList)
    const search = qs.parse(location.search.replace('?', ''))
    const isMyMachine: boolean = type === ListingListTypes.MyMachine
    const isStandardMachine: boolean = type === ListingListTypes.StandardMachine
    const machines: MachinesSearch = useAppSelector(state => state.machine.machinesSearch)
    const category: Category = useAppSelector(state => state.sparePart.category)
    const findProducts: MachinesSearch = useAppSelector(state => state.quickSearch.findProducts)
    const spareParts: FilteredSparePart = useAppSelector(state => state.sparePart.searchResult)
    const {pricesFilter, selectedCategory, isFilterClose, sortValue} = useAppSelector(state => state.sparePart)


    const useData = (type: ListingListTypes) => {
        switch (type) {
            case ListingListTypes.MyMachine:
                return machines
            case ListingListTypes.Category:
                return spareParts
            case ListingListTypes.Search:
                return findProducts
            default:
                // starndard machines as default
                return machines
        }
    }
    const machineSearch = useData(type)
    const showFilters = machineSearch.itemsTotal === 0 && type === ListingListTypes.Search

    const makeBread = (): BreadCrumbsObject[] => [
        {name: t('common:home'), link: '/'},
        {
            name: type !== ListingListTypes.Category ? t(type) : category.name,
            link: isMyMachine ? '/my/machines' : isStandardMachine ? '/machines' : `/c/${params.alias}`
        },
    ];
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        if (!!search.page && isNumber(search.page) && selectedPage !== Number(search.page) && machineSearch.pagination.length >= Number(search.page)) {
            setSelectedPage(Number(search.page))
        }
        if (!(isMyMachine) && !(isStandardMachine) && type !== ListingListTypes.Search && type !== ListingListTypes.Category) {
            dispatch(getCategory
            ({
                alias: type
            }))
        }
    }, [])

    useEffect(() => {
        if (type === ListingListTypes.Category && params.alias) {
            dispatch(getCategory
            ({
                alias: params.alias
            }))
        }
    }, [params.alias, type])

    useEffect(() => {
        setSelectedPage(1)
    }, [selectedCategory, pricesFilter])

    useEffect(() => {
        dispatch(setCheckedBoxesList({}))
        dispatch(getStandardMachines());

    }, [type])


    useEffect(() => {
        if (isMobile) {
            dispatch(setCloseFilter(true));
        }
    }, []);


    const setupSearchAndNavigate = (path: string, queryParams?: Record<string, any>) => {
        const newSearch = {page: selectedPage, ...queryParams};
        const result = navigate({
            pathname: path,
            search: `?${qs.stringify(newSearch)}`,
        });
        return result;
    };

    const handleFilter = (commonParamsParam?: FilterProps) => {
        const commonParams = commonParamsParam || {
            page: selectedPage,
            perPage: 12,
            order: sortValue === 'sortAZ' ? 'asc' : 'desc',
            attribute: checkedBoxesList,
            minPrice: pricesFilter?.minPrice,
            maxPrice: pricesFilter?.maxPrice,
        };

        if (isMyMachine) {
            setupSearchAndNavigate('/my/machines', commonParams);
            dispatch(getMyMachinesSearch({...commonParams}));
        } else if (isStandardMachine) {
            setupSearchAndNavigate('/machines', commonParams);
            dispatch(getStandardMachinesSearch({...commonParams}));
        } else if (type === ListingListTypes.Search) {
            dispatch(getFindProducts({...commonParams, searchValue: search.searchValue || ''}));
        } else if (type === ListingListTypes.Category) {
            setupSearchAndNavigate(`/c/${params.alias}`, {...commonParams, category: selectedCategory || params.alias});
            dispatch(findSpareParts({...commonParams, category: selectedCategory || params.alias}));

        }
    };
    const [selectedSortValue, setSelectedSortValue] = useState<string>("sortAZ")


    useEffect(() => {
        const filter: FilterProps = {
            page: 1,
            perPage: 12,
            order: 'sortAZ',
            attribute: {},
            minPrice: undefined,
            maxPrice: undefined,
        }
        selectedPage !== 1 && setSelectedPage(1)
        dispatch(setSelectedCategory(""));
        dispatch(setCheckedBoxesList({}))
        dispatch(setPricesFilter({minPrice: undefined, maxPrice: undefined}))

        handleFilter(filter)
    }, [params.alias])


    useEffect(() => {
        dispatch(setSortValue(selectedSortValue))
    }, [selectedSortValue])

    useEffect(() => {
        handleFilter()
    }, [selectedPage, sortValue, checkedBoxesList, selectedCategory, pricesFilter, type])

    return (
        <Page breadCrumbs={makeBread()} matchToHeaderWidth={true}>
            <Typography
                sx={{
                    fontSize: mobileView ? '2.6rem' : '7rem',
                    fontWeight: 700,
                    lineHeight: '85px',
                    color: primaryColor.textTopHeader,
                    mt: '40px',
                    textAlign: 'center'
                }}
            >
                {type !== ListingListTypes.Category ? t(type) : category.name}
            </Typography>
            {mobileView ?
                <MobileView
                    showFilters={showFilters}
                    machineSearch={machineSearch}
                    type={type}
                    setSelectedPage={setSelectedPage}
                    selectedPage={selectedPage}
                    // setCloseOpenFilter={setCloseOpenFilter}
                    checkedBoxesList={checkedBoxesList}
                    handleFilter={handleFilter}
                    searchValue={search.searchValue}
                />
                : <Grid container
                        sx={{
                            marginTop: '50px',
                        }}
                >
                    <Grid xs={3} item display={'flex'}>
                        {!showFilters && <Filters
                            filters={machineSearch.filterResults?.attributes}
                            type={type}
                            setSelectedPage={setSelectedPage}
                            searchItemsCount={machineSearch.itemsTotal}
                        />}
                    </Grid>
                    <Grid xs={9} item container
                          sx={{
                              height: 'fit-content',
                              animation: isFilterClose ? machineListFlowLeft : machineListFlowRight,
                              paddingLeft: isFilterClose ? 0 : '25px'
                          }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '39px',
                                flexWrap: 'wrap',
                                padding: {md: '0px 5px', xl: '0px 20px'},
                            }}
                        >

                            <Box sx={{
                                marginTop: '-5px',
                            }}>

                                <SortByItemsMenu
                                    handleSortMenuItemClick={setSelectedSortValue}
                                    sortValue={selectedSortValue}
                                    sortByItems={productSortValues}
                                    translationNamespace="searchPage"
                                />
                            </Box>

                            {type === ListingListTypes.Search &&
                                <Typography
                                    sx={{
                                        margin: 'auto',
                                        paddingTop: {xs: '10px', lg: 0},
                                        textAlign: 'center',
                                        flex: {xs: '0 0 100%', lg: 1},
                                        order: {xs: 2, lg: 0},
                                        fontSize: highlightedFonts,
                                    }}
                                >
                                    {t('searchResult{{value}}', {value: !!search.searchValue ? search.searchValue : ''})}
                                </Typography>

                            }
                            <Box>
                                <Typography
                                    sx={{
                                        color: primaryColor.drawerText,
                                        fontSize: {xs: '1.8rem', lg: '2.4rem'},
                                        fontWeight: {xs: 400, md: 300},
                                    }}
                                >
                                    {t('itemsTotal{{value}}', {value: machineSearch.itemsTotal ? machineSearch.itemsTotal : 0})}
                                </Typography>
                            </Box>


                        </Box>

                        {machineSearch.itemsTotal > -1 && <Box
                            sx={{
                                width: '100%',
                                fontSize: '2rem',
                            }}
                        >
                            {/*{type === ListingListTypes.Search && ((machineSearch && machineSearch.itemsCount === 0) || !search.searchValue) ?
                            <EmptySearchResults/> :
                            <ListingList machineList={machineSearch} type={type}/>}*/}
                            {type === ListingListTypes.Search ?
                                (machineSearch && machineSearch.itemsCount === 0) ?
                                    <EmptySearchResults/> : !search.searchValue ?
                                        <EmptySearchResults/> :
                                        <ListingList machineList={machineSearch} type={type}/>
                                : <ListingList machineList={machineSearch} type={type}/>}
                        </Box>}

                    </Grid>
                </Grid>}
            {/* {type===ListingListTypes.Search && machineSearch.itemsTotal === 0 && <EmptySearchResults />}*/}
            <Box
                sx={{
                    color: primaryColor.drawerText,
                    margin: '85px auto 126px',
                    width: 'fit-content',
                }}
            >
                {(isFilterClose || !isMobile) && machineSearch.pagination?.length > 1 ?
                    <CustomPagination
                        pagination={machineSearch.pagination}
                        currentPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    /> : null}

            </Box>
        </Page>
    )
}

export default (ListingPage)