import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import {useEffect, useRef} from "react";
import {getLocaleDateString, getLocaleTimeString} from "../../../utils/localization";
import {useTranslation} from "react-i18next";
import Markdown from 'markdown-to-jsx'
import {useAppDispatch} from "../../../hook";
import {makeMyMessageRead} from "../../../reducers/myAccount";
import {MyMessage} from "../../../interfaces/backend";

const Message = (props: { data: MyMessage }) => {
    const {data} = props
    const dispatch = useAppDispatch()
    const messageRef = useRef<HTMLDivElement>(null)
    const {t} = useTranslation('myAccount')
    const isInput = data.isAuthorUser
    const isUnread = !data.isAuthorUser && data.isUnread
    // const textLinesArray = text.split("<br />")
    const style = {
        main: {
            width: {xs: '90%', md: '40%'},
            borderRadius: '20px',
            opacity: '0.7',
            alignSelf: !isInput ? 'none' : 'flex-end',
            paddingY: {xs: '15px', md: 0}
        },
        name: {
            paddingLeft: '17px',
            color: primaryColor.messageText,
            fontSize: '1.4rem'
        },
        boxStyle: {
            backgroundColor: !isInput ? primaryColor.outputMessage : primaryColor.inputMessage,
            borderRadius: '20px',
            fontSize: '1.4rem',
            padding: '15px',
            color: primaryColor.messageText,
            wordWrap: 'break-word'
        }
    }

    const observerHandler = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                dispatch(makeMyMessageRead({code: data.code}));
                if (messageRef.current) {
                    observer.unobserve(messageRef.current)
                }
            }
        })
    }

    useEffect(() => {
        if (!isUnread) {
            return
        }

        const observer = new IntersectionObserver(observerHandler, {threshold: 0.5})
        if (messageRef.current) {
            observer.observe(messageRef.current)
        }

        return () => {
            if (messageRef.current) {
                observer.unobserve(messageRef.current)
            }
        }
    }, [isUnread])

    return (
        <Box ref={messageRef} sx={style.main}>
            <Typography sx={style.name}>
                {isInput ? t('you') : t('support')}
            </Typography>
            <Box sx={style.boxStyle}>
                {/*{textLinesArray.map((line, key) =>*/}
                {/*    <Box key={key}>*/}
                <Markdown options={{
                    overrides: {
                        a: {
                            // component: Link,
                            props: {
                                target: '_blank',
                                rel: "noopener noreferrer"
                            },
                        },
                    },
                }}>
                    {data.message}
                </Markdown>
                {/*</Box>)}*/}
                <Box sx={{textAlign: 'end'}}>
                    {getLocaleDateString(data.createdAt)} {getLocaleTimeString(data.createdAt)}
                </Box>
            </Box>
        </Box>
    )
}

export default (Message)