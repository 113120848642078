import {Box, Tooltip, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../../theme";
import {useAppDispatch} from "../../../../hook";
import {resetSelectedTreeNode, setSparePartSchemaOpen} from "../../../../reducers/sparePart";
import {Schema} from "../../../../interfaces/backend";
import {useTranslation} from "react-i18next";
import ArrowButton from "../../../common/ArrowButton";
import {useState} from "react";
import ProductSchemaIcon from "../../../common/svg/productSchema";

interface TableTitleProps {
    schema: Schema[];
}

const TableTitle = (props: TableTitleProps) => {
    const {schema} = props;
    const dispatch = useAppDispatch();
    const {t} = useTranslation("spareParts");

    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingY: {xs: "15px", md: "35px"},
                paddingX: '35px',
                color: primaryColor.treeItemsColor,
            }}>

            <Box
                onClick={() => dispatch(resetSelectedTreeNode())}
                sx={{
                    display: 'flex',
                    width: 'fit-content',
                    cursor: 'pointer',
                    paddingY: "10px",
                    marginLeft: {xs: '-5px', md: '-10px', lg: '-35px'},
                }}
            >
                <ArrowButton
                    direction={"left"}
                    color={primaryColor.arrowButtonColorSlider}
                    width={35}
                />

                <Typography
                    sx={{
                        fontSize: defaultFonts,
                        fontWeight: 300,
                        paddingLeft: {xs: '10px', lg: '30px'},
                        marginRight: 'auto',
                    }}
                >
                    {t("partsListTableTitle")}
                </Typography>

            </Box>


            {schema?.length ? (
                <Tooltip title={t("seeProductSchema")}>
                    <Box
                        onClick={() => dispatch(setSparePartSchemaOpen(true))}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <ProductSchemaIcon
                            isActive={hover}
                            sx={{cursor: "pointer", height: "45px", width: "45px"}}
                        />
                    </Box>
                </Tooltip>
            ) : null}
        </Box>
    );
};

export default TableTitle;