import React from "react";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {setBillingFormOpen,} from "../../../reducers/address";
import {EditIconBox} from "../../../pages/Checkout/ShippingAddressCardList/style";
import {StyledTypography} from "../../common/StyledComponents";

import theme, {defaultFonts} from "../../../theme";
import BillingAddressForm from "./BillingAddressForm";

interface BillingAddressProps {
    isMyAccountPage?: boolean;
}

export default function BillingAddress(props: BillingAddressProps) {

    const {t} = useTranslation("myData");
    const dispatch = useAppDispatch();
    const {isMyAccountPage} = props;

    const me = useAppSelector((state) => state.auth.me);

    const countries = useAppSelector((state) => state.metadata.countries);

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const billingAddress = useAppSelector(
        (state) => state.address.billingAddress
    );

    const isBillingFormEditMode = useAppSelector(
        (state) => state.address.isBillingFormEditMode
    );

    const selectedCountry = countries.find(
        (v) => v.code === billingAddress?.country
    );

    const handleClick = () => {
        dispatch(setBillingFormOpen(true));
    };

    const BillingData = () => {
        return (
            billingAddress && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: (!isMyAccountPage && mobileView) ? 'center' : 'unset'
                    }}
                >
                    <StyledTypography>
                        {billingAddress.customer?.companyName}
                    </StyledTypography>
                    <StyledTypography>
                        {billingAddress.streetName} {billingAddress?.streetNumber}
                    </StyledTypography>
                    <StyledTypography>
                        {billingAddress.postalCode} {billingAddress.city}
                    </StyledTypography>
                    <StyledTypography>{selectedCountry?.name}</StyledTypography>
                </Box>
            )
        );
    };

    return (
        <>
            {isMyAccountPage && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: isBillingFormEditMode ? 'column' : 'row',
                    mb: '10px'
                }}>
                    <Typography sx={{
                        fontSize: defaultFonts, fontWeight: 300,
                        ml: 0, mr: "10px",
                    }}>
                        {t("billingAddress")}
                    </Typography>

                    {isBillingFormEditMode ?
                        <BillingAddressForm
                            billingAddress={billingAddress}
                        />
                        : <>
                            {me.isAdmin &&
                                <Box
                                    sx={{mt: '2px'}}
                                    onClick={handleClick}
                                >
                                    <EditIconBox/>
                                </Box>
                            }
                        </>
                    }

                </Box>
            )}

            {!isBillingFormEditMode && <BillingData/>}
        </>
    );
}
