import {TableSortColumnDefinition} from "../interfaces/general";

export const userManagementColumns: TableSortColumnDefinition[] = [
    {
        id: 'userName',
        label: 'user',
    },
    {
        id: 'isEnabled',
        label: 'status',
    },
    {
        id: 'isAdmin',
        label: 'typeOfUser',
    },
    {
        id: 'cartLimit',
        label: 'cartLimit',
    },
    {
        id: '',
        label: '',
    }
];

export const orderSortValues: TableSortColumnDefinition[] = [
    {
        id: 'createdAt',
        label: 'createdAt',
    },
    {
        id: 'status',
        label: 'status',
    },
    {
        id: 'orderId',
        label: 'id',
    },
];

export const productSortValues: TableSortColumnDefinition[] = [
    {
        id: 'sortAZ',
        label: 'sortAZ'
    },
    {
        id: 'sortZA',
        label: 'sortZA'
    }
]