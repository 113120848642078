import React, {useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";
import AnimatedMenu from "./AnimatedMenu";
import {StaticPageContent} from "../../../interfaces/backend";
import ImageContent from "./ImageContent";

interface DesktopProps<T> {
    items: Array<T>;
    contentComponent: React.ComponentType<{ data: T }>;
}

const DesktopComponent = <T extends StaticPageContent>(props: DesktopProps<T>) => {
    const {items, contentComponent: ContentComponent} = props;
    const itemRefs = useRef<Array<Element>>([]);
    const [visible, setVisible] = useState<Array<boolean>>([]);

    useEffect(() => {
        itemRefs.current = itemRefs.current.slice(0, items.length);
        let newVisible: Array<boolean> = [];

        items.length && items.forEach(() => newVisible.push(false));
        items.forEach((item, index: number) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    const entry = entries[0];
                    newVisible = newVisible.map((item, i) =>
                        i === index ? entry.isIntersecting : item
                    );
                    setVisible(newVisible);
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                    }
                },
                {
                    threshold: 0.3,
                }
            );
            itemRefs && itemRefs.current && observer.observe(itemRefs.current[index]);
        });
    }, [items]);

    return (
        <Box
            sx={{
                paddingY: {
                    xs: "40px",
                    lg: "60px",
                },
                width: "100%",
                margin: "auto",
            }}
        >
            {items.map((item, index: number) => (
                <Box
                    ref={(el) => (itemRefs.current[index] = el as Element)}
                    key={index}
                    sx={{
                        paddingY: "10px",
                    }}
                >
                    <AnimatedMenu
                        index={index}
                        visible={visible}
                        content={<ContentComponent data={item}/>}
                        imageContent={<ImageContent image={item.image}/>}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default DesktopComponent;
