import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BillingAddress, ShippingAddress, User} from "../interfaces/backend";
import {initialAddressState} from "../interfaces/initialStates";
import {initialBillingAddress, initialContactData, initialShippingAddress,} from "./initialCartValues";

const initialState: initialAddressState = {
    shippingAddresses: [],
    shippingAddress: initialShippingAddress,
    billingAddress: initialBillingAddress,
    isEditableBillingAddress: false,
    useAsShipping: false,
    error: '',
    isShippingAddressFormOpened: false,
    isEditMode: false,
    contactData: initialContactData,
    isContactsFormEditMode: false,
    isBillingFormEditMode: false,
};

export const addressSlice = createSlice(
    {
        name: "address",
        initialState: initialState,
        reducers: {
            getBillingAddress: (state) => {
            },
            setBillingAddress: (state, action: PayloadAction<BillingAddress>) => {
                state.billingAddress = action.payload
            },
            setBillingFormOpen: (state, action: PayloadAction<boolean>) => {
                state.isBillingFormEditMode = action.payload;
            },

            setBillingAddressInput: (state, action: PayloadAction<{ target: EventTarget & HTMLInputElement }>
            ) => {
                state.billingAddress = {
                    ...state.billingAddress,
                    [action.payload.target.name]: action.payload.target.value,
                };
            },
            patchBillingAddress: (state, action: PayloadAction<{ billingAddress: BillingAddress }>) => {
            },

            setBillingCustomerInput: (
                state,
                action: PayloadAction<{ target: EventTarget & HTMLInputElement }>
            ) => {
                const target = action.payload.target;
                state.billingAddress.customer = {
                    ...state.billingAddress.customer!,

                    //note: It seems we need to change the 'customer?: Customer' to the 'customer: Customer' in the User interface. Because here as a temporary solution we're letting Typescript to treat 'customer' as a non-nullable value, allowing it to modify its properties without any type errors. Because of the non-null assertion (!), we need to make sure that the 'customer' value is indeed not null or undefined at runtime. Because If it turns out be null (/ undefined),  there will occur a runtime error. So we need to decide to keep the "customer?."  in the interface or update it by solving reason why it is not required.
                    [target.name]: target.value,
                };
            },


            setBillingCountry: (state, action: PayloadAction<{ country: string }>) => {
                state.billingAddress = {
                    ...state.billingAddress,
                    country: action.payload.country,
                };
            },
            setShippingAddresses: (state, action: PayloadAction<Array<ShippingAddress>>) => {
                // let addresses = action.payload
                // if (addresses) addresses.sort((a:ShippingAddress, b: ShippingAddress) => b.isDefault - a.isDefault)
                state.shippingAddresses = action.payload
            },
            addShippingAddress: state => {
                state.isShippingAddressFormOpened = true
                state.isEditMode = false
                state.shippingAddress = initialShippingAddress
            },
            editShippingAddress: (state, action: PayloadAction<ShippingAddress>) => {
                state.isShippingAddressFormOpened = true
                state.isEditMode = true
                state.shippingAddress = action.payload
            },
            successChangeShippingAddress: state => {
                state.isShippingAddressFormOpened = false
            },
            closeShippingAddressForm: state => {
                state.isShippingAddressFormOpened = false
            },
            setShippingAddressInput: (state, action: PayloadAction<{ target: EventTarget & HTMLInputElement }>) => {
                state.shippingAddress = {
                    ...state.shippingAddress,
                    [action.payload.target.name]: action.payload.target.value
                }
            },
            setShippingCountry: (state, action: PayloadAction<{ country: string }>) => {
                state.shippingAddress = {
                    ...state.shippingAddress,
                    country: action.payload.country
                }
            },
            getShippingAddresses: () => {
            },
            patchShippingAddress: (state, action) => {
            },
            postShippingAddress: (state, action) => {
            },
            deleteShippingAddress: (state, action) => {
            },
            getContactData: (state) => {
            },
            setContactData: (state, action: PayloadAction<User>) => {
                state.contactData = action.payload
            },
            setContactDataInput: (state, action: PayloadAction<{ target: EventTarget & HTMLInputElement }>) => {
                state.contactData = {
                    ...state.contactData,
                    [action.payload.target.name]: action.payload.target.value,
                };
            },
            setContactsFormEditable: (state, action: PayloadAction<boolean>) => {
                state.isContactsFormEditMode = action.payload;
            },
            patchContactData: (state, action) => {
            },
        },
    });
export const {
    getBillingAddress,
    setBillingAddress,
    setBillingFormOpen,
    setBillingAddressInput,
    setBillingCountry,
    setBillingCustomerInput,
    patchBillingAddress,
    setShippingAddresses,
    getShippingAddresses,
    addShippingAddress,
    editShippingAddress,
    successChangeShippingAddress,
    closeShippingAddressForm,
    patchShippingAddress,
    postShippingAddress,
    setShippingAddressInput,
    setShippingCountry,
    deleteShippingAddress,
    getContactData,
    setContactData,
    setContactDataInput,
    setContactsFormEditable,
    patchContactData,
} = addressSlice.actions;

export default addressSlice.reducer
