import {useEffect, useState} from "react";
import {Box, Grid, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";
import SearchBox from "../../common/searchBox";
import TemplateBox from '../index';
import {useAppDispatch} from "../../../hook";
import {getQuickSearchOptions} from "../../../reducers/quickSearch";
import {SearchSuggestionElement} from "../../../interfaces/backend";
import {getBatchProducts} from "../../../reducers/quickOrder";

const QuickSearch = () => {
    const lgView = useMediaQuery(theme.breakpoints.down('lg'))
    const {t} = useTranslation("quickOrder")
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<SearchSuggestionElement | null>(null);

    const handleClickAdd = () => {
        searchValue && dispatch(getBatchProducts({
            products: [{code: searchValue.code, quantity: 1}],
            isInitialLoad: false
        }));
        setSearchValue(null);
    };

    useEffect(() => {
        if (lgView) {
            handleClickAdd()
        }
    }, [searchValue])

    const handleInputChange = (inputValue: string) => {
        dispatch(getQuickSearchOptions({
            searchValue: inputValue,
            onlyOrderable: true
        }));
        if (!inputValue.trim()) {
            setSearchValue(null);
        }
    }

    const handleOnSelect = (item: SearchSuggestionElement) => setSearchValue(item);

    const searchBoxContent = (
        <Grid container sx={{
            paddingY: {
                xs: '5px',
                md: '10px',
                lg: '25px'
            },
            display: "flex",
            alignItems: "center"
        }}>
            <Grid item xs={12} lg={10}>
                <SearchBox
                    onInputChange={handleInputChange}
                    onSelect={handleOnSelect}
                    showClearIcon={false}
                    styleText={{
                        '& ::placeholder': {
                            color: primaryColor.inputTextColorOnWhite
                        },
                        '& .MuiFormControl-root.MuiTextField-root': {
                            borderBottom: "unset",
                        }
                    }}
                    placeholder={t('searchParts') as string}
                    styledPaper={{
                        marginTop: '20px',
                        textAlign: "left",
                        maxHeight: {xs: '250px', md: '400px'},
                        '& .MuiTypography-root': {
                            paddingX: {
                                xs: '25px', md: '0px',
                                '2xl': '10%'
                            },
                        }
                    }}
                    inputStyle={{
                        paddingLeft: {
                            xs: '40px',
                            md: '12px',
                            '2xl': '10%'
                        },
                        marginTop: '10px',
                        flex: 1,
                        backgroundColor: primaryColor.quickSearchInputFieldBackground,
                        color: primaryColor.inputTextColorOnWhite,
                        height: {
                            xs: '45px',
                            md: '60px',
                            lg: '90px'
                        },
                    }}
                    inputValue={searchValue?.name || ""}
                />

            </Grid>

            <Grid item lg={2}>
                <Box
                    component={'img'}
                    src={`/images/icons/${searchValue ? 'quickOrderCartIconActive.svg' : 'quickOrderCartIcon.svg'}`}
                    sx={{
                        cursor: searchValue ? 'pointer' : '',
                        display: {xs: 'none', lg: "inline"},
                    }}
                    onClick={handleClickAdd}
                    alt={"QuickOrder cart icon"}
                />
            </Grid>
        </Grid>
    );

    return (
        <TemplateBox
            title={t('quickSearchTitle')}
            description={t('quickSearchDescription')}
            relatedContent={searchBoxContent}
        />
    )
}

export default QuickSearch