import {call, put} from 'redux-saga/effects';
import {openErrorSnack, openInfoSnack} from '../reducers/snackbar';
import {RequestError} from './exceptions';
import {logout} from "../reducers/auth";
import {refreshToken} from "../sagas/authSaga";
import {addTask, deleteTask} from "../reducers/styleControls";

export function* callHttp(method: (...args: any[]) => any, url: string, data?: any, arrayKey?: string, useSpinner: boolean = true): any {
    let args: Array<any> = [url];
    if (data) {
        args.push(data)
    }
    if (arrayKey) {
        args.push(arrayKey)
    }

    try {
        if (useSpinner) yield put(addTask());
        return yield call(method, ...args);
    } catch (err: any) {
        if (err.code === 401) {
            // For ExpiredTokenException case
            if (err.type === 'ApiException' || err.type === 'UsernameNotFoundException' || err.type === 'BadCredentialsException') {
                yield put(openErrorSnack(err));
            } else if (err.type === 'ExpiredTokenException' || err.type === 'AccessDeniedException' /*token cookie is expired and we don't have it at all*/) {
                yield call(refreshToken);
                try {
                    if (useSpinner) yield put(addTask());
                    return yield call(method, ...args);
                } catch (err2: any) {
                    // probably err2.status should stay here
                    if (err2.code === 502) {
                        yield put(openInfoSnack('Server is offline. Please wait for api to be up.'));
                    }
                } finally {
                    if (useSpinner) yield put(deleteTask());
                }
            } else {
                // For AuthenticationException, InvalidTokenException, AccessDeniedException
                yield put(logout());
                return;
            }
        } else if (err.code === 303) {
            //throw new RequestError(err.status, err.message); - old way, should be checked for Articles when language is changed
            throw new RequestError(err)
        } else {
            //yield put(openErrorSnack(err));
            throw err
        }
    } finally {
        if (useSpinner) yield put(deleteTask());
    }
}

export function* callHttpWithoutSpinner(method: (...args: any[]) => any, url: string, data?: any, arrayKey?: string): any {
    return yield callHttp(method, url, data, arrayKey, false);
}