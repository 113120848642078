import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductDetailsPageContent} from "../interfaces/backend";
import {initialProductDetailsState} from "../interfaces/initialStates";
import {initialProduct} from "./initialCartValues";

const initialState: initialProductDetailsState = {
    productDetails: initialProduct
};

export const productDetailsSlice = createSlice({
        name: "productDetails",
        initialState: initialState,
        reducers: {
            getProductDetails: (state, action) => {
            },
            getProductDetailsByCode: (state, action) => {
            },
            setProductDetails: (state: initialProductDetailsState, action: PayloadAction<ProductDetailsPageContent>) => {
                state.productDetails = action.payload
            },
            getDocument: (state, action) => {
            },
        }
    }
)

export const {
    getProductDetails,
    getProductDetailsByCode,
    setProductDetails,
    getDocument,
} = productDetailsSlice.actions

export default productDetailsSlice.reducer