import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MachinesSearch} from "../interfaces/backend";

interface QuickSearchState {
    suggestions: any[];
    searchBoxOpen: boolean;
    findProducts: MachinesSearch

}

// set items -1 to check and avoid loading the screen until redux finish
const initialState: QuickSearchState = {
    suggestions: [],
    searchBoxOpen: false,
    findProducts: {
        items: [],
        pagination: [],
        currentPage: 1,
        itemsTotal: -1,
        itemsCount: -1,
        filterResults:
            {
                attributes: [
                    {
                        code: "",
                        name: "",
                        values: [
                            {
                                content: "",
                                count: 0
                            }
                        ]
                    }
                ]
            }
    }

};

export const quickSearchSlice = createSlice({
    name: "quickSearch",
    initialState,
    reducers: {
        setQuickSearchOptions: (state: QuickSearchState, {payload}) => {
            state.suggestions = payload;
        },
        getQuickSearchOptions: (state, action) => {
        },
        getFindProducts: (state, action) => {
        },
        setFindProducts: (state: QuickSearchState, {payload}) => {
            state.findProducts = payload;
        },
        openSearchBox: (state: QuickSearchState, action: PayloadAction<boolean>) => {
            state.searchBoxOpen = action.payload;
        },
    }
});

export const {
    getQuickSearchOptions,
    setQuickSearchOptions,
    getFindProducts,
    setFindProducts,
    openSearchBox,
} = quickSearchSlice.actions;

export default quickSearchSlice.reducer;
