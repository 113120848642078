import {useAppDispatch, useAppSelector, useAppSettings} from "../../hook";
import {useTranslation} from "react-i18next";
import {setComment, setInternalOrderNumber} from "../../reducers/cart";
import Box from "@mui/material/Box";
import CheckoutDropzone from "./CheckoutDropzone";
import {StyledBox} from "../../components/common/StyledComponents";
import {openInfoSnack} from "../../reducers/snackbar";
import {ChangeEvent} from "react";


export default function AdditionalInformation() {
    const comment = useAppSelector(state => state.cart.comment)
    const internalOrderNumber = useAppSelector(state => state.cart.internalOrderNumber)
    const {shopUsesPrices, isInternalOrderNumberMandatory, showOrderDocuments} = useAppSettings();
    const {t} = useTranslation(['checkoutPage', 'messages',])
    const dispatch = useAppDispatch()

    const handleChangeInternalNumber = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            e.target.value.length <= 30 ?
                setInternalOrderNumber(e.target.value) :
                openInfoSnack(t('messages:30 restriction'))
        )
    }

    const handleChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            e.target.value.length < 256 ?
                setComment(e.target.value) :
                openInfoSnack(t('messages:255 restriction'))
        )
    }

    return (
        <Box sx={{marginTop: "-10px"}}>
            <StyledBox
                label={
                    (isInternalOrderNumberMandatory ? '*' : '') +
                    t('checkoutPage:internalOrderNumber')
                }
                name={'number'}
                value={internalOrderNumber || ''}
                handleChange={handleChangeInternalNumber}
            />

            <StyledBox
                label={t("comment")}
                name='comment'
                multiline={true}
                maxRows={'255'}
                value={comment || ''}
                handleChange={handleChangeComment}
            />

            {shopUsesPrices && showOrderDocuments &&
                <CheckoutDropzone
                    // showFilesInPreview={false}
                    // messageSent={true}
                />
            }
        </Box>
    );
}