import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledTextField} from "../../common/StyledComponents";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {setFilteredUsers, setSearchUserByInput} from "../../../reducers/myAccount";

const StyledUserSearch = () => {
    const {t} = useTranslation("myAccount");
    const [searchTerm, setSearchTerm] = useState("");
    const users = useAppSelector(state => state.myAccount.users)
    const dispatch = useAppDispatch();


    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setSearchTerm(inputValue);
        filterUsers(inputValue);
    };

    useEffect(() => {
        if (searchTerm) {
            dispatch(setSearchUserByInput(searchTerm))
        }

    }, [searchTerm])

    const filterUsers = (inputValue: string) => {
        const nameFilter = inputValue.toLowerCase();
        const searchedUsers = users.filter((user) => {
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            const email = user.email.toLowerCase();
            const userType = user.isAdmin ? t("keyUser").toLowerCase() : t("user").toLowerCase();


            return (
                fullName.includes(nameFilter) ||
                email.includes(nameFilter) ||
                userType.includes(nameFilter)
            );
        });

        dispatch(setFilteredUsers(searchedUsers));
    };

    return (
        <Box sx={{width: "100%"}}>
            <StyledTextField
                required={false}
                label={t("searchUser")}
                name="searchUser"
                type="text"
                value={searchTerm}
                handleChange={handleSearchInputChange}
            />
        </Box>
    );
};

export default StyledUserSearch;
