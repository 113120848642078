import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import {Box} from "@mui/material";
import {primaryColor} from "../../theme";
import {MenuHomePage} from "../../interfaces/backend";
import Arrow from "../../components/common/svg/arrow";

export default function MenuBox(props: { menu: MenuHomePage }) {
    const {menu} = props
    const [rotate, setRotate] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(false)
    const handleChange = () => {
        setExpanded(!expanded)
        setRotate(!rotate)
    }

    const imageBoxStyling =
        {
            width: '100%',
            height: '145px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url('${menu.image}')`
        }

    const menuStyling =
        {
            height: '78px',
            opacity: 0.8,
            backgroundColor: primaryColor.articleDescriptionBackground,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer'
            },
            marginTop: "-78px"
        }

    return (
        <Box>
            <Box
                sx={imageBoxStyling}
            />
            <Box
                sx={menuStyling}
                onClick={() => handleChange()}
            >
                <Typography variant={'h2'}
                            sx={{
                                mb: "15px",
                                fontWeight: 300,
                                textTransform: "uppercase",
                                fontSize: {
                                    xs: '1.8rem',
                                }
                            }}
                >{menu.title} </Typography>
                <Arrow fill={(expanded) ? primaryColor.badgeColor : primaryColor.whiteText}
                       rotate={rotate}/>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: {xs: '264px', sm: '400px'},
                    overflow: 'hidden',
                    textAlign: 'center',
                    marginBottom: (expanded) ? '30px' : "15px",
                    maxHeight: (expanded) ? '250px' : '0px',
                    transition: 'margin-bottom 500ms, max-height 500ms',
                    margin: '0 auto'
                }}
                dangerouslySetInnerHTML={{
                    __html: menu.description
                }}
            />
        </Box>
    )
}