import {Box} from "@mui/material";
import InputWrapper from "../InputWrapper";
import {useAppDispatch} from "../../../hook";
import {updateCartItem} from "../../../reducers/cart";
import {ItemProps} from "./mobile/ProductItemDetails";


const QuantityUpdater = (props: ItemProps) => {
    const {item} = props;
    const dispatch = useAppDispatch();

    const onChangeQuantity = (code: string, quantity: number) => {
        dispatch(
            updateCartItem({
                code,
                quantity: quantity,
            })
        );
    };

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "120px",
            }}
        >
            <InputWrapper
                onChange={(quantity: number) => {
                    onChangeQuantity(item.code, quantity);
                }}
                value={item.quantity}
            />
        </Box>
    );
};

export default QuantityUpdater;