import Header from "../components/common/header";
import Footer from "../components/common/footer";
import {Box, Container, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../theme";
import {useAppDispatch, useAppSelector, useAppSettings} from "../hook";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {CookieConsent, getCookieConsentValue} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import BreadCrumbs from "../components/common/BreadCrumbs";
import {BreadCrumbsObject} from "../interfaces/general";
import {HeaderImageProvider} from "../components/common/contexts/HeaderImageContext";
import {setMiniCartOpen, setMyDataOpened} from "../reducers/styleControls";
import {useLocation} from "react-router-dom";

interface PageProps {
    children: ReactNode;
    showHeaderImage?: boolean;
    breadCrumbs?: Array<BreadCrumbsObject>;
    matchToHeaderWidth?: boolean;
}

const Page = (props: PageProps) => {
    const [consentAccepted, setConsentAccepted] = useState<boolean>(false);
    const [analyticsActivated, setAnalyticsActivated] = useState<boolean>(false);
    const {t} = useTranslation('startPage')
    const {googleAnalytics} = useAppSettings();
    const {breadCrumbs, children, showHeaderImage = true, matchToHeaderWidth} = props;
    const backgroundBlurred = useAppSelector(state => state.styleControls.backgroundBlurred)
    const dispatch = useAppDispatch()
    const cart = useAppSelector(state => state.cart.cart)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const pageRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation()

    useEffect(() => {
        if (pageRef.current) {
            pageRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [location.pathname]);

    useEffect(() => {
        if (getCookieConsentValue() === "true" && (!analyticsActivated || consentAccepted)) {
            if (googleAnalytics) {
                generateAnalyticsCode(googleAnalytics)
                setAnalyticsActivated(true)
            }
        }
    }, [googleAnalytics, consentAccepted])

    const generateAnalyticsCode = (codeString: string) => {
        const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gim;
        let match;
        while (match = re.exec(codeString)) {
            if (match[0].match(/<script.*?src=["|'](.*?)["|']/gim)) {
                generateFromSource(match[0]);
            } else {
                generateFromContent(match[0]);
            }
        }
    }

    const generateFromContent = (codeString: string) => {
        const contentString = codeString.replace(/<script\b[^>]*>|<\/script>/gi, '');
        setTimeout(contentString, 0)
    };

    const generateFromSource = (codeString: string) => {
        let script = document.createElement('script')
        let template = document.createElement('div')
        template.innerHTML = codeString

        const element = template.getElementsByTagName('script')[0]
        for (const attribute of element.attributes) {
            if (attribute) {
                script.setAttribute(attribute.name, attribute.value ? attribute.value : "")
            }
        }
        document.head.appendChild(script)
    };

    useEffect(() => {
        if (cart.itemsQuantity === 0 || cart.itemsQuantity === undefined) {
            dispatch(setMiniCartOpen(false))
        }
    }, [cart.itemsQuantity]);


    useEffect(() => {
        dispatch(setMyDataOpened(false))
    }, [dispatch]);


    return (
        <Box bgcolor={primaryColor.background} ref={pageRef}>
            <HeaderImageProvider value={showHeaderImage}>
                <Header/>
            </HeaderImageProvider>
            <Box
                minHeight={400}
                sx={{
                    filter: backgroundBlurred ? 'blur(25px)' : 'unset',
                }}
            >

                {breadCrumbs && !mobileView && (
                    <Container maxWidth={'2xl'}>
                        <BreadCrumbs links={breadCrumbs}/>
                    </Container>
                )}

                {matchToHeaderWidth ? (
                    <Container maxWidth={'2xl'}>
                        {children}
                    </Container>
                ) : (
                    <>
                        {children}
                    </>
                )}


            </Box>
            <Box sx={{filter: backgroundBlurred ? 'blur(25px)' : 'unset'}}>
                <Footer/>
            </Box>

            <CookieConsent
                buttonText={t('Accept all Cookies')}
                style={{background: "#2B373B"}}
                buttonStyle={{color: "#4e503b", fontSize: "1.3rem"}}
                expires={50}
                onAccept={() => {
                    setConsentAccepted(true);
                }}
                enableDeclineButton
                declineButtonText={t('Accept only necessary Cookies')}
            >
                <Typography>{t('useCookiesText')}</Typography>
                {t('cookieBannerText')}{' '}
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={t('cookieBannerLink') as string}
                    style={{
                        color: primaryColor.blue,
                        textDecoration: 'underline',
                    }}
                >
                    {t('learnMore')}
                </a>
            </CookieConsent>
        </Box>
    )
}

export default (Page)
