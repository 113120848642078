import {Box, Grid} from "@mui/material";
import Logo from "../logo";
import {primaryColor} from "../../../../theme";
import * as React from "react";
import LeftIcons from "./leftIcons";
import {useAppSelector} from "../../../../hook";
import AuthDynamicComponent from "../../../accessControl/AuthDynamicComponent";
import MainMenuMobile from "./mainMenuMobile";

export default function MobileView() {

    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);


    return (
        <Grid container
              sx={{
                  justifyContent: 'space-between',
                  paddingY: '16px',
                  paddingLeft: {xs: '22px', sm: '30px'},
                  paddingRight: '30px',
                  bgcolor: primaryColor.headerColor,
                  zIndex: 1300,
                  position: 'fixed',
                  top: serviceUserEmail ? '75px' : '0'
              }}
        >
            <Grid item xs={6}>
                <Box sx={{
                    width: '100%',
                    marginLeft: "auto"
                }}>
                    <Box display={'flex'}
                         justifyContent='start'
                         alignItems='center'
                    >
                        <AuthDynamicComponent
                            children={
                                <MainMenuMobile/>
                            }
                        />

                        <Logo mobileView={true}/>
                    </Box>

                </Box>
            </Grid>
            <Grid
                item
                container
                color={primaryColor.whiteText}
                xs={6}
                sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >

                <AuthDynamicComponent children={
                    <LeftIcons/>
                }/>


            </Grid>
        </Grid>

    )
}
