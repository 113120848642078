import {put, takeEvery} from 'redux-saga/effects';
import {CATEGORY, FILTER_CATEGORY} from '../constants/api';

import {callHttp} from '../utils/api';
import {get, getBlob} from '../utils/httpUtil';
import {filteredSearchResultAction, getCategoryAction, getSchemasAction} from "../interfaces/actions";
import {clearSchemas, setCategory, setSchemas, setSearchResult} from "../reducers/sparePart";
import {Category, FilteredSparePart} from "../interfaces/backend";
import {openErrorSnack} from "../reducers/snackbar";


export function* findSpareParts(action: filteredSearchResultAction) {
    try {
        const searchResult: FilteredSparePart = yield callHttp(get, FILTER_CATEGORY(action.payload))
        yield put(setSearchResult(searchResult));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getCategory(action: getCategoryAction) {
    try {
        const category: Category = yield callHttp(get, CATEGORY(action.payload.alias))
        yield put(setCategory(category));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getSchemas(action: getSchemasAction) {
    try {
        yield put(clearSchemas());
        const {schemas} = action.payload;
        let newSchemas = [];

        for (let i = 0; i < schemas.length; i++) {
            const schema = schemas[i];

            try {
                const imageUrl: string = yield callHttp(getBlob, schema.url);

                const updatedSchema = {...schema, url: imageUrl};
                newSchemas.push(updatedSchema);
            } catch (error) {

                console.error('Error in getImages saga:', error);
            }
        }
        yield put(setSchemas(newSchemas));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}


export default function* sparePartSaga() {
    yield takeEvery('sparePart/findSpareParts', findSpareParts)
    yield takeEvery('sparePart/getCategory', getCategory)
    yield takeEvery('sparePart/getSchemas', getSchemas)
}

