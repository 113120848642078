import {Box} from "@mui/material";
import {primaryColor} from "../../../../theme";

export interface StatusBarProps {
    percent: number,
    isRejected: boolean
}

const OrderStatusBar = (props: StatusBarProps) => {
    const {percent, isRejected} = props;

    return (
        <Box
            sx={{
                height: '6px',
                width: '100%',
                display: 'flex',
                mt: "10px"
            }}
        >
            <Box
                sx={{
                    width: percent + '%',
                    height: '100%',
                    backgroundColor: primaryColor.orderStatusColor,
                    opacity: '1'
                }}
            />
            <Box
                sx={{
                    height: '6px',
                    width: (100 - percent) + '%',
                    backgroundColor: !isRejected ? primaryColor.ordersListStatusBackgroundColor : primaryColor.ordersListStatusRejectedBackgroundColor,
                    opacity: !isRejected ? '0.2' : '1'
                }}
            />
        </Box>
    )
}

export default OrderStatusBar
