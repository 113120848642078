import React from "react";
import {Box, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../../theme";
import {calculateCartPriceDetails} from "../../../../utils/productFunctions";
import {formatMoney} from "../../../../utils/localization";
import {useAppSettings,} from "../../../../hook";
import {ItemProps} from "./ProductItemDetails";


const CartItemPriceRow = (props: ItemProps) => {
    const {item} = props;
    const {showDiscount, showDiscountPerItem} = useAppSettings();
    const {prices} = calculateCartPriceDetails(item, showDiscountPerItem);

    return (
        <>
            {showDiscount && prices.length > 1 ? (
                <Box>
                    {prices.map((price, index) => (
                        <Box key={index}>
                            <Typography
                                sx={
                                    index < prices.length - 1
                                        ? {
                                            textDecoration: "line-through",
                                            textDecorationColor: primaryColor.lineThroughPrice,
                                            textDecorationThickness: "1px",
                                            textDecorationTrim: "auto",
                                            color: primaryColor.lineThroughPrice,
                                            opacity: 0.3,
                                            fontSize: defaultFonts,
                                        }
                                        : {
                                            fontSize: defaultFonts,
                                            color: primaryColor.discountCheckoutPage,
                                        }
                                }
                            >
                                {formatMoney(price)}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Typography>{formatMoney(prices.pop() as number)}</Typography>
            )}

        </>
    );
};

export default CartItemPriceRow;
