import Message from "./Message";
import {Box} from "@mui/material";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {MyMessage} from "../../../interfaces/backend";
import {addMyMessage, getMyMessages} from "../../../reducers/myAccount";
import {useAppDispatch, useAppSelector} from "../../../hook";
import initializeEventSource from "../../../utils/eventSource";
import {MY_MESSAGES_DISCOVER} from "../../../constants/api";

const style = {
    messageBox: {
        padding: {xs: '25px 30px', md: '30px 50px'},
        display: 'flex',
        flexDirection: 'column',
        height: '765px',
        overflow: 'auto'
    }
}

const MESSAGES_COUNT = 24

const Messages = () => {
    const dispatch = useAppDispatch()
    const myMessages = useAppSelector(state => state.myAccount.myMessages)
    const [height, setHeight] = useState<number>(0);
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const retrieved = useRef(false)

    const handleScrollEvent = (e: SyntheticEvent) => {
        if (messagesContainerRef.current && e.currentTarget.scrollTop === 0) {
            setHeight(messagesContainerRef.current.scrollHeight)
            dispatch(getMyMessages({fromMessage: myMessages[myMessages.length - 1].code}))
        }
    };

    const getData = () => {
        if (!retrieved.current) {
            retrieved.current = true
            dispatch(getMyMessages({}))
        }
    }

    const setData = (data: MyMessage) => {
        dispatch(addMyMessage(data))
    }

    useEffect(() => {
        if (messagesContainerRef.current) {
            if (height !== 0) {
                messagesContainerRef.current.scrollTo(0, messagesContainerRef.current.scrollHeight - height)
                setHeight(0)
            } else {
                messagesContainerRef.current.scrollTo(
                    {
                        top: messagesContainerRef.current.scrollHeight,
                        behavior: myMessages.length <= MESSAGES_COUNT ? "auto" : "smooth"
                    }
                )
            }
        }
    }, [myMessages])

    useEffect(() => {
        const eventSourcePromise = initializeEventSource(
            MY_MESSAGES_DISCOVER,
            getData,
            setData
        );
        return () => {
            eventSourcePromise.then(res => res?.abort());
        };
    }, []);


    return (
        <Box ref={messagesContainerRef} onScroll={handleScrollEvent} sx={style.messageBox}>
            {myMessages.slice(0).reverse().map((message, index) => (
                <Message
                    key={message.code}
                    data={message}
                />
            ))}
        </Box>
    )
}

export default Messages;