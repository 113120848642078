import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Cookies} from "react-cookie-consent";
import {initialAuthState} from "../interfaces/initialStates";
import {forgotPassAction} from "../interfaces/actions";
import {Customer, LoginResponse, Me, TemporaryAuthData, UserRoleTypes} from "../interfaces/backend";
import {initialUser} from "./initialCartValues";
import {SESSION_EXPIRATION} from "../constants/common";
import {extractTokensFromTempAuthData} from "../utils/httpUtil";
import {LOGOUT} from "../constants/api";

export const initialValue_Me: Me = initialUser

const initialState: initialAuthState = {
    token: Cookies.get('token'),
    refreshToken: Cookies.get('refreshToken'),
    isSuccessResetPassword: false,
    // isSuccessChangePassword: false,
    // remember: false,
    // sessionExpired: false,
    // redirectToLogin: false,
    role: UserRoleTypes.NormalUser,
    me: initialValue_Me,
    serviceCustomers: [],
    isSelectUserModalOpen: false,
    storedUserEmail: '',
};
// const expiredTime = new Date(new Date().getTime() +  1000 * 1000); // 1000 seconds

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers:
        {
            registerUser: (state, action) => {
            },
            receiveAuthData: (state: initialAuthState, action: PayloadAction<LoginResponse>) => {
                const {token, refreshToken, expire} = action.payload;
                const isDevelopMode = process.env.NODE_ENV === "development";
                Cookies.set('token', token, {
                    expires: SESSION_EXPIRATION / 86400,
                    secure: !isDevelopMode,
                    sameSite: 'Strict'
                });
                Cookies.set('refreshToken', refreshToken, {
                    expires: SESSION_EXPIRATION / 86400,
                    secure: !isDevelopMode,
                    sameSite: 'Strict'
                });

                state.token = token;
                state.refreshToken = refreshToken;

                // if there is email (when storeServiceUserEmail invoked) it means serviceCustomer has been selected, no need tempAuthData anymore;
                // if user is not service user (when receiveAuthData) no need on tempAuthData

                sessionStorage.removeItem('temporaryAuthData');
            },
            setTemporaryAuthData: (state: initialAuthState, action: PayloadAction<TemporaryAuthData>) => {
                sessionStorage.setItem('temporaryAuthData', JSON.stringify(action.payload));
            },
            login: (state, action) => {
            },
            logout: (state: initialAuthState) => {
                authSlice.caseReducers.setTemporaryAuthData(state, {
                    type: 'auth/setTemporaryAuthData',
                    payload: {
                        data: {token: state.token, refreshToken: state.refreshToken, expire: 0} as LoginResponse,
                        storedUrls: [LOGOUT({refreshToken: state.refreshToken})]
                    }
                });

                Cookies.remove('token')
                Cookies.remove('refreshToken')
                localStorage.removeItem('serviceCustomerEmail')

                return {
                    ...state,
                    token: '',
                    refreshToken: '',
                    isSelectUserModalOpen: false,
                    storedUserEmail: ''
                }
            },
            successResetPassword: (state: initialAuthState) => {
                state.isSuccessResetPassword = true
            },
            forgotPassword: (state, action: forgotPassAction) => {
            },
            setMyRole: (state: initialAuthState, action: PayloadAction<{ code: string }>) => {
                state.role = action.payload && action.payload.code ? action.payload.code : state.role
            },
            setMe: (state: initialAuthState, action: PayloadAction<Me>) => {
                state.me = action.payload;
                if (action.payload?.customer?.currency) {
                    sessionStorage.setItem('currency', action.payload.customer.currency)
                }
            },
            getMe: () => {
            },
            resetPassword: (state, action) => {
            },
            setSelectUserModalOpen: (state: initialAuthState, action: PayloadAction<boolean>) => {
                // token removed to prevent login until serviceCustomer not selected
                //  if serviceCustomer  is selected then no need tempAuthData
                action.payload ? Cookies.remove('token') : sessionStorage.removeItem('temporaryAuthData');
                state.isSelectUserModalOpen = action.payload;
            },
            storeServiceUserEmail: (state: initialAuthState, action: PayloadAction<string>) => {
                const userEmail = action.payload

                if (userEmail) {
                    const extractedTokens = extractTokensFromTempAuthData();
                    if (extractedTokens) {
                        authSlice.caseReducers.receiveAuthData(state, {
                            type: 'auth/receiveAuthData',
                            payload: extractedTokens.data
                        });
                    }
                    localStorage.setItem('serviceCustomerEmail', userEmail);
                }
            },
            getServiceUserFromStorage: (state: initialAuthState) => {
                const serviceCustomerEmail = localStorage.getItem('serviceCustomerEmail');

                if (serviceCustomerEmail) {
                    state.storedUserEmail = serviceCustomerEmail
                }
            },
            setServiceCustomers: (state: initialAuthState, action: PayloadAction<Customer[]>) => {
                state.serviceCustomers = action.payload
            },
            getServiceCustomers: () => {
            },
        }
})
export const
    {
        registerUser,
        receiveAuthData,
        login,
        logout,
        forgotPassword,
        setMyRole,
        setMe,
        getMe,
        successResetPassword,
        resetPassword,
        setSelectUserModalOpen,
        setTemporaryAuthData,
        storeServiceUserEmail,
        getServiceUserFromStorage,
        setServiceCustomers,
        getServiceCustomers
    } = authSlice.actions

export default authSlice.reducer
