import * as React from 'react';
import {ReactNode, useState} from 'react';
import {Box, styled, TypographyProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import theme, {primaryColor} from "../../../theme";
import CheckoutArrow from "../../common/svg/checkoutArrow";

export const AsteriskTypography = styled((props: TypographyProps) => (
    <Typography {...props} />
))(({theme}) => ({
    color: primaryColor.asteriskColor,
    marginRight: '4px',
    fontSize: "2rem",
    marginTop: "-5px"
}));

interface CheckoutAccordionProps {
    title: ReactNode,
    content: ReactNode,
    mandatory?: boolean
}

export default function CheckoutAccordion(props: CheckoutAccordionProps) {
    const {title, content, mandatory = false} = props

    const [rotate, setRotate] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(true)

    const handleChange = () => {
        setExpanded(!expanded)
        setRotate(!rotate)
    }

    return (
        <Box
            sx={{
                mt: '20px',
                paddingY: "15px",
                [theme.breakpoints.down("lg")]: {
                    paddingX: "40px"
                },
                [theme.breakpoints.down("md")]: {
                    backgroundColor: primaryColor.pdpBackground
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => handleChange()}
            >
                <Box
                    sx={{
                        fontSize: '2rem',
                        color: primaryColor.shoppingCartTitle,
                        mr: {xs: 0, md: '10px'},
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        display: "flex",
                        mb: {xs: '10px', md: 0}
                    }}
                >
                    {mandatory && <AsteriskTypography>*</AsteriskTypography>}
                    {title}
                </Box>
                <CheckoutArrow rotate={rotate}/>
            </Box>
            <Box
                sx={{
                    marginTop: "15px"
                }}
            >
                {expanded && content}
            </Box>
        </Box>
    )
}