import {put, select, takeEvery} from 'redux-saga/effects';
import {callHttp} from '../utils/api';
import {del, get, getBlob, post} from '../utils/httpUtil';
import {
    CART_DISCOUNT_DELETE,
    CART_DISCOUNT_SET,
    CHECKOUT_CART,
    INQUIRY_ORDER,
    ORDER,
    PAYMENT_METHOD,
    SHIPPING_ADDRESS,
    SHIPPING_OPTION,
} from '../constants/api';
import {openErrorSnack, openSuccessSnack,} from '../reducers/snackbar'
import {Cart, Inquiry, Order, PaymentMethod, ShippingAddress, ShippingOption} from "../interfaces/backend";
import {
    getCart,
    getCheckoutCart,
    setCheckoutCart,
    setComment,
    setDefaultShippingAddress,
    setInternalOrderNumber,
    setOrderResult,
    setPaymentMethods,
    setShippingOptions,
    setStripePaymentIntents,
    setStripePublicData
} from "../reducers/cart";
import {orderAction} from "../interfaces/actions";
import i18next from "i18next";
import {initialStripePaymentIntents, initialStripePublicData} from "../reducers/initialCartValues";

i18next.loadNamespaces('messages');

export function* getCheckoutCartSaga() {
    try {
        const checkoutCart: Cart = yield callHttp(get, CHECKOUT_CART)
        yield put(setCheckoutCart(checkoutCart))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getShippingOptions() {
    try {
        const options: Array<ShippingOption> = yield callHttp(get, SHIPPING_OPTION)

        yield put(setShippingOptions(options))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getPaymentMethods() {
    try {
        const methods: Array<PaymentMethod> = yield callHttp(get, PAYMENT_METHOD)
        yield put(setPaymentMethods(methods))

    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* order(action: orderAction) {
    const {shopUsesPrices} = yield select(state => state.settings.items);
    shopUsesPrices ? yield* orderCart(action) : yield* orderInquiry(action)
}

function* orderCart(action: orderAction) {
    try {
        const {comment, internalOrderNumber} = action.payload
        const order: Order = yield callHttp(post, ORDER, {comment, internalOrderNumber})

        yield put(setComment(null))
        yield put(setInternalOrderNumber(null))
        yield put(setOrderResult(order))
        yield put(getCart())
        yield put(getCheckoutCart())
        yield put(setStripePaymentIntents(initialStripePaymentIntents))
        yield put(setStripePublicData(initialStripePublicData))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

function* orderInquiry(action: orderAction) {
    try {
        const {comment, internalOrderNumber} = action.payload
        const order: Inquiry = yield callHttp(post, INQUIRY_ORDER, {comment, internalOrderNumber})

        yield put(setComment(null))
        yield put(setInternalOrderNumber(null))
        yield put(setOrderResult(order))
        yield put(getCart())
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getMessageFile(action: { type: string, payload: { url: string, name: string } }) {
    try {
        const {url, name} = action.payload
        const file: string = yield callHttp(getBlob, url);
        const a = document.createElement('a');
        a.href = file;
        a.download = name;
        a.click();
        a.remove();
        document.addEventListener('focus', (w) => {
            window.URL.revokeObjectURL(file);
        });
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getDefaultShippingAddress() {
    try {
        const addresses: ShippingAddress[] = yield callHttp(get, SHIPPING_ADDRESS);
        yield put(setDefaultShippingAddress(addresses));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* removeCheckoutVoucher(action: { type: string, payload: { code: string } }) {
    const {code} = action.payload;

    try {
        yield callHttp(del, CART_DISCOUNT_DELETE(code));
        yield put(openSuccessSnack(i18next.t('messages:Discount code removed')));

        yield put(getCheckoutCart());

    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* addCheckoutVoucher(action: { type: string, payload: { code: string } }) {
    const {code} = action.payload
    try {
        const checkoutCart: Cart = yield callHttp(post, CART_DISCOUNT_SET, {code});
        yield put(openSuccessSnack(i18next.t('messages:Discount code added')));

        yield put(setCheckoutCart(checkoutCart));

    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export default function* checkoutSaga() {
    yield takeEvery('cart/getShippingOptions', getShippingOptions)
    yield takeEvery('cart/getPaymentMethods', getPaymentMethods)
    yield takeEvery('cart/order', order)
    yield takeEvery('cart/getMessageFile', getMessageFile)
    yield takeEvery('cart/getCheckoutCart', getCheckoutCartSaga)
    yield takeEvery('cart/getDefaultShippingAddress', getDefaultShippingAddress)
    yield takeEvery('cart/addCheckoutVoucher', addCheckoutVoucher)
    yield takeEvery('cart/removeCheckoutVoucher', removeCheckoutVoucher)
}

