import {StyledBox} from "../../../components/common/StyledComponents";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import ButtonsBox, {buttonsBoxMainWrapper} from "../../common/ButtonsBox";
import {getContactData, patchContactData, setContactsFormEditable} from "../../../reducers/address";
import {ContactData} from "../../../interfaces/backend";
import {Box, FormControl} from "@mui/material";
import {useEffect, useState} from "react";
import {StyledContainer} from "../../common/StyledComponents";

interface ContactsFormProps {
    contact: ContactData;
    onInputChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ContactsForm = (props: ContactsFormProps) => {
    const {t} = useTranslation("myData")


    const dispatch = useAppDispatch();
    const {contact, onInputChange} = props;
    const [formValid, setFormValid] = useState(false);


    const handleConfirm = () => {
        dispatch(patchContactData({contact: contact}));
        dispatch(setContactsFormEditable(false));
    };

    const handleCancel = () => {
        dispatch(setContactsFormEditable(false));
        dispatch(getContactData());

    };

    useEffect(() => {
        const validateForm = () => {

            return !(!contact.firstName ||
                !contact.firstName.trim() ||
                !contact.lastName ||
                !contact.lastName.trim() ||
                !contact.email ||
                !contact.email.trim());
        };
        setFormValid(validateForm());
    }, [contact]);

    return (
        <FormControl sx={{
            margin: 'auto',
            width: '100%',
        }}>
            <form onSubmit={handleConfirm}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <StyledContainer>
                        <StyledBox
                            label={t("firstName")}
                            name="firstName"
                            handleChange={onInputChange}
                            required={true}
                            value={contact.firstName || ""}
                        />

                        <StyledBox
                            label={t("lastName")}
                            name="lastName"
                            handleChange={onInputChange}
                            required={true}
                            value={contact.lastName || ""}
                        />
                    </StyledContainer>
                    <StyledContainer>
                        <StyledBox
                            label={t("phone")}
                            name="phone"
                            handleChange={onInputChange}
                            required={false}
                            value={contact.phone || ""}
                        />
                        <StyledBox
                            label={t("email")}
                            name="email"
                            value={contact.email}
                            disabled={true}
                        />
                    </StyledContainer>

                    <StyledContainer>
                        <StyledBox
                            label={t("mobile")}
                            name="mobile"
                            handleChange={onInputChange}
                            required={false}
                            value={contact.mobile || ""}
                        />
                    </StyledContainer>

                    <Box sx={{...buttonsBoxMainWrapper}}>
                        <ButtonsBox
                            handleCancel={handleCancel}
                            confirmButtonText={t("confirmContacts")}
                            disabled={!formValid}
                        />
                    </Box>
                </Box>

            </form>
        </FormControl>
    );
};
export default ContactsForm;
