import {FilterAttribute} from "../../../interfaces/backend";
import FilterCheckBox from "../filters/FilterCheckBox";
import FilterInAccordion from "./FilterInAccordion";
import {Typography} from "@mui/material";


export default function MobileFilter(props: {
    filter: FilterAttribute,
    handleCheckboxChange: (code: string, content: string) => void,
    isFilterSelected: boolean;

}) {

    const {filter, handleCheckboxChange, isFilterSelected} = props;


    return (
        <FilterInAccordion
            filterName={
                <Typography sx={{fontSize: "1.8rem"}}>{filter.name}</Typography>
            }
            isFilterSelected={isFilterSelected}
            filterContent={
                <>
                    {filter.values.map((item, key) => (
                        <FilterCheckBox
                            item={item}
                            key={key}
                            filterCode={filter.code}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    ))}
                </>
            }
        />

    )
}