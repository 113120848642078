import {Box, SxProps} from "@mui/material";

interface CloseIconProps {
    sx?: SxProps;
    handleClick?: () => void;
    customIcon?: string;
}

const CloseIcon = (props: CloseIconProps) => {
    const {sx, handleClick, customIcon} = props;
    const defaultIcon = "/images/cancel.svg";

    return (
        <Box
            component={"img"}
            src={customIcon || defaultIcon}
            sx={{
                height: {xs: "15px", lg: '18px'},
                cursor: 'pointer',
                opacity: 0.75,
                "&:hover": {
                    opacity: 1,
                },
                ...sx,
            }}
            alt={'close icon'}
            onClick={() => handleClick && handleClick()}
        />
    );
};


export default CloseIcon;