export const isEmailValid = (email: string): boolean => {
    const allSpacesRemoved = email.trim();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(allSpacesRemoved).toLowerCase());
}

export const isNumber = (value: any) => {
    if (typeof value === "string") {
        return !isNaN(Number(value));
    }
}

export const filterNonNumericChars = (text: string): string => {
    return text.replace(/[^0-9.,\s]/g, '');
};