import React from 'react';
import TableRow from '@mui/material/TableRow';
import {Box, Divider, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from '../../../theme';
import {useTranslation} from "react-i18next";
import {calculateCartPriceDetails, TakeFirst2DImage} from "../../../utils/productFunctions";
import {useAppDispatch, useAppSettings} from "../../../hook";
import {CartItem, CheckoutStageType} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import InputWrapper from "../../../components/common/InputWrapper";
import {deleteCartItem, updateCartItem} from "../../../reducers/cart";
import {StyledTableCell} from "../styleElements";
import DiscountInfo from "../../../components/common/ProductTableItem/DiscountInfo";
import ProductImage from '../../../components/common/ProductTableItem/ProductImage';
import ProductLink from '../../../components/common/ProductLink/ProductLink';
import {DeleteIcon} from '../../../components/quickOrder/ResultTable/DesktopTableRow';

export default function CartTableItemDesktop(props: { item: CartItem, position: number, stage: CheckoutStageType }) {
    const {item, position, stage} = props;
    const {t} = useTranslation('checkoutPage')
    const dispatch = useAppDispatch()
    const {shopUsesPrices, showDiscount, showDiscountPerItem} = useAppSettings();
    const {prices, lastPrice} = calculateCartPriceDetails(item, showDiscountPerItem);

    const onChangeQuantity = (code: string, quantity: number) => {
        dispatch(updateCartItem({
            code: code,
            quantity: quantity,
        }))
    }

    return (
        <>
            <TableRow>
                <StyledTableCell sx={{textAlign: 'center'}}>{position}</StyledTableCell>
                <StyledTableCell sx={{paddingX: '5px'}}>
                    <ProductImage src={TakeFirst2DImage(item.product.image)}/>
                </StyledTableCell>
                <StyledTableCell>
                    <ProductLink linkTo={item.product.alias} text={item.product.name}
                                 sx={{
                                     fontSize: '2rem',
                                 }}
                    />
                    <ProductLink linkTo={item.product.alias} text={`${t('materialNumber')}: ${item.product.code}`}
                                 sx={{
                                     fontSize: '2rem',
                                     marginBottom: '15px',
                                 }}
                    />
                </StyledTableCell>

                {shopUsesPrices ?
                    <StyledTableCell sx={{textAlign: 'right', fontWeight: 300}}>
                        {showDiscount && prices.length > 1 ?
                            <>
                                {
                                    prices.map((price, index) =>
                                        <Box key={`${price}_${index}`}>
                                            <Typography
                                                sx={{
                                                    fontSize: defaultFonts,
                                                    ...(index < prices.length - 1
                                                        ? {
                                                            textDecoration: 'line-through',
                                                            textDecorationColor: primaryColor.lineThroughPrice,
                                                            textDecorationThickness: '1px',
                                                            textDecorationTrim: 'auto',
                                                            color: primaryColor.lineThroughPrice,
                                                            opacity: 0.3,
                                                        }
                                                        : {color: primaryColor.discountCheckoutPage}),
                                                }}
                                            >
                                                {formatMoney(price)}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </>
                            :
                            <Typography sx={{
                                fontSize: defaultFonts,
                            }}>
                                {formatMoney(prices.pop() as number)}
                            </Typography>
                        }
                    </StyledTableCell>
                    : null
                }

                <StyledTableCell>
                    {stage === CheckoutStageType.ShoppingCart ?
                        <InputWrapper
                            onChange={(val: number) => onChangeQuantity(item.code, val)}
                            value={item.quantity}
                        />
                        :
                        <Typography sx={{
                            textAlign: 'center', fontSize: defaultFonts

                        }}>{item.quantity}</Typography>
                    }
                </StyledTableCell>

                {shopUsesPrices ?
                    <StyledTableCell sx={{textAlign: 'right'}}>
                        {formatMoney(lastPrice * item.quantity)}
                    </StyledTableCell>
                    :
                    null
                }
                {stage === CheckoutStageType.ShoppingCart ?
                    <StyledTableCell sx={{
                        minWidth: '55px',
                        width: "55px"
                    }}>
                        <DeleteIcon onClick={() => dispatch(deleteCartItem(item))}/>
                    </StyledTableCell>
                    : null
                }
            </TableRow>
            {stage === CheckoutStageType.Checkout && item.discounts.length > 0 ? (
                <TableRow>
                    <StyledTableCell colSpan={2}></StyledTableCell>
                    <StyledTableCell
                        sx={{
                            paddingTop: 0,
                            width: "100%",
                            maxWidth: "300px",
                            wordBreak: 'break-word'
                        }}
                    >
                        <DiscountInfo discounts={item.discounts}/>
                    </StyledTableCell>
                    <StyledTableCell colSpan={shopUsesPrices ? 3 : 1}></StyledTableCell>
                </TableRow>

            ) : null}

            <TableRow>
                <StyledTableCell colSpan={shopUsesPrices ? 6 : 4} sx={{paddingX: 0}}>
                    <Divider sx={{borderBottom: '0.5px solid ' + primaryColor.ordersTableDivider}}/>
                </StyledTableCell>
                {stage === CheckoutStageType.ShoppingCart && <StyledTableCell></StyledTableCell>}
            </TableRow>

        </>

    )
}