import {Box, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../../hook";
import {cancelOrder, getOrderDetails, getOrderHistory, setOrderDetails} from "../../../reducers/myAccount";
import MyOrderItem from "../common/myOrders/MyOrderItem";
import MyOrderDetails from "./MyOrderDetails";
import {Order} from "../../../interfaces/backend";
import CustomPagination from "../../ListingPage/Pagination";
import theme, {primaryColor} from "../../../theme";
import MyOrderItemTablet from "../common/myOrders/mobile/MyOrderItemTablet";
import MyOrderDetailsTablet from "./mobile/MyOrderDetailsTablet";
import SortByItemsMenu from "../../../components/myData/SortByItemsMenu";
import {orderSortValues} from "../../../constants/table";
import {setOperation} from "../../../utils/productFunctions";
import {OperationList, TableSortColumnDefinition} from "../../../interfaces/general";

export interface MyOrdersProps {
    id?: string,
}

const MyOrders = (props: MyOrdersProps) => {
    const {id} = props
    const dispatch = useAppDispatch()
    const orderHistory = useAppSelector(state => state.myAccount.orderHistory)
    const [sortValue, setSortValue] = useState<string>("createdAt")
    const [pageNumber, setPageNumber] = useState<number>(1)
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'))
    const orderDetails: Order = useAppSelector(
        (state) => state.myAccount.orderDetails
    );
    const cancelOrderId: string = useAppSelector(
        (state) => state.myAccount.cancelOrderId
    );

    const cancelOrderFlow = () => {
        dispatch(cancelOrder({
            orderId: cancelOrderId,
            filtering: {
                orderBy: sortValue,
                order: 'desc',
                page: pageNumber,
                perPage: 10
            }
        }))
    }

    const setSelectedOrder = (orderId: Order) => {
        dispatch(setOrderDetails(orderId))
    }

    useEffect(() => {
        if (!!id) {
            dispatch(getOrderDetails({orderId: id}))
        } else if (orderDetails) {
            dispatch(setOrderDetails({}))
        }
    }, [orderHistory, id])

    useEffect(() => {
        if (!id) {
            dispatch(getOrderHistory({
                orderBy: sortValue,
                order: 'desc',
                page: pageNumber,
                perPage: 10
            }))
        }
    }, [sortValue, pageNumber])

    const {shopUsesPrices} = useAppSettings();

    const renderLabelByOperation = (label: string) => {
        return setOperation(shopUsesPrices, label as keyof OperationList);
    };

    return (
        <Box sx={{backgroundColor: primaryColor.background, minHeight: 'inherit',}}>
            {!orderDetails.orderId && !id
                ? (orderHistory && <Box>
                    {orderHistory.items.length > 0 &&
                        <Box mb={'19px'} display={'flex'} sx={{
                            justifyContent: {
                                xs: 'center',
                                md: 'flex-start',
                            }
                        }}>
                            <SortByItemsMenu
                                handleSortMenuItemClick={setSortValue}
                                sortValue={sortValue}
                                sortByItems={orderSortValues.map((item: TableSortColumnDefinition) => ({
                                    id: item.id,
                                    label: renderLabelByOperation(item.label),
                                }))}
                                translationNamespace="myAccount"
                            />
                        </Box>
                    }

                    {orderHistory.items.map((order, i) =>
                        mobileView
                            ? <MyOrderItemTablet key={i} order={order} setSelectedOrder={setSelectedOrder}
                                                 cancelOrder={cancelOrderFlow}/>
                            : <MyOrderItem key={i} order={order} setSelectedOrder={setSelectedOrder}
                                           cancelOrder={cancelOrderFlow} sx={{paddingX: '10%'}}/>)
                    }

                    <Box sx={{marginTop: '72px', marginBottom: '59px', justifyContent: 'center', display: 'flex'}}>
                        {orderHistory.pagination.length > 1 &&
                            <CustomPagination currentPage={pageNumber} pagination={orderHistory.pagination}
                                              setSelectedPage={setPageNumber}/>}
                    </Box>
                </Box>)
                : mobileView ? <MyOrderDetailsTablet order={orderDetails}/> : <MyOrderDetails order={orderDetails}/>}
        </Box>
    )
}

export default (MyOrders)