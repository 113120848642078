import {useAllMyAccountTabs} from "../../../components/common/Tabs";
import AllTabs from "../../../components/common/Tabs/AllTabs";
import {useTranslation} from "react-i18next";
import Page from "../../page";


const AccountTabs = () => {

    const {t} = useTranslation(['myAccount']);
    const accountDetailsTabs = useAllMyAccountTabs();

    return (
        <Page>
            <AllTabs allTabs={accountDetailsTabs}
                     topTitle={String(t("myAccount:myAccount"))}
            />
        </Page>
    )
}

export default (AccountTabs)
