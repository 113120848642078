import {Box, ListItemButton, Typography} from "@mui/material";
import {primaryColor} from "../../../../theme";
import {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {accountMenu} from "../../../../data";
import {Translation, useTranslation} from "react-i18next";
import {setOpenedDrawerID} from "../../../../reducers/static";
import {MenuItem} from "../../../../interfaces/backend";
import {useAppDispatch, useAppSelector} from "../../../../hook";
import {setSelectLanguageOpen} from "../../../../reducers/styleControls";
import ArrowButton from "../../ArrowButton";
import {getSelectedLng} from "../../../../utils/localization";

const ListItemSample = ({title, id, isActive}: { title: string; id?: string; isActive: boolean }) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation("common");
    const bgColor = !isActive ? primaryColor.drawerBackground : primaryColor.drawerHover;
    const isSelectLanguageOpen = useAppSelector((state) => state.styleControls.isSelectLanguageOpen);

    const handleBoxClick = () => {
        dispatch(setSelectLanguageOpen(!isSelectLanguageOpen))
    };

    useEffect(() => {
        dispatch(setSelectLanguageOpen(false))
    }, []);

    return <ListItemButton
        sx={{
            paddingLeft: {xs: '45px', sm: '55px', lg: '65px'},
            paddingRight: '30px',
            backgroundColor: bgColor,
            '&:hover': {
                color: primaryColor.drawerText,
                backgroundColor: {xs: bgColor, md: primaryColor.drawerHover}
            },
        }}
        onClick={() => {
            if (id === "language-button") {
                handleBoxClick();
            } else {
                dispatch(setOpenedDrawerID(""))
                return null
            }
        }}
    >
        {id !== 'language-button' ?
            <Typography
                variant='h6'
                sx={{

                    marginLeft: '15px',
                    color: primaryColor.drawerText
                }}
            >
                {title}
            </Typography>
            :
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',

                }}>
                <Typography
                    variant={'h6'}
                    sx={{
                        marginLeft: '15px',
                        width: '50%',
                        color: primaryColor.drawerText
                    }}
                >
                    {t('language')}:
                </Typography>


                {!isSelectLanguageOpen &&
                    <Typography
                        variant={'h6'}
                        sx={{
                            color: primaryColor.drawerText,
                            marginRight: 'auto',
                            width: '100%'
                        }}
                    >
                        {t("language" + getSelectedLng())}
                    </Typography>}
                <ArrowButton
                    direction={isSelectLanguageOpen ? 'up' : 'down'}
                    color={primaryColor.arrowButtonColorSlider}
                    width={15}
                />
            </Box>
        }
    </ListItemButton>
}

const StyledLink = ({link, title, id}: { link: string, title: string, id: string }) => {
    return (
        <NavLink to={link} style={{width: '100%'}}>
            {({isActive}) => (
                <ListItemSample title={title} id={id} isActive={isActive}/>
            )}
        </NavLink>
    )
}


export const accountDrawerList: Array<React.ReactElement> = accountMenu.map(item =>
    <Translation ns='common'>{t => (
        <StyledLink
            key={item.id}
            id={item.id}
            link={item.route}
            title={t(item.text)}
        />
    )}
    </Translation>
)


export const normalDrawerList = (mainMenuLinks: MenuItem[]) => {
    const drawerMenu = [...mainMenuLinks, {link: "language", title: 'language', id: 'language-button'}];

    return drawerMenu.map(item => {
        return item.id !== 'language-button'
            ? <StyledLink key={item.id} id={item.id} link={item.link} title={item.title}/>
            : <ListItemSample key={item.id} id={item.id} title={item.title} isActive={false}/>
    });
};