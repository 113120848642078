import React from "react";
import {Box, MenuItem, Select, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {useTranslation} from "react-i18next";
import {TableSortColumnDefinition} from "../../interfaces/general";
import ArrowButton from "../common/ArrowButton";

export interface SortingProps {
    handleSortMenuItemClick: Function;
    sortValue: string;
    sortByItems: TableSortColumnDefinition[];
    translationNamespace: string;
}

const SortByItemsMenu = (props: SortingProps) => {

    const {handleSortMenuItemClick, sortValue, sortByItems, translationNamespace} = props;
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
    const {t} = useTranslation(translationNamespace);

    return (
        <Select
            value={sortValue}
            sx={{
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                    border: "none",
                    marginX: 'auto',
                    backgroundColor: "transparent",
                }
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        "& .MuiList-root.MuiMenu-list": {
                            paddingY: 0,
                        },
                        borderRadius: "unset",
                    },
                },
            }}

            IconComponent={(props) => (
                <Box
                    sx={{mt: '-5px', marginRight: {md: '-10px'}}}
                    {...props}
                >
                    <ArrowButton
                        direction={"up"}
                        color={primaryColor.arrowButtonColorSlider}
                        width={!isTablet ? 26 : (mobileView ? 16 : 23)}
                        sx={{opacity: '0.8'}}
                    />
                </Box>
            )}
        >
            {sortByItems.map((item, i) => (item.id &&
                <MenuItem
                    key={item.id}
                    value={item.id}
                    sx={{
                        "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
                            background: primaryColor.sortItemsByDialogHoveredBackground,
                        },
                        "&.MuiButtonBase-root.MuiMenuItem-root": {
                            background: primaryColor.sortItemsByDialogBackground,
                            height: "fit-content",
                            display: sortValue === item.id ? 'none' : 'flex'
                        },
                    }}
                    onClick={() => handleSortMenuItemClick(item.id)}
                >
                    <Typography
                        sx={{
                            color: primaryColor.drawerText,
                            fontSize: {xs: '1.8rem', lg: '2.4rem'},
                            fontWeight: {xs: 400, md: 300},
                            marginRight: {xs: '5px', md: "15px"},

                        }}
                    >
                        {
                            sortValue === item.id
                                ? t('sortBy' + item.label)
                                : (t(item.label))
                        }
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    );
};

export default SortByItemsMenu;