import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";

interface ButtonsBoxProps {
    confirmButtonText: string;
    handleCancel: () => void;
    disabled?: boolean;
}

export const buttonsBoxMainWrapper = {
    paddingTop: "50px",
    width: "100%",
    maxWidth: {
        xs: "390px",
        md: "275x",
        lg: "500px",
    },
    margin: {
        xs: "auto",
        lg: "auto 0 auto auto",
    },
}


const ButtonsBox = (props: ButtonsBoxProps) => {
    const {t} = useTranslation('common')
    const {handleCancel, confirmButtonText, disabled} = props;

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: {xs: "column-reverse", lg: "row"},
                justifyContent: "flex-end",
            }}
        >

            <Typography
                onClick={handleCancel}
                sx={{
                    cursor: "pointer",
                    textAlign: 'center',
                    width: {
                        xs: '200px'
                    },

                    color: primaryColor.linkColor,
                    "&:hover": {
                        color: primaryColor.badgeColor,
                    },
                    paddingY: {xs: '20px', lg: 'unset'},
                    fontWeight: 400,
                    fontSize: defaultFonts,
                }}
            >
                {t("cancel")}
            </Typography>

            <Button
                type={"submit"}
                variant={"contained"}
                disabled={disabled}
                sx={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: defaultFonts,
                }}
            >
                {confirmButtonText}
            </Button>


        </Box>
    );
};

export default ButtonsBox;
