import React, {ReactElement} from "react";
import {Box, Button, SxProps, Tooltip} from "@mui/material";
import {IconComponentProps} from "../../../interfaces/general";

export interface BaseProps extends IconComponentProps {
    buttonContent?: string;
    buttonStyles?: SxProps;
    onClick: () => void;
    isDisabled?: boolean;
    variant?: "contained" | "text";
}

interface ButtonWithIconProps {
    icon: ReactElement;
    baseProps: BaseProps;
}

interface TooltipWrapperProps {
    children: React.ReactNode;
}

const ButtonWithIcon = (props: ButtonWithIconProps) => {
    const {buttonContent, onClick, isDisabled, buttonStyles, variant} =
        props.baseProps;
    const {icon} = props;

    const TooltipWrapper = ({children}: TooltipWrapperProps) => {
        if (isDisabled || !buttonContent) {
            return <>{children}</>;
        } else {
            return (
                <Tooltip title={buttonContent}>{children as React.ReactElement}</Tooltip>
            );
        }
    };

    return (
        <TooltipWrapper>
            <Button
                sx={buttonStyles}
                onClick={onClick}
                disabled={isDisabled}
                variant={variant}
            >
                <Box
                    component={"span"}
                    sx={{paddingRight: buttonContent ? "21px" : 0, display: 'inline-flex'}}
                >
                    {icon}
                </Box>
                {buttonContent}
            </Button>
        </TooltipWrapper>
    );
};

export default React.memo(ButtonWithIcon);