import TableCell, {TableCellProps} from "@mui/material/TableCell";
import {primaryColor, tableFontSize} from "../../../theme";
import {styled} from "@mui/material";
import MinusIcon from "../../common/svg/minusIcon";
import PlusIcon from "../../common/svg/plusIcon";
import {TreeItem, treeItemClasses, TreeItemProps} from "@mui/x-tree-view";

export const StyledTableCell = styled((props: TableCellProps) => (
    <TableCell
        sx={{
            fontSize: tableFontSize.contentSecondary,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            borderBottom: "none",
            maxWidth: "50px",
        }}
        {...props}
    />
))(() => ({
    color: primaryColor.treeItemsColor,
}));

interface TreeProps extends TreeItemProps {
    expandIcon?: React.ReactNode;
    collapseIcon?: React.ReactNode;
}

export const StyledTreeItem = styled(
    ({
         nodeId,
         label,
         children,
         sx,
         ...otherProps
     }: TreeProps) => (
        <TreeItem
            nodeId={nodeId}
            label={label}
            {...otherProps}
            expandIcon={<PlusIcon/>}
            collapseIcon={<MinusIcon/>}
            sx={{
                ...sx,
                "& .MuiCollapse-root.MuiCollapse-vertical.MuiTreeItem-group":
                    {
                        marginLeft: "0px !important",
                    },
                "& .Mui-selected.Mui-focused": {
                    // backgroundColor: "unset",
                },
                "& .Mui-selected": {
                    // backgroundColor: "unset",
                },
                "& .MuiTreeItem-iconContainer": {
                    right: '20px',

                }
            }}
        >
            {children}
        </TreeItem>
    )
)(({nodeId, children}) => ({
    [`& .${treeItemClasses.content}`]: {
        width: "100%",
        position: "relative",
        padding: "15px 25px",
    },
    [`& .${treeItemClasses.label}`]: {
        "& .MuiBox-root:hover": {
            color: nodeId === 'root-parent' ? !children && primaryColor.selectedTreeTab : primaryColor.selectedTreeTab
        },
    },
    // [`& .${treeItemClasses.root}`]: {
    //    "& .MuiTreeItem-content:not(.MuiTreeItem-label):hover .MuiTreeItem-iconContainer svg path": {
    //     fill: primaryColor.selectedTreeTab,
    //     stroke: primaryColor.selectedTreeTab,
    //     }
    // },
}));