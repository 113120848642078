import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {addShippingOption, getShippingOptions} from "../../reducers/cart";
import RadioGroup, {RadioGroupItem} from "./RadioGroup";
import {ShippingMethod} from "../../interfaces/backend";
import {StyledTypography} from "../../components/common/StyledComponents";
import {formatMoney} from "../../utils/localization";
import InfoToolTip from "../../components/common/CustomTooltips/InfoTooltip";
import {Box} from "@mui/material";
import {checkEmptyTag} from "../../utils/productFunctions";

const RadioGroupItemContent = (props: { data: ShippingMethod }) => {
    const {description, price, name} = props.data;

    return (
        <>
            <Box component={"span"} sx={{marginRight: "15px"}}>{name}</Box>

            {
                price > 0 && <StyledTypography sx={{marginRight: '10px', display: "inline"}}>
                    {formatMoney(price)}
                </StyledTypography>
            }

            {checkEmptyTag(description) &&
                <InfoToolTip
                    toolTipContent={
                        <Box dangerouslySetInnerHTML={{__html: description}}/>
                    }
                />
            }
        </>
    )
}

export default function ShippingOptions() {

    const dispatch = useAppDispatch()
    const shippingOptions = useAppSelector(state => state.cart.shippingOptions)

    const shippingMethod = useAppSelector(state => state.cart.checkoutCart.shippingMethod)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(addShippingOption({id: event.target.value}))
    }
    useEffect(() => {
        dispatch(getShippingOptions())
    }, [])

    const radioGroupOptions: RadioGroupItem[] = shippingOptions?.map(
        (option): RadioGroupItem => ({
            id: option.id.toString(),
            content: <RadioGroupItemContent data={option}/>
        })
    ) || [];

    return (
        <RadioGroup
            options={radioGroupOptions}
            value={shippingMethod?.id?.toString()}
            handleChange={handleChange}
        />
    )
}
