import * as React from 'react';
import {ReactNode, useRef} from 'react';
import {Box} from "@mui/material";
import {primaryColor} from "../../../../theme";
import BaseCarousel from "..";
import Slider from 'react-slick';

interface ArticlesCarouselProps {
    articleBoxes: ReactNode[];
}

const ArticlesCarousel = (props: ArticlesCarouselProps) => {
    const {articleBoxes} = props;

    const sliderRef = useRef<Slider>(null);

    return (
        <Box
            sx={{
                "& .slick-dots": {
                    paddingLeft: {md: '20px', lg: "40px"},
                    maxWidth: "inherit",
                    bottom: "40px",
                    textAlign: "left",
                    listStyle: 'none',

                    "& li": {
                        backgroundColor: primaryColor.carouselWhiteDots,
                        opacity: 0.5,
                        width: 20,
                        height: 20,
                        display: 'inline-block',
                        margin: '0 10px ',
                        borderRadius: "50%",
                        "& button:before": {
                            display: "none"
                        }
                    },

                    "&  .slick-active": {
                        opacity: 1,
                    },
                },
            }}
        >
            <BaseCarousel
                ref={sliderRef}
                carouselItems={articleBoxes}
                slidesToShow={1}
                dots={true}
            />
        </Box>
    );
}
export default ArticlesCarousel;