import {Accordion, AccordionDetails, AccordionSummary, Box, FormGroup,} from "@mui/material";
import {ReactElement, useEffect, useState} from "react";
import {primaryColor} from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FilterInAccordionProps {
    filterName: ReactElement;
    filterContent: ReactElement;
    isFilterSelected: boolean;
}

const FilterInAccordion = (props: FilterInAccordionProps) => {
    const {filterName, filterContent, isFilterSelected} = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const handleChangeAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (isFilterSelected) {
            setIsExpanded(isFilterSelected);
        }
    }, [isFilterSelected]);

    return (
        <Box
            sx={{
                marginBottom: "30px",
                "& .MuiPaper-root.MuiAccordion-root": {
                    boxShadow: "none",
                },
            }}
        >
            <Accordion
                expanded={isExpanded}
                onChange={handleChangeAccordion}
                sx={{
                    backgroundColor: "transparent",
                    color: primaryColor.mobileFilterColor,
                    "& .MuiAccordionSummary-expandIconWrapper": {
                        color: primaryColor.mobileFilterColor,
                    },
                    "& .MuiAccordionDetails-root": {
                        padding: "0px",
                    },
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ": {
                        //transform: 'rotate(190deg)'
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "0px",
                    },
                    "& .MuiAccordionSummary-content": {
                        paddingRight: "10px",
                        margin: "0px",
                    },
                    "& .MuiAccordionSummary-root": {
                        minHeight: "10px",
                        padding: "0px",
                        width: "fit-content",
                    },
                }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    {filterName}
                </AccordionSummary>
                <AccordionDetails sx={{fontSize: "1.5rem"}}>
                    <FormGroup>{filterContent}</FormGroup>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default FilterInAccordion;
