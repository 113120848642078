import {put} from "redux-saga/effects";
import {getCart, getCheckoutCart, setCart, setComment, setInternalOrderNumber} from "../../reducers/cart";
import {openErrorSnack, openSuccessSnack} from "../../reducers/snackbar";
import {Cart, CartMethods} from "../../interfaces/backend";
import {callHttp} from "../../utils/api";
import {del, get, patch, post} from "../../utils/httpUtil";
import {CART, CART_ITEMS, CART_ITEMS_CODE} from "../../constants/api";
import {deleteCartItemAction, updateCartItemAction, updateCartMultipleItemsAction} from "../../interfaces/actions";
import i18next from "i18next";

i18next.loadNamespaces(['messages']);

class CartSagaClass implements CartMethods {
    * getCart() {
        try {
            const cart: Cart = yield callHttp(get, CART)
            yield put(setCart(cart))
        } catch (err: any) {
            yield put(openErrorSnack(err))
        }
    }

    * deleteCartItem(action: deleteCartItemAction) {
        try {
            const {code} = action.payload
            yield callHttp(del, CART_ITEMS_CODE(code));
            const cart: Cart = yield callHttp(get, CART);

            if (cart.items === undefined) {
                yield put(setComment(''))
                yield put(setInternalOrderNumber(''))
            }
            yield put(setCart(cart))
            yield put(getCheckoutCart())
            yield put(openSuccessSnack(i18next.t('messages:Removed from cart')));
        } catch (err: any) {
            yield put(openErrorSnack(err));
        }
    }

    * updateCartItem(action: updateCartItemAction) {
        try {
            const {code, quantity} = action.payload
            const cart: Cart = yield callHttp(patch, CART_ITEMS_CODE(code), {quantity})
            yield put(setCart(cart))
        } catch (err: any) {
            yield put(getCart())
            yield put(openErrorSnack(err))
        }
        yield put(getCheckoutCart())
    }

    * addCartItem(action: updateCartItemAction) {
        try {
            const {code, quantity} = action.payload
            const cart: Cart = yield callHttp(post, CART_ITEMS, {items: [{product: code, quantity}]})

            yield put(setCart(cart))
            yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
        } catch (err: any) {
            yield put(openErrorSnack(err))
        }
        yield put(getCheckoutCart())
    }

    * addCartMultipleItems(action: updateCartMultipleItemsAction) {
        try {
            const {items} = action.payload
            const cart: Cart = yield callHttp(post, CART_ITEMS, {items: items})

            yield put(setCart(cart))
            yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
        } catch (err: any) {
            yield put(getCart())
            yield put(openErrorSnack(err))
        }
    }
}

export default CartSagaClass;