import React from "react";
import {Box, Link, SxProps, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {defaultFonts, primaryColor} from "../../../theme";

interface ProductDetailsOverviewProps {
    iconSx?: SxProps;
    containerSx?: SxProps;
    linkTo: string;
    iconText?: string;
}

const ProductDetailsOverview = (props: ProductDetailsOverviewProps) => {
    const {linkTo, iconSx, containerSx, iconText} = props;
    const {t} = useTranslation("productPage");

    return (
        <Tooltip title={t("goToProductDetails")}>
            <Link
                href={`/product/${linkTo}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: primaryColor.baseTextColor,
                    ...containerSx
                }}
            >
                <Box
                    className="clickable-element"
                    component={"img"}
                    src="/images/icons/product_information.svg"
                    sx={{...iconSx}}
                />

                {iconText && (
                    <Typography sx={{fontSize: defaultFonts}} className="clickable-element">
                        {iconText}
                    </Typography>
                )}
            </Link>
        </Tooltip>

    );
};

export default ProductDetailsOverview;