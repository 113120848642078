import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAppSelector, useAppSettings} from "../../hook";
import {StyledTypography} from "../../components/common/StyledComponents";

export default function InvoicingCompanyAddress() {
    const [showInvoicingCompanyAddress, setShowInvoicingCompanyAddress] = useState(false)

    const countries = useAppSelector(state => state.metadata.countries)

    const {
        companyName,
        companyStreetName,
        companyStreetNumber,
        companyPostalCode,
        companyCity,
        companyCountry
    } = useAppSettings();


    const country = countries.find(item => item.code === companyCountry?.toUpperCase())?.name


    useEffect(() => {
        setShowInvoicingCompanyAddress(!!companyName && !!companyPostalCode && !!companyCity && !!country)
    }, [companyName, companyPostalCode, companyCity, country]);


    return (
        showInvoicingCompanyAddress ?
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: {xs: 'center', md: 'start'}
                }}>
                <StyledTypography sx={{textTransform: 'uppercase'}}>{companyName}</StyledTypography>
                <StyledTypography>{companyStreetName} {companyStreetNumber}</StyledTypography>
                <StyledTypography>{companyPostalCode} {companyCity}</StyledTypography>
                <StyledTypography>{country}</StyledTypography>
            </Box> : null
    )
}