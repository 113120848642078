import {getOptions} from "./httpUtil";
import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";

class RetriableError extends Error {
}

class FatalError extends Error {
}

interface Props {
    link: string,
    topic: string,
    token: string;
}

const initializeEventSource = async (
    discoverLink: string,
    getDataHandler: () => void,
    onMessageHandler: (data: any) => void
) => {
    const response = await fetch(discoverLink, getOptions())
    // await response.json().then((data: Props) => {
    //     if (data.link && data.topic && data.token) {
    //         const token = data.token
    //         const hub = new URL(data.link, window.origin);
    //         hub.searchParams.append('topic', data.topic);
    //
    //         fetchEventSource(hub.toString(),
    //             {
    //                 openWhenHidden: true,
    //                 headers: {
    //                     'Authorization': 'Bearer ' + token,
    //                 },
    //                 async onopen(response) {
    //                     if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
    //                         getDataHandler();
    //                     } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
    //                         console.error("response failed with error", response);
    //                     }
    //                 },
    //                 onmessage(msg) {
    //                     // if the server emits an error message, throw an exception
    //                     // so it gets handled by the onerror callback below:
    //                     if (msg.event === 'FatalError') {
    //                         console.error("Message Fatal Error");
    //                     }
    //                     const eventData: MyMessage = JSON.parse(msg.data);
    //                     onMessageHandler([eventData]);
    //                 },
    //                 onclose() {
    //                     // if the server closes the connection unexpectedly, retry:
    //                 },
    //                 onerror(err) {
    //                     console.error(err);
    //                 }
    //             });
    //     }
    // });

    return await response.json().then((data: Props) => {
        if (data?.link && data?.topic && data?.token) {
            const token = data.token

            const hub = new URL(data.link, window.origin);
            hub.searchParams.append('topic', data.topic);
            const ctrl = new AbortController();
            fetchEventSource(
                hub.toString(),
                {
                    signal: ctrl.signal,
                    openWhenHidden: true,
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                    async onopen(response) {
                        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                            getDataHandler();
                        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                            console.error("response failed with error", response);
                            throw new FatalError();
                        } else {
                            throw new RetriableError();
                        }
                    },
                    onmessage(msg) {
                        // if the server emits an error message, throw an exception
                        // so it gets handled by the onerror callback below:
                        if (msg.event === 'FatalError') {
                            console.error("Message Fatal Error");
                        }
                        const eventData = JSON.parse(msg.data);
                        onMessageHandler(eventData);
                    },
                    onclose() {
                        // if the server closes the connection unexpectedly, retry:
                        throw new RetriableError();
                    },
                    onerror(err) {
                        console.error(err);
                        if (err instanceof FatalError) {
                            throw err; // rethrow to stop the operation
                        }
                    }
                });
            return Promise.resolve(ctrl);
        }
    });
};

export default initializeEventSource;