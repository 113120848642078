import {Box, Typography} from "@mui/material";
import {t} from "i18next";
import ProductLink from "../../ProductLink/ProductLink";
import {CartItem} from "../../../../interfaces/backend";

export interface ItemProps {
    item: CartItem;
}

interface ProductItemDetailsProps extends ItemProps {
    withQuantity?: boolean;
}

const ProductItemDetails = (props: ProductItemDetailsProps) => {
    const {item, withQuantity} = props;

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <ProductLink
                linkTo={item.product.alias}
                text={item.product.name}
            />
            <ProductLink
                linkTo={item.product.alias}
                text={`${t("materialNumber")}: ${item.product.code}`}
                sx={{
                    fontSize: "1.5rem",
                    marginBottom: "16px",

                }}
            />
            {withQuantity && (
                <Typography
                    sx={{
                        // fontSize: "1.5rem",
                        fontWeight: 400,
                    }}
                >
                    {`${t("quantity")}: ${item.quantity}`}
                </Typography>
            )}
        </Box>
    );
};
export default ProductItemDetails;
