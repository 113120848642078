import React, {useEffect, useState} from "react";
import {Box, FormControl, useMediaQuery} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {useTranslation} from "react-i18next";
import {ContainerBox} from "../../../pages/Account/UserManagement/style";
import {StyledBox, StyledContainer, StyledTypography,} from "../../common/StyledComponents";
import CustomAutocomplete from "../../common/customAutoComplete";
import ButtonsBox from "../../common/ButtonsBox";

import {addUser, patchUser, setNewUserInput} from "../../../reducers/myAccount";
import {openErrorSnack} from "../../../reducers/snackbar";
import {filterNonNumericChars, isEmailValid} from "../../../utils/validation";
import {formatMoney, parseLocaleFloat} from "../../../utils/localization";
import IOSSwitchComp from "../../../pages/Account/UserManagement/IOSSwitchComp";
import {Title, User} from "../../../interfaces/backend";
import {CodeName} from "../../../interfaces/general";
import theme, {primaryColor} from "../../../theme";
import {useNavigate} from "react-router";
import NavTabBack from "../../common/Tabs/NavTabBack";
import {initialUser} from "../../../reducers/initialCartValues";
import {useParams} from "react-router-dom";


export default function UserEdit() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation("myAccount");
    const lgView = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();
    const params = useParams()


    const isModeEditUser = params.id !== 'new-user';
    const [user, setUser] = useState<User | null>(null);

    const users = useAppSelector(state => state.myAccount.users)
    const titles = useAppSelector((state) => state.metadata.titles);
    const title = titles.find(title => title.code === user?.title) || null;
    const [selectedTitle, setSelectedTitle] = useState<Title | null>(title)


    const userTypes: CodeName[] = [
        {code: 'keyUser', name: t("keyUser") as string},
        {code: 'user', name: t("user") as string}
    ];

    const userTypeItem = userTypes.find((item) => item.code === (user?.isAdmin ? 'keyUser' : 'user')) || null;
    const [selectedUserType, setSelectedUserType] = useState<CodeName | null>(userTypeItem)


    const handleInputChange = (name: string, value: any) => {
        setUser((prevUser) => (prevUser ? {...prevUser, [name]: value} : null));
        dispatch(setNewUserInput({target: {name, value}}));
    };

    const handleTitleChange = (title: Title | null) => {
        if (title) {
            handleInputChange('title', title.code);
        }
    };
    const handleRoleChange = (selectedRole: CodeName | null) => {
        if (selectedRole) {
            handleInputChange('isAdmin', selectedRole.code === 'keyUser');
        }
    };

    const validateForm = () => {
        if (!user) return false;

        let message = "";

        let isCartLimitValid = true;

        if (user.cartLimit) {
            try {
                parseLocaleFloat(user.cartLimit);
            } catch (e) {
                isCartLimitValid = false;
            }
        }

        if (!user.title) {
            message = t("Validation Messages:Salutation is Required!");
        } else if (!user.firstName) {
            message = t("Validation Messages:First Name is Required!");
        } else if (!user.lastName) {
            message = t("Validation Messages:Last Name is Required!");
        } else if (!user.email) {
            message = t("Validation Messages:Email is Required!");
        } else if (!isEmailValid(user.email)) {
            message = t("Validation Messages:Wrong email format!");
        } else if (user.isAdmin === null || user.isAdmin === undefined) {
            message = t("Validation Messages:User Role is Required!");
        } else if (!isCartLimitValid) {
            message = t("Validation Messages:Cart limit is in wrong format!");
        } else {
            return true;
        }
        dispatch(openErrorSnack({message: message}));
        return false;
    };
    const submitForm = () => {
        if (!user) return false;

        try {
            const numericValue = filterNonNumericChars(user.cartLimit)

            const cartLimit = numericValue ? parseLocaleFloat(numericValue) : null;


            let userSample = {
                id: isModeEditUser ? user.id : "",
                title: user.title,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                isAdmin: user.isAdmin,
                isEnabled: user.isEnabled,
                cartLimit: cartLimit,
                mobile: "",
                phone: "",
            };
            isModeEditUser
                ? dispatch(patchUser({user: userSample}))
                : dispatch(addUser({user: userSample}));
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const isSubmissionSuccessful = validateForm() && submitForm();

        if (isSubmissionSuccessful) {

            navigate({
                pathname: '/my-account/management',
            });
        }
    };

    const commonStyles = {
        width: {
            xs: '100%',
            lg: "48%",
        },
        minWidth: "195px",
    };

    const responsiveStyles = {
        width: "100%",
        marginTop: "30px",
    };

    useEffect(() => {
        if (isModeEditUser) {
            const selectedUser = users.find((user) => user.id === Number(params.id));
            if (selectedUser) {
                setUser({
                    ...selectedUser,
                    cartLimit: selectedUser.cartLimit ? formatMoney(parseInt(selectedUser.cartLimit)) : '',
                });
            }
        } else {
            setUser(initialUser);
        }
    }, [params.id, users, isModeEditUser]);

    useEffect(() => {
        if (user) {
            const userTypeItem = userTypes.find((item) => item.code === (user.isAdmin ? 'keyUser' : 'user')) || null;
            setSelectedUserType(userTypeItem);

            const title = titles.find((title) => title.code === user.title) || null;
            setSelectedTitle(title);

        }
    }, [user, titles, userTypes]);


    return (
        <ContainerBox>
            <NavTabBack
                title={t("backToUsersList")}
                linkTo={'/my-account/management'}
                sx={{
                    marginX: 0
                }}/>

            <FormControl
                sx={{
                    width: "100%",
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            flexDirection: "column",

                        }}
                    >
                        <StyledContainer>
                            <Box
                                sx={{
                                    ...commonStyles,
                                    paddingTop: "20px",
                                }}
                            >

                                <CustomAutocomplete
                                    options={titles}
                                    handle={handleTitleChange}
                                    label={t("title")}
                                    required={true}
                                    value={selectedTitle}

                                />
                            </Box>
                        </StyledContainer>
                        <StyledContainer>
                            <StyledBox
                                label={t("firstName")}
                                name="firstName"
                                handleChange={(e) => handleInputChange('firstName', e.target.value)}
                                required={true}
                                value={user?.firstName || ""}
                                style={{
                                    width: '48%',
                                    ...(lgView && responsiveStyles),
                                }}
                            />
                            <StyledBox
                                label={t("lastName")}
                                name="lastName"
                                handleChange={(e) => handleInputChange('lastName', e.target.value)}
                                required={true}
                                value={user?.lastName || ""}
                                style={{
                                    width: '48%',
                                    ...(lgView && responsiveStyles),
                                }}
                            />
                        </StyledContainer>

                        <StyledContainer>
                            <StyledBox
                                label={t("email")}
                                required={true}
                                name="email"
                                handleChange={(e) => handleInputChange('email', e.target.value)}
                                value={user?.email || ""}
                                style={{
                                    width: '48%',
                                    ...(lgView && responsiveStyles),
                                }}
                            />
                            <Box
                                sx={{
                                    ...commonStyles,
                                    paddingTop: {
                                        xs: "20px",
                                        lg: "unset",
                                    },
                                }}
                            >
                                <CustomAutocomplete
                                    options={userTypes}
                                    handle={handleRoleChange}
                                    label={t("typeOfUser")}
                                    required={true}
                                    value={selectedUserType}
                                />
                            </Box>
                        </StyledContainer>

                        <StyledContainer>
                            <StyledBox
                                label={t("cartLimit")}
                                required={false}
                                name="cartLimit"
                                handleChange={(e) => handleInputChange('cartLimit', e.target.value)}
                                value={user?.cartLimit}
                                style={{
                                    width: '48%',
                                    ...(lgView && responsiveStyles),
                                }}
                            />
                        </StyledContainer>

                        <StyledContainer>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: primaryColor.myAccountColorLimitText,
                                    justifyContent: "space-between",
                                    marginTop: '35px',
                                    ...commonStyles,

                                }}
                            >
                                <StyledTypography>
                                    {t("status")}
                                </StyledTypography>

                                <IOSSwitchComp
                                    checked={user?.isEnabled || false}
                                    onChange={() => handleInputChange('isEnabled', !user?.isEnabled)}
                                />
                            </Box>

                            <Box sx={{
                                marginTop: '35px',
                                ...commonStyles
                            }}>
                                <ButtonsBox
                                    handleCancel={() => {
                                        navigate({
                                            pathname: '/my-account/management',
                                        })
                                    }}
                                    confirmButtonText={
                                        isModeEditUser ? t("saveEditUser") : t("saveNewUSer")
                                    }
                                />
                            </Box>

                        </StyledContainer>

                    </Box>
                </form>
            </FormControl>
        </ContainerBox>
    );
}