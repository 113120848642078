import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {useAppSelector} from "../../hook";
import {useNavigate} from "react-router";
import {StyledTypography,} from "../../components/common/StyledComponents";
import OrderItemsList from "./OrderItemsList";
import {Inquiry} from "../../interfaces/backend";

export default function InquiryCompletedSummary() {
    const {t} = useTranslation("checkoutPage");
    const order: Inquiry = useAppSelector((state) => state.cart.orderResult);
    const navigate = useNavigate();

    useEffect(() => {
        if (order.orderId === "") navigate("/cart");
    }, [order]);

    return (
        <>
            <StyledTypography>{t("items")}</StyledTypography>
            <OrderItemsList items={order.items} withPrices={false}/>
        </>
    );
}
