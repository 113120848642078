import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../theme";
import {FilterAttributeValue} from "../../../interfaces/backend";
import {useAppSelector} from "../../../hook";


export default function FilterCheckBox(props: {
    item: FilterAttributeValue,
    filterCode: string,
    handleCheckboxChange: (code: string, content: string) => void
}) {
    const {item, filterCode, handleCheckboxChange} = props

    const checkedBoxesList = useAppSelector(state => state.sparePart.checkedBoxesList)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            <FormControlLabel
                sx={{
                    '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                        color: primaryColor.machinePageText,
                        fontSize: defaultFonts,
                        lineHeight: '28px',
                        fontWeight: 400,
                        width: '70%'
                    }
                }}
                control={
                    <Checkbox
                        sx={{pl: '11px'}}
                        checked={
                            checkedBoxesList &&
                            Object.keys(checkedBoxesList).length > 0 &&
                            Object.keys(checkedBoxesList).includes(filterCode) &&
                            checkedBoxesList[filterCode].includes(item.content)
                        }
                        onChange={() => handleCheckboxChange(filterCode, item.content)}
                    />
                }
                label={item.content}
            />
            <Typography sx={{
                color: primaryColor.machinePageText,
                fontSize: defaultFonts,
                lineHeight: '28px',
                fontWeight: 400
            }}>
                {item.count}
            </Typography>
        </Box>
    )
}