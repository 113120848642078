import {ReactElement} from 'react';
import {useAppSelector, useAppSettings} from "../../../hook";
import {Navigate} from "react-router-dom";

interface protectedRouteProps {
    children: ReactElement;
    guestCanAccess?: boolean
}

const ProtectedRoute = (props: protectedRouteProps) => {
    const {children, guestCanAccess = true} = props;
    const token = useAppSelector(state => !!state.auth.token);
    const {allowGuestAccess} = useAppSettings();
    const hasAccess = token || (guestCanAccess && allowGuestAccess);

    return (hasAccess
            ? (<>{children}</>)
            : (<Navigate replace to="/login"/>)
    )
}

export default (ProtectedRoute)