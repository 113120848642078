import {Box, FormGroup, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import {FilterAttribute} from "../../../interfaces/backend";
import FilterCheckBox from "./FilterCheckBox";

export default function Filter(props: {
    filter: FilterAttribute,
    handleCheckboxChange: (code: string, content: string) => void
}) {

    const {filter, handleCheckboxChange} = props

    return (
        <Box sx={{
            // width: '92%',
            marginY: '30px',
        }}>
            <Typography
                sx={{
                    fontSize: {xs: '1.8rem', lg: '2rem'},
                    color: primaryColor.inputLabelTextColor,
                    fontWeight: 300,
                    lineHeight: '28px',
                    marginBottom: '5px',
                    textTransform: 'uppercase'
                }}
            >
                {filter.name}
            </Typography>
            <FormGroup>
                {filter.values.map((item, key) =>
                    <FilterCheckBox
                        item={item}
                        key={key}
                        filterCode={filter.code}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                )}
            </FormGroup>
        </Box>
    )
}