import React from "react";
import {Box, Table, TableBody, TableContainer, TableFooter, TableHead, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import TableHeadComponent from "./TableHeadComponent";
import tableStyles from "./tableStyles";
import {useAppDispatch, useAppSettings} from "../../../hook";
import {addQuickOrderToCart, deleteQuickOrderProduct, updateOrderQuantity} from "../../../reducers/quickOrder";
import {QuickOrderProduct} from "../../../interfaces/general";
import theme, {primaryColor} from "../../../theme";
import DesktopTableRow from "./DesktopTableRow";
import MobileTableRow from "./MobileTableRow";
import AddToCartButton from "../../common/AddToCartButton";


export interface ResultTableProps {
    products: QuickOrderProduct[];
}

const ResultTable = (props: ResultTableProps) => {
    const {products} = props;
    const dispatch = useAppDispatch();
    const {t} = useTranslation('quickOrder');
    const {shopUsesPrices} = useAppSettings();

    const handleDelete = (productToDelete: string) => {
        dispatch(deleteQuickOrderProduct(productToDelete));
    };

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const handleQuantityChange = (code: string, quantity: number) => {
        dispatch(updateOrderQuantity({code, quantity}));
    };

    const renderRows = () => {

        return products.map((product, index) => (
            mobileView ?
                <MobileTableRow
                    key={product.code}
                    product={product}
                    index={index}
                    onDelete={() => handleDelete(product.code)}
                    onChangeQuantity={(quantity: number) => handleQuantityChange(product.code, quantity)}
                />
                :
                <DesktopTableRow
                    key={product.code}
                    product={product}
                    index={index + 1}
                    onDelete={() => handleDelete(product.code)}
                    onChangeQuantity={(quantity: number) => handleQuantityChange(product.code, quantity)}
                />
        ));
    };

    const itemTotalNumber = products.reduce((total, product) => {
        return total + product.quantity;
    }, 0);


    return (
        <>
            {mobileView ?
                renderRows()
                :
                <>
                    <Typography
                        variant={'h20'}
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            fontWeight: 300,
                            paddingLeft: '16px'
                        }}
                    >
                        {itemTotalNumber} {t('items')}
                    </Typography>
                    <TableContainer sx={tableStyles({isInquiry: !shopUsesPrices})}>
                        <Table>
                            <TableHead>
                                <TableHeadComponent/>
                            </TableHead>
                            <TableBody>
                                {renderRows()}
                            </TableBody>
                            <TableFooter>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>}

            <Box sx={{
                textAlign: {
                    xs: 'center',
                    md: 'end'
                },
                paddingY: '20px',
                paddingRight: {
                    md: '50px',
                    xl: '65px',
                    '2xl': '70px'
                },
                ...mobileView && {
                    maxWidth: '350px',
                    width: '80%',
                    margin: "auto",
                }
            }}>
                <AddToCartButton
                    buttonContent={(shopUsesPrices
                        ? t('addListToShoppingCart')
                        : t('addListToInquiry')) as string
                    }
                    onClick={() => {
                        dispatch(addQuickOrderToCart({products}));
                    }}/>
            </Box>
        </>
    );
};

export default ResultTable;
