import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialSnackbarState} from "../interfaces/initialStates";
import {MessageSnackbarAction} from "../interfaces/general";

const initialState: initialSnackbarState = {
    errorMessages: [],
    successMessage: "",
    infoMessage: ""
};

export const snackbarSlice = createSlice(
    {
        name: "snackbar",
        initialState: initialState,
        reducers: {
            openErrorSnack: (state: initialSnackbarState, action: PayloadAction<MessageSnackbarAction>) => {
                const {payload} = action;
                let errors: string[] = [];

                if (payload.code && (payload.code >= 500 && payload.code < 600)) {
                    errors.push('Internal Server Error');
                } else if (payload.fields?.length) {
                    errors = payload.fields.map(field => field.message)
                } else {
                    errors.push(payload.message);
                }

                errors = errors.filter(error => !!error);//filter empty error messages

                return {
                    ...state,
                    errorMessages: errors
                }
            },
            openSuccessSnack: (state: initialSnackbarState, action: PayloadAction<string>) => {
                state.successMessage = action.payload
            },
            openInfoSnack: (state: initialSnackbarState, action: PayloadAction<string>) => {
                state.infoMessage = action.payload
            },
        }
    }
)
export const {
    openSuccessSnack,
    openInfoSnack,
    openErrorSnack,
} = snackbarSlice.actions

export default snackbarSlice.reducer