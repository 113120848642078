import {useAppSelector} from "../../hook";
import {MenuHomePage} from "../../interfaces/backend";
import HomeContent from "./HomeContent";
import DesktopComponent from "../../components/common/ContentWithAnimation/DesktopComponent";

const DesktopHome = () => {
    const menus: Array<MenuHomePage> = useAppSelector(state => state.staticContent.menuHomePage)

    return (
        <>
            {menus.length > 0 && (
                <DesktopComponent<MenuHomePage>
                    items={menus}
                    contentComponent={HomeContent}
                />
            )}
        </>
    );
};
export default DesktopHome;