import React from "react";
import {useTranslation} from "react-i18next";
import {SelectedDocumentProps} from "./index";
import CustomAutocomplete from "../../common/customAutoComplete";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";


export interface FilterDocumentsProps {
    setSelectedDocument: React.Dispatch<React.SetStateAction<SelectedDocumentProps | null>>,
    selectedDocument: SelectedDocumentProps | null;
    documentsArray: SelectedDocumentProps[];
    defaultSelectedGroup: SelectedDocumentProps | null
}

const FilterDocumentsMenu = (props: FilterDocumentsProps) => {
    const {t} = useTranslation("machineDetails");

    const navigate = useNavigate();
    const location = useLocation();

    const {setSelectedDocument, selectedDocument, documentsArray} = props;
    const onFilterChange = (value: SelectedDocumentProps | null) => {
        setSelectedDocument(value)
        const groupFilter = value ? `&group=${value.code}` : ''
        navigate({
            pathname: location.pathname,
            search: `?tab=documents${groupFilter}`,
        });
    }
    return (
        <CustomAutocomplete options={documentsArray}
                            handle={value => onFilterChange(value || props.defaultSelectedGroup)}
                            label={t('builderGroup').toString()}
                            value={selectedDocument}/>
    );
};

export default FilterDocumentsMenu;
