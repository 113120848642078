import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface StyleControlsState {
    isMiniCartOpened: boolean,
    isShippingAddressFormOpened: boolean,
    tasks: number,
    isMyDataOpened: boolean,
    backgroundBlurred: boolean,
    isMiniCartOpenNeeded: boolean,
    isSelectLanguageOpen: boolean
}

const initialState: StyleControlsState = {
    isMiniCartOpened: false,
    isShippingAddressFormOpened: false,
    tasks: 0,
    isMyDataOpened: false,
    backgroundBlurred: false,
    isMiniCartOpenNeeded: false,
    isSelectLanguageOpen: false,

};

export const styleControlsSlice = createSlice(
    {
        name: "styleControls",
        initialState: initialState,
        reducers: {
            setMiniCartOpen: (state: StyleControlsState, action: PayloadAction<boolean>) => {
                state.isMiniCartOpened = action.payload
                state.backgroundBlurred = state.isMiniCartOpened
            },
            setIsMiniCartOpenNeeded: (state: StyleControlsState, action: PayloadAction<boolean>) => {
                state.isMiniCartOpenNeeded = action.payload
            },
            setMyDataOpened: (state: StyleControlsState, action: PayloadAction<boolean>) => {
                state.isMyDataOpened = action.payload
                state.backgroundBlurred = state.isMyDataOpened
            },
            setFormOpenedStatus: (state: StyleControlsState, action: PayloadAction<boolean>) => {
                state.isShippingAddressFormOpened = action.payload
            },
            addTask: (state: StyleControlsState) => {
                state.tasks++;
            },
            deleteTask: (state: StyleControlsState) => {
                state.tasks--;
            },
            setSelectLanguageOpen: (state: StyleControlsState, action: PayloadAction<boolean>) => {
                state.isSelectLanguageOpen = action.payload
            }

        }
    }
)
export const
    {
        setMiniCartOpen,
        setFormOpenedStatus,
        addTask,
        deleteTask,
        setMyDataOpened,
        setIsMiniCartOpenNeeded,
        setSelectLanguageOpen,
    } = styleControlsSlice.actions

export default styleControlsSlice.reducer
