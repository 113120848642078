import {Box, BoxProps, Paper, PaperProps, styled, SxProps, Typography, TypographyProps} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import theme, {defaultFonts, primaryColor} from "../../../theme";

export interface FieldProps {
    type?: string,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    label?: string | null,
    required?: boolean,
    style?: SxProps,
    value?: string,
    disabled?: boolean,
    name?: string,
    multiline?: boolean,
    maxRows?: string,

}

export const StyledBox = styled((props: FieldProps) => (
    <Box
        className="custom-styled-box"
        mt={2}
        sx={{
            width: {
                xs: '100%',
                lg: '48%'
            },
            ...props.style
        }}
    >
        <StyledTextField
            handleChange={props.handleChange}
            label={props.label}
            required={props.required}
            value={props.value}
            disabled={props.disabled}
            name={props.name}
            multiline={props.multiline}
            maxRows={props.maxRows || ''}
        />
    </Box>
))(({theme}) => ({}))

export const StyledTextField = styled((props: FieldProps) => (
    <TextField
        required={props.required}
        label={props.label}
        name={props.name}
        type={props.type}
        value={props.value}
        inputProps={{autoComplete: "off"}}
        multiline={props.multiline}
        maxRows={props.maxRows || ''}

        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.handleChange && props.handleChange(event)
        }
    />
))(({theme}) => ({}))


export const StyledContainer = styled((props: BoxProps) => (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down("lg")]: {
                flexDirection: 'column',
            }
        }}>
            {props.children}
        </Box>
    )
)
(({theme}) => ({}))

export const GroupTwoBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '48%',
    [theme.breakpoints.down("lg")]: {
        width: '100%',
    }
}));


export const StyledTypography = styled((props: TypographyProps) => (
    <Typography {...props} />
))(({theme}) => ({
    display: 'flex',
    fontWeight: 400,
    fontSize: defaultFonts.lg,
    [theme.breakpoints.down('lg')]: {
        fontSize: defaultFonts.md,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: defaultFonts.sm,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: defaultFonts.xs,
    },
    color: primaryColor.whiteText,
}));

export const StyledPaper = styled((props: PaperProps) => (
    <Paper {...props} />
))(({theme}) => ({
    backgroundColor: primaryColor.backgroundOverlay,
    borderRadius: '0px',
    minHeight: '860px',
    marginLeft: '20px',
    // paddingX: { md: '40px', lg: '75px'},
    // paddingY: { md: '30px', lg: '50px'}
}));
