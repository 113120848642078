import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialSparePartState} from "../interfaces/initialStates";
import {
    Category,
    CheckedBoxList,
    FilteredSparePart,
    PricesFilter,
    Schema,
    SparePartsTreeSelectedNode
} from "../interfaces/backend";
import {initialProduct} from "./initialCartValues";

const initialState: initialSparePartState = {
    searchResult: {
        items: [],
        itemsCount: 0,
        itemsTotal: 0,
        pagination: [],
        currentPage: 1,
        request: {
            category: '',
            inStock: false,
            order: 'asc',
            page: 1,
            perPage: 1
        },
        filterResults: {
            attributes: []
        },
    },
    checkedBoxesList: {},
    pricesFilter: {
        minPrice: undefined,
        maxPrice: undefined
    },
    isFilterClose: false,
    selectedCategory: "",
    sortValue: "",
    category: {
        alias: '',
        children: [],
        code: '',
        image: '',
        name: ''
    },
    sparePartsTreeSelectedNode: {
        product: initialProduct,
        label: "",
        level: 0,
        serialNumber: 0,
        groupChildren: []
    },
    isSparePartSchemaOpen: false,
    schemas: [],
    expandedTreeNodes: ['root-parent']


}

export const sparePartSlice = createSlice({
    name: "sparePart",
    initialState: initialState,
    reducers:
        {
            setSearchResult: (state, action: PayloadAction<FilteredSparePart>) => {
                state.searchResult = action.payload
            },
            findSpareParts: (state, action) => {
            },
            getCategory: (state, action) => {
            },
            setCheckedBoxesList: (state, action: PayloadAction<CheckedBoxList>) => {
                state.checkedBoxesList = action.payload
            },
            setPricesFilter: (state, action: PayloadAction<PricesFilter>) => {
                state.pricesFilter = action.payload
            },
            setCloseFilter: (state, action: PayloadAction<boolean>) => {
                state.isFilterClose = action.payload
            },
            setCategory: (state, action: PayloadAction<Category>) => {
                state.category = action.payload
            },
            setSelectedCategory: (state, action: PayloadAction<string>) => {
                state.selectedCategory = action.payload
            },
            setSelectedTreeNode: (state, action: PayloadAction<SparePartsTreeSelectedNode>) => {
                state.sparePartsTreeSelectedNode = action.payload
            },


            setSortValue(state, action: PayloadAction<string>) {
                state.sortValue = action.payload;
            },
            setSparePartSchemaOpen: (state, action: PayloadAction<boolean>) => {
                state.isSparePartSchemaOpen = action.payload
            },

            setSchemas: (state, action: PayloadAction<Schema[]>) => {
                state.schemas = action.payload
            },
            getSchemas: (state, action: PayloadAction<{ schemas: Schema[] }>) => {
            },
            clearSchemas: (state: initialSparePartState) => {
                state.schemas = initialState.schemas;
            },
            setExpandedTreeNodes: (state: initialSparePartState, action: PayloadAction<string[]>) => {
                state.expandedTreeNodes = action.payload
            },
            resetSelectedTreeNode: (state) => {
                state.sparePartsTreeSelectedNode = initialState.sparePartsTreeSelectedNode;
            },
            resetSparePartTree: (state: initialSparePartState) => {
                state.expandedTreeNodes = initialState.expandedTreeNodes
                state.sparePartsTreeSelectedNode = initialState.sparePartsTreeSelectedNode;
            }
        }
})

export const
    {
        setSearchResult,
        findSpareParts,
        getCategory,
        setCategory,
        setSelectedCategory,
        setCheckedBoxesList,
        setPricesFilter,
        setCloseFilter,
        setSortValue,
        setSelectedTreeNode,
        resetSelectedTreeNode,
        setSparePartSchemaOpen,
        setSchemas,
        getSchemas,
        clearSchemas,
        setExpandedTreeNodes,
        resetSparePartTree
    } = sparePartSlice.actions


export default sparePartSlice.reducer
