import React from "react";
import {Cart, CartItem} from "../../../interfaces/backend";
import CartTableItemMobile from "./CartTableItemMobile";
import {primaryColor} from "../../../theme";

interface CartTableMobileProps {
    data: Cart;
}

const CartTableMobile = (props: CartTableMobileProps) => {
    const {data} = props;


    return (
        <>
            {data?.items.map((row: CartItem, i: number) => (
                <CartTableItemMobile
                    key={row.code}
                    item={row}
                    sx={{
                        backgroundColor: primaryColor.cartMobileTableItemBackground,
                        mb: "16px",
                        padding: "30px 20px 14px",
                    }}
                />
            ))}
        </>
    );
};

export default CartTableMobile;
