import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {useTranslation} from "react-i18next";
import {Cart, CartItem, CheckoutStageType} from "../../../interfaces/backend";
import {StyledHeadTableCell} from "../styleElements";
import CartTableItemDesktop from "./CartTableItemDesktop";
import {useAppSettings} from '../../../hook';
import TableCell from "@mui/material/TableCell";

export interface TableDesktopProps {
    stage: CheckoutStageType,
    data: Cart
}

export default function CartTableDesktop({stage, data}: TableDesktopProps) {
    const {t} = useTranslation('checkoutPage')
    const {shopUsesPrices} = useAppSettings();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledHeadTableCell>
                        {t('position')}
                    </StyledHeadTableCell>
                    <StyledHeadTableCell/>
                    <StyledHeadTableCell>{t('data')}</StyledHeadTableCell>
                    {shopUsesPrices &&
                        <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('productPrice')}</StyledHeadTableCell>}
                    <StyledHeadTableCell sx={{textAlign: 'center'}}>{t('quantity')}</StyledHeadTableCell>
                    {shopUsesPrices &&
                        <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('subtotal')}</StyledHeadTableCell>}
                    {stage === CheckoutStageType.ShoppingCart
                        ? <TableCell sx={{minWidth: "55px", width: "55px", border: "0 none"}}/>
                        : null
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data?.items.map((row: CartItem, i: number) =>
                        <CartTableItemDesktop
                            key={i}
                            position={i + 1}
                            item={row}
                            stage={stage}
                        />
                    )
                }
            </TableBody>
        </Table>
    )
}