import {Box, Tooltip, Typography} from "@mui/material"
import {defaultFonts} from "../../../theme";
import {StandardMachine} from "../../../interfaces/backend";
import {Link} from "react-router-dom";
import React from "react";
import {setLineLimit, TakeFirst2DImage} from "../../../utils/productFunctions";
import {ListingBox, ListingBoxContent, ListingBoxWrapper, ListingTypography} from "./style";
import {Availability} from "../../../components/common/general/availability";
import {useLocation} from "react-router";
import PriceRendered from "../../../components/common/priceRendered";
import {useTranslation} from "react-i18next";
import {useAppSettings} from "../../../hook";

interface StandardMachineItemProps {
    machine: StandardMachine,
}

const StandardMachineItem = (props: StandardMachineItemProps) => {
    const {machine} = props
    const machineProduct = machine.product;
    const Image2D = TakeFirst2DImage(machineProduct.image)
    const {pathname} = useLocation();
    const {t} = useTranslation('productPage')
    const {shopUsesPrices} = useAppSettings();

    return (
        <ListingBoxWrapper>
            <Link to={`/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
                <ListingBox imageUrl={Image2D} sx={{minHeight: '190px'}}/>
            </Link>

            <ListingBoxContent>
                <Link to={`/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
                    <Tooltip title={machineProduct.name} placement="top-start">
                        <ListingTypography>{machineProduct.name}</ListingTypography>
                    </Tooltip>

                    <Tooltip title={machineProduct.code} placement="top-start">
                        <ListingTypography>{machineProduct.code}</ListingTypography>
                    </Tooltip>
                </Link>
                <Availability inStock={machineProduct.inStock} isOrderable={machineProduct.isOrderable} containerSx={{
                    height: {xs: 65, md: 60}
                }} textSx={{maxWidth: '70%', minWidth: 'fit-content', fontSize: defaultFonts}}/>


                <Box sx={{
                    height: {xs: 65, md: 60},
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center"
                }}>{shopUsesPrices ?
                    <PriceRendered productPrice={machineProduct.price}
                                   productDiscountPrice={machineProduct.discountPrice}
                                   styles={{fontSize: {xs: '2.0rem', md: '2.2rem', lg: '2.4rem'}}}/>
                    :
                    <Typography sx={{
                        fontSize: {xs: '2.0rem', md: '2.2rem', lg: '2.4rem'},
                        ...setLineLimit(2),
                    }}>
                        {t('priceOnInquiry')}
                    </Typography>
                }
                </Box>

            </ListingBoxContent>
        </ListingBoxWrapper>
    )
}

export default (StandardMachineItem)