import {put, select, takeEvery} from 'redux-saga/effects';
import {callHttp, callHttpWithoutSpinner} from '../utils/api';
import {del, get, post} from '../utils/httpUtil';
import {
    CHECKOUT_DOCUMENTS,
    CHECKOUT_DOCUMENTS_FILE,
    CHECKOUT_PAYMENT_METHOD,
    CHECKOUT_SHIPPING_ADDRESS,
    CHECKOUT_SHIPPING_OPTION,
    ORDER_OPTION,
    SET_CHECKOUT_ORDER_OPTION,
    STRIPE_PAYMENT_INTENTS,
    STRIPE_PUBLIC_DATA,
    TERMS,
} from '../constants/api';
import {openErrorSnack} from '../reducers/snackbar'
import {Cart, CartMethods, OrderOption, StripePaymentIntents, StripePublicData, Term} from "../interfaces/backend";
import {
    getCheckoutCart,
    setCartShippingAddress,
    setCheckoutCart,
    setOrderOptions,
    setStripePaymentIntents,
    setStripePublicData,
    setTerms
} from "../reducers/cart";
import {
    addOrderOptionAction,
    addPaymentMethodAction,
    addShippingAddressAction,
    addShippingOptionAction,
    deleteAttachmentAction,
    deleteCartItemAction,
    getTermsAction,
    updateCartItemAction,
    updateCartMultipleItemsAction,
    uploadAttachmentsAction,
} from "../interfaces/actions";
import i18next from "i18next";
import CartSagaClass from "./helpers/cartSagaClass";
import InquirySagaClass from "./helpers/inquirySagaClass";

i18next.loadNamespaces(['messages']);

export function* getTerms(action: getTermsAction) {
    try {
        const terms: Array<Term> = yield callHttp(get, TERMS),
            category = action.payload.category,
            chosenTerms: Array<Term> = terms.filter((term) => term.category === category)
        yield put(setTerms(chosenTerms))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* getCart() {
    const cartClass: CartMethods = yield* getClass();
    yield cartClass.getCart();
}

export function* deleteCartItem(action: deleteCartItemAction) {
    const cartClass: CartMethods = yield* getClass();
    yield cartClass.deleteCartItem(action);
}

export function* updateCartItem(action: updateCartItemAction) {
    const cartClass: CartMethods = yield* getClass();
    yield cartClass.updateCartItem(action);
}


export function* addCartItem(action: updateCartItemAction) {
    const cartClass: CartMethods = yield* getClass();
    yield cartClass.addCartItem(action);
}


export function* addCartMultipleItems(action: updateCartMultipleItemsAction) {
    const cartClass: CartMethods = yield* getClass();
    yield cartClass.addCartMultipleItems(action);
}

export function* getStripePaymentIntents() {
    try {
        const paymentIntents: StripePaymentIntents = yield callHttp(get, STRIPE_PAYMENT_INTENTS);
        yield put(setStripePaymentIntents(paymentIntents));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getStripePublicData() {
    try {
        const publicData: StripePublicData = yield callHttp(get, STRIPE_PUBLIC_DATA);
        yield put(setStripePublicData(publicData));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* addShippingOption(action: addShippingOptionAction) {
    try {
        const id = action.payload.id;
        const checkoutCart: Cart = yield callHttp(post, CHECKOUT_SHIPPING_OPTION, {
            id: parseInt(id, 10),
        });
        yield put(setCheckoutCart(checkoutCart));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* addPaymentMethod(action: addPaymentMethodAction) {
    try {
        const method = action.payload.method
        const checkoutCart: Cart = yield callHttp(post, CHECKOUT_PAYMENT_METHOD, {
            id: parseInt(method, 10),
        });
        yield put(setCheckoutCart(checkoutCart))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* addCartShippingAddress(action: addShippingAddressAction) {
    try {
        let shippingAddress = action.payload.shippingAddress

        let newAddress = (({
                               title,
                               firstName,
                               lastName,
                               companyName,
                               streetName,
                               streetNumber,
                               additional,
                               postalCode,
                               city,
                               country
                           }) => ({
            title, firstName, lastName, companyName,
            streetName, streetNumber, additional, postalCode, city, country
        }))(shippingAddress);

        const checkoutCart: Cart = yield callHttp(post, CHECKOUT_SHIPPING_ADDRESS, newAddress);
        yield put(setCartShippingAddress())
        yield put(setCheckoutCart(checkoutCart))
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getOrderOptions() {
    try {
        const options: Array<OrderOption> = yield callHttp(get, ORDER_OPTION);

        yield put(setOrderOptions(options))
    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* addCartOrderOption(action: addOrderOptionAction) {
    try {
        const cart: Cart = yield callHttp(post, SET_CHECKOUT_ORDER_OPTION, action.payload)
        yield put(setCheckoutCart(cart))

    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* uploadAttachments(action: uploadAttachmentsAction) {
    try {
        const {attachedFiles} = action.payload
        let formData = new FormData()

        if (attachedFiles.length > 0) {
            attachedFiles.map((file: string | Blob) => formData.append('documents[][file]', file));
        }

        const checkoutCart: Cart = yield callHttpWithoutSpinner(post, CHECKOUT_DOCUMENTS, {
            formData,
            multipart: true,
        })
        // yield put(getCart())
        yield put(setCheckoutCart(checkoutCart));

    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

export function* deleteAttachment(action: deleteAttachmentAction) {
    try {
        yield callHttp(del, CHECKOUT_DOCUMENTS_FILE(action.payload.file))
        yield put(getCheckoutCart())

    } catch (err: any) {
        yield put(openErrorSnack(err))
    }
}

function* getClass() {
    const {shopUsesPrices} = yield select(state => state.settings.items);
    return shopUsesPrices ? new CartSagaClass() : new InquirySagaClass();
}

export default function* cartSaga() {
    yield takeEvery('cart/getTerms', getTerms)
    yield takeEvery('cart/getCart', getCart)
    yield takeEvery('cart/deleteCartItem', deleteCartItem)
    yield takeEvery('cart/updateCartItem', updateCartItem)
    yield takeEvery('cart/addCartItem', addCartItem)
    yield takeEvery('cart/addCartMultipleItems', addCartMultipleItems)
    yield takeEvery('cart/addShippingOption', addShippingOption)
    yield takeEvery('cart/addPaymentMethod', addPaymentMethod)
    yield takeEvery('cart/addCartShippingAddress', addCartShippingAddress)
    yield takeEvery('cart/getOrderOptions', getOrderOptions)
    yield takeEvery('cart/addCartOrderOption', addCartOrderOption)
    yield takeEvery('cart/deleteAttachment', deleteAttachment)
    yield takeEvery('cart/uploadAttachments', uploadAttachments)
    yield takeEvery('cart/getStripePaymentIntents', getStripePaymentIntents)
    yield takeEvery('cart/getStripePublicData', getStripePublicData)
}