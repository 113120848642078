import {Box} from '@mui/material';
import React from 'react';
import InputWrapper from '../../common/InputWrapper';
import {DeleteIcon, TableRowComponentProps} from './DesktopTableRow';
import PriceRendered from '../../common/priceRendered';
import {useAppSettings} from '../../../hook';


const ProductQuantityPriceBox = (props: TableRowComponentProps) => {
    const {product, onDelete, onChangeQuantity, customStyles} = props;
    const {shopUsesPrices} = useAppSettings();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                maxWidth: '500px',
                mt: '5px',
            }}
        >
            <Box sx={{
                width: '100%',
                maxWidth: '120px',
            }}>
                <InputWrapper
                    onChange={(quantity: number) => {
                        onChangeQuantity(quantity);
                    }}
                    value={product.quantity}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: "center",
                justifyContent: "space-between",
                width: '55%',
                ...customStyles,
            }}>

                <Box sx={{
                    minHeight: '100%',
                    maxHeight: '60px',
                }}>
                    {shopUsesPrices &&
                        <PriceRendered productPrice={product.price} productDiscountPrice={product.discountPrice}/>}
                </Box>

                <DeleteIcon onClick={() => onDelete(product.code)}/>
            </Box>
        </Box>
    );
};

export default ProductQuantityPriceBox;