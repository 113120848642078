import {Box, useMediaQuery} from "@mui/material";
import theme from "../../../../theme";
import DesktopView from "./desktopView";
import MobileView from "./mobileView";


const BottomHeader = () => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return !mobileView ? (
        <DesktopView/>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <MobileView/>
        </Box>
    )
}

export default (BottomHeader)