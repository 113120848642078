import {Box, Typography} from "@mui/material";
import AddToCartIcon from "../../common/AddToCartIcon";
import {useAppDispatch, useAppSettings} from "../../../hook";
import {addCartItem} from "../../../reducers/cart";
import {primaryColor} from "../../../theme";
import {formatMoney} from "../../../utils/localization";
import {useTranslation} from "react-i18next";
import {ItemProps} from "../../common/ProductTableItem/mobile/ProductItemDetails";


const MobileOrderItemPriceRow = (props: ItemProps) => {
    const {shopUsesPrices} = useAppSettings();
    const dispatch = useAppDispatch();
    const {t} = useTranslation("myAccount");
    const {item} = props;
    const {product} = item;
    const rowPrice = item.discountPrice || item.price;

    return (
        <Box
            sx={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                width: "100%",
                paddingTop: 0,
            }}
        >
            <Box>
                {shopUsesPrices && (
                    <>
                        {item.discountPrice ? (
                            <Box sx={{width: "fit-content"}}>
                                <Typography
                                    sx={{
                                        textDecoration: "line-through",
                                        textDecorationColor: primaryColor.lineThroughPrice,
                                        textDecorationThickness: "1px",
                                        textDecorationTrim: "auto",
                                        color: primaryColor.lineThroughPrice,
                                        opacity: 0.3,
                                    }}
                                >
                                    {rowPrice ? formatMoney(item.price) : t("requestPrice")}
                                </Typography>
                                <Typography sx={{color: primaryColor.discountCheckoutPage}}>
                                    {formatMoney(rowPrice)}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography sx={{width: "auto"}}>
                                {rowPrice ? formatMoney(rowPrice) : t("requestPrice")}
                            </Typography>
                        )}
                    </>
                )}
            </Box>

            <Box sx={{width: "fit-content"}}>
                {item.isOrderable && (
                    <Box sx={{textAlign: "right", marginBottom: "-5px"}}>
                        <AddToCartIcon
                            onClick={() =>
                                dispatch(
                                    addCartItem({
                                        code: product.code,
                                        quantity: item.quantity,
                                    })
                                )
                            }
                            sx={{
                                cursor: "pointer",
                            }}
                            toolTipTitle={shopUsesPrices ? t("addToCart") : t("addToInquiry")}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MobileOrderItemPriceRow;
