import {Box, Button, Divider, DividerProps, styled, SxProps, Typography} from "@mui/material";
import React from "react";
import TableCell, {TableCellProps} from "@mui/material/TableCell";
import theme, {primaryColor, tableFontSize} from "../../theme";

interface BoxButtonProps {
    labelButton: string,
    handleClick?: () => void,
    disabled?: boolean
}

interface SubmitButtonProps {
    labelButton: string,
    disabled?: boolean,
    form?: string,
}

interface PriceProps {
    name: string,
    price: string,
    style?: SxProps,
    styleColor?: string
}

const buttonStyle = {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down("md")]: {
        justifyContent: 'center',
    }
}

export const StyledTableCell = styled((props: TableCellProps) => (
    <TableCell sx={{
        fontSize: tableFontSize.contentMain
    }}{...props}/>
))(({theme}) => ({
    color: primaryColor.shoppingCartTitle,
    borderBottom: 'none ',
}))

export const StyledHeadTableCell = styled((props: TableCellProps) => (
    <TableCell sx={{
        fontSize: tableFontSize.contentMain,
    }}{...props} />
))(({theme}) => ({
    color: primaryColor.shoppingCartTitle,
    borderBottom: '2.5px solid ' + primaryColor.filterText,
    '&.MuiTableCell-root': {
        fontWeight: 400
    }
}))

export const StyledBoxButton = styled((props: BoxButtonProps) => {
        const {labelButton, handleClick, disabled} = props
        return (
            <Box
                sx={{...buttonStyle}}
            >
                <Button
                    disabled={disabled}
                    variant="contained"
                    onClick={handleClick}
                >
                    {labelButton}
                </Button>
            </Box>
        )
    }
)(({theme}) => ({}))

export const StyledSubmitButton = styled((props: SubmitButtonProps) => {
        const {labelButton, disabled, form} = props
        return (
            <Box
                sx={{...buttonStyle}}
            >
                <Button
                    disabled={disabled}
                    variant="contained"
                    form={form}
                    type='submit'
                >
                    {labelButton}
                </Button>
            </Box>
        )
    }
)(({theme}) => ({}))

export const StyledPriceInfo = styled(({name, price, style, styleColor}: PriceProps) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '5px'
        }}
    >
        <Typography sx={{
            fontSize: tableFontSize.contentMain,
            mr: '5px', color: !styleColor ? primaryColor.shoppingCartTitle : styleColor,
            ...style
        }}>{name}:</Typography>
        <Typography sx={{
            fontSize: tableFontSize.contentMain,
            color: !styleColor ? primaryColor.shoppingCartTitle : styleColor, ...style
        }}>{price}</Typography>
    </Box>
))(({theme}) => ({}))


export const StyledDivider = styled((props: DividerProps) => (
    <Divider {...props}/>
))(({theme}) => ({
    marginTop: '35px',
    marginBottom: '35px',
    border: 'solid 1px ' + primaryColor.shoppingCartTitle
}))
