import {primaryColor} from "../../../theme";
import SearchBox from "../../../components/common/searchBox";
import * as React from "react";
import {getQuickSearchOptions} from "../../../reducers/quickSearch";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";

import {Box} from "@mui/material";

export default function EmptySearchResults() {
    const dispatch = useAppDispatch()
    const {t} = useTranslation('common')
    const handleInputChange = (searchValue: string) => dispatch(getQuickSearchOptions({searchValue: searchValue}));

    const paperStyle = {
        tabsGrid: {
            backgroundColor: primaryColor.background,
            borderRadius: '0px',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '90px'
        },
        dataGrid: {
            backgroundColor: primaryColor.searchBackground,
            borderRadius: '0px',
            minHeight: '294px',
            padding: '76px 15% 75px 15%'

        },
        dataFrame: {
            margin: '0px 90px 0px 65px',
        },
    }
    return (
        <Paper sx={paperStyle.dataGrid}>
            <Box sx={{display: 'flex'}}>
                <Box
                    component={'img'}
                    src={'/images/icons/exclamationMark.svg'}
                    alt='image'
                    sx={{
                        height: '48px',
                        objectFit: 'cover',
                        mr: '24px'
                    }}/>
                <Box sx={{
                    fontSize: '2rem',
                    color: primaryColor.searchNotFoundColor,
                    display: 'flex',
                    alignSelf: 'center'
                }}
                     dangerouslySetInnerHTML={{
                         __html: t('notFound')
                     }}></Box>
            </Box>
            <SearchBox
                onInputChange={handleInputChange}
                placeholder={t('search') as string}
                styledPaper={{
                    marginTop: {md: '20px'},
                    maxHeight: {md: '400px'},
                }}
                styleRoot={{marginTop: '90px'}}
            />
        </Paper>
    )
}