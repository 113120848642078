import * as React from 'react';
import Modal from '@mui/material/Modal';
import {useAppDispatch, useAppSelector} from "../../../../hook";
import {openSearchBox} from "../../../../reducers/quickSearch";
import Search from "./Search";
import theme, {primaryColor} from '../../../../theme';
import {useMediaQuery} from '@mui/material';


export default function ModalSearchBox() {
    const searchBoxOpen = useAppSelector(state => state.quickSearch.searchBoxOpen)
    const dispatch = useAppDispatch()
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Modal
            open={searchBoxOpen}
            onClose={() => dispatch(openSearchBox(!searchBoxOpen))}
            sx={{
                width: {xs: '100%', md: '80%', lg: '50%'},
                margin: 'auto',
                backgroundColor: {xs: primaryColor.backgroundOverlay, md: 'transparent'},

                top: {xs: '65px', md: 0},
                paddingTop: {xs: '20px', md: 0},
            }}
            slotProps={{
                backdrop: {
                    style: {
                        backgroundColor: mobileView ? 'transparent' : primaryColor.backgroundOverlay,
                        opacity: mobileView ? 1 : 0.9,
                    }
                }
            }}
        >
            <Search/>
        </Modal>
    );
} 