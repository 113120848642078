import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {primaryColor} from "../../../theme";
import React from "react";

const NeedHelp = () => {
    const {t} = useTranslation('myAccount')

    return (
        <Box sx={{color: primaryColor.accountTextColor, padding: '31px 0px 42px 118px'}}>
            <Box sx={{mb: '22px'}}>{t('doYouHaveQuestions')}</Box>
            <Box dangerouslySetInnerHTML={{
                __html: t('toFaq')
            }}></Box>
        </Box>
    )
}

export default React.memo(NeedHelp)