import {Box, Grid, Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {formatMoney, getLocaleDateString} from "../../../../utils/localization";
import OrderStatusBar from "./OrderStatusBar";
import {defaultFonts, primaryColor} from "../../../../theme";
import {getSvg} from "../../../../components/common/Tabs/TabImages";
import ConfirmationDialog, {ConfirmationContent} from "../../../../components/common/Dialogs/ConfirmationDialog";
import {getStatus, OrderItemProp, useMyOrderCommon} from "../../../../components/myData/MyOrders/orderUtils";
import {setCancelOrderId, setConfirmationOpen} from "../../../../reducers/myAccount";
import {countTotalItems} from "../../../../utils/productFunctions";
import {useAppDispatch} from "../../../../hook";


interface ItemProp {
    length: number;
}


export const ItemsCount = (prop: ItemProp) => {
    const {t} = useTranslation("myAccount");

    return (
        <Box
            sx={{
                display: "flex",
                marginLeft: 'auto',
                justifyContent: {
                    lg: 'flex-end',
                    xs: 'flex-start'
                }
            }}
        >
            {getSvg("my-orders")}{" "}
            <Box
                pl={"20px"}
                fontWeight={300}
                letterSpacing={"0.05em"}
                fontSize={defaultFonts}
            >
                {prop.length} {t("items")}
            </Box>
        </Box>
    );
};

const MyOrderItem = (props: OrderItemProp) => {
    const {order, setSelectedOrder, cancelOrder, sx} = props;
    const {t} = useTranslation("myAccount");
    const dispatch = useAppDispatch();
    const {
        isConfirmationOpen,
        CANCELLABLE_ORDERS,
        style,
        firstName,
        lastName,
        shopUsesPrices,
        getOperation
    } = useMyOrderCommon(props);

    return (
        <Box
            sx={{
                color: primaryColor.accountTextColor,
                fontWeight: 700,
                paddingY: '26px',
                marginBottom: '12px',
                backgroundColor: primaryColor.ordersListBackgroundColor,
                ...sx
            }}
        >
            {isConfirmationOpen && (
                <ConfirmationDialog
                    onConfirm={() => {
                        !!cancelOrder && cancelOrder();
                        dispatch(setConfirmationOpen(false));
                    }}
                    handleCancel={() => {
                        dispatch(setConfirmationOpen(false));
                    }}
                    modalContent={<ConfirmationContent dataContent={t(getOperation('SureToCancel'))}/>}
                />
            )}

            <Box display={'flex'}>
                <Box display={'flex'} width={!setSelectedOrder ? '50%' : '100%'}>
                    {t(getOperation('Status'))}:
                    <Typography sx={style.value}>
                        {order.status.name}
                    </Typography>
                </Box>

                {!setSelectedOrder && <ItemsCount length={countTotalItems(order.items)}/>}

                {cancelOrder && CANCELLABLE_ORDERS.findIndex(code => code === order.status.code) !== -1 && (
                    <Box width={'50%'}>
                        <Box
                            sx={{
                                opacity: 0.7,
                                cursor: 'pointer',
                                justifyContent: 'flex-end',
                                display: 'flex'
                            }}
                            onClick={() => {
                                dispatch(setCancelOrderId(order.orderId))
                                dispatch(setConfirmationOpen(true))
                            }}
                        >
                            {t(getOperation('Cancel'))}
                        </Box>
                    </Box>
                )}
            </Box>

            <OrderStatusBar isRejected={order.status.code === 'rejected'} percent={getStatus(order.status.code)}/>

            <Grid container justifyContent='space-between'>
                <Grid item sm={8}>
                    <Box sx={style.element}>
                        {t(getOperation('Date'))}:
                        <Typography sx={style.value}>{getLocaleDateString(order.createdAt)}</Typography>
                    </Box>
                    <Box sx={style.element}>
                        {t(getOperation('Id'))}:
                        <Typography sx={style.value}>{order.orderId}</Typography>
                    </Box>
                    <Box sx={style.element}>
                        {t(getOperation('By'))}:
                        <Typography sx={style.value}>{firstName} {lastName}</Typography>
                    </Box>
                    {shopUsesPrices &&
                        <Box sx={style.element}>
                            {t(getOperation('Total'))}:
                            <Typography sx={style.value}>{formatMoney(order.totalWithTaxAndShipping)}</Typography>
                        </Box>
                    }
                </Grid>
                {setSelectedOrder && (
                    <Grid item sm={4}>
                        <Box sx={{
                            paddingTop: '16px',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <ItemsCount length={countTotalItems(order.items)}/>
                            <Link
                                variant={"simpletext"}
                                href={`/my-account/my-orders/${order.orderId}`}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: "30px",
                                    opacity: 0.7
                                }}
                                onClick={() => {
                                    setSelectedOrder(order);
                                }}
                            >
                                {t(getOperation('View'))}
                            </Link>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default MyOrderItem;
