import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Article} from "../interfaces/backend";
import {initialStartState} from "../interfaces/initialStates";


const initialState: initialStartState = {
    // registrationEmail: "",
    isRegistrationOpen: false,
    isRegistrationSent: false,
    isForgotPassOpen: false,
    isForgotPassSent: false,
    articles: []
};

export const startSlice = createSlice(
    {
        name: "start",
        initialState: initialState,
        reducers: {
            openRegisterForm: (state: initialStartState, action: PayloadAction<boolean>) => {
                state.isRegistrationOpen = action.payload
            },
            receiveRegisterData: (state: initialStartState) => {
                state.isRegistrationSent = true
            },
            closeRegistrationModal: (state: initialStartState) => {
                state.isRegistrationSent = false
                state.isRegistrationOpen = false
            },
            receiveForgotPass:
                state => ({
                    ...state,
                    isForgotPassSent: true,
                    isForgotPassOpen: false
                }),
            openForgotPasswordModal: (state: initialStartState) => {
                state.isForgotPassOpen = true
            },
            closeForgotPasswordModal: (state: initialStartState) => {
                state.isForgotPassSent = false
                state.isForgotPassOpen = false
            },
            setArticles: (state: initialStartState, action: PayloadAction<Array<Article>>) => {
                state.articles = action.payload
            },
            getArticles: (state, action) => {
            },
        }
    }
)
export const {
    setArticles,
    getArticles,
    openRegisterForm,
    receiveRegisterData,
    closeRegistrationModal,
    openForgotPasswordModal,
    closeForgotPasswordModal,
    receiveForgotPass,
} = startSlice.actions

export default startSlice.reducer