import {Box, useMediaQuery} from "@mui/material";
import {Document, Machine} from "../../../interfaces/backend";
import {useAppSelector} from "../../../hook";
import FilterDocumentsMenu from "./FilterDocumentsMenu";
import React, {useEffect, useState} from "react";
import {CodeName} from "../../../interfaces/general";
import {DocumentsListsMenu} from "./DocumentsList";
import {useLocation} from "react-router-dom";
import qs from "qs";
import theme from "../../../theme";

export interface SelectedDocumentProps extends CodeName {
    documents?: Document[]
}

export interface MachineDocumentsProps {
    docsList?: SelectedDocumentProps[]
}

const MachineDocuments = (props: MachineDocumentsProps) => {
    const {docsList} = props
    const selectedMachine: Machine = useAppSelector(state => state.machine.machine);
    const defaultSelectedGroup: SelectedDocumentProps = {
        code: selectedMachine.product.code,
        name: selectedMachine.product.name,
        documents: selectedMachine.product.document
    }
    const location = useLocation();
    const search = qs.parse(location.search.replace('?', ''))
    const [groupDocuments, setGroupDocuments] = useState<SelectedDocumentProps[]>([])
    const [selectedGroup, setSelectedGroup] = useState<SelectedDocumentProps | null>({
        code: selectedMachine.product.code,
        name: selectedMachine.product.name,
        documents: selectedMachine.product.document
    })
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        docsList && setGroupDocuments(docsList)
        if (search.group) {
            setSelectedGroup(
                groupDocuments.find(gd => gd.code === search.group) || groupDocuments.find(gd => gd.code === selectedMachine.product.code) || null
            )
        } else {
            setSelectedGroup(
                groupDocuments.find(gd => gd.code === selectedMachine.product.code) || null
            )
        }
        if (!selectedGroup && selectedMachine) {
            // in case not selected group we set root to selected here
            setSelectedGroup(
                groupDocuments.find(gd => gd.code === selectedMachine.product.code) || null
            )
        }
    }, [selectedMachine, selectedGroup, groupDocuments])

    return (
        <Box>
            <Box sx={{paddingX: '30px', paddingTop: mobileView ? '0px' : '30px'}}>{selectedMachine.spareParts &&
                <FilterDocumentsMenu setSelectedDocument={setSelectedGroup}
                                     selectedDocument={selectedGroup}
                                     documentsArray={groupDocuments}
                                     defaultSelectedGroup={defaultSelectedGroup}/>}</Box>
            <Box sx={{marginX: '15px', marginTop: '40px'}}>
                {selectedGroup && selectedGroup.documents &&
                    <DocumentsListsMenu documents={selectedGroup.documents}/>}
            </Box>
        </Box>
    )
}

export default MachineDocuments;
