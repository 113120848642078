import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {primaryColor} from "../../../theme";
import {memo} from "react";
import ContactPhone from "../../../components/login/modalContents/contactPhone";

const ReturnOptions = () => {
    const {t} = useTranslation('myAccount')

    return (
        <Box sx={{
            color: primaryColor.accountTextColor,
            fontWeight: {xs: '500', md: '300'},
            padding: '31px 10px 42px 118px'
        }}>
            <Box>{t('wantToReturn')}</Box>
            <Box sx={{mb: '22px'}}>{t('readyToHelp')}</Box>
            <ContactPhone/>
        </Box>
    )
}

export default memo(ReturnOptions)