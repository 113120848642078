import {Box} from "@mui/material";

import {primaryColor} from "../../theme";
import CheckoutOptions from "../../pages/Checkout/CheckoutOptions";
import CheckoutSummary from "../../pages/Checkout/CheckoutSummary";
import CostArea from "../../pages/Checkout/CheckoutSummary/CostArea";
import VoucherArea from "../../pages/Checkout/CheckoutSummary/VoucherArea";

interface CheckOutComponentsProps {
    handleClick: any;
    enableBuyButton: any;
    checkoutCart: any;
    validate: () => boolean

}

const CheckOutComponents = (props: CheckOutComponentsProps) => {
    const {
        handleClick,
        enableBuyButton,
        checkoutCart,
        validate
    } = props;
    return (
        <Box
            sx={{
                color: primaryColor.whiteText,
            }}
        >
            <Box
                sx={{
                    mt: 0,
                    mb: "15px",
                }}
            >
                <CheckoutSummary
                    handleClick={handleClick}
                    enableBuyButton={enableBuyButton}
                    checkoutCart={checkoutCart}
                    validate={() => validate()}
                />
            </Box>
            <Box
                sx={{
                    display: {md: "none"},
                }}
            >
                <VoucherArea/>
            </Box>

            <CheckoutOptions
                handleClick={handleClick}
                enableBuyButton={enableBuyButton}
                checkoutCart={checkoutCart}
                validate={() => validate()}
            />

            <Box
                sx={{
                    display: {md: "none"},
                }}
            >
                <CostArea
                    checkoutCart={checkoutCart}
                    handleClick={handleClick}
                    enableBuyButton={enableBuyButton}
                    validate={() => validate()}
                />
            </Box>
        </Box>
    );
};

export default CheckOutComponents;
