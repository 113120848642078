import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {extendQuickOrderProductsAction,} from "../interfaces/actions";
import {QuickOrderProduct, QuickOrderSimpleProduct} from "../interfaces/general";

const localStorageName = "quickOrderProducts";
const saveLocalStorage = (products: QuickOrderProduct[]) => {
    const mappedProducts = products.map(product => {
        return {code: product.code, quantity: product.quantity};
    });
    localStorage.setItem(localStorageName, JSON.stringify(mappedProducts));
}

const getLocalStorage = (): QuickOrderProduct[] => {
    let mapperProducts: QuickOrderProduct[];

    try {
        const products: Array<any> = JSON.parse(localStorage.getItem(localStorageName) || "[]");
        const filteredProducts = products.filter(product => product?.code && product?.quantity);
        mapperProducts = filteredProducts.map(product => {
            return {
                code: product.code,
                name: '',
                quantity: product.quantity,
                price: 0,
                discountPrice: 0,
            } as QuickOrderProduct;
        });
    } catch (e) {
        mapperProducts = [];
    }

    return mapperProducts;
}

interface QuickOrderProductState {
    quickOrderProducts: QuickOrderProduct[];
}

const initialState: QuickOrderProductState = {
    quickOrderProducts: getLocalStorage(),
};

export const quickOrderSlice = createSlice({
    name: "quickOrder",
    initialState,
    reducers: {
        getBatchProducts: (state, action) => {
        },

        updateOrderQuantity: (state: QuickOrderProductState, action: PayloadAction<QuickOrderSimpleProduct>) => {
            const {code, quantity} = action.payload;

            const updatedProducts = state.quickOrderProducts.map((product) => {
                if (product.code === code) {
                    return {
                        ...product,
                        quantity: quantity,
                    };
                }
                return product;
            });
            state.quickOrderProducts = updatedProducts;
            saveLocalStorage(updatedProducts);
        },

        deleteQuickOrderProduct: (state: QuickOrderProductState, action: PayloadAction<string>) => {
            const codeToRemove = action.payload;
            state.quickOrderProducts = state.quickOrderProducts.filter((product) => product.code !== codeToRemove);
            saveLocalStorage(state.quickOrderProducts);
            if (!state.quickOrderProducts.length) {
                quickOrderSlice.caseReducers.emptyOrder(state);
            }
        },

        addQuickOrderToCart: (state, action) => {
        },

        emptyOrder: (state: QuickOrderProductState) => {
            state.quickOrderProducts = [];
            localStorage.removeItem(localStorageName);
        },

        extendOrder: (state: QuickOrderProductState, action: PayloadAction<extendQuickOrderProductsAction>) => {
            let existingProducts: QuickOrderProduct[] = state.quickOrderProducts;
            const newProducts = action.payload.products;

            if (action.payload.isInitialLoad) {
                existingProducts = newProducts;
            } else {
                newProducts.forEach((product) => {
                    const existingProductIndex = existingProducts.findIndex(p => p.code === product.code);
                    if (existingProductIndex !== -1) {
                        existingProducts[existingProductIndex].quantity += product.quantity;
                    } else {
                        existingProducts.unshift(product);
                    }
                });
            }

            state.quickOrderProducts = existingProducts;
            saveLocalStorage(existingProducts);
        },
    },
});


export const {
    deleteQuickOrderProduct,
    updateOrderQuantity,
    emptyOrder,
    addQuickOrderToCart,
    getBatchProducts,
    extendOrder
} = quickOrderSlice.actions;


export default quickOrderSlice.reducer;
