import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import {useAppDispatch, useAppSelector} from "../../hook";
import {closeForgotPasswordModal, closeRegistrationModal} from "../../reducers/start";
import React, {useEffect, useState} from "react";
import CommonDialog from "../common/Dialogs/commonDialog";
import PasswordResetRequirement from "./modalContents/passwordResetRequirement";
import PasswordResetConfirm from "./modalContents/passwordResetConfirm";
import RegistrationSuccessModal from "./modalContents/registrationSuccessModal";
import {setSelectUserModalOpen} from "../../reducers/auth";
import ServiceSelectUser from "./ServiceSelectUser";

export default function LoginArea(props: { containerRef: React.RefObject<HTMLDivElement> }) {
    const {t} = useTranslation('startPage')
    const dispatch = useAppDispatch()

    const isRegistrationSent = useAppSelector(state => state.start.isRegistrationSent)
    const isForgotPassOpen = useAppSelector(state => state.start.isForgotPassOpen)
    const isForgotPassSent = useAppSelector(state => state.start.isForgotPassSent)
    const isRegistrationOpen = useAppSelector(state => state.start.isRegistrationOpen)
    const isSelectUserModalOpen = useAppSelector(state => state.auth.isSelectUserModalOpen)

    const [registrationFormClosed, setRegistrationFormClosed] = useState(false)

    useEffect(() => {
        const onCancelClick = () => {
            props.containerRef.current?.scrollIntoView();
        }
        onCancelClick()
    }, [props.containerRef, registrationFormClosed])

    const handleClick = () => {
        setRegistrationFormClosed(!registrationFormClosed)
    }

    return (
        <>
            {isRegistrationSent &&
                <CommonDialog
                    title={t('register')}
                    closeAction={() => {
                        dispatch(closeRegistrationModal())
                    }}
                >
                    <RegistrationSuccessModal/>
                </CommonDialog>
            }

            {isForgotPassOpen &&
                <CommonDialog
                    title={t('passwordResetTitle')}
                    closeAction={() => {
                        dispatch(closeForgotPasswordModal())
                    }}
                >
                    <PasswordResetRequirement/>
                </CommonDialog>
            }

            {isForgotPassSent &&
                <CommonDialog
                    title={t('passwordResetTitle')}
                    closeAction={() => {
                        dispatch(closeForgotPasswordModal())
                    }}
                >
                    <PasswordResetConfirm/>
                </CommonDialog>
            }
            {isSelectUserModalOpen &&
                <CommonDialog
                    title={t('chooseACustomer')}
                    closeAction={() => {
                        dispatch(setSelectUserModalOpen(false));
                    }}>
                    <Box sx={{marginTop: '6px'}}>
                        <ServiceSelectUser/>
                    </Box>
                </CommonDialog>
            }

            {!isRegistrationOpen
                ? <LoginForm/>
                : <RegisterForm onCancelClick={handleClick}/>}
        </>
    )
}