import {PaginationItem} from "@mui/material"
import React, {Dispatch, SetStateAction} from "react";
import Pagination from "@mui/material/Pagination";
import ArrowButton from "../../components/common/ArrowButton";

interface PaginationProps {
    currentPage: number,
    pagination: number[],
    setSelectedPage: Dispatch<SetStateAction<number>> | { (newPage: number): void }
}

const PreviousArrowIcon = () => <ArrowButton width={26} direction={"left"}/>
const NextArrowIcon = () => <ArrowButton width={26} direction={"right"}/>

export default function CustomPagination(props: PaginationProps) {
    const {pagination, currentPage, setSelectedPage} = props


    return (
        <>{
            pagination.length > 1 &&
            <Pagination
                renderItem={item =>
                    <PaginationItem
                        {...item}
                        slots={{previous: PreviousArrowIcon, next: NextArrowIcon}}
                    />
                }
                hideNextButton={currentPage === pagination.length}
                hidePrevButton={currentPage === 1}
                onChange={(event, page) => setSelectedPage(page)}
                count={pagination.length}
                page={currentPage}
                defaultPage={1}
            />
        }
        </>

    )
}