import {put, takeEvery} from 'redux-saga/effects';

import {COUNTRIES, PUBLICDATA, TERM, TITLES} from '../constants/api';

import {callHttp} from '../utils/api';
import {get} from '../utils/httpUtil';
import {setCountries, setServerDate, setTerm, setTitles} from "../reducers/metadata";
import {Country, PublicData, Term, Title} from "../interfaces/backend";
import {openErrorSnack} from '../reducers/snackbar';
import {getTermAction} from '../interfaces/actions';

export function* getCountries() {
    const countries: Array<Country> = yield callHttp(get, COUNTRIES)
    yield put(setCountries(countries))
}

export function* getTitles() {
    const titles: Array<Title> = yield callHttp(get, TITLES)
    yield put(setTitles(titles))
}

export function* getPublicData() {
    const publicData: PublicData = yield callHttp(get, PUBLICDATA);

    localStorage.setItem('cacheBuild', publicData.cacheBuild);
    yield put(setServerDate(new Date(publicData.serverDate)));

    if (localStorage.getItem('translationsBuild')) {
        if (
            localStorage.getItem('translationsBuild') !== publicData.translationBuild
        ) {
            localStorage.removeItem('translationsBuild');
            //lngList().forEach(lng => localStorage.removeItem(lng + '_cache'));
        }
    } else {
        localStorage.setItem('translationsBuild', publicData.translationBuild);
    }
}

export function* getTerm(action: getTermAction) {
    const {alias} = action.payload;

    try {
        const data: Term = yield callHttp(get, TERM(alias));
        yield put(setTerm(data));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}


export default function* metadataSaga() {
    yield takeEvery('metadata/getTerm', getTerm)
    yield takeEvery('metadata/getCountries', getCountries)
    yield takeEvery('metadata/getTitles', getTitles)
    yield takeEvery('metadata/getPublicData', getPublicData)
}
