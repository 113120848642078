import {Box} from "@mui/material";
import Filters from "../filters/Filters";
import * as React from "react";
import {CheckedBoxList, ListingListTypes, MachinesSearch} from "../../../interfaces/backend";
import EmptySearchResults from "../quickSearch/EmptySearchResults";
import ListingList from "../ListingList";
import {useAppSelector} from "../../../hook";

interface MobileListingPageProps {
    showFilters: boolean,
    machineSearch: MachinesSearch,
    type: ListingListTypes,
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>,
    selectedPage: number,
    // setCloseOpenFilter: Function,
    checkedBoxesList: CheckedBoxList,
    handleFilter: Function,
    searchValue: any
}

export default function MobileView(props: MobileListingPageProps) {

    const {
        setSelectedPage,
        machineSearch,
        type,
        // setCloseOpenFilter,
        handleFilter,
        searchValue,
    } = props
    const {isFilterClose} = useAppSelector(state => state.sparePart)


    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column',
            padding: {xs: "5px", sm: '0px'},
            minHeight: 'fit-content'
        }}>
            <Box sx={{}}>
                {<Filters
                    filters={machineSearch.filterResults?.attributes}
                    type={type}
                    setSelectedPage={setSelectedPage}
                    handleFilter={handleFilter}
                    searchItemsCount={machineSearch.itemsTotal}
                />}
            </Box>
            {isFilterClose && <Box
                sx={{
                    width: '100%',
                }}

            >
                {type === ListingListTypes.Search ?
                    (machineSearch && machineSearch.itemsCount === 0) ?
                        <EmptySearchResults/> : !searchValue ?
                            <EmptySearchResults/> :
                            <ListingList machineList={machineSearch} type={type}
                                // isMobile={true}
                            />
                    : <ListingList machineList={machineSearch} type={type}
                        // isMobile={true}
                    />}
            </Box>
            }

        </Box>
    )
}