import React, {useEffect} from "react";
import {useAppDispatch} from "../../../hook";
import {getUsers} from "../../../reducers/myAccount";
import UserEdit from "../../../components/myData/UserManagement/UserEdit";
import UserTable from "../../../components/myData/UserManagement/UserTable";
import {useParams} from "react-router-dom";


export default function UserManagement() {

    const dispatch = useAppDispatch()
    const params = useParams()


    useEffect(() => {
        dispatch(getUsers())
    }, [])


    return (
        !params.id
            ? <UserTable/>
            : <UserEdit/>
    )
}
