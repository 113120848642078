import React from "react";
import {Box, useMediaQuery} from "@mui/material";
import {getSvg} from "./TabImages";
import {TabData} from "../../../pages/Account/AccountPage";
import theme, {defaultFonts, primaryColor} from "../../../theme";

const Tab = ({text, selected, isLast, item}: TabData) => {
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const toHighlightTab = selected && !mobileView;
    const style = {

        selectedStyle: toHighlightTab && {
            position: "relative",
            "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: "11px",
                backgroundColor: primaryColor.myAccountTabSelected,
            },
        },

        selectedText: !toHighlightTab &&
            !mobileView && {
                opacity: "0.5",
            },
    };

    const conditionalBackgroundColor = {
        backgroundColor: toHighlightTab
            ? primaryColor.myAccountTabSelectedBackground
            : primaryColor.myAccountDataBackground,
    };

    return (
        <Box
            sx={{
                ...style.selectedStyle,
                backgroundColor: conditionalBackgroundColor,
                color: primaryColor.myAccountTabText,
                fontSize: defaultFonts,
                height: "90px",
                display: "inline-flex",
                width: "100%",
                cursor: "pointer",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "75px",
                    alignItems: "center",
                    justifyContent: 'center',
                    backgroundColor: conditionalBackgroundColor,
                    ...style.selectedText,
                }}
            >
                {getSvg(item)}
            </Box>
            <Box
                sx={{
                    ...style.selectedText,
                    borderBottom: !isLast
                        ? `0.3px solid ${primaryColor.myAccountTabBorder}`
                        : "unset",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: "0px",
                    paddingRight: "15px",
                    fontSize: defaultFonts,
                    fontWeight: 400,
                    textTransform: "uppercase",
                    textAlign: "start",
                    wordBreak: 'break-word'
                }}
            >
                {text}
            </Box>
        </Box>
    );
};

export default Tab;