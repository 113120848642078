import {useAppDispatch, useAppSelector} from "../../../../hook";
import {Machine, MachineTree, SparePartsTreeSelectedNode,} from "../../../../interfaces/backend";
import {TreeView} from "@mui/x-tree-view";
import {Box} from "@mui/material";
import {setExpandedTreeNodes} from "../../../../reducers/sparePart";
import CustomTreeItem from "./customTreeItem";
import SparePartChildrenTable from "../SparePartChildrenTable";
import {primaryColor, secondaryFonts} from "../../../../theme";

const SparePartsTree = () => {
    const dispatch = useAppDispatch();

    const selectedMachine: Machine = useAppSelector(
        (state) => state.machine.machine
    );

    const sparePartsTreeSelectedNode: SparePartsTreeSelectedNode = useAppSelector(
        (state) => state.sparePart.sparePartsTreeSelectedNode
    );


    const expanded = useAppSelector(
        (state) => state.sparePart.expandedTreeNodes
    );

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        dispatch(setExpandedTreeNodes(nodeIds));
    };

    const getTreeItemsFromSpareParts = (
        selectedMachine: Machine,
        draftPartList: MachineTree[],
        level: number = 0
    ): SparePartsTreeSelectedNode[] => {
        if (!Array.isArray(draftPartList)) {
            return [];
        }

        const result: SparePartsTreeSelectedNode[] = [];

        if (level === 0) {

            const parentProductNode: SparePartsTreeSelectedNode = {
                product: selectedMachine.product,
                level: 0,
                label: selectedMachine.spareParts?.label,
                groupChildren: [],
                serialNumber: 0,
                nonGroupChildren: selectedMachine.spareParts?.children,
            };

            const childrenNodes = getChildrenNodes(
                selectedMachine,
                draftPartList.filter((sparePart: MachineTree) => sparePart.isGroup),
                level + 1
            );

            parentProductNode.groupChildren = childrenNodes;

            result.push(parentProductNode);
        }

        return result;
    };

    const getChildrenNodes = (
        selectedMachine: Machine,
        draftPartList: MachineTree[],
        level: number
    ): SparePartsTreeSelectedNode[] => {
        return draftPartList
            .filter((sparePart: MachineTree) => sparePart.isGroup)
            .map((sparePart: MachineTree) => {
                const children = sparePart.children
                    ? getChildrenNodes(selectedMachine, sparePart.children, level + 1)
                    : [];
                return {
                    product: sparePart.product,
                    level: level,
                    label: sparePart.label,
                    groupChildren: children,
                    serialNumber: sparePart.serialNumber,
                    nonGroupChildren: sparePart.children || [],
                };
            });
    };


    const treeItemsData = selectedMachine.spareParts?.children
        ? getTreeItemsFromSpareParts(selectedMachine, selectedMachine.spareParts.children)
        : [];


    return (
        <>
            {sparePartsTreeSelectedNode.product.alias === "" ? (
                <Box
                    sx={{
                        maxWidth: {xs: '100%', md: "80%"},
                        margin: "auto",


                        ".MuiTreeItem-iconContainer": {
                            position: "absolute",
                            right: 10,
                        },
                    }}
                >
                    <TreeView
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        sx={{
                            paddingY: {xs: '25px', md: "75px"},
                            "& .MuiBox-root, .MuiTreeItem-label, .MuiTreeItem-content, .MuiTreeItem-root ": {
                                fontSize: secondaryFonts,
                                color: primaryColor.treeItemsColor,
                            }
                        }}
                    >
                        <CustomTreeItem treeItems={treeItemsData}/>
                    </TreeView>
                </Box>
            ) : (
                <SparePartChildrenTable/>
            )}
        </>
    );
};

export default SparePartsTree;
