import React, {useEffect, useRef} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {ClickedNode, ViewerModel} from '../machineDetails/spareParts/machineViewer/helper';
import {ViewerBoundingBox} from './viewerContext';
import theme from '../../theme';
import {getSelectedLng} from "../../utils/localization";


interface ViewerProps {
    urn: string,
    modelKeyProperty: string,
    onClickModel: (clickedNode: ClickedNode) => void;
    viewerContainerRef: React.RefObject<HTMLDivElement>;
    setViewerContainerRef: React.Dispatch<React.SetStateAction<React.RefObject<HTMLDivElement>>>;
}

const Viewer = (props: ViewerProps) => {
    const {urn, onClickModel, modelKeyProperty, viewerContainerRef, setViewerContainerRef} = props;
    const smView = useMediaQuery(theme.breakpoints.down('sm'))
    const autodeskLoadedURNRef = useRef<string>("");//Try to not load Autodesk twice
    const viewer = new ViewerModel(getSelectedLng());

    useEffect(() => {
        if (viewerContainerRef.current && autodeskLoadedURNRef.current !== urn) {

            viewer.initializeViewer(
                urn,
                viewerContainerRef.current,
                modelKeyProperty,
                onClickModel,
            );
            setViewerContainerRef(viewerContainerRef);
            autodeskLoadedURNRef.current = urn
        }

        return () => {
            viewer.destroy()
        }
    }, [urn, viewerContainerRef]);

    return (
        <ViewerBoundingBox.Provider value={{viewerContainerRef}}>

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Box ref={viewerContainerRef}
                     sx={{
                         width: '100%',
                         height: smView ? `calc(40vh)` : `calc(100vh - 160px)`,
                         position: 'relative',
                     }}/>
            </Box>
        </ViewerBoundingBox.Provider>
    );
};

export default Viewer;
