import * as React from "react";
import {styled, SxProps, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {isNumber} from "../../../utils/validation";
import FilterInAccordion from "../mobile/FilterInAccordion";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {setPricesFilter} from "../../../reducers/sparePart";


const PriceTextField = styled((props: FieldProps) => (
    <TextField
        autoComplete="off"
        label={props.label}
        value={props.value ? props.value : ""}
        type={props.type}
        sx={{
            "& .MuiInputLabel-shrink": {
                marginY: '8px',
            },
            "& .MuiInputBase-root.MuiInput-root": {
                paddingTop: '5px',
            },
            ...props.sx
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.handleChange(event.target.value)
        }
    />
))(({theme}) => ({}))

interface FieldProps {
    type?: string,
    value?: number,
    handleChange: (value: string) => void,
    label?: string | null,
    sx?: SxProps
}

const Prices = () => {
    const {t} = useTranslation('searchPage')
    const {pricesFilter, isFilterClose} = useAppSelector(state => state.sparePart)

    const isExpanded = !!(pricesFilter?.minPrice || pricesFilter?.maxPrice)

    const dispatch = useAppDispatch()

    return (
        <>
            {!isFilterClose ?
                <FilterInAccordion
                    filterName={
                        <Typography sx={{fontSize: {xs: '1.8rem', lg: '2rem'}}}>
                            {t('priceIn{{currency}}', {currency: sessionStorage.getItem('currency') as string})}
                        </Typography>
                    }
                    isFilterSelected={isExpanded}
                    filterContent={
                        <>
                            <PriceTextField
                                value={pricesFilter?.minPrice && pricesFilter?.minPrice > 0 ? pricesFilter?.minPrice : undefined}
                                label={t('minPrice')}
                                sx={{marginBottom: '16px'}}
                                handleChange={(value) => isNumber(value) && dispatch(setPricesFilter({
                                    minPrice: Number(value),
                                    maxPrice: pricesFilter?.maxPrice && pricesFilter?.maxPrice > 0 ? pricesFilter.maxPrice : undefined
                                }))}
                            />

                            <PriceTextField
                                value={pricesFilter?.maxPrice && pricesFilter?.maxPrice > 0 ? pricesFilter?.maxPrice : undefined}
                                label={t('maxPrice')}
                                handleChange={(value) => isNumber(value) && dispatch(setPricesFilter({
                                    minPrice: pricesFilter?.minPrice ? pricesFilter.minPrice : undefined,
                                    maxPrice: Number(value)
                                }))}
                            />
                        </>}
                />

                : null

            }
        </>
    )
}

export default Prices
