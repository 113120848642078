import React, {ChangeEvent, useEffect, useState} from 'react';
import qs from 'qs';
import keycode from 'keycode';
import Downshift, {ControllerStateAndHelpers} from 'downshift';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {openSearchBox, setQuickSearchOptions} from '../../reducers/quickSearch';
import {Box} from "@mui/material";
import {SearchSuggestionElement} from "../../interfaces/backend";
import {downShiftType, searchBoxType} from "../../interfaces/general";
import {useAppDispatch, useAppSelector} from "../../hook";
import {useLocation, useNavigate} from "react-router";
import {renderInput, renderSuggestion} from './searchUtils';
import {openInfoSnack} from '../../reducers/snackbar';
import {useTranslation} from 'react-i18next';
import {primaryColor} from '../../theme';

export default function SearchBox(props: searchBoxType) {

    const suggestions: Array<SearchSuggestionElement> = useAppSelector(state => state.quickSearch.suggestions)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const {t} = useTranslation('messages')
    // const handleSetPage = () => {
    //     // setPage(0)
    // }
    // const setSearchValueFunction = (value: string) => {
    //     // setSearchValue(value)
    // }

    const [state, setState] = useState<downShiftType>({
        inputValue: "",
        openFound: false
    });

    useEffect(() => {
        setState(prevState => ({...prevState, inputValue: props?.inputValue || ""}))
    }, [props?.inputValue])

    // const findProducts = (pattern: string | null) => {
    //     if (!pattern) return;
    //     dispatch(setSearchString(pattern))
    //     //setSearchValueFunction(pattern)
    //     const search = qs.parse(location.search.replace('?', ''))
    //     // If currently on search page, we need to change one search parameter
    //     const newSearch =
    //         location.pathname === '/search'
    //             ? {...search, searchValue: pattern, page: '1'}
    //             : {searchValue: pattern}
    //
    //     //set the page of the pagination in SearchPage = 0
    //     //handleSetPage()
    //
    //     // if(location.pathname === '/quick-order'){
    //     //     return
    //     // }
    //     navigate({
    //         pathname: '/search',
    //         search: `?${qs.stringify(newSearch)}`,
    //     });
    //     // findProducts(newSearch) => dispatch
    // }

    const handleKeyDown = (event: KeyboardEvent, open: boolean, selectedIndex: number | null) => {
        setState(prevState => ({...prevState, openFound: true}))

        if (keycode(event) === 'enter') {
            if (state.inputValue && state.inputValue.length >= 3) {

                const search = qs.parse(location.search.replace('?', ''))
                const newSearch =
                    location.pathname === '/search'
                        ? {...search, searchValue: state.inputValue, page: '1'}
                        : {searchValue: state.inputValue}
                navigate({
                    pathname: '/search',
                    search: `?${qs.stringify(newSearch)}`,
                });
                openSearchBox(false)
                window.location.reload();

            } else {
                dispatch(openInfoSnack(t('messages: searchValueLengthRequirement')))
            }
        }

        // If enter is pressed when list is open we just need to autocomplete instead of search
        /*if (!open || (open && selectedIndex === null))
            findProducts(state.inputValue)*/
        if (props.onKeyDown) {
            props.onKeyDown(event, state.inputValue)
        }

        //setState(prevState => ({...prevState, openFound: false}))
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({...prevState, inputValue: event.target.value}))
        //dispatch(getQuickSearchOptions({searchValue: event.target.value}))
        props.onInputChange(event.target.value)
    }

    // const handleChange = (item: string | null, stateAndHelpers: ControllerStateAndHelpers<string>) => {
    //     console.log("handleChange", item, stateAndHelpers)
    //     if (typeof item === "string") {
    //         localStorage.setItem('searchValue', item)
    //         // if(state.inputValue && !state.openFound){
    //         //     const foundSuggestion = suggestions.find(
    //         //         (suggestion) => suggestion.name === item
    //         //       );
    //         //       if (foundSuggestion) {
    //         //         props.handleInputChange(foundSuggestion.alias);
    //         //     }
    //         // }
    //     }
    //     setState(prevState => ({...prevState, inputValue: item}))
    // }

    const foundItemInSuggestions = (item: string): SearchSuggestionElement | undefined => suggestions?.find(
        (suggestion) => suggestion.name === item
    );

    const handleSelect = (selectedItem: string | null, stateAndHelpers: ControllerStateAndHelpers<string>) => {
        setState(prevState => ({...prevState, inputValue: selectedItem}))
        if (props.onSelect && selectedItem) {
            const foundItem = foundItemInSuggestions(selectedItem);
            if (foundItem) {
                props.onSelect(foundItem)
            }
        }
    }

    // const deleteInputValue = () => {
    //     setState(prevState => ({...prevState, inputValue: ''}))
    // }

    return (
        <Downshift
            inputValue={state.inputValue}
            // onChange={handleChange}
            onSelect={handleSelect}
            isOpen={state.openFound}
            // stateReducer={stateReducer}
        >
            {(
                {
                    getInputProps,
                    getItemProps,
                    getRootProps,
                    isOpen,
                    // inputValue,
                    highlightedIndex
                }
            ) => (
                <Box {...getRootProps()} sx={{
                    flexGrow: 1,
                    position: 'relative',
                }}>
                    {renderInput({
                        // fullWidth: true,
                        placeholder: props.placeholder,
                        dispatch: dispatch,
                        InputProps: getInputProps({
                            onFocus: () => {
                                dispatch(setQuickSearchOptions([]));
                            },
                            onChange: handleInputChange,
                            onKeyDown: (e: KeyboardEvent) => {
                                handleKeyDown(e, isOpen, highlightedIndex)
                            },
                            placeholder: props.placeholder,
                        }),
                        showClearIcon: props.showClearIcon,
                        styleRoot: props.styleRoot,
                        styleText: props.styleText,
                        inputStyle: props.inputStyle,
                        // searchIconElement: (
                        //     <Box
                        //         component={'img'}
                        //         src={`/images/Search.svg`}
                        //         alt='Search'
                        //         onClick={() => {
                        //             setState(prevState => ({...prevState, openFound: false}))
                        //             findProducts(state.inputValue);
                        //         }}
                        //     />
                        // )
                    })}
                    {isOpen && (
                        <Box
                            sx={{
                                width: '100%',
                                // position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 1,
                                position: 'absolute'
                            }}
                        >
                            <ClickAwayListener
                                onClickAway={() => setState(prevState => ({...prevState, openFound: false}))}
                            >
                                <Paper
                                    // className ={classes.searchBarPaper}

                                    sx={{
                                        backgroundColor: primaryColor.drawerBackground,
                                        overflow: 'auto',
                                        width: '100%',

                                        ...(props.styledPaper || {})
                                    }}
                                    onClick={() => setState(prevState => ({...prevState, openFound: false}))}
                                    square
                                >
                                    {suggestions &&
                                        suggestions
                                            // .slice(0, 7)
                                            .map((suggestion: SearchSuggestionElement, index: number) =>
                                                renderSuggestion({
                                                    suggestion,
                                                    index,
                                                    itemProps: getItemProps({item: suggestion.name}),
                                                    highlightedIndex,
                                                })
                                            )}
                                </Paper>
                            </ClickAwayListener>
                        </Box>
                    )}
                </Box>
            )}
        </Downshift>
    );
}