import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MenuHomePage, MenuItem, SocialElement, StaticPageContent} from "../interfaces/backend";
import {initialStaticState} from "../interfaces/initialStates";
import {getMenuPayloadAction, getStaticPageContentAction} from "../interfaces/actions";
import {SelectedTabObject} from "../interfaces/general";

const staticAdapter = createEntityAdapter();
const initialState: initialStaticState = staticAdapter.getInitialState({
    mainMenu: [],
    footerMenu: [],
    headerMenu: [],
    social: [],
    openedDrawerID: "",
    staticPageContent: {
        title: '', description: '', image: '', alias: '', category: '', createdAt: new Date()
    },
    menuHomePage: [],
    selectedTab: {
        tabName: '',
        tabId: ''
    }
});

export const staticSlice = createSlice({
        name: "staticContent",
        initialState: initialState,
        reducers: {
            setFooterMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.footerMenu = action.payload
            },
            setMainMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.mainMenu = action.payload
            },
            setHeaderMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.headerMenu = action.payload
            },
            setSelectedTab: (state: initialStaticState, action: PayloadAction<SelectedTabObject>) => {
                state.selectedTab = action.payload
            },
            setSocialLinks: (state: initialStaticState, action: PayloadAction<Array<SocialElement>>) => {
                state.social = action.payload
            },
            setOpenedDrawerID: (state: initialStaticState, action: PayloadAction<string>) => {
                state.openedDrawerID = action.payload
            },
            setStaticPageContent: (state: initialStaticState, action: PayloadAction<StaticPageContent>) => {
                state.staticPageContent = action.payload
            },
            setMenuHomePage: (state: initialStaticState, action: PayloadAction<Array<MenuHomePage>>) => {
                state.menuHomePage = action.payload
            },
            getStaticPageContent: (state, action: getStaticPageContentAction) => {
            },
            getSocialLinks: () => {
            },
            getMenu: (state: initialStaticState, action: PayloadAction<getMenuPayloadAction>) => {
            },
            getMenuHomePage: () => {
            }
        }
    }
)

export const {
    setSocialLinks,
    getSocialLinks,
    setFooterMenu,
    setMainMenu,
    setHeaderMenu,
    getMenu,
    setOpenedDrawerID,
    setStaticPageContent,
    getStaticPageContent,
    getMenuHomePage,
    setMenuHomePage,
    setSelectedTab
} = staticSlice.actions

export default staticSlice.reducer