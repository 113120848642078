import {useAppDispatch, useAppSelector} from "../../../hook";
import React, {useState} from "react";
import {AddressBox, ContainerBox, StyledText} from "./style";
import {ShippingAddress} from "../../../interfaces/backend";
import {initialShippingAddress} from "../../../reducers/initialCartValues";
import {useTranslation} from "react-i18next";
import {addCartShippingAddress} from "../../../reducers/cart";

interface BillingAddressCardProps {
    setIsBillingAddressUsed: React.Dispatch<React.SetStateAction<boolean>>,
    isBillingAddressUsed: boolean
}

export default function ShippingSameAsBillingAddress(props: BillingAddressCardProps) {
    const billingAddress = useAppSelector(state => state.address.billingAddress)

    const dispatch = useAppDispatch()
    const [isThisAddressSelected, setIsThisAddressSelected] = useState<boolean>(false)
    const {t} = useTranslation('checkoutPage')
    const {setIsBillingAddressUsed, isBillingAddressUsed} = props;


    const shippingAddress: ShippingAddress = {
        ...initialShippingAddress,
        companyName: billingAddress.customer?.companyName || '',
        streetName: billingAddress.streetName,
        streetNumber: billingAddress.streetNumber || '',
        postalCode: billingAddress.postalCode,
        city: billingAddress.city,
        country: billingAddress.country,
        phone: billingAddress.phone || ''
    }

    return (
        <ContainerBox
            selected={isThisAddressSelected && isBillingAddressUsed}
            onClick={() => {
                dispatch(addCartShippingAddress({shippingAddress}))
                setIsThisAddressSelected(true)
                setIsBillingAddressUsed(true)
            }}
            sx={{justifyContent: 'center'}}
        >
            <AddressBox>
                <StyledText
                    sx={{
                        WebkitLineClamp: '3',
                        fontWeight: 600
                    }}
                > {t('ShippingAddressEqualBillingAddress')} </StyledText>
            </AddressBox>
        </ContainerBox>
    )
}
