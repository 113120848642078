// export const menus = [
//     {route: '/machines', text: 'allMachines'},
//     {route: '/my/machines', text: 'machine'},
//     {route: '/spare-parts', text: 'spareParts'},
//     {route: '/accessory', text: 'accessory'},
//     {route: '/fanshop', text: 'fanshop'},
//     {route: '/quick-order', text: 'quick order'}
// ];

export const accountMenu = [
    {route: '/my-account/my-data', text: 'myData', id: 'my-account-button'},
    {route: '/logout', text: 'logout', id: 'logout-button'}
];
