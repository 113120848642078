import {List, ListItem} from "@mui/material";
import {primaryColor} from "../../../../../../theme";


interface MenuListItemProps {
    listItems: Array<React.ReactElement>;
    onClick: () => void;
}

const MenuListItem = (props: MenuListItemProps) => {
    const {listItems, onClick} = props;

    return (
        <List>
            {listItems.map((item, index) => (
                <ListItem
                    key={index}
                    disablePadding
                    onClick={item.key !== 'language-button' ? onClick : undefined}
                    sx={{
                        borderTop: "4.5px solid" + primaryColor.background,
                    }}
                >
                    {item}
                </ListItem>
            ))}
        </List>
    );
};

export default MenuListItem;
