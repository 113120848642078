import TableRow from "@mui/material/TableRow";
import {StyledTableCell} from "../../../Cart/styleElements";
import {Divider, Typography} from "@mui/material";
import {TakeFirst2DImage} from "../../../../utils/productFunctions";
import DiscountInfo from "../../../../components/common/ProductTableItem/DiscountInfo";
import {formatMoney} from "../../../../utils/localization";
import {addCartItem} from "../../../../reducers/cart";
import React from "react";
import {CartItem} from "../../../../interfaces/backend";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSettings} from "../../../../hook";
import PriceRendered from "../../../../components/common/priceRendered";
import AddToCartIcon from "../../../../components/common/AddToCartIcon";
import ProductLink from "../../../../components/common/ProductLink/ProductLink";
import ProductImage from "../../../../components/common/ProductTableItem/ProductImage";
import {primaryColor} from "../../../../theme";

export default function OrdersTableItemDesktop(props: { item: CartItem }) {
    const dispatch = useAppDispatch()
    const {item} = props;
    const {product} = item;
    const {t} = useTranslation('myAccount')
    const rowPrice = item.discountPrice || item.price;
    const {shopUsesPrices} = useAppSettings();
    const numberOfColumns = shopUsesPrices ? 4 : 2;

    return (
        <>
            <TableRow>
                <StyledTableCell sx={{paddingX: '5px'}}>
                    <ProductImage src={TakeFirst2DImage(product?.image)}/>
                </StyledTableCell>
                <StyledTableCell>
                    <ProductLink
                        linkTo={product?.alias}
                        text={item.name}
                        sx={{fontSize: '2rem'}}
                    />
                    <ProductLink
                        linkTo={product?.alias}
                        text={`${t('materialNumber')}: ${item.code}`}
                        sx={{fontSize: '2rem'}}
                    />
                </StyledTableCell>

                {shopUsesPrices && <StyledTableCell sx={{textAlign: 'right', fontWeight: 300}}>
                    <PriceRendered productPrice={item.price} productDiscountPrice={item.discountPrice}/>

                    {/* {item.discountPrice ? (
          <Box>
              <Typography
                variant={'h20'}
                sx={{
                  textDecoration: 'line-through',
                  textDecorationColor: primaryColor.lineThroughPrice,
                  textDecorationThickness: '1px',
                  textDecorationTrim: 'auto',
                  color: primaryColor.lineThroughPrice,
                  opacity: 0.3,

                }}
              >
                {rowPrice
                  ? formatMoney(item.price)
                  : t('requestPrice')}
              </Typography>
              <Typography sx={{color: primaryColor.discountCheckoutPage}}
                          fontSize={'2rem'}
              >
                {formatMoney(rowPrice)}
              </Typography>
            </Box>
          ) :
          <Typography variant={'h20'}>
            {rowPrice
              ? formatMoney(rowPrice)
              : t('requestPrice')}
          </Typography>
        } */}
                </StyledTableCell>}

                <StyledTableCell>
                    <Typography sx={{textAlign: 'right'}}>{item.quantity}</Typography>
                </StyledTableCell>

                {shopUsesPrices && <StyledTableCell sx={{textAlign: 'right'}}>
                    {rowPrice
                        ? formatMoney(rowPrice * item.quantity)
                        : t('requestPrice')}
                </StyledTableCell>}

                <StyledTableCell sx={{
                    paddingX: {lg: '5px', xl: '15px'},
                    width: "55px"
                }}>
                    {item.isOrderable &&
                        <AddToCartIcon
                            onClick={() =>
                                dispatch(addCartItem({
                                    code: product.code,
                                    quantity: item.quantity,
                                }))}
                            sx={{
                                cursor: 'pointer',
                                verticalAlign: 'bottom',
                            }}
                            toolTipTitle={(shopUsesPrices ? t('addToCart') : t('addToInquiry')) as string}
                        />
                    }
                </StyledTableCell>
            </TableRow>

            {item.discounts.length > 0 ? (
                <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell
                        sx={{
                            paddingTop: 0,
                            width: "fit-content",
                        }}
                    >
                        <DiscountInfo discounts={item.discounts}/>
                    </StyledTableCell>
                    <StyledTableCell colSpan={numberOfColumns}></StyledTableCell>
                </TableRow>
            ) : null}

            <TableRow>
                <StyledTableCell colSpan={numberOfColumns + 1}>
                    <Divider sx={{borderBottom: '0.5px solid ' + primaryColor.ordersTableDivider}}/>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
            </TableRow>
        </>
    );
}