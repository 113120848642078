import Tab from "./Tab";
import {Box, Typography, useMediaQuery} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {TabData} from "../../../pages/Account/AccountPage";
import {setOpenedDrawerID} from "../../../reducers/static";
import {setMyDataOpened} from "../../../reducers/styleControls";
import theme, {primaryColor} from "../../../theme";
import {Link} from 'react-router-dom';
import {useLocation} from "react-router";
import {SelectedTabObject} from "../../../interfaces/general";

export interface AllTabsProps {
    allTabs: Array<Array<TabData>>,
    topTitle?: string,
    useParameter?: boolean,
}

const AllTabs = (props: AllTabsProps) => {
    const {allTabs, useParameter, topTitle} = props
    const dispatch = useAppDispatch()
    const location = useLocation()
    const selectedTabObject: SelectedTabObject = useAppSelector(state => state.staticContent.selectedTab);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // updating selectedName after the rendering
    // useEffect(() => {
    //   if (isMobile) {
    //     const tab = allTabs.find((tabsList) =>
    //         tabsList.some((tab) => tab.item === selectedItem)
    //     );
    //     // updating selectedName only  when it is needed

    //     if (tab && tab[0].text !== selectedName) {
    //       setSelectedName(tab[0].text);
    //     }
    //   }
    // }, [allTabs, isMobile, selectedItem, selectedName, setSelectedName]);

    return (
        <>
            {isMobile && topTitle && <Box sx={{
                color: primaryColor.myAccountTabText,
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography sx={{fontSize: '2.6rem', fontWeight: '600'}}>{topTitle}</Typography>
            </Box>}
            {allTabs.map((tabsList, index) => {
                    return (
                        <Box key={index} sx={{marginBottom: isMobile ? '17px' : '70px'}}>
                            <Paper sx={{
                                backgroundColor: primaryColor.background,
                                borderRadius: '0px',
                                display: 'flex',
                                flexDirection: 'column',
                                '&.MuiPaper-root': {
                                    boxShadow: 'none',
                                },
                            }}>
                                {tabsList.map((tab, i) => {
                                        const params: TabData = {
                                            ...tab,
                                            selected: selectedTabObject.tabId === tab.item,
                                            isLast: tabsList.length === i + 1
                                        }

                                        if (tab.item !== 'exit')
                                            return (
                                                /*            <Box key={i}
                                                                component="a"
                                                                href={`/my-account/${tab.item}`}
                                                                onClick={() => isMobile && dispatch(setMyDataOpened(false)) && dispatch(setOpenedDrawerID(""))}>
                                                              <Tab {...params} />location.pathname + '?tab=' + tab.item
                                                            </Box>*/
                                                /*              <Box key={i} onClick={()=>    navigate({
                                                      pathname: `${location.pathname}`,
                                                      search: `?tab=${tab.item}`,
                                                    })}>*/
                                                useParameter ?
                                                    <Link key={i} to={location.pathname + '?tab=' + tab.item
                                                    }>
                                                        <Tab {...params} />
                                                    </Link>
                                                    : <Link key={i} to={`/my-account/${tab.item}`}
                                                            onClick={() => isMobile && dispatch(setMyDataOpened(false)) && dispatch(setOpenedDrawerID(""))}>
                                                        <Tab {...params} />
                                                    </Link>
                                            )
                                        else
                                            return (
                                                <Link key={i} to={`/logout`}>
                                                    <Tab {...params} />
                                                </Link>
                                            )
                                    }
                                )}
                            </Paper>
                        </Box>
                    )
                }
            )}
        </>
    )
}
export default (AllTabs)
 