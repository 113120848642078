import createSagaMiddleware from 'redux-saga'
import {configureStore} from "@reduxjs/toolkit";
import staticReducer from './reducers/static'
import searchReducer from './reducers/search'
import quickSearchReducer from "./reducers/quickSearch";
import startReducer from './reducers/start'
import metadataReducer from "./reducers/metadata"
import cartReducer from "./reducers/cart"
import authReducer from "./reducers/auth"
import productDetailsReducer from "./reducers/productdetails"
import machineReducer from "./reducers/machine"
import addressReducer from "./reducers/address"
import sparePartReducer from "./reducers/sparePart"
import styleControlsReducer from "./reducers/styleControls"
import myAccountReducer from "./reducers/myAccount";
import quickOrderReducer from "./reducers/quickOrder";
import viewerReducer from "./reducers/viewerReducer";
import snackbarReducer from "./reducers/snackbar";
import settingsReducer from './reducers/settings'

export const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {
        staticContent: staticReducer,
        search: searchReducer,
        quickSearch: quickSearchReducer,
        start: startReducer,
        metadata: metadataReducer,
        cart: cartReducer,
        productDetails: productDetailsReducer,
        machine: machineReducer,
        auth: authReducer,
        sparePart: sparePartReducer,
        styleControls: styleControlsReducer,
        address: addressReducer,
        myAccount: myAccountReducer,
        quickOrder: quickOrderReducer,
        viewer: viewerReducer,
        snackbar: snackbarReducer,
        settings: settingsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {
            serializableCheck: {
                // // Ignore these action types
                ignoredActions: ['staticContent/getMenu', 'metadata/setServerDate', 'snackbar/openErrorSnack'],
                // // Ignore these field paths in all actions
                // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['staticContent.staticPageContent.createdAt', 'metadata.serverDate'],
            },
        }
    ).concat(sagaMiddleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store