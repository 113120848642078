import React from 'react';
import {Link} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {BreadCrumbsObject} from "../../../interfaces/general";
import {primaryColor} from "../../../theme";

const BreadCrumbs = (props: { links: Array<BreadCrumbsObject> }) => {
    const {links} = props;
    return (
        <Box sx={{display: 'flex', marginTop: '23px', fontSize: '1.5rem', fontWeight: '300'}}>
            {links &&
                links.map((k: BreadCrumbsObject, i: number) => (
                    <Box
                        key={i}
                        sx={{color: primaryColor.breadcrumbsColor, display: 'flex'}}
                    >
                        {k.link ? (
                            <Link style={{color: primaryColor.breadcrumbsColor}} to={k.link}>
                                {k.name}
                            </Link>
                        ) : (
                            <Typography sx={{color: primaryColor.breadcrumbsColor}}>{k.name}</Typography>
                        )}

                        {i !== links.length - 1 && <Box sx={{
                            marginX: '6px',
                            fontSize: '1rem',
                            alignSelf: 'center'
                        }}> | </Box>}
                    </Box>
                ))}
        </Box>
    );/**/
};

export default (BreadCrumbs)
