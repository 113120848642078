import {Box, SxProps} from '@mui/material';
import * as React from "react";
import {useTranslation} from "react-i18next";

interface modalProps {
    isOrderable: boolean,
    inStock: boolean,
    iconSx?: SxProps,
    textSx?: SxProps,
    containerSx?: SxProps
}

export const Availability = (props: modalProps) => {
    const {isOrderable, inStock, iconSx, textSx, containerSx} = props;
    const {t} = useTranslation('productPage')

    return (
        <Box sx={{
            // textTransform: 'lowercase',
            display: 'inline-flex',
            alignItems: "center",
            ...containerSx
        }}>
            <Box component={'img'}
                 sx={iconSx}
                 alt='status'
                 src={isOrderable
                     ? (inStock
                         ? '/images/icons/icon-available.svg'
                         : '/images/icons/icon-available-limited.svg')
                     : '/images/icons/icon-not-available.svg'
                 }
            />
            <Box sx={{marginLeft: '10px', ...textSx}}>
                {isOrderable
                    ? (inStock
                        ? t('available')
                        : t('limited'))
                    : t('notAvailable')}
            </Box>
        </Box>
    )
}