import {Autocomplete, Box, Popper, PopperProps, TextField} from "@mui/material";
import React, {SyntheticEvent} from "react";
import {primaryColor} from "../../theme";
import {CodeName} from "../../interfaces/general";

const CustomPaper = (props: PopperProps) => {
    return <Popper
        {...props}
        sx={{
            '& .MuiPaper-root.MuiAutocomplete-paper': {
                backgroundColor: primaryColor.autoCompleteBackground,
                borderRadius: '0',
                color: primaryColor.whiteText,
                '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
                    maxWidth: 'fit-content',
                    '&:hover': {
                        backgroundColor: primaryColor.autoCompleteItemColor
                    }
                },
                '& .MuiAutocomplete-listbox': {
                    height: 'fit-content',
                    maxHeight: '200px',

                },
                '& .MuiAutocomplete-noOptions': {
                    display: 'none'
                }
            }
        }}
    />
}

interface CustomAutoCompleteProps {
    options: Array<CodeName>,
    handle: (value: CodeName | null) => void,
    label: string,
    required?: boolean,
    value: CodeName | null
}

export default function CustomAutocomplete(props: CustomAutoCompleteProps) {
    const {options, handle, label, value, required = false} = props

    return <Autocomplete
        autoComplete
        includeInputInList
        options={options}
        value={value}
        getOptionLabel={(option: CodeName) => option.name}
        isOptionEqualToValue={(option: CodeName, value: CodeName | null) => option.code === value?.code}
        onChange={(e: SyntheticEvent, value: CodeName | null) => handle(value)}
        popupIcon={
            <Box
                component={'img'}
                src={'/images/arrowUp.svg'}
                sx={{
                    transform: 'rotate(180deg)',
                    transition: '200ms linear all'
                }}
            />
        }
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                required={required}
                variant={'standard'}
            />
        )}

        sx={{
            width: '100%',
            '& .MuiSvgIcon-root': {
                display: 'hide',
                color: primaryColor.whiteIcon
            },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 2,
        }}
        // open={true}
        PopperComponent={CustomPaper}
    />
};
