import {Document} from "../../../interfaces/backend";
import React, {useMemo, useState} from "react";
import {Box, styled, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery,} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import {TableCellProps} from "@mui/material/TableCell";
import CustomPagination from "../../../pages/ListingPage/Pagination";
import {getDocument} from "../../../reducers/productdetails";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";

export interface DocumentsListsMenuProps {
    documents: Document[]
}

export const StyledTableCell = styled((props: TableCellProps) => (
    <TableCell {...props}/>
))(({theme}) => ({
    color: primaryColor.documentsCellColor,
    fontWeight: 300,
    paddingTop: '4px',
    paddingBottom: '4px',
    borderBottom: 'none',
    //backgroundColor: mobileView? primaryColor.documentsCellBackground: 'none',
    alignItems: 'center',
    height: '60px',
    fontSize: '2rem',
    [theme.breakpoints.down("lg")]: {
        fontSize: '1.5rem',
    },
}))

export const DocumentsListsMenu = (props: DocumentsListsMenuProps) => {
    const {documents} = props
    const [currentPage, setCurrentPage] = useState<number>(0)
    const rowsPerPage = 30
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageDocuments = documents.slice(startIndex, endIndex)
    const dispatch = useAppDispatch()
    const {t} = useTranslation("machineDetails");
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const findFormat = (url: string) => {
        return url.split('.').pop()
    }

    const handleChangePage = (newPage: number) => {
        setCurrentPage(newPage - 1);
    };

    let paginationArray: number[] = useMemo(() => {
        const totalPages = Math.ceil(documents.length / rowsPerPage);
        return Array.from({length: totalPages}, (_, i) => i + 1);
    }, [documents.length]);

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableBody
                        sx={{
                            backgroundColor: primaryColor.documentsBackground,
                            width: '100%'
                        }}
                    >
                        {currentPageDocuments.map((row: Document, i: number) => {
                            return (
                                <TableRow sx={mobileView ? {
                                    borderBottom: 'ridge',
                                    borderColor: primaryColor.documentsBorder,
                                    //borderWidth: '0.8px'
                                } : {}} key={i}>

                                    <StyledTableCell sx={{
                                        backgroundColor: !mobileView ? primaryColor.documentsCellBackground : primaryColor.background,
                                        width: !mobileView ? '60%' : '70%',
                                        lineBreak: 'anywhere'
                                    }}>
                                        <Box sx={{display: 'inline-flex', cursor: 'pointer'}}
                                             onClick={() => dispatch(getDocument({url: row.url}))}>
                                            <Box
                                                component={'img'}
                                                src={'/images/icons/file_icon.svg'}
                                                sx={{
                                                    marginRight: '11px',
                                                    maxHeight: '34px'
                                                }}
                                            />
                                            <Box sx={{
                                                display: 'inline-flex', marginLeft: '15px'
                                            }}>
                                                {row.title}
                                            </Box>
                                        </Box>
                                    </StyledTableCell>
                                    {!mobileView ? <>

                                        <StyledTableCell sx={{
                                            textAlign: 'right',
                                            backgroundColor: primaryColor.documentsCellBackground
                                        }}>
                                            {t('{{format}} - file', {format: findFormat(row.url)})}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{
                                            textAlign: 'right',
                                            whiteSpace: 'nowrap',
                                            backgroundColor: primaryColor.documentsCellBackground
                                        }}>
                                            {row.size / 1000} KB
                                        </StyledTableCell>
                                    </> : <StyledTableCell
                                        sx={{textAlign: 'right', backgroundColor: primaryColor.background}}>
                                        <Box>{t('{{format}} - file', {format: findFormat(row.url)})}</Box>
                                        <Box> {row.size / 1000} KB</Box>
                                    </StyledTableCell>}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{justifyContent: 'center', display: 'flex', paddingY: '20px'}}>
                <CustomPagination
                    currentPage={currentPage + 1}
                    pagination={paginationArray}
                    setSelectedPage={handleChangePage}
                />
            </Box>
        </Box>
    )
}
