import React from "react";
import {Article} from "../../interfaces/backend";
import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import {useTranslation} from "react-i18next";
import {cleanHTMLTags} from "../../utils/productFunctions";
import {Link} from "react-router-dom";

const BlogContent = (props: { data: Article }) => {
    const {title, description, alias} = props.data;
    const {t} = useTranslation("common");

    return (
        <Box
            sx={{
                width: {xs: "100%", lg: "60%"},
                textAlign: {
                    xs: "center",
                    lg: "unset",
                },
            }}
        >
            <Link to={`/blog/${alias}`}>
                <Typography
                    sx={{
                        color: primaryColor.animatedMenuArticleTitle,
                        alignItems: 'center',
                        paddingY: {xs: '30px', lg: '5px'},
                        fontWeight: 300,
                        fontSize: {
                            xs: "1.8rem",
                            lg: "4rem",
                        },
                        textTransform: {
                            xs: "uppercase",
                            lg: "unset",
                        },
                        wordBreak: "break-word",
                    }}
                >
                    {title}
                </Typography>

                <Box
                    sx={{
                        paddingY: "25px",
                        display: {
                            xs: "none",
                            lg: "block",
                        },
                    }}
                >
                    <Typography
                        variant="h7"
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            textEllipsis: "ellipsis",
                            wordBreak: "break-word",
                            maxHeight: "10em",
                        }}
                    >
                        {cleanHTMLTags(description)}
                    </Typography>
                </Box>

                <Typography
                    variant="h3"
                    sx={{
                        fontSize: "2rem",
                        fontWeight: 700,
                        color: primaryColor.animatedMenuReadMore,
                        display: {
                            xs: "none",
                            lg: "block",
                        },
                    }}
                >
                    {t("readMore")}
                </Typography>
            </Link>
        </Box>
    );
};
export default BlogContent;
