import React from 'react';
import {Box} from "@mui/material";
import {primaryColor} from "../../../../../theme";
import {CartItem, Order} from "../../../../../interfaces/backend";
import CheckoutProductMobile from '../../../../../components/common/ProductTableItem/mobile/CheckoutProductMobile';

interface OrdersTableProps {
    order: Order,
}

export default function OrdersTableTablet(props: OrdersTableProps) {
    const {items} = props.order
    return (
        <Box sx={{
            backgroundColor: primaryColor.orderHistoryTabletBackground,
            paddingX: {xs: '30px', md: '0px'},
            paddingY: '30px'
        }}>
            {items.map((row: CartItem, i: number) =>
                <CheckoutProductMobile
                    key={row.code}
                    useDivider={i + 1 < items.length}
                    item={row}
                    sx={{
                        paddingX: {md: '20px'}
                    }}
                />)}
        </Box>
    )
}