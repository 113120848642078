import React, {useMemo, useState} from "react";
import {defaultFonts, primaryColor, secondaryFonts} from "../../../theme";
import {Box, BoxProps, styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DiscountObject, DiscountValueType} from "../../../interfaces/backend";

interface DiscountInfoProps {
    discounts: DiscountObject[]
}

interface ContainerBoxProps extends BoxProps {
    isOpen?: boolean;
}

const ContainerBox = styled(({isOpen, ...otherProps}: ContainerBoxProps) => (
    <Box {...otherProps} />
))(({theme, isOpen}) => ({

    display: 'flex',
    flexDirection: 'row',
    alignItems: isOpen ? 'start' : 'center',
    cursor: 'pointer',
    color: primaryColor.whiteText,
    width: '100%',
    '&:hover': {
        color: primaryColor.orangeHoverColor
    },
    [theme.breakpoints.down("md")]: {
        flexDirection: isOpen ? 'column' : 'row',
    },

}));
const IconBox = styled(() => (
    <Box
        component={'img'}
        src={'/images/icons/percentageIcon.svg'}
    />
))(({theme}) => ({
    height: '22px',
    [theme.breakpoints.down("md")]: {
        height: '20px',
    },
}));


export default function DiscountInfo({discounts}: DiscountInfoProps) {

    const [opened, setOpened] = useState(false)
    const {t} = useTranslation('checkoutPage')
    let discountInfo = useMemo(() =>
            discounts.filter((item) => item.discount.valueType === DiscountValueType.Percentage)
                .map(item => ` ${item.discount.discountCode} (${-item.discount.value}%)`).toString(),
        [discounts]);


    return (
        discountInfo.length > 0 ? <ContainerBox onClick={() => setOpened(!opened)} isOpen={opened}>

            <IconBox/>
            {!opened ?
                <Typography
                    sx={{
                        marginLeft: '10px',
                        fontSize: {
                            xs: defaultFonts.xs,
                            sm: defaultFonts.sm,
                            lg: defaultFonts.md,
                        }
                    }}
                >{t('info')}
                </Typography>
                :
                <Typography sx={{
                    marginLeft: {xs: 0, md: '10px'},
                    color: primaryColor.orangeHoverColor,
                    width: '100%',
                    wordBreak: 'break-word',
                    fontSize: secondaryFonts,
                }}> {discountInfo
                }</Typography>
            }
        </ContainerBox> : <></>
    )
}