import MyData from "../../pages/Account/data/MyData";
import MyOrders from "../../pages/Account/data/MyOrders";
import NeedHelp from "../../pages/Account/data/NeedHelp";
import ChangePassword from "../../pages/Account/data/ChangePassword";
import React from "react";
import ReturnOptions from "../../pages/Account/data/ReturnOptions";
import UserManagement from "../../pages/Account/UserManagement";
import CustomerSupportService from "../../pages/Account/CustomerSupport";
import Documents, {SelectedDocumentProps} from "../machineDetails/documents";
import SensorData from "../machineDetails/sensorData";
import MachineInfo from "../machineDetails/machineInfo";
import {useTranslation} from "react-i18next";
import SpareParts from "../machineDetails/spareParts";
import {useAppSettings} from "../../hook";

interface PageRelationsProps {
    id?: string,
}

export function findItem(arr: { item: string, text: string }[][], itemToFind?: string) {
    let res: { item: string, text: string } | undefined;
    arr.some(
        o => {
            res = o.find(
                of => of.item === itemToFind)
            return res
        }
    )
    return res;
}

export interface MachineDetailsPageRelationsProps {
    docsList?: SelectedDocumentProps[]
}


// here is relation on the component and the tab
export const machineDetailsPageRelations = (props: MachineDetailsPageRelationsProps) => {
    return [
        {
            name: "spare-parts",
            page: <SpareParts/>
        },
        {
            name: "machine-info",
            page: <MachineInfo/>
        },
        {
            name: "sensor-data",
            page: <SensorData/>
        },
        {
            name: "documents",
            page: <Documents docsList={props.docsList}/>
        }
    ]
}

export default function useAllMachineDetailsTabs() {
    const {t} = useTranslation('machineDetails')

    return (
        [
            [
                {
                    item: 'spare-parts',
                    text: t('machineDetails:spareParts'),
                    page: <SpareParts/>

                },
                {
                    item: 'machine-info',
                    text: t('machineDetails:machineInfo'),
                    page: <MachineInfo/>

                },
                {
                    item: 'sensor-data',
                    text: t('machineDetails:sensorData'),
                    page: <SensorData/>

                },
                {
                    item: 'documents',
                    text: t('machineDetails:documents'),
                    page: <Documents/>
                },
            ]
        ]
    )
}

export function useAllMyAccountTabs() {
    const {t} = useTranslation(['myAccount']);
    const {shopUsesPrices} = useAppSettings();

    return (
        [
            [
                {
                    item: 'my-data',
                    text: t('myAccount:myData'),
                },
                {
                    item: 'my-orders',
                    text: shopUsesPrices ? t('myAccount:myOrders') : t('myAccount:myInquiries')
                },
                {
                    item: 'management',
                    text: t('myAccount:userManagement')
                },
                {
                    item: 'change-password',
                    text: t('myAccount:changePassword')
                },
                {
                    item: 'customer-support',
                    text: t('myAccount:customerSupport'),
                },
            ],
            [
                {
                    item: 'return-options',
                    text: t('myAccount:returnOptions')
                },
                {
                    item: 'need-help',
                    text: t('myAccount:needHelp'),
                },
            ],
            [
                {
                    item: 'exit',
                    text: t('common:logout'),
                },
            ]
        ]
    )
}


// here is relation on the component and the tab
export const myAccountPageRelations = (params: PageRelationsProps) => {
    return [
        {
            name: "my-data",
            page: <MyData/>
        },
        {
            name: "my-orders",
            page: <MyOrders id={params.id}/>
        },
        {
            name: "need-help",
            page: <NeedHelp/>
        },
        {
            name: "change-password",
            page: <ChangePassword/>
        },
        {
            name: "return-options",
            page: <ReturnOptions/>
        },
        {
            name: "management",
            page: <UserManagement/>
        },
        {
            name: "customer-support",
            page: <CustomerSupportService/>
        },
    ]
}