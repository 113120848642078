import React from "react";
import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import ArrowButton from "../../components/common/ArrowButton";
import {isGoBackActive} from "../../utils/productFunctions";
import {useLocation} from "react-router-dom";

interface CommonContent {
    title: string;
    description: string;
    image?: string;
    createdAt?: string;
}

interface CommonStructuresProps {
    handleNavBack: () => void;
    content: CommonContent;
    customPageBackground?: string;
}

export function CommonStructures(props: CommonStructuresProps) {
    const {handleNavBack, content, customPageBackground} = props;
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();
    const isActive = isGoBackActive(location.key);

    return (
        <Box
            sx={{
                color: primaryColor.whiteText,
                paddingBottom: "60px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    maxHeight: "fit-content",
                    justifyContent: "center",
                    padding: "0 45px",
                }}
            >
                {isActive && (
                    <Box
                        onClick={handleNavBack}
                        sx={{
                            paddingTop: {
                                xs: "0px",
                            },
                        }}
                    >
                        <ArrowButton
                            direction={"left"}
                            color={primaryColor.arrowButtonColorSlider}
                            width={mobileView ? 25 : 40}
                        />
                    </Box>
                )}
                {
                    <Typography
                        sx={{
                            flex: 1,
                            paddingY: {
                                xs: '20px',
                                lg: '10px'
                            },
                            textAlign: "center",
                            fontSize: {xs: "2.6rem", md: "7rem"},
                            fontWeight: 700,
                            maxWidth: "950px",
                        }}
                    >
                        {content.title}
                    </Typography>
                }
            </Box>

            <Box
                sx={{
                    backgroundColor:
                        customPageBackground || primaryColor.staticPageBackground,
                }}
            >
                <Box
                    sx={{
                        width: "75%",
                        maxWidth: "850px",
                        margin: "0 auto",
                        wordWrap: "break-word",
                    }}
                >
                    {content.image && (
                        <Box
                            sx={{
                                width: "100%",
                                height: "auto",
                                overflowY: "hidden",
                                marginBottom: {
                                    xs: "-30px",
                                    lg: "-50px",
                                },
                                maxHeight: {
                                    xs: "106px",
                                    sm: "200px",
                                    md: "300px",
                                    lg: "455px",
                                },

                            }}
                        >
                            <Box
                                component={"img"}
                                alt={content.title}
                                src={content.image}
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                        </Box>
                    )}

                    <Box
                        sx={{
                            paddingY: {
                                xs: "30px",
                                lg: "50px",
                            },
                        }}
                    >
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: content.description ? content.description : "",
                            }}
                        />
                        {content.createdAt && <Typography>{content.createdAt}</Typography>}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
