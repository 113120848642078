import {primaryColor} from "../../../theme";

export const swiperStyles = {
    slider: {
        color: primaryColor.whiteTab,
        textAlign: 'center'
    },
    slider__swiper_container1: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    slider__swiper_container2: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    slider__flex: {
        display: "flex",
        alignItems: "flex-start",
        textAlign: 'center'
    },
    slider__col: {
        display: "flex",
        flexDirection: "column",
        width: "80px",
        marginRight: "12px"
    },
    slider__nav: {
        cursor: "pointer",
        textAlign: "center",
        fontSize: "1.4rem",
        //height: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
        '&:focus': {
            outline: 'none'
        },
    },
    slider__thumbs_slider__image_swiper_slide_thumb_active: {
        WebkitFilter: "grayscale(0%)",
        filter: "grayscale(0%)",
        opacity: "1"
    },
    slider__thumbs_slider__image: {
        transition: "0.25s",
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)",
        opacity: "0.5",
        '&:hover': {
            opacity: "1"
        },
    },
    slider__thumbs: {
        height: "calc(400px - 40px)",
        swiper_slide_thumb_active: {
            WebkitFilter: "grayscale(0%)",
            filter: "grayscale(0%)",
            opacity: "1"
        }
    },
    slider__images: {
        height: "400px",
        width: "590px",
        backgroundColor: primaryColor.pdpImageBackground

    },
    slider__images__slider__image: {
        '& img': {
            display: "block",
            width: "100%",
            //backgroundColor: primaryColor.staticPageBackground,
            maxHeight: "400px",
            transition: '3s',
            objectFit: 'contain',
            cursor: 'pointer'


        },
        '&:hover': {
            '& img': {
                transform: "scale(1.1)",
            }
        },
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '590px',
        justifyContent: 'space-around',
        height: '100%'
    },
    slider__image: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        '& img': {
            display: "block",
            width: "100%",
            height: "100%",
            objectFit: "cover"
        }
    }
};