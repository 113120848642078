import {Box, TextField} from "@mui/material";
import {primaryColor} from "../../../theme";
import React, {useState} from "react";
import {sendMyMessage} from "../../../reducers/myAccount";
import {useAppDispatch} from "../../../hook";
import {useTranslation} from "react-i18next";
import {openErrorSnack} from "../../../reducers/snackbar";
import keycode from "keycode";

const InputArea = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation(['myAccount', 'Validation Messages'])
    const [textMessage, setTextMessage] = useState<string>("");

    const handleSend = () => {
        if (textMessage.trim().length > 0) {
            dispatch(sendMyMessage({message: textMessage}))
            setTextMessage("")
        } else {
            dispatch(openErrorSnack({message: t('Validation Messages:supportCenterMessageRequired')}))
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (keycode(event.nativeEvent) === "enter" && !event.shiftKey) {
            event.preventDefault();
            handleSend()
        }
    }

    return (
        <Box
            sx={{
                border: 0,
                display: 'flex',
                paddingY: '29px',
                backgroundColor: primaryColor.textAreaBackground,
                marginTop: "5px"
            }}
        >
            <Box
                component={'img'}
                src={'/images/icons/document_attach.svg'}
                sx={{
                    marginLeft: "20px",
                    marginRight: '10px',
                    height: '30px',
                }}
            />
            <TextField
                placeholder={t('myAccount:writeAMessage') as string}
                multiline
                maxRows={3}
                sx={{
                    resize: 'none',
                    border: 0,
                    fontSize: '1.6rem'
                }}
                value={textMessage}
                onChange={event => setTextMessage(event.target.value)}
                onKeyDown={handleKeyDown}
            />
            <Box
                component={'img'}
                src={'/images/icons/message_send.svg'}
                sx={{
                    marginLeft: "10px",
                    marginRight: '20px',
                    height: '30px',
                    cursor: "pointer"
                }}
                onClick={handleSend}
            />
        </Box>
    )
}

export default InputArea