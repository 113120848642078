import React, {useRef} from 'react';

import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../page";
import {defaultFonts, primaryColor} from "../../theme";
import InquiryCompletedSummary from "./InquiryCompletedSummary";
import {useAppSelector, useAppSettings} from "../../hook";
import ReactToPrint from "react-to-print";
import {NavigateButton} from '../SpareParts/subComponents';
import {setOperation} from '../../utils/productFunctions';
import {OperationList} from '../../interfaces/general';
import OrderCompletedSummary from './OrderCompletedSummary';

export default function OrderCompletedPage() {
    const order = useAppSelector(state => state.cart.orderResult)
    const {t} = useTranslation('checkoutPage')
    const componentRef = useRef(null);
    const {shopUsesPrices} = useAppSettings();

    function getOperation(action: keyof OperationList): string {
        return setOperation(shopUsesPrices, action);
    }

    const contentStyles = {
        fontSize: defaultFonts,
        paddingY: '3px',
        fontWeight: 300,
        lineHeight: '1.8rem',
    }

    const wrapperStyles = {
        width: '100%',
        maxWidth: {
            xs: '450px',
            md: 'unset',
        },
        paddingX: '25px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    }

    return (
        <Page>
            <Box ref={componentRef}>
                <Box
                    sx={{
                        ...wrapperStyles,
                        color: primaryColor.shoppingCartTitle,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            paddingY: {
                                xs: '5px',
                                sm: '10px',
                                lg: '20px',
                            },
                            fontSize: {
                                xs: '2.6rem',
                                md: '3rem',
                                lg: '7rem',
                            }
                        }}
                    >
                        {t(getOperation('OperationName'))}
                    </Typography>
                    <Typography
                        sx={{
                            mt: {
                                xs: '10px',
                                md: '40px',
                                lg: '80px',
                            },
                            fontSize: {
                                xs: '2rem',
                                md: '2.5rem',
                                lg: '3rem'
                            },
                            lineHeight: '2.4rem',
                            fontWeight: 300,
                            textTransform: 'uppercase'
                        }}
                    >
                        {/* {t('thanks for order')} */}
                        {t(`thanks for ${getOperation('OperationName')}`)}
                    </Typography>
                    <Typography
                        variant={'h20'}
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            mt: {
                                xs: '30px',
                                sm: '40px',
                                lg: '50px',
                            },
                            ...contentStyles
                        }}
                    >
                        {/* t('your order number is')} */}
                        {t(`your ${getOperation('OperationName')} number is`)}
                    </Typography>
                    <Typography
                        variant={'h20'}
                        sx={{
                            color: primaryColor.discountCheckoutPage,
                            ...contentStyles,
                        }}
                    >
                        {order.orderId}
                    </Typography>
                    <Typography
                        variant={'h20'}
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            ...contentStyles,
                        }}
                    >
                        {t('make ready')}
                    </Typography>

                    <Box sx={{
                        mt: '30px',
                        mb: {
                            xs: '30px',
                            lg: '60px',
                        },
                        fontWeight: 400,
                        width: {
                            xs: "100%",
                            sm: "auto"
                        }
                    }}>
                        <NavigateButton
                            content={t('back')}
                            navigateTo={"/"}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        ...wrapperStyles,
                        mb: '50px',
                        backgroundColor: {
                            lg: primaryColor.backgroundOverlay
                        },
                        "& > div": {
                            width: {
                                xs: '100%',
                                md: '70%',
                                lg: '55%'
                            },
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'start',
                            mt: {xs: '30px', sm: '50px'},
                        }}
                    >
                        {shopUsesPrices ? <OrderCompletedSummary/> : <InquiryCompletedSummary/>}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            lg: 'flex-start'
                        },
                    }}>
                        <ReactToPrint
                            trigger={() =>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginY: {
                                            xs: '30px',
                                            md: '25px',
                                            lg: '53px'
                                        },
                                        width: '100%',
                                        alignItems: 'center',
                                        maxWidth: 'fit-content',
                                        cursor: 'pointer',
                                        backgroundColor: {
                                            xs: primaryColor.buttonColor,
                                            lg: 'unset'
                                        },
                                        paddingX: {
                                            xs: '25px',
                                            lg: 'unset'
                                        },
                                        height: '43px',
                                        fontWeight: 400,
                                    }}>
                                    <Box
                                        component={'img'}
                                        src={'/images/icons/printIcon.svg'}
                                        sx={{
                                            width: {
                                                xs: '24px',
                                                md: '28px',
                                                lg: '34px',
                                            },
                                            height: {
                                                xs: '24px',
                                                md: '28px',
                                                lg: '34px',
                                            },
                                        }}
                                    />
                                    <Typography
                                        variant="h20"
                                        sx={{
                                            color: primaryColor.shoppingCartTitle,
                                            fontSize: contentStyles.fontSize,
                                            ml: '10px',
                                            width: '100%',
                                        }}>
                                        {t('printSummary')}
                                    </Typography>
                                </Box>
                            }
                            content={() => componentRef.current}
                            documentTitle={order.orderId}
                            copyStyles={false}
                        />
                    </Box>
                </Box>
            </Box>
        </Page>
    )
}