import React from 'react';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Cart, CartItem} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import CheckoutAccordion from "../../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import {defaultFonts} from '../../../theme';
import CheckoutProductMobile from '../../../components/common/ProductTableItem/mobile/CheckoutProductMobile';

export default function CheckoutCartTableMobile(props: { data: Cart }) {
    const {t} = useTranslation('checkoutPage')
    const {data} = props;

    return (
        <CheckoutAccordion
            title={
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Typography variant={'h20'} sx={{mb: '18px'}}>
                        {t('cart')}
                    </Typography>
                    <Typography sx={{mb: '4px', fontSize: defaultFonts}}>
                        {data.itemsQuantity} {t('items')}
                    </Typography>
                    <Typography sx={{fontWeight: 700, fontSize: defaultFonts}}>
                        {formatMoney(data.totalWithTaxAndShipping)}
                    </Typography>
                </Box>
            }
            content={
                data?.items?.length > 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >

                    {data?.items.map((row: CartItem, i: number) =>
                        <CheckoutProductMobile
                            key={row.code}
                            useDivider={i + 1 < data.items.length}
                            item={row}

                        />
                    )}
                </Box>
            }
            mandatory={false}
        />
    )
}