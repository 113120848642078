import React from "react";
import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import {handleNavBack, isGoBackActive} from "../../utils/productFunctions";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowButton from "../../components/common/ArrowButton";

interface TitlePdpProps {
    title: string;
    subTitle?: string;
    withNavBack?: boolean;
}

const TitlePdp = (props: TitlePdpProps) => {
    const {title, subTitle, withNavBack} = props;
    const navigate = useNavigate();

    const location = useLocation();
    const isActive = withNavBack && isGoBackActive(location.key);

    return (

        <Box sx={{
            //backgroundColor: primaryColor.productDetailsBackground,
            color: primaryColor.pdpTitleText,
            // height: '81px',
            display: 'inline-flex',
            width: '100%',
            fontWeight: 600,
            marginBottom: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: isActive ? 'pointer' : 'unset',
        }}
        >
            {isActive && <Box
                onClick={() => {
                    handleNavBack(navigate)
                }
                }
                sx={{
                    // height: '34px',
                    width: '17px',
                    objectFit: 'cover',
                    color: primaryColor.accountTextColor,
                    marginRight: '20px',
                    marginLeft: '25px',
                }}>
                <ArrowButton
                    direction={"left"}
                    width={40}
                />

            </Box>}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                marginRight: isActive ? '60px' : '0'
            }}

            >
                <Typography sx={{
                    fontSize: {xs: '2.6rem', md: '5.5rem', lg: '7rem'},
                    fontWeight: {xs: '600', lg: '700'},
                    color: primaryColor.pageTitle,
                }}>
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: primaryColor.whiteText,
                        fontSize: '2rem',
                        textTransform: "uppercase",
                        paddingX: '10px'

                    }}
                >
                    {subTitle}
                </Typography>
            </Box>
        </Box>)
}

export default (TitlePdp)
