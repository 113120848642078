import React from "react";
import {useTranslation} from "react-i18next";
import {formatMoney} from "../../../utils/localization";
import {useAppSelector} from "../../../hook";
import {Box} from "@mui/material";
import {StyledDivider, StyledPriceInfo} from "../styleElements";
import {useNavigate} from "react-router";
import Summary from ".";

const CartSummary = () => {
    const {t} = useTranslation("checkoutPage");
    const navigate = useNavigate();


    const {cart} = useAppSelector((state) => state.cart);

    const handleClick = () => {
        navigate("/checkout");
    };


    const detailsContent = (
        <Box>
            <StyledPriceInfo
                name={t("productCost")}
                price={formatMoney(cart.total)}
            />
            <StyledPriceInfo
                name={t("taxValue")}
                price={formatMoney(cart.totalTax)}
            />
            <StyledDivider/>
            <StyledPriceInfo
                name={t("totalCost")}
                style={{fontWeight: 700}}
                price={formatMoney(cart.totalWithTax)}
            />
            <Box>{t("taxDeliveryCost")}</Box>
        </Box>
    );

    return (
        <Summary
            summaryDetailsContent={detailsContent}
            handleClick={handleClick}
            confirmButtonText={t("confirmation")}
        />
    );
};

export default CartSummary;
