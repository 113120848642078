import {useAppDispatch, useAppSelector} from "../../../hook";
import {ShippingAddress} from "../../../interfaces/backend";
import {addCartShippingAddress, setShippingAddress,} from "../../../reducers/cart";
import React, {useEffect, useState} from "react";
import {setFormOpenedStatus} from "../../../reducers/styleControls";
import {deleteShippingAddress, editShippingAddress,} from "../../../reducers/address";
import {AddressBox, ContainerBox, EditIconBox, StyledText, UnderAddressBox,} from "./style";
import {Box} from "@mui/material";
import ConfirmationDialog, {ConfirmationContent,} from "../../../components/common/Dialogs/ConfirmationDialog";
import {useTranslation} from "react-i18next";

export type ShippingAddressProps = {
    address: ShippingAddress;
    isBillingAddressUsed: boolean;
    setIsBillingAddressUsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ShippingAddressCheckout(props: ShippingAddressProps) {
    const {address, isBillingAddressUsed, setIsBillingAddressUsed} = props;
    const dispatch = useAppDispatch();
    const {t} = useTranslation("checkoutPage");

    const shippingAddress = useAppSelector((state) => state.cart.shippingAddress);
    const countries = useAppSelector((state) => state.metadata.countries);
    const country = countries.find((v) => v.code === address?.country)?.name;


    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const onConfirm = () => {
        setIsConfirmationOpen(false);
        dispatch(deleteShippingAddress({shippingAddress: address}));
    };


    const [selected, setSelected] = useState<boolean>(false);

    const handleEditIconClick = () => {
        if (selected) {
            dispatch(setFormOpenedStatus(true));
            dispatch(editShippingAddress(address));
        }
    };

    useEffect(() => {
        const isAddressSelected = !isBillingAddressUsed &&
            (shippingAddress?.id === address.id ||
                (shippingAddress.id === undefined && address.isDefault));

        setSelected(!!isAddressSelected);

        if (isAddressSelected) {
            dispatch(addCartShippingAddress({shippingAddress: address}));
        }
    }, [shippingAddress, address, isBillingAddressUsed]);


    return (
        <ContainerBox
            selected={selected}
            onClick={() => {
                if (!isBillingAddressUsed && selected) {
                    dispatch(addCartShippingAddress({shippingAddress: address}));
                }
                dispatch(setShippingAddress(address));
                setIsBillingAddressUsed(false);
            }}
        >
            <AddressBox>
                <StyledText fontWeight={600} mb={"11px"} mt={"5px"}>
                    {address.firstName} {address.lastName}
                </StyledText>
                <StyledText>{address.companyName}</StyledText>
                <StyledText>
                    {address.streetName} {address.streetNumber}
                </StyledText>
                <StyledText>
                    {address.postalCode} {address.city}
                </StyledText>
                <StyledText>{country}</StyledText>
                <StyledText>{address.phone}</StyledText>
            </AddressBox>
            <UnderAddressBox>
                <Box onClick={handleEditIconClick}>
                    {selected && (
                        <EditIconBox/>
                    )}
                </Box>
            </UnderAddressBox>
            {isConfirmationOpen && (
                <ConfirmationDialog
                    onConfirm={onConfirm}
                    handleCancel={() => {
                        setIsConfirmationOpen(false);
                    }}
                    modalContent={
                        <ConfirmationContent
                            dataContent={t("sureToDeleteShippingAddress")}
                        />
                    }
                />
            )}
        </ContainerBox>
    );
}
