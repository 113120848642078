import React from "react";
import {Grid, keyframes} from "@mui/material";
import {primaryColor} from "../../../theme";

interface AnimatedMenuProps {
    index: number;
    visible: boolean[];
    content: React.ReactNode;
    imageContent: React.ReactNode;
}

const AnimatedMenu = (props: AnimatedMenuProps) => {
    const {index, visible, content, imageContent} = props;

    const leftTransform = "translateX(-100%)";
    const rightTransform = "translateX(100%)";
    const sideFlow = keyframes` to {
                                  transform: translateX(0);
                                }`;
    const transformProperties = "1.1s linear normal forwards";
    const displayFromRight = `${sideFlow} ${transformProperties}`;
    const displayFromLeft = `${sideFlow} ${transformProperties}`;

    const commonGridStyles = {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: primaryColor.animatedMenuBackGround,
        height: {
            xs: "275px",
            lg: "358px"
        },
    }

    const contentGridStyles = {
        paddingX: "4%",

        order: index % 2 === 0 ? 1 : 2,
        alignItems: index % 2 === 0 ? "flex-end" : "flex-start",
        textAlign: {
            xs: "left",
            lg: index % 2 === 0 ? "right" : "left",
        },
        transform: index % 2 === 0 ? leftTransform : rightTransform,
        animation: visible[index]
            ? index % 2 === 0
                ? displayFromLeft
                : displayFromRight
            : null,
    };

    const imageGridStyles = {
        order: index % 2 === 0 ? 2 : 1,
        alignItems: "center",
        transform: index % 2 === 0 ? rightTransform : leftTransform,
        animation: visible[index]
            ? index % 2 === 0
                ? displayFromRight
                : displayFromLeft
            : null,
    };


    return (
        <Grid
            container
            sx={{
                color: primaryColor.animatedMenuText,
                overflow: "hidden",
            }}
        >
            <Grid
                item
                xs={6}
                sx={{
                    ...commonGridStyles,
                    ...contentGridStyles,
                }}
            >
                {content}
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    ...commonGridStyles,
                    ...imageGridStyles,
                }}
            >
                {imageContent}
            </Grid>
        </Grid>
    );
};

export default AnimatedMenu;
