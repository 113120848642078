import React from "react";
import ButtonWithIcon, {BaseProps} from "../../common/ButtonWithIcon";
import {primaryColor} from "../../../theme";
import PlusIcon from "../../common/svg/plusIcon";


const AddNewUserButton = (props: BaseProps) => {
    const {buttonContent, onClick, fillColor, variant} = props;

    const backgroundColor = variant === "text" ? "transparent" : primaryColor.addNewUserButtonBackground;

    const buttonStyles = {
        borderRadius: "1.065px",
        width: "100%",
        marginY: "20px",
        // fontSize: defaultFonts,
        backgroundColor: backgroundColor,
        color: fillColor,
        "&:hover": {
            backgroundColor: {xs: backgroundColor, lg: "initial"},
        }
    }


    return (
        <ButtonWithIcon
            icon={<PlusIcon fillColor={fillColor}/>}
            baseProps={{
                buttonContent,
                buttonStyles,
                onClick,
            }}
        />
    );
};

export default React.memo(AddNewUserButton);
