import {AddToCartItem, CartItem, Order} from "../../../interfaces/backend";
import {useAppSelector, useAppSettings} from "../../../hook";
import {OperationList} from "../../../interfaces/general";
import {setOperation} from "../../../utils/productFunctions";
import {SxProps} from "@mui/material";


export const getStatus = (statusCode: string): number => {
    if (statusCode === "pending") return 1;
    else if (statusCode === "inprogress") return 25;
    else if (statusCode === "shipped") return 75;
    else if (statusCode === "ready") return 75;
    else if (statusCode === "completed") return 100;
    else return 0;
};

export const reOrder = (items: Array<CartItem>) => {
    let productsToAdd: Array<AddToCartItem | undefined> = items
        .filter((item) => item.isOrderable)
        .map((item) => {
            return {
                product: item.code,
                quantity: item.quantity,
            };
        });
    return productsToAdd.filter(
        (product) => product !== undefined
    ) as AddToCartItem[];
};

export const checkIsProductNull = (order: Order): boolean => {
    const items = order.items;
    const filteredItems = items.filter((item) => item.product);
    return filteredItems.length === 0;
};


export interface OrderItemProp {
    order: Order;
    setSelectedOrder?: Function;
    cancelOrder?: Function;
    sx?: SxProps;
}

export const useMyOrderCommon = (props: OrderItemProp) => {
    const {order} = props;
    const CANCELLABLE_ORDERS = ["pending"];
    const {firstName, lastName} = order.user;
    const {shopUsesPrices} = useAppSettings();

    function getOperation(action: keyof OperationList): string {
        return setOperation(shopUsesPrices, action);
    }

    const isConfirmationOpen = useAppSelector(
        (state) => state.myAccount.isConfirmationOpen
    );

    const style = {
        element: {
            marginTop: {xs: 0, md: "10px"},
            display: "flex",
        },
        value: {
            wordBreak: 'break-word',
            fontWeight: 300,
            marginLeft: {xs: "6px", md: 0, lg: "6px"},
        },
        description: {
            fontWeight: 700,
        },
    };

    return {
        isConfirmationOpen,
        CANCELLABLE_ORDERS,
        style,
        firstName,
        lastName,
        shopUsesPrices,
        getOperation,
    };
};

