import {Box, Typography} from "@mui/material";
import {User} from "../../../interfaces/backend";
import {useState} from "react";
import {defaultFonts, primaryColor, secondaryFonts} from "../../../theme";
import Arrow from "../../common/svg/arrow";
import {StyledTypography} from "../../common/StyledComponents";
import IOSSwitchComp from "../../../pages/Account/UserManagement/IOSSwitchComp";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hook";
import {patchUser} from "../../../reducers/myAccount";
import UserManagementButtons from "./userManagementButtons";
import {formatMoney} from "../../../utils/localization";

interface UserTableMobileItemProps {
    user: User;
}

const UserTableMobileItem = (props: UserTableMobileItemProps) => {
    const {t} = useTranslation("myAccount");
    const dispatch = useAppDispatch();

    const {user} = props;
    const [rotate, setRotate] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
        setRotate(!rotate);
    };

    const dynamicStyles = {
        display: !expanded ? "none" : "block",
    };

    const handleChangeStatus = (user: User) => {
        const temp = {...user, isEnabled: !user.isEnabled};
        dispatch(patchUser({user: temp}));
    };

    const itemRows = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingY: "5px",
        width: {
            xs: "100%",
            lg: "48%",
        },
        minWidth: "195px",
    };

    const userType = user?.isAdmin ? t("keyUser") : t("user")

    return (
        <Box
            sx={{
                borderRadius: "0.972px",
                width: "100%",
                margin: "5px auto",
                paddingY: "7.5px",
                paddingX: "25px",
                textAlign: "center",

                color: primaryColor.userTableMobileColor,
                backgroundColor: primaryColor.userTableMobileItemBackground,
                borderLeft: user.isEnabled
                    ? `11px solid ${primaryColor.userManagementTableItemActiveUser}`
                    : `11px solid ${primaryColor.userManagementTableItemNotActiveUser}`,
            }}
        >
            <Box
                sx={{
                    height: "fit-content",
                    margin: "0 auto",
                }}
                onClick={() => handleChange()}
            >
                <Typography
                    sx={{
                        fontSize: defaultFonts,
                    }}
                >
                    {user.firstName} {user.lastName}

                </Typography>
                <Typography sx={{fontSize: secondaryFonts}}>{user.email}</Typography>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "52.5%",
                        marginLeft: "auto",
                        paddingTop: "10px",
                    }}
                >
                    <Arrow
                        fill={expanded ? primaryColor.badgeColor : primaryColor.whiteText}
                        rotate={rotate}
                    />

                    <StyledTypography
                        sx={{
                            display: expanded ? "none" : "unset",
                        }}
                    >
                        {userType}
                    </StyledTypography>
                </Box>
            </Box>

            <Box
                sx={{
                    paddingY: "10px",
                    ...dynamicStyles,
                }}
            >
                <Box
                    sx={{
                        ...itemRows,
                    }}
                >
                    <StyledTypography>{t("status")}</StyledTypography>

                    <IOSSwitchComp
                        checked={user.isEnabled}
                        onChange={() => handleChangeStatus(user)}
                    />
                </Box>

                <Box
                    sx={{
                        ...itemRows,
                    }}
                >
                    <StyledTypography>{t("userType")}</StyledTypography>
                    <StyledTypography>
                        {userType}
                    </StyledTypography>
                </Box>
                <Box
                    sx={{
                        ...itemRows,
                    }}
                >
                    <StyledTypography>{t("cartLimit")}</StyledTypography>

                    <StyledTypography>{
                        user.cartLimit === null ?
                            t('unlimited') :
                            formatMoney(parseInt(user.cartLimit as string))
                    }</StyledTypography>
                </Box>

                <Box
                    sx={{
                        maxWidth: {
                            xs: "350px",
                            md: "450px",
                        },
                        margin: "5px auto",
                    }}
                >
                    <UserManagementButtons user={user}/>
                </Box>
            </Box>
        </Box>
    );
};

export default UserTableMobileItem;
