import React, {useEffect} from "react";
import Paper from "@mui/material/Paper";
import Page from "./page";
import {useTranslation} from "react-i18next";
import {Box, Grid} from "@mui/material";
import {primaryColor} from "../theme";
import UploadFile from "../components/quickOrder/UploadFile";
import QuickSearch from "../components/quickOrder/QuickSearch";
import ResultTable from "../components/quickOrder/ResultTable";
import {useAppDispatch, useAppSelector} from "../hook";
import {BreadCrumbsObject, QuickOrderProduct} from "../interfaces/general";
import {getBatchProducts,} from "../reducers/quickOrder";


export const style = {
    pageCommon: {
        backgroundColor: primaryColor.sparePartsBackground,
        borderRadius: '0px',
        height: '100%',
        padding: {
            xs: '25px 0px',
            md: '55px 10px 15px',
        }
    },
};


const QuickOrder = () => {
    const {t} = useTranslation('quickOrder');
    const dispatch = useAppDispatch();
    const quickOrderProducts: QuickOrderProduct[] = useAppSelector(
        (state) => state.quickOrder.quickOrderProducts
    );

    useEffect(() => {
        dispatch(getBatchProducts({
            products: quickOrderProducts.map(p => {
                return {code: p.code, quantity: p.quantity}
            }),
            isInitialLoad: true
        }));
    }, [dispatch]);

    const makeBread = (): BreadCrumbsObject[] => [
        {name: t('common:home'), link: '/'},
        {name: t('common:quickOrder'), link: '/quick-order'},
    ];

    return (
        <Page breadCrumbs={makeBread()}>
            <Box sx={{
                fontSize: {
                    xs: '2.6rem',
                    md: '5rem',
                    lg: '7rem'
                },
                color: primaryColor.pageTitle,
                fontWeight: '700',
                textAlign: "center"
            }}>
                {t('common:Quick Orders')}
            </Box>
            <Box sx={{margin: '40px 0px 70px 0px'}}>
                <Grid container justifyContent="center" spacing={3}>
                    <Grid key={1} xs={12} md={6} item>
                        <Paper sx={{...style.pageCommon}}>
                            <QuickSearch/>
                        </Paper>
                    </Grid>
                    <Grid key={2} xs={12} md={6} item>
                        <Paper sx={{...style.pageCommon}}>
                            <UploadFile/>
                        </Paper>
                    </Grid>
                    <Grid key={3} xs={12} md={11} lg={10} item>
                        {quickOrderProducts?.length > 0 &&

                            <ResultTable products={quickOrderProducts}/>}
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
};

export default QuickOrder;