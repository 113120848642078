import {useAppDispatch} from "../hook";
import {useEffect} from "react";
import {logout} from "../reducers/auth";
import {setOpenedDrawerID} from "../reducers/static";

const LogoutPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(logout());
        dispatch(setOpenedDrawerID(""))
    }, [dispatch]);

    return (
        <></>
    )
}

export default LogoutPage