import {Box, Divider, SxProps} from "@mui/material";
import {primaryColor} from "../../../theme";
import {TakeFirst2DImage} from "../../../utils/productFunctions";
import ProductImage from "../../../components/common/ProductTableItem/ProductImage";
import ProductItemDetails, {ItemProps} from "../../../components/common/ProductTableItem/mobile/ProductItemDetails";
import QuantityUpdater from "../../../components/common/ProductTableItem/QuantityUpdater";
import CartItemPriceRow from "../../../components/common/ProductTableItem/mobile/CartItemPriceRow";
import {useAppDispatch} from "../../../hook";
import {deleteCartItem} from "../../../reducers/cart";
import {DeleteIcon} from "../../../components/quickOrder/ResultTable/DesktopTableRow";


interface CartTableItemMobileProps extends ItemProps {
    useDivider?: boolean;
    sx?: SxProps;
}

const CartTableItemMobile = (props: CartTableItemMobileProps) => {
    const {item, useDivider, sx} = props;
    const dispatch = useAppDispatch();

    return (
        <Box
            sx={{
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                }}
            >
                <ProductImage
                    src={TakeFirst2DImage(item.product.image)}
                    sx={{marginRight: "20px"}}
                />

                <ProductItemDetails item={item}/>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginY: "16px",
                }}
            >
                <Box
                    sx={{
                        marginRight: "20px",
                        width: "100%",
                        maxWidth: "120px",
                    }}
                >
                    <QuantityUpdater item={item}/>


                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "inline-flex",
                    }}
                >
                    <CartItemPriceRow item={item}/>
                    <Box sx={{marginLeft: "auto", paddingTop: '5px'}}>
                        <DeleteIcon onClick={() => dispatch(deleteCartItem(item))}/>
                    </Box>
                </Box>


            </Box>

            {useDivider && (
                <Divider
                    sx={{
                        backgroundColor: primaryColor.productTableDividerColor,
                        marginBottom: "16px",
                        // marginX: {xs: '-40px', md: 'unset'}
                    }}
                />
            )}
        </Box>
    );
};

export default CartTableItemMobile;
