import {Box, Grid, Typography} from "@mui/material";
import {formatMoney, getLocaleDateString} from "../../../../utils/localization";
import * as React from "react";
import {Order} from "../../../../interfaces/backend";
import {useMyOrderCommon} from "../../../../components/myData/MyOrders/orderUtils";
import {useTranslation} from "react-i18next";

export interface ItemHeaderProps {
    order: Order,
}

interface ItemDetailsRowProps {
    label: string;
    value: string;
}


const ItemHeader = (props: ItemHeaderProps) => {
    const {order} = props
    const {t} = useTranslation("myAccount");
    const {
        style,
        firstName,
        lastName,
        shopUsesPrices,
        getOperation
    } = useMyOrderCommon(props);


    const ItemDetailsRow = (props: ItemDetailsRowProps) => {
        const {label, value} = props;

        return (
            <Box sx={{display: {xs: 'flex', md: 'unset'}}}>
                <Typography sx={style.description}>{label}:</Typography>
                <Typography sx={style.value}>{value}</Typography>
            </Box>
        );
    };

    return (
        <Grid container justifyContent="space-between" spacing={{md: 2}} marginTop={"10px"}>
            <Grid item xs={12} md={6} marginTop={{md: '10px'}}>
                <ItemDetailsRow
                    label={t(getOperation('Date'))}
                    value={getLocaleDateString(order.createdAt)}
                />
                <ItemDetailsRow label={t(getOperation('Id'))} value={order.orderId}/>
            </Grid>
            <Grid item xs={12} md={6} marginTop={{md: '10px'}}>
                <ItemDetailsRow
                    label={t(getOperation('By'))}
                    value={`${firstName} ${lastName}`}
                />
                {shopUsesPrices && (
                    <ItemDetailsRow
                        label={t(getOperation('Total'))}
                        value={formatMoney(order.totalWithTaxAndShipping)}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default ItemHeader
