import {put, select, takeEvery} from 'redux-saga/effects';

import {GET_MENU_BY_ID, GET_MENU_HOMEPAGE, GET_SETTINGS, GET_SOCIAL, GET_STATIC_ITEM} from '../constants/api';

import {callHttp} from '../utils/api';
import {get} from '../utils/httpUtil';

import i18n from '../i18n';
import {setMenuHomePage, setSocialLinks, setStaticPageContent,} from "../reducers/static";
import {setSettings} from "../reducers/settings";
import {openErrorSnack} from "../reducers/snackbar";
import {Menu, MenuHomePage, SettingElement, SocialElement, StaticPageContent} from "../interfaces/backend";
import {getMenuAction, getStaticPageContentAction} from "../interfaces/actions";

export function* getMenu(action: getMenuAction) {
    const {menuId, handler} = action.payload;

    //FF issue, menu is called before settings are loaded
    //However this method is a problem on moving from mobile view to desktop view. Therefore, settings are called in 2 places
    // let settings: Array<SettingElement> = yield select(state => state.staticContent.settings);
    // if (!settings.length) {
    //   //this should wait until setSettings is called
    //   yield take('staticContent/setSettings');
    //   settings = yield select(state => state.staticContent.settings);
    // }

    try {
        //const settingsItem = settings.find(x => x.name === type && x.value);
        if (menuId) {
            const menu: Menu = yield callHttp(get, GET_MENU_BY_ID(menuId))
            if (menu?.items?.length > 0) {
                yield put(handler(menu.items));
            }
        }
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getSettings() {
    try {
        const settings: Array<SettingElement> = yield callHttp(get, GET_SETTINGS);
        yield put(setSettings(settings));

        //set languages from BE to localStorage
        const {languages, defaultLanguage} = yield select(state => state.settings.items);
        let needReload = false;
        if (languages.length > 0) {
            const currentLanguage = localStorage.getItem('i18nextLng');

            if (defaultLanguage) {
                if (languages.indexOf(currentLanguage as string) < 0 || sessionStorage.getItem("fallback_lng_loaded")) {
                    i18n.changeLanguage(defaultLanguage);
                    sessionStorage.removeItem("fallback_lng_loaded");
                    needReload = true;
                }
            }
        }

        if (needReload) {
            window.location.reload();
        }
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getSocialLinks() {
    try {
        const social: Array<SocialElement> = yield callHttp(get, GET_SOCIAL);
        yield put(setSocialLinks(social));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getStaticPageContent(action: getStaticPageContentAction) {
    const {alias, category} = action.payload
    try {
        const staticContent: StaticPageContent = yield callHttp(get, GET_STATIC_ITEM(alias))
        yield put(setStaticPageContent(staticContent))
    } catch (err: any) {
        if (
            err.status === 303 &&
            err.message.lang === localStorage.getItem('i18nextLng')
        ) {
            window.location.href = '/' + category + '/' + err.message.alias;
        }
    }
}

export function* getMenuHomePage() {
    try {
        const menus: Array<MenuHomePage> = yield callHttp(get, GET_MENU_HOMEPAGE)
        yield put(setMenuHomePage(menus))
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}


export default function* staticSaga() {
    yield takeEvery('settings/getSettings', getSettings)
    yield takeEvery('staticContent/getSocialLinks', getSocialLinks)
    yield takeEvery('staticContent/getMenu', getMenu)
    yield takeEvery('staticContent/getStaticPageContent', getStaticPageContent)
    yield takeEvery('staticContent/getMenuHomePage', getMenuHomePage)
}