import * as React from 'react';
import {ReactNode} from 'react';
import {Box} from '@mui/material';
import Slider, {Settings} from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ResponsiveSettingsProps {
    breakpoint: number;
    settings: BaseCarouselSettingsProps;
}

interface BaseCarouselSettingsProps {
    speed?: number,
    dots?: boolean,
    infinite?: boolean,
    arrows?: boolean,
    autoplay?: boolean,
    slidesToShow?: number,
    slidesToScroll?: number,
    centerMode?: boolean,
    centerPadding?: string,
    responsive?: ResponsiveSettingsProps[],
    carouselItems?: ReactNode[],
    // customPaging?: (index: number) => React.ReactElement,
    // onClickDot?: (index: number) => void,

}

const BaseCarousel = React.forwardRef<Slider, BaseCarouselSettingsProps>(
    ({carouselItems, responsive, ...props}, ref) => {
        const {
            speed,
            dots,
            infinite,
            arrows,
            autoplay,
            slidesToShow,
            slidesToScroll,
            centerMode,
            centerPadding,
            // customPaging,
        } = props;

        const mergeSettings = (
            customSettings: Partial<BaseCarouselSettingsProps>,
            carouselItemsLength?: number
        ): Settings => {
            // const adjustedSlidesToShow =
            //   carouselItemsLength &&
            //   customSettings.slidesToShow &&
            //   carouselItemsLength < customSettings.slidesToShow
            //     ? carouselItemsLength
            //     : undefined;

            return {
                speed: customSettings.speed ?? 500,
                dots: customSettings.dots ?? false,
                infinite: customSettings.infinite ?? true,
                arrows: customSettings.arrows ?? false,
                autoplay: customSettings.autoplay ?? false,
                // slidesToShow: adjustedSlidesToShow !== undefined ? adjustedSlidesToShow : customSettings.slidesToShow ?? 3,
                slidesToShow: customSettings.slidesToShow ?? 3,
                slidesToScroll: customSettings.slidesToScroll ?? 1,
                centerMode: customSettings.centerMode ?? true,
                centerPadding: customSettings.centerPadding ?? '0px',
            };
        };

        const settings: Settings = {
            ...mergeSettings(props, carouselItems?.length),
            responsive: responsive?.map(({breakpoint, settings}) => ({
                breakpoint,
                settings: mergeSettings(settings, carouselItems?.length),
            })),
        };

        return (
            <Box>
                <Slider ref={ref} {...settings}>
                    {carouselItems &&
                        carouselItems.map((item, index) => (
                            <Box key={index}>{item}</Box>
                        ))}
                </Slider>
            </Box>
        );
    }
);

export default BaseCarousel;
