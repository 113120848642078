import React from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Order} from "../../../../interfaces/backend";
import {StyledHeadTableCell, StyledTableCell} from "../../../Cart/styleElements";
import {countTotalItems, setOperation} from "../../../../utils/productFunctions";
import OrdersTableItemDesktop from "./OrdersTableItemDesktop";
import {OperationList} from '../../../../interfaces/general';
import {useAppSettings} from "../../../../hook";

interface OrdersTableProps {
    order: Order
}

export default function OrdersTableDesktop(props: OrdersTableProps) {
    const {t} = useTranslation('myAccount')
    const {items} = props.order
    const {shopUsesPrices} = useAppSettings();

    function getOperation(action: keyof OperationList): string {
        return setOperation(shopUsesPrices, action);
    }

    return (
        <Box sx={{
            width: {xs: 'auto', lg: 'calc(100% + 55px)'}
        }}>
            <Box sx={{fontSize: '2rem', fontWeight: '700',}}>{t(getOperation('OperationName'))}</Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledHeadTableCell>
                            {countTotalItems(items)} {t('items')}
                        </StyledHeadTableCell>
                        <StyledHeadTableCell>{t('data')}</StyledHeadTableCell>
                        {shopUsesPrices &&
                            <StyledHeadTableCell sx={{textAlign: 'right'}}>
                                {t('productPrice')}
                            </StyledHeadTableCell>
                        }
                        <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('quantity')}</StyledHeadTableCell>
                        {shopUsesPrices &&
                            <StyledHeadTableCell sx={{textAlign: 'right'}}>{t('subtotal')}</StyledHeadTableCell>
                        }
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <OrdersTableItemDesktop item={item} key={item.code}/>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}

