import React from 'react';
import ButtonWithIcon, {BaseProps} from '../ButtonWithIcon';
import {useAppSettings} from '../../../hook';
import InquiryIcon from '../svg/InquiryIcon';
import CartIcon from '../svg/CartIcon';

const AddToCartButton = (props: BaseProps) => {
    const {buttonContent, buttonStyles, onClick, isDisabled, variant = "contained"} = props;
    const {shopUsesPrices} = useAppSettings();

    return (
        <ButtonWithIcon
            icon={shopUsesPrices ? <CartIcon/> : <InquiryIcon/>}
            baseProps={{
                buttonContent,
                buttonStyles,
                onClick,
                isDisabled,
                variant
            }}
        />
    );
};

export default React.memo(AddToCartButton);