import * as React from 'react';
import {Box} from "@mui/material";
import {primaryColor} from "../../../../theme"
import {Link} from "react-router-dom";
import {styled} from '@mui/material/styles';
import {Machine} from "../../../../interfaces/backend";
import {TakeFirst2DImage} from "../../../../utils/productFunctions";
import {useLocation} from "react-router";

export interface MachineItemProps {
    machine: Machine
}

export const slideMinWidthMobile = 380
export const slideMinWidthDesktop = 400

const MachineCarouselItem = (props: MachineItemProps) => {
    const {machine} = props;
    const machineProduct = machine.product;
    const image = TakeFirst2DImage(machineProduct.image);
    const {pathname} = useLocation();

    const SlideName = styled(Box)({
        fontWeight: 'normal',
        backgroundColor: 'transparent',
        color: primaryColor.textHeader,
        width: '100%',
        padding: '5px 20px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        margin: '0 auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.6rem',
    })

    return (
        <Link to={`/my/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px 10px',

                    height: {
                        xs: '200px',
                        lg: "250px",
                    },
                    width: '98%',
                    maxWidth: {
                        xs: `${slideMinWidthMobile}px`,
                        lg: `${slideMinWidthDesktop}px`,
                        xl: `${slideMinWidthDesktop}px`,
                    },
                    minWidth: '250px',

                    margin: '0 auto',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',

                }}>


            </Box>
            <SlideName className='slideName'>
                <Box component={'span'}>{machineProduct.name}</Box>
                <Box component={'span'}>{machineProduct.code}</Box>
            </SlideName>
        </Link>
    );
}
export default MachineCarouselItem;