import {Box, useMediaQuery} from "@mui/material"
import theme, {primaryColor} from "../../../theme";
import DesktopView from "./desktopView";
import MobileView from "../../common/header/BottomHeader/mobileView";

const LoginHeader = () => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            {!mobileView ?
                <Box sx={{
                    backgroundColor: primaryColor.headerColor,
                    color: primaryColor.textHeader,
                }}
                >
                    <DesktopView/>
                </Box>
                :
                <MobileView/>
            }
        </>
    )
}

export default (LoginHeader)