import SvgIcon from '@mui/material/SvgIcon';
import React from "react";
import {primaryColor} from "../../../theme";
import {IconComponentProps} from '../../../interfaces/general';


const CartIcon = (props: IconComponentProps) => {
    const {isActive = false, sx = {}} = props;
    const fillColor = isActive ? primaryColor.orangeHoverColor : 'white'

    return (
        <SvgIcon width="32" height="31" viewBox="0 0 32 31" fill="none" sx={sx}>
            <path
                d="M31.255 7.37595C30.9742 6.88946 30.5721 6.48404 30.088 6.19925C29.6038 5.91445 29.0541 5.76 28.4925 5.75095H7.6925L6.75 2.07845C6.65478 1.72394 6.44233 1.41211 6.14725 1.19376C5.85218 0.975403 5.49187 0.863387 5.125 0.875953H1.875C1.44402 0.875953 1.0307 1.04716 0.725951 1.3519C0.421205 1.65665 0.25 2.06998 0.25 2.50095C0.25 2.93193 0.421205 3.34525 0.725951 3.65C1.0307 3.95475 1.44402 4.12595 1.875 4.12595H3.89L8.375 20.7985C8.47023 21.153 8.68268 21.4648 8.97775 21.6831C9.27283 21.9015 9.63314 22.0135 10 22.001H24.625C24.9251 22 25.2191 21.9161 25.4743 21.7583C25.7296 21.6005 25.9362 21.3752 26.0713 21.1072L31.4013 10.4472C31.6323 9.96298 31.7398 9.42909 31.7143 8.89319C31.6888 8.35729 31.531 7.83604 31.255 7.37595ZM23.6175 18.751H11.235L8.58625 9.00095H28.4925L23.6175 18.751Z"
                fill={fillColor}/>
            <path
                d="M9.1875 30.125C10.5337 30.125 11.625 29.0337 11.625 27.6875C11.625 26.3413 10.5337 25.25 9.1875 25.25C7.84131 25.25 6.75 26.3413 6.75 27.6875C6.75 29.0337 7.84131 30.125 9.1875 30.125Z"
                fill={fillColor}/>
            <path
                d="M25.4375 30.125C26.7837 30.125 27.875 29.0337 27.875 27.6875C27.875 26.3413 26.7837 25.25 25.4375 25.25C24.0913 25.25 23 26.3413 23 27.6875C23 29.0337 24.0913 30.125 25.4375 30.125Z"
                fill={fillColor}/>
        </SvgIcon>
    );
}

export default CartIcon;