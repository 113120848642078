import React, {ReactElement} from "react";
import {useAppSelector} from "../../../hook";
import {useAccessRights} from "../accessControlUtils";

interface AuthDynamicComponentProps {
    children: ReactElement;
    fallback?: ReactElement;
}

const AuthDynamicComponent = (props: AuthDynamicComponentProps) => {
    const {children, fallback} = props;

    const isLoggedIn = useAppSelector(state => !!state.auth.token);
    const loggedInOrAllowedGuest = useAccessRights();

    if (!loggedInOrAllowedGuest) {
        return <></>;
    }

    if (!isLoggedIn && fallback) {
        return <>{fallback}</>
    } else {
        return <>{children}</>;
    }
};

export default AuthDynamicComponent;