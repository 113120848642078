import {useAppDispatch, useAppSelector} from "../../../hook";
import {ShippingAddress} from "../../../interfaces/backend";
import React, {useState} from "react";
import {deleteShippingAddress, editShippingAddress,} from "../../../reducers/address";
import {
    AddressBox,
    ContainerBox,
    DeleteIconBox,
    StyledText,
    UnderAddressBox,
} from "../../../pages/Checkout/ShippingAddressCardList/style";
import {Box} from "@mui/material";
import ConfirmationDialog, {ConfirmationContent,} from "../../common/Dialogs/ConfirmationDialog";
import {useTranslation} from "react-i18next";

export type ShippingAddressProps = {
    address: ShippingAddress;
};

export default function ShippingAddressMyData(props: ShippingAddressProps) {
    const {address} = props;

    const dispatch = useAppDispatch();
    const {t} = useTranslation("checkoutPage");

    const countries = useAppSelector((state) => state.metadata.countries);
    const country = countries.find((v) => v.code === address?.country)?.name;


    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

    const handleDeleteIconClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        setIsConfirmationOpen(true);
    };

    const onConfirm = () => {
        setIsConfirmationOpen(false);
        dispatch(deleteShippingAddress({shippingAddress: address}));
    };


    return (
        <>
            <ContainerBox
                selected={address.isDefault}
                onClick={() => {
                    dispatch(editShippingAddress(address));
                }}
                isMyAccountPage={true}
            >
                <AddressBox>
                    <StyledText fontWeight={600} mb={"11px"} mt={"5px"}>
                        {address.firstName} {address.lastName}
                    </StyledText>
                    <StyledText>{address.companyName}</StyledText>
                    <StyledText>
                        {address.streetName} {address.streetNumber}
                    </StyledText>
                    <StyledText>
                        {address.postalCode} {address.city}
                    </StyledText>
                    <StyledText>{country}</StyledText>
                    <StyledText>{address.phone}</StyledText>
                </AddressBox>


                <UnderAddressBox>
                    <Box onClick={handleDeleteIconClick}>
                        <DeleteIconBox/>
                    </Box>
                </UnderAddressBox>

            </ContainerBox>
            {isConfirmationOpen && (
                <ConfirmationDialog
                    onConfirm={onConfirm}
                    handleCancel={() => {
                        setIsConfirmationOpen(false);
                    }}
                    modalContent={
                        <ConfirmationContent
                            dataContent={t("sureToDeleteShippingAddress")}
                        />
                    }
                />
            )}
        </>

    );
}
