import {Box, Typography} from "@mui/material";
import {primaryColor} from "../../theme";
import Button from "@mui/material/Button";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmailValid} from "../../utils/validation";
import {StyledTextField} from "./registerForm";
import {openForgotPasswordModal, openRegisterForm} from "../../reducers/start";
import {useAppDispatch} from "../../hook";


import UserAuthForm from "./UserAuthForm";
import {login} from "../../reducers/auth";

const textStyle = {
    cursor: 'pointer',
    color: primaryColor.linkColor,
    fontSize: '1.6rem',
    '&:hover': {
        color: primaryColor.badgeColor
    }
}


export default function LoginForm() {
    const {t} = useTranslation('startPage')
    const dispatch = useAppDispatch()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [enableLogin, setEnableLogin] = useState<boolean>(false)
    useEffect(() => {
        setEnableLogin(validate())
    }, [email, password])

    const validate = (): boolean => {
        return isEmailValid(email) && password.length > 0
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        validate() &&
        dispatch(login({
                email: email,
                password: password,
            })
        )
    };

    const sideActions = (
        <Box mt={2} display={"flex"}>
            <Typography
                sx={textStyle}
                onClick={() => dispatch(openForgotPasswordModal())}
            >
                {t("forgotPassword")}
            </Typography>
            <Typography color={primaryColor.textHeader} mx={1}>
                |
            </Typography>
            <Typography
                onClick={() => dispatch(openRegisterForm(true))}
                sx={textStyle}
            >
                {t("Register")}
            </Typography>
        </Box>
    )

    const loginFormContent = (
        <>
            <form onSubmit={handleSubmit}>

                <Box mt={2} mb={5}>
                    <StyledTextField
                        label={t("username")}
                        type="email"
                        handleChange={(value) => setEmail(value)}
                    />
                </Box>
                <Box mt={6} mb={5}>
                    <StyledTextField
                        label={t("password")}
                        type="password"
                        handleChange={(value) => setPassword(value)}
                    />
                </Box>
                <Button
                    type={"submit"}
                    variant="contained"
                    disabled={!enableLogin}
                    sx={{width: "100%"}}
                >
                    {t("login")}
                </Button>

            </form>
            {sideActions}
        </>

    );

    return (
        <UserAuthForm
            formContent={loginFormContent}
            pageTitle={t("Order spare parts and devices comfortably")}
            formTitle={t("access for existing customers")}
        />
    );
}
 