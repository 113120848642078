import Box from "@mui/material/Box";
import ShippingAddressForm from "./ShippingAddressForm";
import ShippingAddressCardList from "./ShippingAddressCardList";
import {closeShippingAddressForm} from "../../reducers/address";
import {useEffect} from "react";
import {useAppDispatch} from "../../hook";

interface ShippingAddressCompProps {
    isMyAccountPage?: boolean;
}

export default function ShippingAddressComp(props: ShippingAddressCompProps) {
    const {isMyAccountPage} = props;
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(closeShippingAddressForm())
    }, [])


    return (
        <Box
            sx={{
                marginBottom: '0px'
            }}
        >
            <ShippingAddressCardList isMyAccountPage={isMyAccountPage}/>
            <ShippingAddressForm isMyAccountPage={isMyAccountPage}/>
        </Box>
    )
}