import React from "react";
import {Box, Tooltip} from "@mui/material";
import {useAppSettings} from "../../../hook";
import {useTranslation} from "react-i18next";
import {IconComponentProps} from "../../../interfaces/general";
import CartIcon from "../svg/CartIcon";
import InquiryIcon from "../svg/InquiryIcon";


const AddToCartIcon = (props: IconComponentProps) => {
    const {sx = {}, onClick, isActive, toolTipTitle} = props;
    const {shopUsesPrices} = useAppSettings();
    const {t} = useTranslation("common");

    const toolTip = toolTipTitle ?? (shopUsesPrices ? t('cart') : t('inquiry'));


    return (
        <Tooltip title={toolTip}>
            <Box onClick={onClick}>
                {shopUsesPrices
                    ? <CartIcon isActive={isActive} sx={sx}/>
                    : <InquiryIcon isActive={isActive} sx={sx}/>
                }
            </Box>
        </Tooltip>
    );
};

export default AddToCartIcon;