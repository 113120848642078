import {Box, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import SearchIcon from "../../../svg/SearchIcon";

interface MenuSearchIconProps {
    isActive?: boolean;
}

const MenuSearchIcon = (props: MenuSearchIconProps) => {
    const {isActive = false} = props;
    const {t} = useTranslation("common");

    return (
        <Tooltip title={t("search")}>
            <Box component={"span"}> {/* Added only to avoid Tooltip error related to ref for no-standard components*/}
                <SearchIcon
                    isActive={isActive}
                    sx={{
                        height: {xs: "20px", md: '26px', lg: "28px"},
                        width: "auto"
                    }}
                />
            </Box>
        </Tooltip>
    )
}

export default MenuSearchIcon;