import {Error, ErrorFields} from "../interfaces/backend";

export class RequestError {
    readonly code: number
    readonly message: string
    readonly fields: ErrorFields[]
    readonly type: string

    constructor(error: Error) {
        this.code = error.code;
        this.message = error.message;
        this.fields = error.fields;
        this.type = error.type;
    }

    getErrors(): string {
        let messages = "";
        this.fields.forEach(field => {
            messages = messages.concat(field.message).concat(' ')
        })
        if (!messages.trim()) {
            messages = this.message;
        }
        return messages;
    }

    getFields(): ErrorFields[] {
        return this.fields
    }
}

// export class ValidationError {
//     message: string
//
//     constructor(message: string) {
//         this.message = message;
//     }
// }
