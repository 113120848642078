import {BoxProps, styled, Typography, TypographyProps} from "@mui/material";
import Box from "@mui/material/Box";
import {primaryColor} from "../../../theme";
import React from "react";

export const StyledText = styled((props: TypographyProps) => (
    <Typography {...props} variant={'subtitle2'}/>
))(({theme}) => ({
    color: primaryColor.whiteText,
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    paddingLeft: '8px',
    paddingRight: '8px',
    maxWidth: '130px',
    overflowWrap: 'break-word'
}));

interface ContainerBoxProps extends BoxProps {
    selected?: boolean | undefined
    isMyAccountPage?: boolean | undefined
}

export const ContainerBox = styled(({selected, isMyAccountPage, ...otherProps}: ContainerBoxProps) => (
    <Box {...otherProps}/>
))(({theme, selected, isMyAccountPage}) => ({
    width: '155px',
    height: '155px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    marginRight: '23px',
    marginLeft: '0px',
    backgroundColor: primaryColor.backgroundTextField,
    opacity: selected ? 1 : 0.5,
    position: 'relative',
    marginBottom: '23px',
    padding: '5px 8px',

    [theme.breakpoints.down("md")]: {
        marginRight: isMyAccountPage ? '23px' : '11px',
        // marginLeft: isMyAccountPage ? '0px' : '5px',
    },

    [theme.breakpoints.down("sm")]: {
        width: '150px',
    },
    borderTop: selected ? `6px solid ${primaryColor.discountCheckoutPage}` : '6px solid transparent',
}));

export const AddressBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
}));

export const UnderAddressBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%',
    position: 'absolute',
    bottom: '0',
    right: '7px'
}));

export const EditIconBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({

    '&::before': {
        content: 'url("/images/icons/editIcon.svg")'
    },
    '&:hover::before': {
        content: 'url("/images/icons/editIcon-white.svg")'
    },
}));


export const DeleteIconBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    '&::before': {
        content: 'url("/images/icons/delete-icon-new_orange.svg")'
    },

    '&:hover::before': {
        content: 'url("/images/icons/delete-icon-new.svg")'
    },
}));