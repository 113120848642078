import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../../../hook";
import CustomDrawer from "./leftIcons/customDrawer";
import {getMenu, setMainMenu} from "../../../../reducers/static";
import {MenuItem} from "../../../../interfaces/backend";
import {normalDrawerList} from "./drawerListItem";
import MenuListItem from "./leftIcons/AccountMenu/menuListItem";
import {ClickAwayListener} from "@mui/material";
import {useEffect, useState} from "react";
import CloseIcon from "../../CloseButton";


export default function MainMenuMobile() {
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch()
    const {mainMenu} = useAppSettings();
    const mainMenuLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.mainMenu)
    const list = normalDrawerList(mainMenuLinks);

    useEffect(() => {
        dispatch(getMenu({menuId: mainMenu, handler: setMainMenu}))
    }, [dispatch, mainMenu])

    return (
        <ClickAwayListener
            onClickAway={() => setOpen(false)}
        >
            <Box>
                <Button
                    sx={{
                        paddingRight: "12px",
                        minWidth: "unset",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "unset",
                        marginLeft: '-10px',
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    <CloseIcon
                        customIcon={!open ? "/images/drawerIcon.svg" : undefined} sx={{
                        width: 'auto',
                        height: '18px',
                        marginRight: '10px',
                        marginLeft: '5px'
                    }}/>
                </Button>
                <CustomDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    drawerMenuList={
                        <MenuListItem listItems={list} onClick={() => setOpen(false)}/>
                    }
                />
            </Box>
        </ClickAwayListener>
    );
}