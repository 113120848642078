import {createTheme} from '@mui/material/styles';
import {createBreakpoints} from '@mui/system';
import React from "react";
import {Box} from "@mui/material";

export const primaryColor = {
    baseTextColor: '#F5F5F5',
    blue: '#0070b2',
    headerColor: '#1F1E1F',
    documentsFilter: '#404040',
    documentsFilterTextColor: '#fff',
    productDetailsBackground: '#1F1E1F',
    productDetailsHoverBackground: '#4F4B4F',
    productDetailsSelectedTabMarker: '#FF6550',
    productDetailsPriceInfo: '#F5F5F5',
    documentsBorder: 'rgba(255, 255, 255, 0.10)',
    textHeader: '#B6B6B6',
    productTableDividerColor: '#B6B6B6',
    badgeColor: '#D0573B',
    textTopHeader: '#F5F5F5',
    background: '#141414',
    iconColor: '#D0573B',
    whiteText: '#fff',
    drawerList: '#1F1E1F',
    drawerHover: '#D0573B',
    filterText: '#FF6550',
    orderCompletedDividerColor: '#FF6550',
    buttonColor: '#FF6550',
    drawerBackground: '#222222',
    textDecorationColor: '#D0573B',
    searchBox: 'rgba(255, 255, 255, 0.2)',
    searchSuggestionText: '#FFFFFF',
    articleDescriptionBackground: '#141414',
    articlePageBackground: '#141414',
    staticPageBackground: '#1F1E1F',
    languageSelectTextMobile: 'rgba(255, 255, 255, 0.3)',
    drawerText: 'rgba(255, 255, 255, 0.8)',
    autoCompleteBackground: '#222222',
    autoCompleteItemColor: 'rgba(255, 255, 255, 0.2)',
    borderMiniCart: 'rgba(255, 255, 255, 0.2)',
    whiteIcon: '#fff',
    whiteTab: '#fff',
    linkColor: '#B6B6B6',
    scrollBarThumbColor: '#B6B6B6',
    scrollBarTrackColor: 'transparent',
    activeTab: '#F5F5F5',
    pageTitle: '#F5F5F5',
    pdpLongDescription: '#F5F5F5',
    backgroundOverlay: '#1F1E1F',
    backgroundSchemaModalOverlay: '#FFFFFF',
    inputLabelTextColor: '#F5F5F5',
    inputTextColor: '#F5F5F5',
    machinePageText: 'rgba(255, 255, 255, 0.5)',
    ordersTableDivider: 'rgba(255, 255, 255, 0.5)',
    addressCard: 'rgba(255, 255, 255, 0.05)',
    checkBox: 'rgba(255, 255, 255, 0.1)',
    machinePageDescriptionBackground: 'linear-gradient(322.46deg, #151515 2.62%, #1F1E1F 103.85%)',
    shoppingCartTitle: 'rgba(255, 255, 255, 0.95)',
    documentsCellBackground: '#1F1E1F',
    documentsCellColor: 'rgba(255, 255, 255, 0.95)',
    backgroundTextField: 'rgba(255, 255, 255, 0.1)',
    pdpImageBackground: 'rgba(255, 255, 255, 0.1)',
    discountCheckoutPage: "#FF623E",
    pdpTabFont: "#404040",
    asteriskColor: "#FF623E",
    breadcrumbsColor: 'rgba(255, 255, 255,  0.5)',
    miniCartBackground: 'rgba(255, 255, 255,  0.05)',
    documentsBackground: 'rgba(255, 255, 255,  0.05)',
    sparePartsBackground: '#2B2B2B',
    myAccountTabText: '#F5F5F5',
    pdpTitleText: '#F5F5F5',
    myAccountTabBorder: 'rgba(255, 255, 255, .3)',
    myAccountTabSelected: '#FF623E',
    myAccountDataBackground: '#1F1E1F',
    myAccountTabSelectedBackground: "#2B2B2B",
    myAccountColorLimitText: '#fff',
    sparePartsTop: '#FFFFFF',
    sparePartsText: '#F5F5F5',
    sparePartsButton: 'rgba(255, 255, 255, 0.95)',
    inputTextColorOnDark: '#FFFFFF',
    inputTextColorOnWhite: '#000000',
    sparePartsSearchPlaceholderTextColor: '#FFFFFF80',
    lineThroughPrice: 'rgba(255, 255, 255, 0.95)',
    machineViewerPopupBackground: 'linear-gradient(322.46deg, #151515 2.62%, #1F1E1F 103.85%)',
    machineViewerPopupTextColor: '#fff',
    machineViewerPopupGoToProductBackground: '#FFFFFF0D',
    machineViewerPopupCartBackground: '#D0573B',
    machineViewerPopupCloseIconColor: '#fff',
    carouselWhiteDots: '#fff',
    accountPhoneColor: '#FF6550',
    orangeHoverColor: '#FF6550',
    accountTextColor: '#FFFFFF',
    orderStatusColor: '#9AE053',
    ordersListBackgroundColor: '#1F1E1F',
    ordersListStatusBackgroundColor: '#FFFFFF',
    ordersListStatusRejectedBackgroundColor: '#B91C1A',
    successMessageBgColor: '#5C7F39',
    errorMessageBgColor: '#7F1A1B',
    infoMessageBgColor: '#0F4578',
    quickSearchPageColor: '#F5F5F5',
    quickSearchInputFieldBackground: '#fff',
    grayBackgroundColor: '#353435',
    tableBottomBorderLineColor: "0.5px solid rgba(255, 255, 255, 0.5)",
    selectedPagination: '#FF6550',
    paginationColor: '#FFFFFF',
    treeItemsColor: '#FFFFFF',
    treeItemBackgroundColor: '#1F1F1F',
    treeParentBackgroundColor: '#2B2B2B',
    sparePartsTreeBackground: '#1F1E1F',
    sparePartsTreeParentBackground: '#555555',
    sparePartsTreeBorderBottom: 'rgba(255, 255, 255, 0.1)',
    sparePartsTreeExpandedChildBackgroundColor: '#2B2B2B',
    selectedTreeTab: '#FF6550',
    noDiscountPrice: '#F5F5F5',
    searchBackground: '#1F1E1F',
    searchSuggestHoveredBackground: "#535353",
    searchNotFoundColor: '#F5F5F5',
    blogPageTextColor: '#fff',
    relatedProductsTextColor: '#F5F5F5',
    relatedItemsCardBackground: "linear-gradient(318deg, #151515 0%, #1F1E1F 100%)",
    relatedItemsCardBoxShadow: "5px 5px 14px 0px rgba(0, 0, 0, 0.50), -6px -6px 10px 0px rgba(20, 20, 20, 0.50) inset, 6px 6px 10px 0px rgba(59, 59, 59, 0.25) inset",
    pdpBackground: "#2B2B2B",
    clearFilter: '#FFFFFF',
    mobileMenuGrayButton: '#555555',
    orderHistoryTabletBackground: '#2B2B2B',
    mobileFilterColor: '#F5F5F5',
    animatedMenuText: '#fff',
    animatedMenuBackGround: '#1F1E1F',
    animatedMenuArticleTitle: '#fff',
    animatedMenuReadMore: '#D0573B',
    homeWelcomeUserName: '#fff',
    homeMyMachineTitle: '#fff',
    homeGreetingText: '#fff',
    arrowButtonColorSlider: '#fff',
    inputMessage: '#FF6550',
    outputMessage: 'var(--background-3, #555)',
    messageText: '#F5F5F5',
    textAreaBackground: '#2A292A',
    chatButtonBackground: '#555',
    chatBackground: '#1F1E1F',
    quantityChangeMobileArrowBackground: '#555555',
    cartMobileTableItemBackground: '#2B2B2B',
    cartTableDividerColor: "#FFFFFF",
    technicalDataDividerColor: "#FFFFFF",
    userManagementTableMobileBackground: '#2B2B2B',
    addNewUserButtonBackground: '#555',
    userTableMobileItemBackground: "#404040",
    userTableMobileColor: "#fff",
    outlinedButtonColor: '#555',
    userManagementSortByColor: '#fff',
    userManagementTableItemActiveUser: "#9AE053",
    userManagementTableItemNotActiveUser: "#959595",
    sortItemsByDialogBackground: '#1F1E1F',
    sortItemsByDialogHoveredBackground: "#535353",
    passwordLengthNoteColor: '#FFFFFF',
    cancellationButtonBackground: '#555',
    machinePageDescriptionBackgroundShadow: '5px 5px 14px 0px #00000080,-6px -6px 10px 0px #14141480 inset, 6px 6px 10px 0px #3B3B3B40 inset',
    serviceCustomersListBackground: '#222222',
    addUserDesktopIcon: '#FF623E',
    addNewShippingAddressIcon: '#FF623E',
    addUserMobileIcon: '#FFFFFF'
};

export const defaultFonts = {
    xs: '1.5rem',
    sm: '1.6rem',
    md: '1.8rem',
    lg: '2.0rem',
}

export const secondaryFonts = {
    xs: '1.4rem',
    sm: '1.5rem',
    md: '1.6rem',
    lg: '1.8rem',
}

export const helperTextFonts = {
    xs: '1.1rem',
    sm: '1.4rem',
    md: '1.5rem',
    lg: '1.6rem',
}

export const highlightedFonts = {
    xs: '1.8rem',
    sm: '2.0rem',
    md: '2.2rem',
    lg: '2.4rem',
}

export const tableFontSize = {
    contentSecondary: secondaryFonts,
    contentMain: defaultFonts
}

const fontFamily = ['Inter', 'sans-serif'].join(',');

const breakpoints = createBreakpoints({})
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        '2xl': true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        h7: React.CSSProperties;
        //h8: React.CSSProperties;
        //h25: React.CSSProperties;
        h20: React.CSSProperties;
        //h70: React.CSSProperties;
        h15: React.CSSProperties;
        //h12: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        h7?: React.CSSProperties;
        //h8?: React.CSSProperties;
        //h25?: React.CSSProperties;
        h20?: React.CSSProperties;
        //h70?: React.CSSProperties;
        h15?: React.CSSProperties;
        //h12?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h7: true,
        //h8: true,
        //h25: true
        h20: true
        //h70: true
        h15: true
        //h12: true,
        simpletext: true // user for MuiLink
    }
}


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            '2xl': 1768
        },
    },
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: primaryColor.paginationColor,
                    fontWeight: 400,
                    fontSize: '1.6rem',
                    '&.Mui-selected': {
                        color: primaryColor.selectedPagination
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: (theme) => ({

                //Firefox on windows.version < 11
                '@supports (-moz-appearance: none)': {
                    '*': {
                        scrollbarWidth: 'thin',
                        scrollbarColor: `${primaryColor.scrollBarThumbColor} ${primaryColor.scrollBarTrackColor}`,
                    },

                },

                '*::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: primaryColor.scrollBarThumbColor,
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: primaryColor.scrollBarTrackColor,
                },
                '*::-webkit-scrollbar-corner': {
                    backgroundColor: 'unset'
                },
                html: {
                    fontSize: "62.5%"
                },
                body: {
                    backgroundColor: primaryColor.background,
                    color: primaryColor.baseTextColor
                },
                a: {
                    textDecoration: 'none',
                    color: primaryColor.linkColor,
                    '&:hover': {
                        color: primaryColor.badgeColor
                    }
                }
            }),
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    padding: 'unset',
                    fontSize: '0.65rem',
                    backgroundColor: primaryColor.badgeColor
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    // flexGrow: '1',
                    // maxWidth: 'none',
                    // flexShrink: '1',
                    width: '345px',
                    color: primaryColor.linkColor,
                    backgroundColor: primaryColor.productDetailsBackground,
                    margin: '0 5px 25px',
                    fontSize: '2rem',
                    fontWeight: '400',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    '&:first-of-type': {
                        marginLeft: 0
                    },
                    '&:last-of-type': {
                        marginRight: 0
                    },
                    "&:hover": {
                        backgroundColor: primaryColor.productDetailsHoverBackground,
                        color: primaryColor.whiteText
                    },
                    "&.Mui-selected": {
                        backgroundColor: primaryColor.productDetailsBackground,
                        marginBottom: 0,
                        paddingTop: '15px',
                        paddingBottom: '40px',
                        color: primaryColor.activeTab
                    }
                },
                textColorInherit: {
                    color: primaryColor.linkColor
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: primaryColor.productDetailsSelectedTabMarker,
                    height: 3,
                    top: 0
                },
            }
        },
        MuiButton: {
            variants:
                [
                    {
                        props: {variant: 'text'},
                        style: {
                            color: primaryColor.whiteText,
                        },
                    },
                    {
                        props: {variant: 'contained'},
                        style: {
                            backgroundColor: primaryColor.buttonColor,
                            '&:hover': {
                                fontWeight: 700,
                                backgroundColor: primaryColor.buttonColor,
                            }
                        },
                    },
                    {
                        props: {variant: 'outlined'},
                        style: {
                            backgroundColor: primaryColor.outlinedButtonColor,
                            border: 'none',
                            '&:hover': {
                                fontWeight: 700,
                                backgroundColor: primaryColor.outlinedButtonColor,
                                border: 'none',
                                transition: "none",
                            }
                        },

                    },
                ],
            styleOverrides: {
                root: {
                    // width: '100%',
                    // maxHeight: '43px',
                    fontWeight: 400,
                    fontSize: '2rem',
                    [breakpoints.down('lg')]: {
                        fontSize: '1.6rem',
                        padding: '12px 10px',

                    },
                    padding: '4px 10px',
                    [breakpoints.down('md')]: {
                        fontSize: '1.5rem',
                        width: '100%'
                    },
                    borderRadius: 'unset',
                    textTransform: 'none',
                    minWidth: '170px',
                    "&.Mui-disabled": {
                        opacity: 0.7,
                        backgroundColor: primaryColor.buttonColor,
                        color: primaryColor.textTopHeader
                    }
                }
            }
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                input: {
                    color: primaryColor.inputLabelTextColor,
                    fontSize: defaultFonts.lg,
                    [breakpoints.down('lg')]: {
                        fontSize: defaultFonts.md,
                    },
                    [breakpoints.down('md')]: {
                        fontSize: defaultFonts.sm,
                    },
                    [breakpoints.down('sm')]: {
                        fontSize: defaultFonts.xs,
                    }
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: {variant: 'h7'},
                    style: {
                        fontSize: '1.6rem',
                        fontWeight: '400',
                        color: primaryColor.textTopHeader
                    },
                },
                // {
                //     props: {variant: 'h8'},
                //     style: {
                //         fontSize: '1.8rem',
                //         color: primaryColor.textTopHeader
                //     },
                // },
                // {
                //     props: {variant: 'h25'},
                //     style: {
                //         fontSize: '2.5rem',
                //         fontWeight: '400',
                //         color: primaryColor.textTopHeader,
                //         lineHeight: '3.5rem'
                //     },
                // },
                {
                    props: {variant: 'h20'},
                    style: {
                        fontSize: '2rem',
                        fontWeight: '400',
                        color: primaryColor.textTopHeader,
                        lineHeight: '2.8rem'
                    },
                },
                // {
                //     props: {variant: 'h12'},
                //     style: {
                //         fontSize: '1.2rem',
                //         fontWeight: '300',
                //         color: primaryColor.whiteText,
                //         lineHeight: '1.452rem'
                //     },
                // },
                // {
                //     props: {variant: 'h70'},
                //     style: {
                //         fontSize: '7rem',
                //         fontWeight: '700',
                //         lineHeight: '8.4rem'
                //     },
                // },
                {
                    props: {variant: 'h15'},
                    style: {
                        fontSize: '1.5rem',
                        fontWeight: '300',
                        lineHeight: '1.8rem'
                    },
                },
            ],
            styleOverrides: {
                // body1: {
                //     color: 'inherit',
                //     fontWeight: '300',
                // },
                h2: {
                    fontWeight: 700,
                    fontSize: '5rem',
                    letterSpacing: '0.01em',
                    color: primaryColor.whiteText,
                    lineHeight: '61px',
                    [breakpoints.down('lg')]: {
                        fontSize: '1.8rem',
                        lineHeight: '2.1rem',
                    }
                },
                h3: {
                    fontWeight: 700,
                    fontSize: '4.5rem',
                    letterSpacing: '0.01em',
                    color: primaryColor.whiteText,
                    [breakpoints.down('sm')]: {
                        fontSize: '2.6rem',
                        lineHeight: '3.15rem',
                    }
                },
                // h5: {
                //     fontSize: '2.3rem'
                // },
                h6: {
                    fontSize: '2rem',
                    lineHeight: '2.4rem',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    [breakpoints.down('sm')]: {
                        fontSize: '1.5rem',
                        lineHeight: '1.8rem',
                    }
                },
                subtitle2: {
                    fontSize: '1.2rem',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '1.5rem'
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        backgroundColor: primaryColor.backgroundTextField,
                        border: 'unset',
                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            color: primaryColor.whiteText
                        }
                    },
                },
            ],
            styleOverrides: {
                root: {
                    label: {
                        color: primaryColor.inputLabelTextColor,
                        opacity: 0.5
                    },
                    width: '100%',
                    borderBottom: '1.5px solid' + primaryColor.iconColor,
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: primaryColor.inputTextColor,
                        "&:focus::placeholder": {
                            color: 'transparent'
                        },
                        "&::placeholder": {
                            opacity: 1,
                        },
                    },
                    "& .MuiInput-input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${primaryColor.background} inset`,
                        WebkitTextFillColor: primaryColor.whiteText,
                    },
                    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                        color: primaryColor.inputLabelTextColor,
                        opacity: 1
                    },
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard"
            }
        },
        MuiCheckbox: {
            defaultProps: {
                icon:
                    <Box
                        component={'img'}
                        src={'/images/checkBox.svg'}
                        height={'20px'}
                    />
                ,
                checkedIcon:
                    <Box
                        component={'img'}
                        src={'/images/Union.svg'}
                        height={'20px'}
                        width={'20px'}
                        sx={{
                            backgroundColor: primaryColor.checkBox,
                        }}
                    >
                    </Box>
            }
        },
        MuiRadio: {
            defaultProps: {
                icon:
                    <Box
                        component={'img'}
                        src={'/images/icons/radioIcon.svg'}
                    />
                ,
                checkedIcon:
                    <Box
                        component={'img'}
                        src={'/images/icons/radioCheckedIcon.svg'}
                    />
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: primaryColor.inputLabelTextColor,
                        opacity: 1
                    },
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: primaryColor.inputLabelTextColor,
                    fontSize: helperTextFonts.lg,

                    [breakpoints.down('lg')]: {
                        fontSize: helperTextFonts.md,
                    },
                    [breakpoints.down('md')]: {
                        fontSize: helperTextFonts.sm,
                    },
                    [breakpoints.down('sm')]: {
                        fontSize: helperTextFonts.xs,
                    },

                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: primaryColor.inputLabelTextColor,
                    fontSize: secondaryFonts.lg,
                    [breakpoints.down('lg')]: {
                        fontSize: secondaryFonts.md,
                    },
                    [breakpoints.down('md')]: {
                        fontSize: secondaryFonts.sm,
                    },
                    [breakpoints.down('sm')]: {
                        fontSize: secondaryFonts.xs,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: primaryColor.grayBackgroundColor,
                    fontSize: '1.2rem'
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: primaryColor.linkColor,
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "none"
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'simpletext'},
                    style: {
                        color: primaryColor.whiteText,
                        "&:hover": {
                            color: "inherit"
                        }
                    },
                },
            ],
        }
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: fontFamily
    },
    palette: {
        primary: {
            main: primaryColor.blue,
        },
    },
});

export default theme;