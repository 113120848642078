import {Box, Container, Divider, Grid, GridProps, styled, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../../hook";
import React, {useEffect} from "react";
import {getMenu, setFooterMenu} from "../../../reducers/static";
import {MenuItem} from "../../../interfaces/backend";
import {isEmailValid} from "../../../utils/validation";


interface ContactElement {
    link: string,
    text: string,
    image: string,
    alt: string,
}

const StyledGrid = styled((props: GridProps) => (
    <Grid item xs={12}  {...props}  />
))(({theme}) => ({
    justifyContent: "center",
    alignItems: "center",
}));

const StyledLink = (linkContent: MenuItem) => {
    const {title, link, isBlank} = linkContent

    return <Box
        component="a"
        href={link}
        target={isBlank ? "_blank" : '_self'}
        sx={{
            color: primaryColor.textHeader,
            textDecoration: 'none',
            fontWeight: 400,
            '&:hover': {
                color: primaryColor.whiteText
            }
        }}
    >
        <Typography
            variant='h6'
            sx={{
                fontWeight: 400,
                fontSize: "1.6rem",
                lineHeight: "1.9rem",
                display: "inline"
            }}
        >
            {title}
        </Typography>
    </Box>
}


const TopFooter = () => {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch()
    const footerLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.footerMenu)
    const {contactPhone, contactForm, submarkUrl, footerMenu} = useAppSettings();


    let contactInfo: Array<ContactElement> = [];
    if (contactPhone) {
        contactInfo.push({
            link: `tel:${contactPhone}`,
            text: contactPhone,
            image: '/images/phone.svg',
            alt: 'phone'
        })
    }
    if (contactForm) {
        contactInfo.push({
            link: (isEmailValid(contactForm) ? 'mailto:' : '') + contactForm,
            text: t('contact'),
            image: '/images/mail.svg',
            alt: 'mail'
        })
    }

    useEffect(() => {
        dispatch(getMenu({menuId: footerMenu, handler: setFooterMenu}))
    }, [dispatch, footerMenu])

    return (
        <Box sx={{
            backgroundColor: primaryColor.headerColor,
            color: primaryColor.textHeader,
            paddingY: 2,
            paddingX: {xs: 1, md: 0}
        }}>
            <Container maxWidth={'2xl'}>
                <Grid container>
                    <Grid
                        item
                        md={3}
                        display={{xs: 'none', md: 'flex'}}
                        order={{xs: 1}}
                        alignItems='center'
                    >
                        <Box
                            component={"img"}
                            src={submarkUrl}
                            alt={'logo'}
                            width={38}
                        />
                        <Typography
                            variant='h6'
                            marginLeft={1}
                        >
                            {t('slogan')}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        md={6.5}
                        lg={7}
                        xl={7.5}
                        order={{xs: 3, md: 2}}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            sm={9}
                            md={11.5}
                            alignContent={"center"}
                            columnSpacing={1}
                        >
                            {
                                footerLinks.map((item, index) =>
                                    <Grid item xs={12} sm={6} lg={4} key={index} textAlign={{xs: "center", sm: "left"}}>
                                        {StyledLink(item)}
                                    </Grid>)
                            }
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        md={2.5}
                        lg={2}
                        xl={1.5}
                        order={{xs: 2, md: 3}}
                        alignContent={{xs: 'center'}}
                        justifyContent={'right'}
                    >
                        <StyledGrid
                            xs={9}
                            sx={{
                                justifyContent: "flex-start",
                                display: {xs: "flex", md: "none"}
                            }}
                        >
                            <Typography
                                variant='h6'
                                sx={{fontSize: "1.6rem"}}>
                                {t("contact")}
                            </Typography>
                        </StyledGrid>
                        <StyledGrid
                            xs={3}
                            md={12}
                            item
                            container
                            sx={{
                                justifyContent: {xs: "right", md: "center"}
                            }}
                        >
                            {contactInfo.map((value, index) =>
                                <StyledGrid key={index} xs={6} sm={4} md={12}>
                                    <Box
                                        component={"a"}
                                        href={value.link}
                                        sx={{
                                            textDecoration: 'none',
                                            color: primaryColor.textHeader,
                                            display: "flex",
                                            justifyContent: {xs: "flex-end", md: "flex-start"},
                                            '&:hover': {
                                                color: primaryColor.whiteText
                                            }
                                        }}
                                    >
                                        <Box
                                            component={"img"}
                                            src={value.image}
                                            width={{xs: "auto", md: 21}}
                                            height={{xs: 23, md: "auto"}}
                                            alt={value.alt}
                                        />
                                        <Typography
                                            variant='h6'
                                            display={{xs: "none", md: "inline"}}
                                            marginLeft={2}
                                            fontSize="1.6rem"
                                        >
                                            {value.text}
                                        </Typography>
                                    </Box>
                                </StyledGrid>
                            )}
                        </StyledGrid>
                        <StyledGrid sx={{display: {xs: 'block', md: 'none'}}}>
                            <Divider
                                sx={{
                                    backgroundColor: primaryColor.textHeader,
                                    marginY: 2
                                }}
                            />
                        </StyledGrid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default React.memo(TopFooter)