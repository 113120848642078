import {Box, Button, keyframes, useMediaQuery} from "@mui/material";
import theme, {defaultFonts, primaryColor} from "../../../theme";
import * as React from "react";
import {Dispatch, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Filter from "./Filter";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../../hook";
import {Category, CheckedBoxList, FilterAttribute, ListingListTypes} from "../../../interfaces/backend";
import {setCheckedBoxesList, setCloseFilter, setSelectedCategory} from "../../../reducers/sparePart";
import Tree from "./Tree";
import Prices from "./Prices";
import FilterHeader from "./FilterHeader";
import MobileFilter from "../mobile/MobileFilter";
import ArrowButton from "../../../components/common/ArrowButton";
import FilterChip from "./FilterChip";
import AuthDynamicComponent from "../../../components/accessControl/AuthDynamicComponent";

const leftFlow = keyframes`
  to {
    transform: translateX(-200%);
  }
`

const rightFlow = keyframes`
from { transform: translateX(-100%);}
  to {
    transform: translateX(0);
  }
`

interface FiltersProps {
    filters: FilterAttribute[],
    setSelectedPage: Dispatch<React.SetStateAction<number>>,
    type: ListingListTypes,
    handleFilter?: Function,
    searchItemsCount: number
}


export default function Filters(props: FiltersProps) {
    const {handleFilter, filters, setSelectedPage, type, searchItemsCount} = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const transformProperties = !isMobile ? '0.5s linear normal forwards' : 'none';

    const hideFilter = `${leftFlow} ${transformProperties}`
    const showFilter = `${rightFlow} ${transformProperties}`
    const {t} = useTranslation('searchPage')
    const {pricesFilter, isFilterClose} = useAppSelector(state => state.sparePart)
    const {shopUsesPrices, showPricesForGuest} = useAppSettings();

    const [checkedBoxesArray, setCheckedBoxesArray] = useState<string[][]>([])
    const [filterAreaScrollable, setFilterAreaScrollable] = useState<boolean>(true)
    const dispatch = useAppDispatch()

    const checkedBoxesList = useAppSelector(state => state.sparePart.checkedBoxesList)
    const findCategoryByAlias = (category: Category, selectedCategory: string): Category | null => {

        if (category.alias === selectedCategory) {
            return category;
        }

        for (const child of category.children) {
            const foundCategory = findCategoryByAlias(child, selectedCategory);
            if (foundCategory) {
                return foundCategory;
            }
        }

        return null;
    };

    const categories: Category = useAppSelector(state => state.sparePart.category)
    const selectedCategory: string = useAppSelector(state => state.sparePart.selectedCategory);

    const filteredCategory = findCategoryByAlias(categories, selectedCategory);

    useEffect(() => {
        let ar = []
        for (const [key, values] of Object.entries(checkedBoxesList)) {
            for (const value of values) {
                ar.push([key, value])
            }
        }
        setCheckedBoxesArray(ar)
    }, [checkedBoxesList])


    const handleCheckboxChange = (code: string, content: string) => {
        let checkedBoxes: CheckedBoxList = {...checkedBoxesList}
        setSelectedPage(1)
        if (!checkedBoxes.hasOwnProperty(code)) {
            checkedBoxes[code] = [content]
            dispatch(setCheckedBoxesList(checkedBoxes))
        } else {
            //uncheck a checkbox
            if (checkedBoxes[code] && checkedBoxes[code].includes(content)) {
                const index = checkedBoxes[code].indexOf(content)
                if (index > -1) {
                    const remainValues = checkedBoxes[code].filter((item: string) => item !== content)
                    const temporaryCheckedBoxes = Object.fromEntries(Object.entries(checkedBoxes).filter(([key]) => !key.includes(code)))
                    if (remainValues.length > 0) {
                        checkedBoxes[code] = remainValues
                        dispatch(setCheckedBoxesList(checkedBoxes))
                    } else {
                        dispatch(setCheckedBoxesList(temporaryCheckedBoxes))
                    }
                }
            } else {
                checkedBoxes[code] = [...checkedBoxes[code], content]
                dispatch(setCheckedBoxesList(checkedBoxes))
            }
        }
    }


    const checkDisabled = () => {
        //         return !(Object.entries(checkedBoxesList).length > 0);
        if (type !== ListingListTypes.MyMachine) {
            return !!((pricesFilter?.minPrice && pricesFilter?.maxPrice && pricesFilter.minPrice > pricesFilter.maxPrice) || searchItemsCount === 0);
        }
        ;
    }


    const [selectedFilterCodes, setSelectedFilterCodes] = useState<string[]>([]);

    useEffect(() => {
        const newSelectedFilterCodes: string[] = [];
        filters && filters.forEach(filter => {
            if (checkedBoxesArray.some(entry => entry[0] === filter.code)) {
                newSelectedFilterCodes.push(filter.code);
            }
        });
        setSelectedFilterCodes(newSelectedFilterCodes);
    }, [checkedBoxesArray, filters]);

    const [isVisible, setIsVisible] = useState(true);

    const handleAnimationEnd = () => {
        setIsVisible(!isFilterClose);
    };
    return (
        <Box
            sx={{
                textOverflow: 'hidden',
                fontWeight: '300px',
                fontSize: defaultFonts,
                width: '100%',
            }}
        >

            <FilterHeader isDisabled={searchItemsCount === 0}/>
            {shopUsesPrices && type !== ListingListTypes.MyMachine && <AuthDynamicComponent
                children={<Prices/>}
                fallback={<>{
                    showPricesForGuest && <Prices/>
                }</>}
            />}


            {type !== ListingListTypes.Search &&
                type !== ListingListTypes.MyMachine &&
                type !== ListingListTypes.StandardMachine &&
                categories.children.length > 0 && !isFilterClose &&

                <Tree
                    category={categories}
                    name={t('category') as string}/>
            }


            {isFilterClose && !isMobile && checkedBoxesArray.map((item: string[], key: number) =>
                <FilterChip
                    key={key}
                    label={item[1]}
                    onClick={() => handleCheckboxChange(item[0], item[1])}
                />
            )}

            {isFilterClose && !isMobile && filteredCategory && categories.children.length > 0 &&
                <FilterChip
                    label={filteredCategory.name}
                    onClick={() => dispatch(setSelectedCategory(""))}
                />
            }

            <Box
                sx={{
                    animation: isFilterClose ? hideFilter : showFilter,
                    display: 'flex',
                    flexDirection: 'column',
                    // paddingRight: {xs: '0px', md: '80px'} ,
                    mt: {xs: '0px', md: '55px'},

                }}
                onAnimationEnd={() => handleAnimationEnd()}
            >

                {(isVisible || !isFilterClose) &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: filterAreaScrollable && filters?.length > 5 ? '800px' : 'auto',
                            overflow: 'hidden',
                            '& :nth-of-type(1)': {
                                mt: '0'
                            },
                            mt: {xs: '0px', md: '20px'},

                        }}
                    >
                        {!isMobile ? filters && filters.map((item, key) =>
                            <Filter
                                key={key}
                                filter={item}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ) :
                            filters && filters.map((item, key) =>
                                <MobileFilter
                                    key={key}
                                    filter={item}
                                    handleCheckboxChange={handleCheckboxChange}
                                    isFilterSelected={selectedFilterCodes.includes(item.code)}
                                />
                            )
                        }
                    </Box>
                }

                {filterAreaScrollable && filters?.length > 5 ?
                    <Box
                        sx={{margin: 'auto'}}
                        onClick={() => setFilterAreaScrollable(false)}>
                        <ArrowButton
                            direction={"down"}
                            color={primaryColor.arrowButtonColorSlider}
                            width={40}
                        />
                    </Box>
                    : null}
            </Box>
            {isMobile && !isFilterClose && <Box>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (handleFilter) {
                            dispatch(setCloseFilter(true))
                            handleFilter()
                        }
                    }}
                    disabled={checkDisabled()}
                    sx={{
                        //width: 'fit-content',
                        // width: '100%',
                    }}
                >
                    {searchItemsCount ?
                        t("viewFilterResults{{count}}", {count: searchItemsCount}) :
                        t("noFilterResultFound")
                    }

                </Button>
            </Box>}
        </Box>
    )
}
