import {Box, useMediaQuery} from "@mui/material";
import {openSearchBox} from "../../../../../reducers/quickSearch";
import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hook";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import theme from "../../../../../theme";
import AuthDynamicComponent from "../../../../accessControl/AuthDynamicComponent";
import AccountMenu from "./AccountMenu";
import {useLocation} from "react-router";
import MiniCartMenu from "./MiniCartMenu";
import MenuAccountIcon from "./AccountMenu/MenuAccountIcon";
import MenuSearchIcon from "./MenuSearchIcon";
import ModalSearchBox from "../modalSearchBox";


const LeftIcons = () => {
    const searchBoxOpen = useAppSelector(
        (state) => state.quickSearch.searchBoxOpen
    );
    const dispatch = useAppDispatch();
    const {t} = useTranslation("common");
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();
    const isMyAccountPage = location.pathname === '/my-account';

    return (
        <Box
            sx={{
                width: {xs: '125px', lg: '150px'},
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center"
            }}
        >
            <AuthDynamicComponent
                children={
                    <Box
                        onClick={() => dispatch(openSearchBox(!searchBoxOpen))}
                        sx={{
                            lineHeight: 0,
                            cursor: "pointer"
                        }}
                    >
                        <MenuSearchIcon isActive={searchBoxOpen}/>
                    </Box>
                }
            />
            <ModalSearchBox/>
            <AuthDynamicComponent
                children={<AccountMenu/>}
                fallback={
                    <Link
                        to={"/login"}
                        style={{
                            lineHeight: 0 //remove additional vertical space
                        }}
                    >
                        <MenuAccountIcon
                            isActive={mobileView && isMyAccountPage}
                            tooltipTitle={t("login") as string}
                        />
                    </Link>
                }
            />

            <AuthDynamicComponent children={<MiniCartMenu/>}/>
        </Box>
    );
};
export default React.memo(LeftIcons);