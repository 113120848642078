import Favicon from "react-favicon";
import React from "react";
import {useAppSettings} from "../../hook";

const FaviconComp = () => {
    const {faviconUrl} = useAppSettings();

    return (
        <>
            {faviconUrl && <Favicon url={faviconUrl}></Favicon>}
        </>
    )
}

export default React.memo(FaviconComp);