import LoginArea from "../components/login";
import {useMediaQuery} from "@mui/material";
import {useAppSelector, useAppSettings} from "../hook";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {getSelectedLng} from "../utils/localization";
import {useRef} from "react";
import LoginPageContent from "./LoginPageContent";
import theme from "../theme";

const LoginPage = () => {
    const {recaptchaSiteKey} = useAppSettings();
    const Ref = useRef<HTMLDivElement>(null);
    const isRegistrationOpen = useAppSelector(
        (state) => state.start.isRegistrationOpen
    );
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));

    return (
        recaptchaSiteKey ?
            <GoogleReCaptchaProvider
                reCaptchaKey={recaptchaSiteKey}
                language={getSelectedLng()}
            >

                <LoginPageContent showArticles={!isRegistrationOpen || !mobileView}>
                    <LoginArea containerRef={Ref}/>
                </LoginPageContent>

            </GoogleReCaptchaProvider>
            :
            <LoginPageContent showArticles={!isRegistrationOpen || !mobileView}>
                <LoginArea containerRef={Ref}/>
            </LoginPageContent>

    )
}


export default (LoginPage)