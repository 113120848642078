import {Box, Grid, Typography, useMediaQuery} from "@mui/material"
import Page from "../page";
import theme, {primaryColor} from "../../theme";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {BreadCrumbsObject, SelectedTabObject} from "../../interfaces/general";
import {useParams} from "react-router-dom";
import AllTabs from "../../components/common/Tabs/AllTabs";
import {findItem, myAccountPageRelations, useAllMyAccountTabs} from "../../components/common/Tabs";
import {StyledPaper} from "../../components/common/StyledComponents";
import {useAppDispatch, useAppSelector} from "../../hook";
import {setSelectedTab} from "../../reducers/static";
import HeaderTab from "../../components/common/Tabs/HeaderTab";

export interface TabData {
    item: string,
    text: string,
    selected?: boolean,
    isLast?: boolean,
}

/*function findItem(arr: { item: string, text: string }[][], itemToFind?: string) {
  let res: { item: string, text: string }|undefined;
  arr.some(
      o => {
        res = o.find(
            of => of.item === itemToFind)
        return res
      }
  )
  return res;
}*/


const AccountPage = () => {
    const {t} = useTranslation(['myAccount']);
    const params = useParams()

    const selectedTabObject: SelectedTabObject = useAppSelector(state => state.staticContent.selectedTab);
    const dispatch = useAppDispatch()

    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const lgView = useMediaQuery(theme.breakpoints.down("lg"));
    const myAccountTabs = useAllMyAccountTabs()
    const makeBread = (): Array<BreadCrumbsObject> => {
        return [
            {name: t('common:home'), link: '/'},
            {name: t('common:myAccount'), link: '/my-account/my-data'},
        ]
    };

    useEffect(() => {
        const tabName = findItem(myAccountTabs, params.tab)

        if (params.tab && tabName) {
            dispatch(setSelectedTab({tabId: params.tab, tabName: tabName.text}))
        }

    }, [params])

    useEffect(() => {
        if (params.tab) {
            const bottomHeader = document.getElementById("my-account-title");
            bottomHeader?.scrollIntoView({block: "start", inline: "nearest"})
        }
    }, [myAccountTabs, params.tab])


    const getPage = (name: string) => {
        const result = myAccountPageRelations({id: params.id}).find(page => page.name === name)
        return result ? result.page : <></>
    }

    return (
        <Page breadCrumbs={makeBread()} matchToHeaderWidth={true}>
            {!mobileView ? <>
                    <Box sx={{
                        fontSize: {md: '5.5rem', lg: '7rem'},
                        color: primaryColor.pageTitle,
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '-5px',
                        fontWeight: '700'
                    }}
                         id={"my-account-title"}
                    >
                        {lgView ? t('myAccount') : selectedTabObject.tabName}
                    </Box>
                    <Box>
                        <Grid
                            container
                            sx={{
                                margin: 'auto',
                                zIndex: 1300,
                                paddingTop: '25px',
                                paddingBottom: '80px'
                            }}
                        >
                            <Grid key={1} xs={4} lg={3} item>
                                <AllTabs allTabs={myAccountTabs}/>
                            </Grid>
                            <Grid key={2} xs={8} lg={9} item>
                                <StyledPaper>
                                    {getPage(selectedTabObject.tabId)}
                                </StyledPaper>
                            </Grid>
                        </Grid>
                    </Box>
                </> :
                <>
                    <Box sx={{
                        color: primaryColor.myAccountTabText,
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{fontSize: '2.6rem', fontWeight: '600'}}>{t("myAccount")}</Typography>
                    </Box>

                    {!params.id && <Box mb={"25px"}>
                        <HeaderTab link={'/my-account'}/>
                    </Box>}

                    {getPage(selectedTabObject.tabId)}
                </>}
        </Page>
    )
}

export default (AccountPage)
