import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Machine, MachinesSearch, ProductTypeEnum, StandardMachine} from "../interfaces/backend";
import {initialMachineState} from "../interfaces/initialStates";


const initialState: initialMachineState = {
    myMachines: [],
    standardMachines: [],
    machinesSearch: {
        items: [],
        pagination: [],
        currentPage: 1,
        itemsTotal: 0,
        itemsCount: 0,
        filterResults:
            {
                attributes: [
                    {
                        code: "",
                        name: "",
                        values: [
                            {
                                content: "",
                                count: 0
                            }
                        ]
                    }
                ]
            }
    },
    machine: {
        productType: null,
        isIndividual: false,
        product: {
            name: "",
            code: "",
            alias: "",
            price: 0,
            discountPrice: 0,
            discountValue: 0,
            priceType: "",
            inStock: true,
            isEnabled: true,
            deliveryTime: 0,
            isOrderable: true,
            isMachine: true,
            isIndividualMachine: true,
            quantity: 0,
            unit: {
                name: "",
                code: ""
            },
            details: {
                shortDescription: "",
                longDescription: "",
                technicalDetails: []
            },
            image: [],
            image3d: {
                url: '',
                urn: '',
                thumbnail: ''
            },
            video: [],
            document: [],
            schema: [],
            relatedProducts: [],
            relatedToProducts: [],
            type: ProductTypeEnum.Product
        },
        spareParts: {
            serialNumber: 0,
            isGroup: false,
            label: '',
            quantity: 0,
            product: {
                name: "",
                code: "",
                alias: "",
                price: 0,
                discountPrice: 0,
                discountValue: 0,
                priceType: "",
                inStock: true,
                isEnabled: true,
                deliveryTime: 0,
                isOrderable: true,
                isMachine: true,
                isIndividualMachine: true,
                quantity: 0,
                unit: {
                    name: "",
                    code: ""
                },
                details: {
                    shortDescription: "",
                    longDescription: "",
                    technicalDetails: []
                },
                image: [],
                image3d: {
                    url: '',
                    urn: '',
                    thumbnail: ''
                },
                video: [],
                document: [],
                schema: [],
                relatedProducts: [],
                relatedToProducts: [],
                type: ProductTypeEnum.Product
            },
            children: []
        },
    },

};

export const machineSlice = createSlice({
        name: "machine",
        initialState: initialState,
        reducers: {
            getMyMachine: (state, action) => {
            },
            getMyMachines: () => {
            },

            setMyMachines: (state: initialMachineState, action: PayloadAction<Array<Machine>>) => {
                state.myMachines = action.payload
            },


            getStandardMachine: (state, action) => {
            },
            getStandardMachines: () => {
            },


            setStandardMachines: (state: initialMachineState, action: PayloadAction<Array<StandardMachine>>) => {
                state.standardMachines = action.payload
            },


            getMyMachinesSearch: (state, action) => {
            },
            getStandardMachinesSearch: (state, action) => {
            },


            // for both machines
            setMachinesSearch: (state: initialMachineState, action: PayloadAction<MachinesSearch>) => {
                state.machinesSearch = action.payload
            },
            setMachine: (state: initialMachineState, action: PayloadAction<Machine>) => {
                state.machine = action.payload
            },
            resetSelectedMachine: (state: initialMachineState) => {
                state.machine = initialState.machine
            },
        }
    }
)

export const {
    getMyMachine,
    getMyMachines,
    // setMyMachine,
    setMyMachines,
    getMyMachinesSearch,
    getStandardMachine,
    resetSelectedMachine,
    // setStandardMachine,
    setMachine,
    getStandardMachines,
    setStandardMachines,
    setMachinesSearch,
    getStandardMachinesSearch,
} = machineSlice.actions

export default machineSlice.reducer