import {SvgIcon} from "@mui/material";
import {primaryColor} from "../../../theme";
import {IconComponentProps} from '../../../interfaces/general';


const SearchIcon = (props: IconComponentProps) => {
    const {isActive = false, sx = {}} = props;
    const fillColor = isActive ? primaryColor.orangeHoverColor : 'white'

    return (
        <SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" sx={sx}>
            <path
                d="M18.3038 16.7578L13.8109 12.2723C14.8477 10.9621 15.4025 9.34254 15.4025 7.67751C15.4025 6.19446 14.9659 4.73869 14.1383 3.51039C13.3107 2.27299 12.1374 1.30855 10.7641 0.744444C9.39981 0.171237 7.89005 0.0256609 6.43485 0.316813C4.97965 0.607966 3.64269 1.31765 2.59677 2.37308C1.55085 3.42851 0.832343 4.75689 0.541303 6.21265C0.250264 7.66842 0.404879 9.17877 0.968768 10.5526C1.53266 11.9265 2.49672 13.1002 3.73364 13.9191C4.97056 14.7471 6.41666 15.1838 7.89914 15.1838C9.56352 15.1838 11.1824 14.6288 12.4921 13.6006L16.9759 18.0953C17.0669 18.1863 17.1669 18.25 17.2852 18.3046C17.4034 18.3501 17.5216 18.3774 17.649 18.3774C17.7763 18.3774 17.8945 18.3501 18.0128 18.3046C18.131 18.2591 18.231 18.1863 18.322 18.0953C18.4129 18.0043 18.4766 17.9042 18.5312 17.786C18.5766 17.6677 18.6039 17.5494 18.6039 17.422C18.6039 17.2946 18.5766 17.1764 18.5312 17.0581C18.4675 16.9489 18.3947 16.8488 18.3038 16.7578ZM2.26935 7.67751C2.26935 6.56749 2.59677 5.47567 3.21523 4.54762C3.83369 3.61958 4.7159 2.90079 5.74363 2.47316C6.77137 2.04553 7.89914 1.93635 8.99054 2.15471C10.0819 2.37308 11.0824 2.90989 11.8736 3.69236C12.6649 4.48393 13.1924 5.48477 13.4107 6.57659C13.629 7.66842 13.5198 8.79663 13.0924 9.82476C12.6649 10.8529 11.9464 11.7355 11.0187 12.3542C10.091 12.9728 9.00873 13.3004 7.89005 13.3004C6.39847 13.3004 4.97056 12.709 3.91554 11.6536C2.86052 10.5981 2.26935 9.16967 2.26935 7.67751Z"
                fill={fillColor}/>
        </SvgIcon>
    )
}

export default SearchIcon;