import {SxProps} from "@mui/material";
import {primaryColor} from "../../../theme";

interface TableStylesProps {
    isInquiry?: boolean
}

const tableStyles = (props: TableStylesProps): SxProps => {
    const {isInquiry = false} = props

    return {

        "& tbody > tr > td": {
            borderBottom: primaryColor.tableBottomBorderLineColor,

            "&:nth-of-type(1), &:nth-of-type(5)": {
                textAlign: "center",
            },
        },

        "& thead > tr > th": {
            "&:nth-of-type(1)": {
                justifyContent: "start",
            },
        },

        "& tbody > tr > td, thead > tr > th": {
            "&:last-of-type": {
                borderBottom: "none",
            },
            "&:nth-of-type(2)": {
                maxWidth: "450px",
            },
            "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)": {
                textAlign: isInquiry ? 'center' : "end",
                maxWidth: "200px",
            },

            "&:nth-of-type(1)": {
                maxWidth: "50px",
            },
            "&:last-child": {
                padding: "0px 10px",
                textAlign: "center",
            },
        },
    }
};

export default tableStyles;