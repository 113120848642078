import {Box, BoxProps, styled, Switch, SwitchProps, SxProps, Theme, Typography, TypographyProps} from "@mui/material";
import {primaryColor} from "../../../theme";
import React from "react";

export const ContainerBox = styled((props: BoxProps) => (
    <Box {...props} sx={{}}/>
))(({theme}) => ({
    backgroundColor: primaryColor.backgroundOverlay,
    width: '100%',
    padding: '29px 40px 50px',
    [theme.breakpoints.down("md")]: {
        margin: '10px auto 25px',
    },

    [theme.breakpoints.down("sm")]: {
        padding: '5px 40px 50px',
    },
    minHeight: '500px',
}));
export const TopBox = styled((props: BoxProps) => (
    <Box {...props}  />
))(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '0px auto 40px ',
    width: '100%',
}));

export const SearchIconBox = styled((props: BoxProps) => (
    <Box {...props}
         component={'img'}
         src={'/images/user-search.svg'}
    />
))(({theme}) => ({
    marginRight: '5px',
    position: 'relative',
    cursor: 'pointer',
}));

export const BrokenText = styled((props: TypographyProps) => (
    <Typography {...props}  />
))(({theme}) => ({
    wordBreak: 'break-word'

}));

export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '10px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'gray',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: primaryColor.machinePageText,
        opacity: 1,
        transition: 'background-color 0.5s ease',
    },
}));


export const getHeadCellStyle = (headCellId: string, openSearchField: boolean): SxProps<Theme> => {
    switch (headCellId) {
        case 'userName':
            return {
                visibility: openSearchField ? 'hidden' : 'visible',
                minWidth: '200px',
                textAlign: 'start',
                paddingX: 0
            };
        case 'isAdmin':
            return {
                minWidth: '100px',
                textAlign: 'start',
            };
        case 'cartLimit':
            return {
                maxWidth: '100px',
                textAlign: 'end',
            };
        default:
            return {
                maxWidth: '100px',
                textAlign: 'center',
                paddingX: 0
            };
    }
};
