import {useEffect, useState} from "react";
import {User} from "../../../interfaces/backend";
import {deleteUser, setChanged} from "../../../reducers/myAccount";
import {useAppDispatch, useAppSelector} from "../../../hook";

export function useUserTableData(users: User[]) {
    const [numberOfActiveUsers, setNumberOfActiveUsers] = useState(users.length);
    const [filteredData, setFilteredData] = useState<User[]>(users);

    const isDataChanged = useAppSelector(
        (state) => state.myAccount.isDataChanged
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (users && isDataChanged) {
            dispatch(setChanged());
            setNumberOfActiveUsers(users.filter((x) => x.isEnabled).length);
            setFilteredData(users);
        }
    }, [users, isDataChanged]);

    return {numberOfActiveUsers, filteredData};
}

export function descendingComparator<T extends Record<string, any>>(a: T, b: T, orderBy: keyof T) {
    if (orderBy === 'cartLimit') {
        if ('cartLimit' in a && 'cartLimit' in b) {
            const cartLimitA = -(parseInt(a.cartLimit, 10) || Infinity);
            const cartLimitB = -(parseInt(b.cartLimit, 10) || Infinity);

            if (cartLimitB < cartLimitA) {
                return -1;
            }
            if (cartLimitB > cartLimitA) {
                return 1;
            }
            return 0;
        }
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof User>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: User[key] }, b: { [key in Key]: User[key] }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function useConfirmationDialog() {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    const [toDeleteRowId, setToDeleteRowId] = useState<number | undefined>();
    const dispatch = useAppDispatch()

    const handleDeleteIconClick = (rowId: number) => {
        setToDeleteRowId(rowId);
        setIsConfirmationOpen(true);
    };

    const onConfirm = () => {
        setIsConfirmationOpen(false);
        if (toDeleteRowId) {
            dispatch(deleteUser({id: toDeleteRowId}))
        }
    };

    const closeConfirmationDialog = () => {
        setIsConfirmationOpen(false);
    };

    return {
        isConfirmationOpen,
        handleDeleteIconClick,
        closeConfirmationDialog,
        onConfirm,
    };
}


export function sortUsers(users: User[], sortUsersBy: string): User[] {
    const sortCriteria: { [key: string]: (user: User) => any } = {
        userName: (user: User) => user.firstName.toLowerCase(),
        isEnabled: (user: User) => user.isEnabled ? 0 : 1,
        isAdmin: (user: User) => user.isAdmin ? 0 : 1,
        cartLimit: (user: User) => -(parseInt(user.cartLimit, 10) || Infinity)
    };

    if (sortUsersBy in sortCriteria) {
        return [...users].sort((a, b) => {
            const sortValueA = sortCriteria[sortUsersBy](a);
            const sortValueB = sortCriteria[sortUsersBy](b);

            if (sortValueA < sortValueB) return -1;
            if (sortValueA > sortValueB) return 1;
            return 0;
        });
    } else {

        return [...users];
    }
}

 