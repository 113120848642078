import React from "react";
import {Box, useMediaQuery} from "@mui/material";
import {getSvg} from "./TabImages";
import {useAppSelector} from "../../../hook";
import theme, {defaultFonts, primaryColor} from "../../../theme";
import {SelectedTabObject} from "../../../interfaces/general";
import NavTabBack from "./NavTabBack";

interface HeaderTabProps {
    link: string;
}

const HeaderTab = (props: HeaderTabProps) => {
    const {link} = props;

    const mobileView = useMediaQuery(theme.breakpoints.down("md"));

    const selectedTabObject: SelectedTabObject = useAppSelector(
        (state) => state.staticContent.selectedTab
    );

    const style = {
        selectedStyle: mobileView && {
            position: "relative",
            "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: "11px",
                backgroundColor: primaryColor.myAccountTabSelected,
            },
        },

    };

    const conditionalBackgroundColor = {
        backgroundColor: mobileView
            ? primaryColor.myAccountTabSelectedBackground
            : primaryColor.myAccountDataBackground,
    };

    return (
        <Box
            sx={{
                ...style.selectedStyle,
                backgroundColor: conditionalBackgroundColor,
                color: primaryColor.myAccountTabText,
                fontSize: defaultFonts,
                height: "74px",
                display: "inline-flex",
                width: "100%",
                paddingLeft: '22px'
                // cursor: "pointer",
            }}
        >
            <NavTabBack
                linkTo={link}
                sx={{
                    width: "27px",
                }}
            />

            <Box
                sx={{
                    margin: 'auto 10px auto 25px',
                    paddingTop: '5px'
                }}
            >
                {getSvg(selectedTabObject.tabId)}
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    width: "100%",
                    fontSize: defaultFonts,
                    fontWeight: 400,
                    textTransform: "uppercase",
                    textAlign: "start",
                    wordBreak: "break-word",
                }}
            >
                {selectedTabObject.tabName}
            </Box>
        </Box>
    );
};

export default HeaderTab;
