import React from 'react';
import {Box, TextField} from "@mui/material";
import {primaryColor, tableFontSize} from '../../../theme';
import ArrowButton from "../ArrowButton";

interface protectedRouteProps {
    onChange: (value: number) => void,
    value: number,
}

const renderQuantityArrow = (direction: "left" | "right", onClick: () => void, opacity?: number) =>
    <Box sx={{
        height: '41.55px',
        minWidth: '41.55px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1.85px',
        opacity: opacity,
        //marginRight: '-15px',
        backgroundColor: {
            xs: primaryColor.quantityChangeMobileArrowBackground,
            lg: 'unset'
        }
    }}
         onClick={onClick}
    >
        <ArrowButton
            direction={direction}
            color={primaryColor.arrowButtonColorSlider}
            sx={{
                width: {
                    xs: '17.89px',
                    lg: '26px'
                }
            }}
        />
    </Box>

const InputWrapper = (props: protectedRouteProps) => {
    const {value, onChange} = props;

    return (
        <Box
            className="input-wrapper-box"
            sx={{
                placeItems: 'center',
                display: 'flex',
                cursor: 'pointer',
                width: '120px',

            }}>

            {renderQuantityArrow(
                "left",
                () => value - 1 > 0 && onChange(value - 1),
                value > 1 ? 1 : 0)
            }

            <Box>
                <TextField
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValue: number = parseInt(event.target.value)
                        newValue > 0 && onChange(newValue)
                    }}
                    sx={{
                        borderBottom: 'unset',
                        '& .MuiInputBase-input.MuiInput-input': {
                            textAlign: 'center',
                            padding: '0',
                            maxWidth: '50px',
                            fontSize: tableFontSize.contentMain,
                        }
                    }}
                />
            </Box>

            {renderQuantityArrow(
                "right",
                () => onChange(value + 1))}
        </Box>
    )
}

export default (InputWrapper)