import React, {useState} from "react";
import {Box, Grid, useMediaQuery} from "@mui/material";
import Arrow from "../common/svg/arrow";
import theme, {primaryColor} from "../../theme";

interface TemplateBoxProps {
    title: string;
    description: string;
    relatedContent?: React.ReactElement;
    isDynamic?: boolean;
}

const TemplateBox = (props: TemplateBoxProps) => {
    const {title, description, relatedContent, isDynamic} = props;
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));
    const [rotate, setRotate] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(!mobileView)
    const dynamicStyles = {
        display: !expanded ? 'none' : 'unset'
    }

    const handleChange = () => {
        setExpanded(!expanded)
        setRotate(!rotate)
    }

    return (
        <Grid container sx={{
            color: primaryColor.quickSearchPageColor,
            margin: '0 auto',
            width: '100%',
            textAlign: 'center',
            height: 'fit-content',
        }}>
            <Grid item xs={12} xl={9} sx={{
                maxWidth: {
                    xs: '80%',
                    sm: '70%',
                    md: '80%',
                    lg: '67%',
                },
                margin: '0 auto',
            }}>
                <Box component='span'
                     sx={{
                         textOverflow: 'ellipsis',
                         overflow: "hidden",
                         display: "-webkit-box",
                         WebkitLineClamp: "2",
                         WebkitBoxOrient: "vertical",
                         fontWeight: 400,
                         fontSize: {
                             xs: '2rem',
                             md: '2.25rem',
                             lg: '3rem',
                         },
                         minHeight: {
                             xs: '0px',
                             md: '50px',
                             lg: '70px'
                         },
                     }}
                     onClick={() => mobileView && handleChange()}
                >
                    {title}
                </Box>

                {mobileView && isDynamic &&
                    <Box onClick={() => handleChange()}>
                        <Arrow
                            fill={(expanded) ? primaryColor.badgeColor : primaryColor.whiteText}
                            rotate={rotate}
                        />
                    </Box>
                }

                <Box sx={{
                    ...(mobileView && isDynamic && dynamicStyles)
                }}>
                    <Box sx={{
                        paddingY: '10px',
                        fontWeight: 300,
                        margin: '0 auto',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: {xs: '4', lg: '5'},
                        WebkitBoxOrient: 'vertical',
                        whiteSpace: 'normal',
                        minHeight: 'fit-content',
                        maxHeight: {
                            xs: '110px',
                            md: '120px',
                            lg: '160px'
                        },
                        fontSize: {
                            xs: "1.5rem",
                            md: "1.6rem",
                            lg: "2rem",
                        }
                    }}>
                        {description}
                    </Box>
                </Box>
            </Grid>

            {(!isDynamic || expanded) && relatedContent}
        </Grid>
    )
}

export default (TemplateBox)