import {Box, Container, Typography, useMediaQuery} from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import theme, {primaryColor} from "../../../theme";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../../hook";
import {MenuItem} from "../../../interfaces/backend";
import React, {useEffect} from "react";
import {getMenu, setHeaderMenu} from "../../../reducers/static";
import ServiceHeader from "./ServiceHeader";
import SelectLanguageDesktop from "./SelectLanguage/desktop";


const StyledLink = (linkContent: MenuItem, index: string | number) => {
    const {title, link, isBlank} = linkContent

    return <Box
        key={index}
        component="a"
        href={link}
        target={isBlank ? "_blank" : '_self'}
    >
        <Typography variant='subtitle2' color={primaryColor.whiteText}>{title}</Typography>
    </Box>
}

const TopHeader = () => {
    const dispatch = useAppDispatch()
    const headerLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.headerMenu);
    const {contactPhone, topMenu} = useAppSettings();
    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(getMenu({menuId: topMenu, handler: setHeaderMenu}))
    }, [dispatch, topMenu])

    return (
        <Container maxWidth={'2xl'}>
            <Box paddingY={3}
                 display={'flex'}
                 justifyContent="end"
                 alignItems="center"
                 columnGap={4}
            >
                {serviceUserEmail && <ServiceHeader/>}

                {!mobileView &&
                    <>
                        {
                            headerLinks.map((item, index) =>
                                StyledLink(item, index)
                            )
                        }

                        {contactPhone && <Box
                            display={'flex'}
                            justifyContent="end"
                            alignItems="center"
                        >
                            <LocalPhoneOutlinedIcon
                                sx={{height: 15, color: primaryColor.textTopHeader}}></LocalPhoneOutlinedIcon>

                            <Typography variant='subtitle2' color={primaryColor.whiteText}>
                                <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                            </Typography>
                        </Box>}
                        <SelectLanguageDesktop/>
                    </>
                }
            </Box>
        </Container>
    )
}

export default React.memo(TopHeader)
