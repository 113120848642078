import Messages from "./Messages";
import InputArea from "./InputArea";


const CustomerSupportService = () => {
    return (
        <>
            <Messages/>
            <InputArea/>
        </>
    )
}

export default (CustomerSupportService)
