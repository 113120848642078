import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SettingElement, SettingsList} from "../interfaces/backend";

interface initialSettingsState {
    items: SettingsList,
    loaded: boolean
}

const initialSettingsValues: SettingsList = {
    companyName: "",
    companyPostalCode: "",
    companyCity: "",
    defaultLanguage: "",
    topMenu: 0,
    recaptchaSiteKey: "",
    googleAnalytics: "",
    showDiscount: true,
    companyStreetNumber: "",
    companyStreetName: "",
    showOrderDocuments: true,
    submarkUrl: "",
    mainMenu: 0,
    contactPhone: "",
    contactForm: "",
    companyCountry: "",
    languages: [],
    isInternalOrderNumberMandatory: false,
    showDiscountPerItem: false,
    allowGuestAccess: false,
    faviconUrl: "",
    defaultCurrency: "",
    logoUrl: "",
    noImageUrl: "",
    showPricesForGuest: false,
    backgroundImageUrl: "",
    footerMenu: 0,
    shopUsesPrices: true,
    showOrderOptions: false,
    allowInCartWithoutPrice: false,
    '3dmodelFunction': "",
    '3dmodelProperty': ""
}

const settingsAdapter = createEntityAdapter();
const initialState: initialSettingsState = settingsAdapter.getInitialState({
    items: initialSettingsValues,
    loaded: false
});

const toCamelCase = (str: string): string =>
    str.replace(/_([a-z])/g, (_match, letter) => letter.toUpperCase());

export const settingsSlice = createSlice({
        name: "settings",
        initialState: initialState,
        reducers: {
            setSettings: (state: initialSettingsState, action: PayloadAction<Array<SettingElement>>) => {
                const settings = action.payload;
                let settingsMap: SettingsList = state.items;

                if (settings && settings.length) {
                    settings.forEach(setting => {
                        let value;
                        const camelCaseKey = toCamelCase(setting.name) as keyof SettingsList;

                        switch (camelCaseKey) {
                            //Boolean value
                            case "showDiscount":
                            case "showOrderDocuments":
                            case "isInternalOrderNumberMandatory":
                            case "showDiscountPerItem":
                            case "allowGuestAccess":
                            case "showPricesForGuest":
                            case "shopUsesPrices":
                            case "showOrderOptions":
                            case "allowInCartWithoutPrice":
                                value = setting.value && parseInt(setting.value) === 1;
                                break;
                            //Integer
                            case "topMenu":
                            case "footerMenu":
                            case "mainMenu":
                                value = setting.value ? parseInt(setting.value) : 0;
                                break;
                            //Array
                            case "languages":
                                value = setting.value ? setting.value.split(";") : [];
                                break;
                            //rest of types, strings
                            default:
                                value = setting.value || "";
                        }

                        //Specific settings adjustments
                        switch (camelCaseKey) {
                            case "noImageUrl":
                                value = value || '/images/product_example_image.png';
                                break;
                            case "languages":
                                const valueAsArray = value as string[];
                                if (valueAsArray.length > 0) {
                                    const storedLanguages = localStorage.getItem('languages');
                                    if (storedLanguages !== valueAsArray.join(',')) {
                                        localStorage.setItem('languages', valueAsArray.join(','))
                                    }
                                }
                                break;
                        }

                        Object.assign(settingsMap, {[camelCaseKey]: value});
                    });
                    state.loaded = true;
                }

                state.items = settingsMap;
            },
            getSettings: (state: initialSettingsState) => {
            },
        }
    }
)

export const {
    setSettings,
    getSettings
} = settingsSlice.actions

export default settingsSlice.reducer