import {CodeName} from "./general";
import {deleteCartItemAction, updateCartItemAction, updateCartMultipleItemsAction} from "./actions";

export interface SettingElement {
    name: string,
    value: string
}

export interface SocialElement extends CodeName {
    url: string,
    image: string
}

export interface SearchSuggestionElement {
    code: string,
    name: string,
    alias: string
}

export interface MenuItem {
    title: string,
    link: string,
    isBlank: boolean,
    id: string
}

export interface Menu {
    id: string,
    name: string,
    items: Array<MenuItem>
}

export interface Image {
    url: string
}

export interface Image3D {
    url: string,
    urn: string,
    thumbnail: string
}

export interface FilterResults {
    attributes: FilterAttribute[]
}

interface ProductType {//until now not used in new FE

}

export interface MachineTree {//until now not used in new FE
    product: ProductDetailsPageContent,
    isGroup: boolean,
    label: string,
    quantity: number,
    serialNumber: number,
    children: MachineTree[]
    spareParts?: MachineTree | null
}

export interface Machine {
    product: ProductDetailsPageContent,
    productType: ProductType | null,
    isIndividual: boolean,
    spareParts: MachineTree | null
}

export interface StandardMachine extends Machine {
}

export interface MachinesSearch {
    items: Machine[],
    currentPage: number,
    itemsTotal: number,
    itemsCount: number,
    pagination: number[],
    filterResults: FilterResults
}

export interface Country extends CodeName {
}

export interface Title extends CodeName {
}

export interface OrderOption {
    code: OrderOptionEnum,
    name: string
}

export interface PublicData {
    cacheBuild: string,
    translationBuild: string,
    serverDate: string
}

export interface Term {
    description: string,
    alias: string,
    label: string,
    labelFormatted: string,
    useFile: boolean,
    title: string,
    category: string,
    errorText: string,
    isMandatory: boolean,
}

export interface FrontendTerm extends Term {
    start: string,
    end: string,
    checked: boolean,
    labelLink: string
}

export interface LoginResponse {
    token: string,
    refreshToken: string,
    expire: number
}

export interface TemporaryAuthData {
    data: LoginResponse,
    storedUrls: Array<string>
}

export interface StaticPageContent {
    title: string,
    description: string,
    image: string,
    alias: string,
    category: string,
    createdAt: Date
}

export interface TechnicalDetails {
    attribute: Attribute,
    content: string,
    isHtml: boolean
}

export interface Unit extends CodeName {
}

export interface ProductDetailsPageContent {
    name: string,
    code: string,
    alias: string,
    price: number,
    discountPrice: number,
    discountValue: number,
    priceType: string,
    inStock: boolean,
    isEnabled: boolean,
    deliveryTime: number,
    isOrderable: boolean,
    isMachine: boolean,
    isIndividualMachine: boolean,
    quantity: number,
    unit: Unit,
    details: Details,
    image: Array<Image>,
    image3d: Image3D,
    video: Array<Video>,
    document: Document[],
    schema: Array<Schema>,
    relatedProducts: Array<ProductDetailsPageContent>,
    relatedToProducts: Array<ProductDetailsPageContent>,
    discountSum?: number,
    type: ProductTypeEnum
}

export interface ProductDetailsProps {
    product: ProductDetailsPageContent
    showLongDescription?: boolean;
    backgroundColor?: string;
}

export interface ProductSearchResult {
    code: string,
    name: string,
    alias: string,
    inStock: boolean,
    isOrderable: boolean,
    image: string | null,
    price: number,
    discountPrice: number,
    priceType: PriceType,
    type: ProductTypeEnum
}


// export interface SelectedSparePart {
//     label: string,
//     name: string,
//     code: string,
//     isOrderable: boolean,
//     price: number
// }

export interface SparePartsTreeSelectedNode {
    product: ProductDetailsPageContent,
    label?: string,
    level: number,
    serialNumber: number,
    groupChildren: SparePartsTreeSelectedNode[]
    nonGroupChildren?: MachineTree[];
}

export interface Schema {
    width: number,
    height: number,
    url: string,
    matches: Array<Matches>
}

export interface Matches {
    value: string,
    xCoord: number,
    yCoord: number,
    xPercent: number,
    yPercent: number,
    width: number,
    height: number,
    wPercent: number,
    hPercent: number
}

export interface Video {
    title: string,
    description: string,
    url: string
}

export interface MenuHomePage extends StaticPageContent {
}

export interface Article extends StaticPageContent {
}

export interface BillingAddress {
    companyName: string,
    streetName: string,
    streetNumber: string,
    postalCode: string,
    city: string,
    country: string,
    customer?: Customer,
    phone?: string
}

export interface Customer {
    companyName: string | undefined,
    code: string,
    vatId: string,
    additional: string,
    isEnabled: boolean,
    currency: string,
    cartMinimumSum: number,
    user: User,
    billingAddress?: BillingAddress
}

export interface Me extends User {
}

export interface User {
    id: number,
    title: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    mobile: string,
    isAdmin: boolean,
    isEnabled: boolean,
    cartLimit: string,
    customer?: Customer,
    newSupportCenterMessages: number
}

export interface MyMessage {
    code: string,
    createdAt: string,
    isAuthorUser: boolean,
    isUnread: boolean,
    message: string
}

export interface ContactData {
    phone: string;
    lastName: string;
    firstName: string;
    email: string,
    mobile: string;
}

export interface Document {
    title: string,
    url: string,
    size: number,
    mimeType: string
}

export interface File extends Document {
    name?: string,
    file?: string,
}

export interface Details {
    shortDescription: string,
    longDescription: string,
    technicalDetails: Array<TechnicalDetails>
}

export interface Attribute {
    code: string,
    name: string,
    type: string,
    isFilter?: boolean
}

export interface FilterAttributeValue {
    content: string,
    count: number
}

export interface FilterAttribute extends CodeName {
    values: Array<FilterAttributeValue>
}

export interface Request {
    category?: string,
    inStock?: boolean,
    order?: string,
    orderBy?: string,
    page: number,
    perPage: number
}

export interface FilteredSparePart {
    items: Machine[],
    itemsCount: number,
    itemsTotal: number,
    pagination: Array<number>,
    currentPage: number,
    request: {
        category: string,
        inStock: boolean,
        order: string,
        page: number,
        perPage: number
    },
    filterResults: {
        attributes: FilterAttribute[]
    }
}

export interface PricesFilter {
    minPrice: number | undefined,
    maxPrice: number | undefined
}

export interface CheckedBoxList {
    [key: string]: Array<string>
}

export interface ShippingMethod {
    id: number,
    name: string,
    price: number,
    isAddressRequired: boolean,
    usePrices: boolean,
    description: string
}

export interface ShippingAddress {
    id?: number,
    title: string,
    firstName: string,
    lastName: string,
    companyName: string,
    streetName: string,
    streetNumber: string,
    additional: string,
    postalCode: string,
    city: string,
    country: string,
    phone: string,
    isDefault?: boolean,
    saveAddress?: boolean,
    sameAsBilling?: boolean,
}

export interface PaymentMethod {
    id: number,
    name: string,
    paymentProvider: string
}

export interface Discount {
    name: string,
    description: string,
    valueType: DiscountValueType,
    value: number,
    discountCode: string
}

export interface DiscountObject {
    discountAmount: number,
    discountAmountPerItem: number,
    discount: Discount
}

export interface CartItem {
    code: string,
    product: ProductDetailsPageContent,
    quantity: number,
    discounts: DiscountObject[],
    price: number,
    discountPrice: number,
    discountValue: number,
    discountSum: number,
    priceType: string,
    total: number
    name: string,
    isOrderable: boolean
}

export interface StripePublicData {
    publicKey: string,
    accountId: string
}

export interface StripePaymentIntents {
    clientSecret: string
}

interface BaseCart {
    itemsQuantity: number;
    items: CartItem[];
}

export interface CartMethods {
    getCart(): Generator;

    deleteCartItem(action: deleteCartItemAction): Generator;

    updateCartItem(action: updateCartItemAction): Generator;

    addCartItem(action: updateCartItemAction): Generator;

    addCartMultipleItems(action: updateCartMultipleItemsAction): Generator;
}

export interface Cart extends BaseCart {
    total: number,
    tax: number,
    totalTax: number,
    shippingTax: number,
    allTaxes: number,
    totalWithTax: number,
    totalDiscount: number,
    totalWithTaxAndShipping: number,
    shippingMethod: ShippingMethod,
    shippingAddress: ShippingAddress,
    paymentMethod: PaymentMethod,
    minimumSum: number,
    orderOption: OrderOptionEnum,
    documents: Array<Document>,
    discounts: OrderDiscount[],
    totalWithoutDiscount: number,
    isAvailableForOnlinePayment: boolean
}

export interface InquiryCart extends BaseCart {
}

export interface Category {
    alias: string,
    children: Category[],
    code: string,
    image: string,
    name: string
}

export interface ShippingOption {
    id: number,
    name: string,
    price: number,
    isAddressRequired: boolean,
    usePrices: boolean,
    description: string
}

export interface OrderHistory {
    currentPage: number,
    items: Array<Order>,
    itemsCount: number,
    itemsTotal: number,
    pagination: Array<number>,
    request: Request
}

export interface Order {
    orderId: string,
    total: number,
    tax: number,
    totalTax: number,
    totalWithTaxAndShipping: number,
    items: Array<CartItem>,
    shippingMethod: ShippingMethod,
    paymentMethod: PaymentMethod,
    shippingAddress: ShippingAddress,
    billingAddress: BillingAddress,
    user: {
        firstName: string,
        lastName: string,
        email: string,
        customerCode: string,
        customerCompanyName: string
    },
    customer: Customer,
    customerUser: Me,
    status: {
        name: string,
        code: string,
        description: string
    },
    createdAt: string,
    comment: string,
    internalOrderNumber: string,
    currency: string,
    orderOption: OrderOptionEnum,
    documents: File[],
    billings: [
        {
            billingID: string,
            generatedAt: string
        }
    ],
    discounts: Array<OrderDiscount>,
    totalDiscount: number,
    totalWithoutDiscount: number
}

export interface OrderDiscount {
    discount: Discount,
    discountAmount: number
}

export interface Inquiry {
    orderId: string,
    items: Array<CartItem>,
    billingAddress: BillingAddress,
    user: {
        firstName: string,
        lastName: string,
        email: string,
        customerCode: string,
        customerCompanyName: string
    },
    customer: Customer,
    customerUser: Me,
    status: {
        name: string,
        code: string,
        description: string
    },
    createdAt: string,
    comment: string,
    internalOrderNumber: string
}

export interface AddToCartItem {
    product: string,
    quantity: number
}

export interface Error {
    code: number
    fields: ErrorFields[]
    message: string
    type: string
}

export interface ErrorFields {
    name: string
    message: string
}

export enum MachineTypes {
    MyMachine = 'myMachine',
    StandardMachine = 'standardMachine'
}

export enum ListingListTypes {
    Category = 'category',
    MyMachine = 'myMachine',
    Search = 'search',
    StandardMachine = 'standardMachine'
}

export enum UserRoleTypes {
    NormalUser = 'ROLE_USER',
    ServiceUser = 'ROLE_SERVICE'

}

export enum PageCategory {
    Article = "article",
    Page = "page",
    Term = "term"
}

export enum PasswordActionType {
    New = "new",
    Reset = "reset",
}

export enum DiscountValueType {
    Percentage = 0,
    CertainAmount = 1
}

export enum CheckoutStageType {
    Checkout = 'checkout',
    ShoppingCart = 'shoppingCart'
}

export enum ProductTypeEnum {
    Product = "product",
    Contract = "contract"
}

export enum PriceType {
    Standard = "standard",
    Discounted = "discounted",
    Individual = "individual"
}

export enum OrderOptionEnum {
    Product = "product",
    Licence = "licence"
}

export interface SettingsList {
    companyName: string;
    companyPostalCode: string;
    companyCity: string;
    defaultLanguage: string;
    topMenu: number;
    recaptchaSiteKey: string;
    googleAnalytics: string;
    showDiscount: boolean;
    companyStreetNumber: string;
    companyStreetName: string;
    showOrderDocuments: boolean;
    submarkUrl: string;
    mainMenu: number;
    contactPhone: string;
    contactForm: string;
    companyCountry: string;
    languages: string[];
    isInternalOrderNumberMandatory: boolean;
    showDiscountPerItem: boolean;
    allowGuestAccess: boolean;
    faviconUrl: string;
    defaultCurrency: string;
    logoUrl: string;
    noImageUrl: string;
    showPricesForGuest: boolean;
    backgroundImageUrl: string;
    footerMenu: number;
    shopUsesPrices: boolean;
    showOrderOptions: boolean;
    allowInCartWithoutPrice: boolean;
    '3dmodelFunction': string;
    '3dmodelProperty': string;
}