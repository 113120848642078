import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {useAppDispatch, useAppSelector, useAppSettings} from "../../hook";
import React, {useEffect, useState} from "react";
import {getCheckoutCart, order} from "../../reducers/cart";
import Page from "../page";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {closeShippingAddressForm, getBillingAddress} from "../../reducers/address";
import CheckOutComponents from "../../components/checkout";
import ShippingAddressForm from "./ShippingAddressForm";
import {OrderOptionEnum} from "../../interfaces/backend";

export default function CheckoutPage() {

    const dispatch = useAppDispatch()
    const {t} = useTranslation('checkoutPage')
    const navigate = useNavigate()
    const {showOrderOptions, isInternalOrderNumberMandatory} = useAppSettings();
    const orderCompleted = useAppSelector(state => state.cart.orderCompleted)
    const internalOrderNumber = useAppSelector(state => state.cart.internalOrderNumber)
    const checkoutCart = useAppSelector(state => state.cart.checkoutCart)
    const isOrderOptionProduct = checkoutCart?.orderOption === OrderOptionEnum.Product || !showOrderOptions;
    const terms = useAppSelector(state => state.cart.terms)
    const comment = useAppSelector(state => state.cart.comment)
    const isEditMode = useAppSelector(state => state.address.isEditMode)
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const [enableBuyButton, setEnableBuyButton] = useState<boolean>(false)
    const isShippingAddressFormOpened = useAppSelector(state => state.address.isShippingAddressFormOpened)

    useEffect(() => {
        dispatch(getCheckoutCart())
        dispatch(getBillingAddress())
    }, [])

    const validateForm = () => {
        let valid = true;

        if ((showOrderOptions && !checkoutCart.orderOption) ||
            (isOrderOptionProduct && !checkoutCart?.shippingMethod) ||
            !checkoutCart.paymentMethod ||
            (isInternalOrderNumberMandatory && !internalOrderNumber) ||
            (isOrderOptionProduct && checkoutCart?.shippingMethod?.isAddressRequired && !checkoutCart.shippingAddress)
        ) {
            valid = false;
        }

        terms.forEach((term) => {
            if (term.isMandatory && !term.checked) {
                valid = false
            }
        });

        return valid;
    }


    useEffect(() => {
        setEnableBuyButton(validateForm())
    }, [checkoutCart, terms, showOrderOptions, isOrderOptionProduct, isInternalOrderNumberMandatory, internalOrderNumber])

    const handleSubmitOrder = () => {
        validateForm() && dispatch(order({
                comment: comment,
                internalOrderNumber: internalOrderNumber
            })
        )
    }

    useEffect(() => {
        orderCompleted && navigate('/order-completed')
    }, [orderCompleted])

    return (
        <Page>
            {!mobileView ?
                <>
                    <Typography
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            textAlign: 'center',
                            fontSize: '7rem',
                            fontWeight: 700,
                            marginY: '38px'
                        }}
                    >
                        {t('checkout')}
                    </Typography>

                    {checkoutCart?.itemsQuantity > 0 ?
                        <CheckOutComponents
                            handleClick={handleSubmitOrder}
                            enableBuyButton={enableBuyButton}
                            checkoutCart={checkoutCart}
                            validate={() => validateForm()}
                        /> : <></>
                    }
                </>
                :
                !isShippingAddressFormOpened ?
                    <>
                        <Typography
                            sx={{
                                color: primaryColor.shoppingCartTitle,
                                textAlign: 'center',
                                fontSize: '2.6rem',
                                fontWeight: 700,
                                marginY: '38px',
                            }}
                        >
                            {t('checkout')}
                        </Typography>

                        {checkoutCart?.itemsQuantity > 0 ?
                            <CheckOutComponents
                                handleClick={handleSubmitOrder}
                                enableBuyButton={enableBuyButton}
                                checkoutCart={checkoutCart}
                                validate={() => validateForm()}
                            />
                            :
                            <></>
                        }
                    </>
                    :
                    <Box sx={{
                        marginBottom: '30px',
                        marginTop: '10px'
                    }}>
                        <Box sx={{display: 'flex', paddingX: '40px', alignItems: 'center', marginBottom: '10px'}}>
                            <Box
                                component='img'
                                src='/images/LeftArrow_Pagination.svg'
                                sx={{
                                    cursor: 'pointer',
                                    marginRight: '40px',
                                }}
                                onClick={() => dispatch(closeShippingAddressForm())}
                            />
                            <Typography sx={{
                                fontSize: '2.6rem',
                                color: primaryColor.shoppingCartTitle,
                                wordBreak: 'break-word'
                            }}>
                                {isEditMode ? t("editShippingAddress") : t("createNewAddress")}</Typography>
                        </Box>

                        <Box
                            sx={{
                                padding: {
                                    xs: '25px 50px',
                                    md: "35px 35px 35px 70px",
                                    lg: "35px 50px 35px 100px"
                                },
                            }}>
                            <ShippingAddressForm/>
                        </Box>
                    </Box>
            }
        </Page>
    )
}
