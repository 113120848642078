import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {FormEvent, useEffect, useState} from "react";
import ShowPasswordInput from "../ShowPasswordInput";
import {openErrorSnack} from "../../../reducers/snackbar";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {changePassword} from "../../../reducers/myAccount";
import {primaryColor} from "../../../theme";

const ChangePassword = () => {
    const {t} = useTranslation('myAccount')
    const [oldPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isFormValid, setIsFormValid] = useState(false)
    const dispatch = useAppDispatch()
    const passwordChangedSuccessfully: boolean = useAppSelector(
        (state) => state.myAccount.passwordChangedSuccessfully
    );

    useEffect(() => {
        if (passwordChangedSuccessfully) {
            setOldPassword("");
            setPassword("");
            setConfirmPassword("");
        }
    }, [passwordChangedSuccessfully, dispatch])

    useEffect(() => {
        setIsFormValid(validateForm(false));
    }, [password, confirmPassword, oldPassword, dispatch])

    const saveNewPassword = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm(true)) {
            dispatch(
                changePassword({
                    oldPassword: oldPassword,
                    newPassword: password,
                })
            )
        }
    }

    const validateForm = (showErrorMessage: boolean) => {
        if (password === "" || confirmPassword === "" || oldPassword === "") {
            showErrorMessage && dispatch(openErrorSnack({message: t('emptyPasswordMessage')}))
            return false;
        } else if (password !== confirmPassword) {
            showErrorMessage && dispatch(openErrorSnack({message: t('passwordNotMatch')}))
            return false;
        } else if (password.length < 8) {
            showErrorMessage && dispatch(openErrorSnack({message: t('passwordTooShort')}))
            return false;
        }

        return true;
    }

    return (
        <Box
            component={"form"}
            sx={{
                padding: {
                    xs: '44px 55px',
                    lg: '44px 10% 42px 104px'
                },
                marginBottom: '25px',
                backgroundColor: primaryColor.myAccountDataBackground,
            }}
            onSubmit={saveNewPassword}
        >
            <Box
                sx={{
                    width: {xs: '100%', lg: '60%'}
                }}

            >
                <ShowPasswordInput
                    value={oldPassword}
                    boxStyle={{marginBottom: '28px'}}
                    handleChange={(value) => setOldPassword(value)}
                    text={t('oldPassword')}
                />
                <ShowPasswordInput
                    value={password}
                    boxStyle={{marginBottom: '28px'}}
                    handleChange={(value) => setPassword(value)}
                    text={t('password')}
                    showHelperText={true}

                />
                <ShowPasswordInput
                    value={confirmPassword}
                    handleChange={(value) => setConfirmPassword(value)}
                    text={t('confirmPassword')}
                    showHelperText={false}

                />
            </Box>
            <Box
                sx={{
                    textAlign: {
                        xs: "center",
                        lg: "right"
                    },
                }}
            >
                <Button
                    type={"submit"}
                    sx={{
                        mt: {
                            xs: '80px',
                            lg: 'unset'
                        }
                    }}
                    variant="contained"
                    disabled={!isFormValid}
                >
                    {t('saveNewPassword')}
                </Button>
            </Box>
        </Box>

    )
}

export default (ChangePassword)