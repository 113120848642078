import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../hook";
import theme, {primaryColor} from '../../theme';
import {openErrorSnack, openInfoSnack, openSuccessSnack} from "../../reducers/snackbar";
import toast, {ToastBar, Toaster} from "react-hot-toast";
import {Typography, useMediaQuery} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

enum MessageType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = "info",
    WARNING = "warning"
}

export const useMessageSnackBar = () => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));

    const showMessage = (message: string, type: MessageType) => {
        let color;
        switch (type) {
            case MessageType.ERROR:
                color = primaryColor.errorMessageBgColor;
                break;
            case MessageType.INFO:
                color = primaryColor.infoMessageBgColor;
                break;
            // case ErrorType.WARNING:
            //     color = primaryColor.successMessageBgColor;
            //     break;
            default:
                color = primaryColor.successMessageBgColor;
        }

        let maxWidth = "33.33%";
        if (tabletView) {
            maxWidth = "60%"
        }
        if (mobileView) {
            maxWidth = "90%"
        }

        toast(
            (t) =>
                <Typography>
                    {message}
                </Typography>
            ,
            {
                style: {
                    backgroundColor: color,
                    maxWidth: maxWidth
                }
            }
        );
    }

    const showSuccessMessage = (message: string) => {
        showMessage(message, MessageType.SUCCESS);
    }

    const showErrorMessage = (message: string) => {
        showMessage(message, MessageType.ERROR);
    }

    const showInfoMessage = (message: string) => {
        showMessage(message, MessageType.INFO);
    }

    return {
        showSuccessMessage,
        showErrorMessage,
        showInfoMessage
    }
}

export default function MessageSnackbar() {
    const dispatch = useAppDispatch();
    const errors = useAppSelector(state => state.snackbar.errorMessages);
    const successMessage = useAppSelector(state => state.snackbar.successMessage);
    const infoMessage = useAppSelector(state => state.snackbar.infoMessage);
    const {showSuccessMessage, showErrorMessage, showInfoMessage} = useMessageSnackBar();

    useEffect(() => {
        if (errors.length) {
            errors.forEach(error => showErrorMessage(error))
            dispatch(openErrorSnack({message: ""}))
        }
    }, [dispatch, errors])

    useEffect(() => {
        if (successMessage) {
            showSuccessMessage(successMessage);
            dispatch(openSuccessSnack(""))
        }
    }, [dispatch, successMessage]);

    useEffect(() => {
        if (infoMessage) {
            showInfoMessage(infoMessage);
            dispatch(openInfoSnack(""))
        }
    }, [dispatch, infoMessage]);

    return (
        <Toaster
            position="bottom-center"
            toastOptions={{
                duration: 5000,
                style: {
                    border: '0 none',
                    width: '100%',
                    padding: '20px 0',
                    borderRadius: 'unset',
                    color: primaryColor.baseTextColor
                }
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({icon, message}) => (
                        <>
                            {/*{icon}*/}
                            {message}
                            {t.type !== 'loading' && (
                                <CloseIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        marginTop: '4px',
                                        marginRight: '10px',
                                        cursor: "pointer"
                                    }}
                                    onClick={() => toast.dismiss(t.id)}
                                />
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    )
}