import {Box} from "@mui/material"
import React from "react";
import {primaryColor} from "../../theme";


const Description = (props: { description: string }) => {
    const {description} = props;
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box
                sx={{color: primaryColor.pdpLongDescription, maxWidth: '600px'}}
                dangerouslySetInnerHTML={{
                    __html:
                    description
                }}
            />
        </Box>
    )
}
export default (Description)
