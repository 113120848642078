import {SxProps} from "@mui/material";
import {defaultFonts, primaryColor} from "../../../theme";
import {StyledTypography} from "../StyledComponents";
import ArrowButton from "../ArrowButton";
import Link from '@mui/material/Link';

interface NavTabBackProps {
    sx?: SxProps;
    title?: string;
    linkTo: string;

}


const NavTabBack = (props: NavTabBackProps) => {
    const {sx, title, linkTo} = props;


    return (
        <Link href={linkTo} sx={{
            height: '74px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 0,
            textDecoration: 'unset',
            ...sx
        }}>
            <ArrowButton
                direction={"left"}
                width={34}
                sx={{
                    padding: 0,
                    color: primaryColor.accountTextColor,
                }}
            />
            {title && <StyledTypography
                sx={{
                    textTransform: "uppercase",
                    marginLeft: "30px",
                    fontWeight: 400,
                    fontSize: defaultFonts,
                    color: primaryColor.accountTextColor,
                }}
            >
                {title}
            </StyledTypography>}

        </Link>
    );
};

export default NavTabBack;