import AccountIcon from "../../../../svg/AccountIcon";
import {Box, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

interface MenuAccountIconProps {
    isActive?: boolean;
    tooltipTitle?: string
}

const MenuAccountIcon = (props: MenuAccountIconProps) => {
    const {isActive = false, tooltipTitle = null} = props;
    const {t} = useTranslation("common");

    return (
        <Tooltip title={tooltipTitle || t("account")}>
            <Box component={"span"}>
                <AccountIcon
                    isActive={isActive}
                    sx={{
                        height: {xs: '20px', md: '26px', lg: '28px'},
                        width: 'auto',
                        color: "transparent"
                    }}
                />
            </Box>
        </Tooltip>
    )
}

export default MenuAccountIcon;