import {Container} from "@mui/material";
import Logo from "../../../components/common/header/logo";

export default function DesktopView() {
    return (
        <Container maxWidth={'2xl'} sx={{
            paddingY: {md: 2},
        }}>

            <Logo mobileView={false}/>
        </Container>
    )
}