export class ViewerModel {

    private viewer: Autodesk.Viewing.GuiViewer3D | null = null
    private readonly language: string;

    constructor(language: string) {
        this.language = language
    }

    private setViewer = (viewer: Autodesk.Viewing.GuiViewer3D) => {
        this.viewer = viewer;
    }

    destroy = () => {
        this.viewer?.finish();
        this.viewer = null;
        typeof Autodesk !== "undefined" && Autodesk?.Viewing?.shutdown();
    }

    initializeViewer = async (
        urn: string,
        viewerContainer: HTMLDivElement,
        modelKeyProperty: string,
        onClickModel: (clickedNode: ClickedNode) => void,
    ) => {
        await this.loadViewerScript();

        const viewerOptions = {
            env: 'AutodeskProduction2',
            api: 'streamingV2_EU',
            language: this.language
        };

        Autodesk.Viewing.Initializer(viewerOptions, () => {
            const viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainer, {extensions: ['Autodesk.DocumentBrowser']});
            viewer.start();

            viewer.loadModel(
                urn,
                {},
                (model: Autodesk.Viewing.Model) => {
                    viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (event: Autodesk.Viewing.ViewerEvent) => {
                        const currSelection = viewer.getSelection();
                        findKeyProperty(currSelection);
                    });

                    const findKeyProperty = (dbIds: number[]) => {
                        dbIds.forEach((dbId: number) => {


                            viewer.getProperties(dbId, (childResult: Autodesk.Viewing.PropertyResult) => {
                                const propertyInChild: Autodesk.Viewing.Property | undefined = childResult.properties.find(
                                    (prop) => prop.attributeName === modelKeyProperty
                                );

                                if (propertyInChild) {
                                    // Found property in child, onClickModel next return
                                    onClickModel({
                                        attributeValue: propertyInChild.displayValue as string,
                                    });
                                } else {
                                    // find in the  parent node
                                    const instanceTree = viewer.model.getData().instanceTree;
                                    const parentNodeId = instanceTree.getNodeParentId(dbId);

                                    if (parentNodeId !== null) {
                                        viewer.getProperties(parentNodeId, (parentResult: Autodesk.Viewing.PropertyResult) => {
                                            const propertyInParent = parentResult.properties.find(
                                                (property) => property.attributeName === modelKeyProperty
                                            );

                                            if (propertyInParent) {
                                                // Found property in the parent, call onClickModel
                                                onClickModel({
                                                    attributeValue: propertyInParent.displayValue as string,
                                                });
                                            }


                                        });
                                    }


                                }
                            })


                        });
                    };
                    //  // Make the toolbar responsive using CSS media queries
                    //  const toolbarContainer = viewerContainer.getElementsByClassName('adsk-control')[0] as HTMLElement;

                    //  const mediaQuery = window.matchMedia('(max-width: 500px)');

                    //  const handleResponsiveToolbar = () => {
                    //    if (toolbarContainer && mediaQuery.matches) {
                    //      // Apply responsive styles for smaller screens
                    //      toolbarContainer.style.display = 'none';
                    //     //  toolbarContainer.style.flexWrap = 'wrap';
                    //    } else {
                    //      // Reset styles for larger screens
                    //      toolbarContainer.style.display = 'inline';
                    //      toolbarContainer.style.width = '';
                    //      toolbarContainer.style.padding = '';
                    //     //  toolbarContainer.style.flexWrap = '';
                    //    }
                    //  };

                    //  // Initialize responsive styles
                    //  handleResponsiveToolbar();

                    //  // Attach event listener to handle changes in media query
                    //  mediaQuery.addEventListener('change', handleResponsiveToolbar);
                },
                (errorCode: number, errorMessage: string, messages: any[]) => {
                    console.log(`Error loading document: ${errorCode} ${errorMessage}`);
                }
            );
            this.setViewer(viewer);
        });
    };
    private loadViewerScript = () => {
        return new Promise<void>((resolve, reject) => {
            if (window.Autodesk && window.Autodesk.Viewing) {
                resolve();
                return;
            }

            // Load viewer style
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';
            document.head.appendChild(link);

            // Load viewer script
            const script = document.createElement('script');
            script.src = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
            script.async = true;

            script.addEventListener('load', () => {
                resolve();
            });

            script.addEventListener('error', (error) => {
                reject(error);
            });

            document.head.appendChild(script);
        });
    };
}

export interface ClickedNode {
    event?: MouseEvent;
    attributeValue: string;
    //directChildren: ClickedItemProperty[]
}