import {SvgIcon} from "@mui/material";
import {IconComponentProps} from "../../../interfaces/general";
import {primaryColor} from "../../../theme";

const ProductSchemaIcon = (props: IconComponentProps) => {
    const {isActive = false, sx = {}} = props;
    const fillColor = isActive ? primaryColor.orangeHoverColor : "white";

    return (
        <SvgIcon
            width="768"
            height="768"
            viewBox="0 0 768 768"
            fill="aqua"
            sx={sx}
        >

            <rect x="-76.8" width="921.6" fill="none" y="-76.799999" height="921.599993" fillOpacity="1"></rect>
            <path fill={fillColor}
                  d="M 368.738281 371.285156 C 324.105469 371.285156 287.789062 334.96875 287.789062 290.335938 L 287.789062 239.738281 L 308.027344 239.738281 L 308.027344 290.335938 C 308.027344 323.8125 335.261719 351.046875 368.738281 351.046875 C 402.210938 351.046875 429.449219 323.8125 429.449219 290.335938 L 429.449219 239.738281 L 449.683594 239.738281 L 449.683594 290.335938 C 449.683594 334.96875 413.367188 371.285156 368.738281 371.285156 Z M 368.738281 371.285156 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 318.140625 348.46875 L 338.375 348.46875 L 338.375 411.589844 L 318.140625 411.589844 Z M 318.140625 348.46875 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 399.085938 348.46875 L 419.324219 348.46875 L 419.324219 411.601562 L 399.085938 411.601562 Z M 399.085938 348.46875 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 358.613281 614.484375 C 351.53125 614.484375 344.660156 614.367188 338.019531 614.125 L 338.734375 593.910156 C 345.132812 594.132812 351.773438 594.246094 358.613281 594.246094 Z M 380.40625 614.125 L 379.691406 593.910156 C 438.5 591.871094 477.667969 581.175781 493.347656 572.632812 L 503.027344 590.394531 C 484.558594 600.457031 442.65625 611.96875 380.398438 614.125 Z M 380.40625 614.125 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 591.632812 451.167969 L 620.664062 350.585938 L 640.105469 356.195312 L 611.074219 456.78125 Z M 591.632812 451.167969 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <g clipPath="url(#c6dce87812)">
                <path fill={fillColor}
                      d="M 378.851562 654.609375 C 292.957031 654.609375 200.359375 636.824219 187.121094 597.097656 L 184.027344 586.386719 L 203.472656 580.796875 L 206.441406 591.101562 C 213.324219 611.707031 281.277344 634.375 378.859375 634.375 C 476.445312 634.375 544.398438 611.707031 551.40625 590.703125 L 554.246094 580.789062 L 573.695312 586.398438 L 570.71875 596.699219 C 557.351562 636.816406 464.742188 654.609375 378.851562 654.609375 Z M 378.851562 654.609375 "
                      fillOpacity="1" fillRule="nonzero"></path>
            </g>
            <path fill={fillColor}
                  d="M 117.601562 356.191406 L 137.046875 350.585938 L 166.066406 451.191406 L 146.621094 456.800781 Z M 117.601562 356.191406 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 217.417969 409.105469 L 189.089844 310.574219 L 176.460938 310.574219 L 176.460938 290.335938 L 204.328125 290.335938 L 236.867188 403.507812 Z M 217.417969 409.105469 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 378.851562 421.882812 C 243.546875 421.882812 115.761719 421.882812 115.761719 340.933594 C 115.761719 313.035156 138.460938 290.335938 166.359375 290.335938 L 186.59375 290.335938 L 186.59375 310.574219 L 166.359375 310.574219 C 149.613281 310.574219 135.996094 324.191406 135.996094 340.933594 C 135.996094 401.644531 259.460938 401.644531 378.851562 401.644531 C 498.242188 401.644531 621.703125 401.644531 621.703125 340.933594 C 621.703125 324.191406 608.085938 310.574219 591.34375 310.574219 L 571.105469 310.574219 L 571.105469 290.335938 L 591.34375 290.335938 C 619.242188 290.335938 641.941406 313.035156 641.941406 340.933594 C 641.941406 421.882812 514.152344 421.882812 378.851562 421.882812 Z M 378.851562 421.882812 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 409.210938 462.355469 C 360.972656 462.355469 311.089844 462.355469 269.046875 458.230469 L 271.027344 438.089844 C 312.078125 442.109375 361.453125 442.109375 409.210938 442.109375 Z M 409.210938 462.355469 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 429.449219 442.117188 L 449.683594 442.117188 L 449.683594 462.355469 L 429.449219 462.355469 Z M 429.449219 442.117188 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 540.304688 409.085938 L 520.835938 403.527344 L 553.175781 290.335938 L 581.261719 290.335938 L 581.261719 310.574219 L 568.433594 310.574219 Z M 540.304688 409.085938 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <g clipPath="url(#c9f01e4ef5)">
                <path fill={fillColor}
                      d="M 146.238281 98.082031 L 166.472656 98.082031 L 166.472656 138.554688 L 146.238281 138.554688 Z M 146.238281 98.082031 "
                      fillOpacity="1" fillRule="nonzero"></path>
            </g>
            <path fill={fillColor}
                  d="M 146.238281 179.027344 L 166.472656 179.027344 L 166.472656 219.503906 L 146.238281 219.503906 Z M 146.238281 179.027344 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 176.597656 148.667969 L 206.957031 148.667969 L 206.957031 168.90625 L 176.597656 168.90625 Z M 176.597656 148.667969 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <g clipPath="url(#d2d5eea619)">
                <path fill={fillColor}
                      d="M 105.761719 148.667969 L 136.125 148.667969 L 136.125 168.90625 L 105.761719 168.90625 Z M 105.761719 148.667969 "
                      fillOpacity="1" fillRule="nonzero"></path>
            </g>
            <path fill={fillColor}
                  d="M 601.464844 138.554688 L 621.703125 138.554688 L 621.703125 179.027344 L 601.464844 179.027344 Z M 601.464844 138.554688 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 601.464844 219.503906 L 621.703125 219.503906 L 621.703125 259.976562 L 601.464844 259.976562 Z M 601.464844 219.503906 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <g clipPath="url(#821312ed6a)">
                <path fill={fillColor}
                      d="M 631.828125 189.140625 L 662.1875 189.140625 L 662.1875 209.378906 L 631.828125 209.378906 Z M 631.828125 189.140625 "
                      fillOpacity="1" fillRule="nonzero"></path>
            </g>
            <path fill={fillColor}
                  d="M 560.992188 189.140625 L 591.355469 189.140625 L 591.355469 209.378906 L 560.992188 209.378906 Z M 560.992188 189.140625 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 459.796875 249.863281 L 277.65625 249.863281 C 266.5 249.863281 257.417969 240.78125 257.417969 229.625 L 257.417969 209.390625 C 257.417969 198.234375 266.5 189.152344 277.65625 189.152344 L 459.796875 189.152344 C 470.953125 189.152344 480.035156 198.234375 480.035156 209.390625 L 480.035156 229.625 C 480.035156 240.78125 470.953125 249.863281 459.796875 249.863281 Z M 277.65625 209.390625 L 277.65625 229.625 L 459.820312 229.625 L 459.796875 209.390625 Z M 277.65625 209.390625 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 459.796875 199.265625 L 439.5625 199.265625 C 439.5625 160.210938 407.78125 128.429688 368.726562 128.429688 C 329.671875 128.429688 297.890625 160.210938 297.890625 199.265625 L 277.65625 199.265625 C 277.65625 149.046875 318.507812 108.195312 368.726562 108.195312 C 418.945312 108.195312 459.796875 149.046875 459.796875 199.265625 Z M 459.796875 199.265625 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 328.253906 124.199219 L 348.488281 124.199219 L 348.488281 199.265625 L 328.253906 199.265625 Z M 328.253906 124.199219 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 388.972656 124.199219 L 409.210938 124.199219 L 409.210938 168.90625 L 388.972656 168.90625 Z M 388.972656 124.199219 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 196.707031 593.902344 C 157.601562 593.902344 125.875 526.550781 125.875 482.59375 C 125.875 460.273438 144.027344 442.117188 166.347656 442.117188 C 216.113281 442.117188 237.183594 521.738281 237.183594 553.425781 C 237.183594 575.746094 219.027344 593.902344 196.707031 593.902344 Z M 166.347656 462.355469 C 155.191406 462.355469 146.109375 471.4375 146.109375 482.59375 C 146.109375 522.59375 174.480469 573.664062 196.707031 573.664062 C 207.863281 573.664062 216.945312 564.582031 216.945312 553.425781 C 216.945312 525.613281 197.832031 462.355469 166.347656 462.355469 Z M 166.347656 462.355469 "
                  fillOpacity="1" fillRule="nonzero"></path>
            <path fill={fillColor}
                  d="M 560.992188 593.902344 C 538.671875 593.902344 520.519531 575.746094 520.519531 553.425781 C 520.519531 534.546875 539.808594 442.117188 591.355469 442.117188 C 613.675781 442.117188 631.828125 460.273438 631.828125 482.59375 C 631.828125 526.550781 600.097656 593.902344 560.992188 593.902344 Z M 591.355469 462.355469 C 560.351562 462.355469 540.757812 535.199219 540.757812 553.425781 C 540.757812 564.582031 549.839844 573.664062 560.992188 573.664062 C 583.21875 573.664062 611.589844 522.59375 611.589844 482.59375 C 611.589844 471.4375 602.507812 462.355469 591.355469 462.355469 Z M 591.355469 462.355469 "
                  fillOpacity="1" fillRule="nonzero"></path>


        </SvgIcon>
    );
};

export default ProductSchemaIcon;
