import {Box} from "@mui/material"
import * as React from "react";
import {Document} from "../../interfaces/backend";
import {getDocument} from "../../reducers/productdetails";
import {useAppDispatch} from "../../hook";
import {primaryColor} from "../../theme";

const Documents = (props: { documents: Array<Document> }) => {
    const {documents} = props;
    const dispatch = useAppDispatch()

    return (
        <Box sx={{
            minHeight: "235px",
            margin: 'auto',
            maxWidth: '600px'
        }}>
            {documents.map((doc, index) =>
                <Box
                    onClick={() =>
                        dispatch(getDocument({url: doc.url}))
                    }
                    key={index}
                    sx={{
                        cursor: 'pointer',
                        marginBottom: "15px",
                        float: "left",
                        clear: "both"
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Box
                            component={'img'}
                            src={'/images/icons/icon-pdf-document.svg'}
                            sx={{
                                marginRight: '11px',
                                maxHeight: '34px'
                            }}
                        />
                        <Box
                            sx={{
                                color: primaryColor.textHeader,
                            }}
                        >
                            {doc.title}
                        </Box>
                    </Box>
                </Box>)}
        </Box>
    )
}

export default (Documents)