import React, {ReactElement, useState} from "react";
import {Box, ClickAwayListener, Tooltip, useMediaQuery} from "@mui/material";
import TooltipIcon from "./TooltipIcon";
import theme from "../../../theme";

interface InfoToolTipProps {
    toolTipContent: ReactElement;
}

// const InfoToolTip = forwardRef<HTMLDivElement, InfoToolTipProps>((props, ref) 
// => {
const InfoToolTip = (props: InfoToolTipProps) => {
    const {toolTipContent} = props;
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const [open, setOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (mobileView) {
            setOpen((prev) => !prev);
        }
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Tooltip
                onClick={handleClick}
                open={mobileView ? open : undefined}
                onClose={!mobileView ? () => setOpen(false) : undefined}
                title={toolTipContent}
                // mobileView opens onClick and onHover.
                // desktop opens only onHover

            >
                <Box
                    sx={{
                        display: 'inline',
                        marginX: '5px',
                    }}

                >
                    <TooltipIcon/>
                </Box>
            </Tooltip>
        </ClickAwayListener>

    );
}
export default InfoToolTip;
