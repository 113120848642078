import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {Box} from "@mui/material";
import {order} from "../../../reducers/cart";
import AdditionalInformation from "../../Checkout/AdditionalInfo";
import Summary from ".";
import {useNavigate} from "react-router";

const InquirySummary = () => {
    const {t} = useTranslation("checkoutPage");
    const orderCompleted = useAppSelector(state => state.cart.orderCompleted)
    const navigate = useNavigate();

    const {comment, internalOrderNumber} = useAppSelector(
        (state) => state.cart
    );

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(
            order({
                comment: comment,
                internalOrderNumber: internalOrderNumber,
            })
        );
    };


    const detailsContent = (
        <Box
            sx={{
                "& .custom-styled-box": {
                    width: "100%",
                },
            }}
        >
            <AdditionalInformation/>
        </Box>
    );

    useEffect(() => {
        orderCompleted && navigate("/order-completed");
    }, [orderCompleted]);

    return (
        <Summary
            summaryDetailsContent={detailsContent}
            handleClick={handleClick}
            confirmButtonText={t("submitInquiry")}
        />
    );
};

export default InquirySummary;
