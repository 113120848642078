import {useMediaQuery} from "@mui/material";
import ResetPasswordForm from "../components/login/resetPasswordForm";
import UserAuthForm from "../components/login/UserAuthForm";
import {PasswordActionType} from "../interfaces/backend";
import LoginPageContent from "./LoginPageContent";
import {useTranslation} from "react-i18next";
import theme from "../theme";

interface ResetPasswordProps {
    passwordAction: string;
}

const ResetPassword = (props: ResetPasswordProps) => {
    const {passwordAction} = props;
    const {t} = useTranslation('startPage')
    const title = passwordAction === PasswordActionType.New ? t('passwordNewTitle') : t('passwordResetTitle');
    const mobileView = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <LoginPageContent showArticles={!mobileView}>
            <UserAuthForm
                formContent={<ResetPasswordForm/>}
                pageTitle={title}
                formTitle={t("pleaseNewPass")}
            />

        </LoginPageContent>
    );
};

export default ResetPassword;
