import {IOSSwitch} from "./style";
import * as React from "react";

type IOSwitchCompProps = {
    checked: boolean,
    onChange: () => void
}

export default function IOSwitchComp(props: IOSwitchCompProps) {
    const {checked, onChange} = props

    return (
        <IOSSwitch
            checked={checked}
            onChange={() => {
                onChange()
            }}
        />
    )
}