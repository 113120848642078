import {get} from "../utils/httpUtil";
import {callHttp} from '../utils/api';
import {
    MACHINE,
    MYMACHINE,
    MYMACHINES,
    MYMACHINESSEARCH,
    STANDARDMACHINES,
    STANDARDMACHINESSEARCH
} from '../constants/api';
import {all, put, takeEvery} from 'redux-saga/effects';
import {openErrorSnack} from "../reducers/snackbar";
import {
    resetSelectedMachine,
    setMachine,
    setMachinesSearch,
    setMyMachines,
    setStandardMachines
} from "../reducers/machine";
import {Machine, MachinesSearch, StandardMachine} from "../interfaces/backend";
import {getMachinesSearchAction, getMyMachineAction} from "../interfaces/actions";
import {resetSparePartTree} from "../reducers/sparePart";


export function* getMyMachines() {
    try {
        const machines: Array<Machine> = yield callHttp(get, MYMACHINES);
        yield put(setMyMachines(machines));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getStandardMachines() {
    try {
        const standardMachines: Array<StandardMachine> = yield callHttp(get, STANDARDMACHINES);
        yield put(setStandardMachines(standardMachines));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getMyMachinesSearch(action: getMachinesSearchAction) {
    try {
        const machines: MachinesSearch = yield callHttp(get, MYMACHINESSEARCH(action.payload));
        yield put(setMachinesSearch(machines));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getStandardMachinesSearch(action: getMachinesSearchAction) {
    try {
        const standardMachines: MachinesSearch = yield callHttp(get, STANDARDMACHINESSEARCH(action.payload));
        yield put(setMachinesSearch(standardMachines));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getMyMachine(action: getMyMachineAction) {
    const {alias, withoutSpareParts} = action.payload;

    yield put(resetSelectedMachine());
    yield put(resetSparePartTree());

    try {
        const details: Machine = yield callHttp(get, MYMACHINE(alias, {withoutSpareParts}));
        yield put(setMachine(details));
    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}

export function* getStandardMachine(action: getMyMachineAction) {
    const {alias, withoutSpareParts} = action.payload;
    yield put(resetSelectedMachine());
    yield put(resetSparePartTree());

    try {
        const details: Machine = yield callHttp(get, MACHINE(alias, {withoutSpareParts}));
        yield put(setMachine(details));

    } catch (err: any) {
        yield put(openErrorSnack(err));
    }
}


export default function* machineRootSaga() {
    yield all([
        takeEvery('machine/getMyMachines', getMyMachines),
        takeEvery('machine/getMyMachine', getMyMachine),
        takeEvery('machine/getStandardMachine', getStandardMachine),
        takeEvery('machine/getMyMachinesSearch', getMyMachinesSearch),
        takeEvery('machine/getStandardMachinesSearch', getStandardMachinesSearch),
    ]);
}

 
