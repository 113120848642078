import {Box, Button, Grid} from "@mui/material";
import OrdersAddress from "../../common/myOrders/OrdersAddress";
import {primaryColor} from "../../../../theme";
import React from "react";
import MyOrderFooter from "../../common/myOrders/MyOrderFooter";
import {Order} from "../../../../interfaces/backend";
import OrderSummary from "../../common/myOrders/OrderSummary";
import MyOrderItemTablet from "../../common/myOrders/mobile/MyOrderItemTablet";
import OrdersTableTablet from "../../common/myOrders/mobile/OrdersTableTablet";
import {checkIsProductNull, reOrder} from "../../../../components/myData/MyOrders/orderUtils";
import {addCartItem, addCartMultipleItems} from "../../../../reducers/cart";
import {countTotalItems, setOperation} from "../../../../utils/productFunctions";
import {ItemsCount} from "../../common/myOrders/MyOrderItem";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSettings} from "../../../../hook";
import {OperationList} from "../../../../interfaces/general";
import NavTabBack from "../../../../components/common/Tabs/NavTabBack";

export interface OrderDetailsTabletProp {
    order: Order,
}

const MyOrderDetailsTablet = (props: OrderDetailsTabletProp) => {
    const {order} = props
    const {t} = useTranslation("myAccount");
    const dispatch = useAppDispatch();
    const {shopUsesPrices} = useAppSettings();

    function getOperation(action: keyof OperationList): string {
        return setOperation(shopUsesPrices, action);
    }

    const confirmOrder = (order: Order) => {
        const items = order.items;
        const productsToAdd = reOrder(items);

        if (productsToAdd.length > 0) {
            dispatch(addCartMultipleItems({items: productsToAdd}));
        } else {
            dispatch(addCartItem(items))
        }
    };

    const isProductNull = checkIsProductNull(order)


    return (
        <Box sx={{
            backgroundColor: primaryColor.ordersListBackgroundColor,
            color: primaryColor.accountTextColor,
            marginBottom: '25px',
            paddingTop: '10px'
        }}>
            <NavTabBack
                title={t('backToOrdersList')}
                linkTo={'/my-account/my-orders'}
                sx={{
                    marginLeft: "14px",
                }}/>

            <MyOrderItemTablet order={order}/>
            {<Box padding={'0px 0px 30px 30px'}><ItemsCount length={countTotalItems(order.items)}/></Box>}

            <Box sx={{marginX: {xs: '30px', md: '20px'}}}>

                <Grid display={'flex'} container>
                    {order.billingAddress && <Grid item xs={12} md={6}>
                        <OrdersAddress addressType={'billingAddress'} address={order.billingAddress}/>
                    </Grid>}
                    {order.shippingAddress && <Grid item mt={{xs: '36px', md: '0px'}} xs={12} md={6}>
                        <OrdersAddress addressType={'shippingAddress'}
                                       address={order.shippingAddress}/>
                    </Grid>}
                </Grid>
            </Box>
            <Box mt={'70px'}>
                <OrdersTableTablet order={order}/>
            </Box>

            <Box sx={{marginX: {xs: '30px', md: '20px'}}}>

                {shopUsesPrices ? <OrderSummary order={order}/> : null}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}> <Button
                    sx={{
                        mt: '80px',
                        width: '60%'
                    }}
                    disabled={isProductNull}
                    variant="contained"
                    onClick={() => {
                        confirmOrder(order)
                    }}
                >{t(getOperation('RepeatOperation'))}</Button></Box>
                <Box pb={'53px'} display={'flex'}>
                    <MyOrderFooter discounts={order.discounts} paymentMethod={order.paymentMethod}
                                   internalOrderNumber={order.internalOrderNumber} documents={order.documents}
                                   comment={order.comment}/>
                </Box>
            </Box>
        </Box>
    )
}

export default (MyOrderDetailsTablet)