import {Box, SxProps, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {defaultFonts, primaryColor,} from "../../theme";
import {formatMoney} from "../../utils/localization";


interface PriceRenderedProps {
    productPrice: number;
    productDiscountPrice: number;
    styles?: SxProps
}

const PriceRendered = (props: PriceRenderedProps) => {
    const {productPrice, productDiscountPrice, styles} = props;
    const {t} = useTranslation('productPage')
    return (
        <>
            {productDiscountPrice ? (
                <Box>
                    <Typography
                        sx={{
                            textDecoration: "line-through",
                            textDecorationColor: primaryColor.lineThroughPrice,
                            textDecorationThickness: "1px",
                            textDecorationTrim: "auto",
                            color: primaryColor.lineThroughPrice,
                            opacity: 0.3,
                            fontSize: defaultFonts,
                            ...styles,
                        }}
                    >
                        {formatMoney(productPrice)}
                    </Typography>

                    <Typography sx={{
                        color: primaryColor.discountCheckoutPage,
                        fontSize: defaultFonts,
                        ...styles,

                    }}>
                        {formatMoney(productDiscountPrice)}
                    </Typography>
                </Box>
            ) : (
                <Typography sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "normal",
                    maxWidth: "100%",
                    fontSize: defaultFonts,
                    ...styles,
                }}>
                    {productPrice ? formatMoney(productPrice) : t('priceOnInquiry')}
                </Typography>
            )}
        </>
    );
};

export default PriceRendered;
