import {Box, Button, Grid} from "@mui/material";
import MyOrderItem from "../common/myOrders/MyOrderItem";
import OrdersAddress from "../common/myOrders/OrdersAddress";
import {primaryColor} from "../../../theme";
import React from "react";
import OrdersTableDesktop from "../common/myOrders/OrdersTableDesktop";
import {useTranslation} from "react-i18next";
import MyOrderFooter from "../common/myOrders/MyOrderFooter";
import {useAppDispatch, useAppSettings} from "../../../hook";
import {Order} from "../../../interfaces/backend";
import OrderSummary from "../common/myOrders/OrderSummary";
import {addCartItem, addCartMultipleItems} from "../../../reducers/cart";
import {checkIsProductNull, reOrder} from "../../../components/myData/MyOrders/orderUtils";
import {OperationList} from "../../../interfaces/general";
import {setOperation} from "../../../utils/productFunctions";
import NavTabBack from "../../../components/common/Tabs/NavTabBack";

export interface OrderDetailsProp {
    order: Order,
}

const MyOrderDetails = (props: OrderDetailsProp) => {
    const {order} = props
    const {t} = useTranslation('myAccount')
    const dispatch = useAppDispatch()


    const confirmOrder = (order: Order) => {
        const items = order.items;
        const productsToAdd = reOrder(items);

        if (productsToAdd.length > 0) {
            dispatch(addCartMultipleItems({items: productsToAdd}));
        } else {
            dispatch(addCartItem(items))
        }
    };

    const {shopUsesPrices} = useAppSettings();
    const isProductNull = checkIsProductNull(order)

    function getOperation(action: keyof OperationList): string {
        return setOperation(shopUsesPrices, action);
    }

    return (
        <Box sx={{
            position: 'relative',
            backgroundColor: primaryColor.ordersListBackgroundColor,
            color: primaryColor.accountTextColor,
        }}>
            <NavTabBack
                sx={{
                    position: 'absolute',
                    left: {lg: 15, xl: 30},
                    top: 15,
                }}
                linkTo={'/my-account/my-orders'}
            />

            <Box sx={{marginX: {lg: '7%', xl: '10%'}}}>
                <MyOrderItem order={order} sx={{paddingX: '0'}}/>

                <Grid sx={{display: 'flex'}} container>
                    {order.billingAddress && <Grid item xs={6}>

                        <OrdersAddress addressType={'billingAddress'} address={order.billingAddress}/>
                    </Grid>}
                    {order.shippingAddress && <Grid item xs={6}>
                        <OrdersAddress addressType={'shippingAddress'}
                                       address={order.shippingAddress}/>
                    </Grid>}
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end'
                }}> <Button
                    sx={{
                        mt: '80px'
                    }}
                    disabled={isProductNull}
                    variant="contained"
                    onClick={() => {
                        confirmOrder(order)
                    }}>
                    {t(getOperation('RepeatOperation'))}
                </Button>
                </Box>
                <Box mt={'70px'}>
                    <OrdersTableDesktop order={order}/>
                </Box>

                {shopUsesPrices ? <OrderSummary order={order}/> : null}
                <Box sx={{display: 'flex', paddingBottom: '53px'}}>
                    <MyOrderFooter discounts={order.discounts} paymentMethod={order.paymentMethod}
                                   internalOrderNumber={order.internalOrderNumber} documents={order.documents}
                                   comment={order.comment}/>
                </Box>
            </Box>
        </Box>
    )
}

export default (MyOrderDetails)
