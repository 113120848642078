interface OrderData {
    code: string;
    quantity: number;
}

export const fetchOrderData = (data: string[][]): OrderData[] => {
    if (data[0][0] === 'SEP=') {
        data.shift();
    }
    data.shift();
    return data.map((item) => ({
        code: item[0],
        quantity: parseInt(item[1], 10),
    }));
};

export const csvReader = <T>(
    files: FileList | null,
    onLoad: (load: { products: T[]; isCSVuploaded: boolean }) => void
) => {
    if (!files?.length) {
        return;
    }

    const csv = require('csvtojson');

    const reader = new FileReader();
    reader.onload = (evt: ProgressEvent<FileReader>) => {
        const fileContent = evt.target?.result as string;
        csv({
            output: 'csv',
            noheader: true,
            delimiter: [',', ';'],
        })
            .fromString(fileContent)
            .then((jsonObj: string[][]) => {
                const products = fetchOrderData(jsonObj) as T[];
                const load = {
                    products,
                    isCSVuploaded: true,
                };
                onLoad(load);
            });
    };
    reader.readAsText(files[0]);
};
	