import {Box, Divider, SxProps} from "@mui/material";
import {primaryColor} from "../../../../theme";
import {TakeFirst2DImage} from "../../../../utils/productFunctions";
import DiscountInfo from "../DiscountInfo";
import ProductImage from "../ProductImage";
import ProductItemDetails, {ItemProps} from "./ProductItemDetails";
import MobileOrderItemPriceRow from "../../../myData/MyOrders/MobileOrderItemPriceRow";


interface CheckoutProductMobileProps extends ItemProps {
    useDivider?: boolean;
    sx?: SxProps;
}

const CheckoutProductMobile = (props: CheckoutProductMobileProps) => {
    const {item, useDivider, sx} = props;


    return (
        <Box
            sx={{
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                }}
            >
                <ProductImage
                    src={TakeFirst2DImage(item.product.image)}
                    sx={{marginRight: "20px"}}
                />

                <ProductItemDetails item={item} withQuantity={true}/>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "start",
                    width: "100%",
                    marginY: "16px",
                }}
            >
                <Box
                    sx={{
                        marginRight: "20px",
                        width: "100%",
                        maxWidth: "120px",
                    }}
                >
                    <DiscountInfo discounts={item.discounts}/>
                </Box>


                <MobileOrderItemPriceRow item={item}/>

            </Box>

            {useDivider && (
                <Divider
                    sx={{
                        backgroundColor: primaryColor.productTableDividerColor,
                        marginBottom: "16px",
                    }}
                />
            )}
        </Box>
    );
};

export default CheckoutProductMobile;
