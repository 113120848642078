import {useAppDispatch} from "../../../../hook";
import {useLocation, useNavigate} from "react-router";
import {primaryColor} from "../../../../theme";
import SearchBox from "../../searchBox";
import {getQuickSearchOptions, openSearchBox} from "../../../../reducers/quickSearch";
import {SearchSuggestionElement} from "../../../../interfaces/backend";
import keycode from "keycode";
import qs from 'qs';
import {useTranslation} from "react-i18next";
import {forwardRef} from "react";

const Search = forwardRef(() => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const {t} = useTranslation('common')

    const handleInputChange = (searchValue: string) => dispatch(getQuickSearchOptions({searchValue: searchValue}));

    const handleOnSelect = (item: SearchSuggestionElement) => {
        dispatch(openSearchBox(false))
        navigate(`/product/${item.alias}`);
    }

    const handleKeyDown = (event: KeyboardEvent, inputValue: string | null) => {
        if (keycode(event) !== 'enter' || !inputValue || inputValue.length < 3) return;

        const search = qs.parse(location.search.replace('?', ''))
        // If currently on search page, we need to change one search parameter
        const newSearch =
            location.pathname === '/search'
                ? {...search, searchValue: inputValue, page: '1'}
                : {searchValue: inputValue}

        navigate({
            pathname: '/search',
            search: `?${qs.stringify(newSearch)}`,
        });
    }


    return (
        <SearchBox
            onInputChange={handleInputChange}
            onSelect={handleOnSelect}
            onKeyDown={handleKeyDown}
            showClearIcon={true}
            styleRoot={{
                paddingX: {xs: '45px', md: 'unset'},
                marginTop: {md: '270px'},
            }}
            placeholder={t('search') as string}
            styledPaper={{
                backgroundColor: {xs: 'transparent', md: primaryColor.drawerBackground},
                marginTop: {xs: '25px', md: '20px'},
                maxHeight: {xs: '90vh', md: '400px'},
                '& .MuiTypography-root': {
                    paddingX: {xs: '25px', md: '15px'},
                }
            }}
        />
    )
})

export default Search;