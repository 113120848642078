import {MenuItem, MenuList, Typography} from "@mui/material";
import {primaryColor} from "../../../../theme";
import {lngList} from "../../../../utils/localization";
import {useTranslation} from "react-i18next";

const SelectLanguageMobile = () => {
    const {i18n, t} = useTranslation("common");

    return (
        <MenuList
            sx={{
                backgroundColor: primaryColor.drawerBackground,
                color: primaryColor.drawerText,
                marginTop: '-3px',
                paddingY: 0
            }}
        >
            {lngList().map(key =>
                <MenuItem
                    key={key}
                    value={key}
                    sx={{
                        paddingY: "2.5px",
                        justifyContent: "center"
                    }}
                    onClick={async () => {
                        const newLanguage = key
                        await i18n.changeLanguage(newLanguage).then(() => {
                            localStorage.setItem("i18nextLng", newLanguage);
                            window.location.reload();
                        });
                    }}
                >
                    <Typography variant={'h6'}>
                        {t("language" + key)}
                    </Typography>
                </MenuItem>
            )}
        </MenuList>
    );
};

export default SelectLanguageMobile;