import {useEffect, useRef, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import Draggable, {DraggableBounds} from "react-draggable";
import MenuContent from './MenuContent';
import theme from '../../../theme';
import MobileMenuContent from './MenuContent/mobile';

interface ContextMenuProps {
    viewerContainerRef: React.RefObject<HTMLDivElement>;
}

const ContextMenu = (props: ContextMenuProps) => {
    const {viewerContainerRef} = props;
    const boxRef = useRef<HTMLDivElement>(null);
    const boxWidth = 425;
    const boxHeight = 260;
    const smView = useMediaQuery(theme.breakpoints.down('sm'));

    const [bounds, setBounds] = useState<DraggableBounds>({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
    });

    useEffect(() => {
        if (boxRef.current && viewerContainerRef.current) {

            const containerRect = viewerContainerRef.current.getBoundingClientRect();
            const elementRect = boxRef.current.getBoundingClientRect();

            setBounds({
                left: containerRect.left - elementRect.left,
                top: containerRect.top - elementRect.top,
                right: containerRect.right - elementRect.right,
                bottom: containerRect.bottom - elementRect.bottom - boxHeight * 0.2,
            });

        }
    }, [boxRef.current, viewerContainerRef, boxWidth]);

    return (
        <>
            {!smView ?
                <Draggable bounds={bounds} nodeRef={boxRef} cancel=".clickable-element">
                    <Box
                        ref={boxRef}
                        sx={{
                            position: 'absolute',
                            height: boxHeight,
                            width: boxWidth,
                            zIndex: 9999,
                            cursor: 'move',
                        }}
                    >
                        <MenuContent/>
                    </Box>
                </Draggable>
                :
                <MobileMenuContent/>
            }
        </>
    );
};

export default ContextMenu;